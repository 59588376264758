<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [
    AccountRole.INSTRUCTOR,
    AccountRole.DEPARTMENT_ADMIN,
    AccountRole.INSTITUTION_ADMIN,
    AccountRole.GIGXR_ADMIN,
  ];
</script>

<script>
  import { onMount } from 'svelte';
  import {
    role,
    institutionIdStore,
    title,
    errorMessage,
    breadcrumbPaths,
    snackbar,
    snackbarMessage,
  } from '../../stores/core-store';
  import Button from '@smui/button';
  import List, {
    Group,
    Item,
    Graphic,
    Meta,
    Label,
    Separator,
    Subheader,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import { authenticatedGet, authenticatedPost } from '../../util/api';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import Checkbox from '@smui/checkbox';
  import { fetchDepartments } from '../../util/api/departments';
  import { fetchClasses } from '../../util/api/classes';
  import { addAccount } from '../../util/api/accounts';
  import KeyValueRow from '../../components/KeyValueRow.svelte';
  import { v4 as uuidv4 } from 'uuid';
  import { Logger } from '../../util/logs';
  import HelperText from '@smui/textfield/helper-text/index';
  import LoadingView from '../../components/LoadingView.svelte';
  import Typeahead from '../../components/Typeahead.svelte';
  import IconButton, { Icon } from '@smui/icon-button';
  import { idArraysEqual } from '../../util/api/array';
  import { setAuthorizedRoles } from '../../util/authorization';
  import AccountRoleSelect from '../../components/accounts/AccountRoleSelect.svelte';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Add User');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'User Management',
      location: '/users/list',
    },
    {
      name: 'Add User',
      location: `/users/add`,
    },
  ]);

  let loading = true;
  let departmentsById = {};
  let classesById = {};

  let availableClasses = {};
  let query = '';
  let form;
  let discardChangesDialog;

  let lastSavedAccount = {
    firstName: '',
    lastName: '',
    email: '',
    departmentIds: [],
    classIds: [],
    accountRole: 'Student',
    registrationStatus: 'Added',
    isActive: true,
  };

  let account = { ...lastSavedAccount };

  let formUnmodified = false;
  let formUnmodifiedOrInvalid = false;
  $: {
    formUnmodified =
      account &&
      account.firstName === lastSavedAccount.firstName &&
      account.lastName === lastSavedAccount.lastName &&
      account.email === lastSavedAccount.email &&
      account.accountRole === lastSavedAccount.accountRole &&
      String(account.isActive) === String(lastSavedAccount.isActive) &&
      idArraysEqual(account.departmentIds, lastSavedAccount.departmentIds) &&
      idArraysEqual(account.classIds, lastSavedAccount.classIds);
  }
  $: {
    account;
    formUnmodifiedOrInvalid =
      formUnmodified ||
      !form.checkValidity() ||
      // Only GIGXR and Institution admins may add accounts without a department.
      (account.departmentIds.length === 0 &&
        $role !== AccountRole.GIGXR_ADMIN &&
        $role !== AccountRole.INSTITUTION_ADMIN);
  }

  $: {
    availableClasses = { ...classesById };
    account.classIds.forEach((id) => delete availableClasses[id]);

    Object.keys(availableClasses).forEach((id) => {
      if (!account.departmentIds.includes(classesById[id].departmentId)) {
        delete availableClasses[id];
      }
    });

    availableClasses = availableClasses;
  }

  onMount(async () => {
    let departments;
    let classes;
    [departments, classes] = await Promise.all([fetchDepartments(), fetchClasses()]);

    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));

    availableClasses = { ...classesById };
    loadAccountFromLastSaved();
    loading = false;
  });

  async function addAndInviteAccount(event) {
    event.preventDefault();
    account.registrationStatus = 'Invited';
    await addAccountHandler(account);
  }

  async function addAccountHandler(event) {
    if (!form.reportValidity()) {
      return;
    }

    Logger.log('Adding user: ', account);
    const newAccount = await addAccount(account);
    snackbarMessage.set('User added!');
    $snackbar.open();
    navigate(`/users/view/${newAccount.accountId}`);
  }

  function loadAccountFromLastSaved(event) {
    account = {
      ...lastSavedAccount,
      departmentIds: [...lastSavedAccount.departmentIds],
      classIds: [...lastSavedAccount.classIds],
    };
  }

  function addClassToListHandler(clazz) {
    account.classIds = [...account.classIds, clazz.classId];

    delete availableClasses[clazz.classId];
    availableClasses = availableClasses;
    query = '';
  }

  function removeClassFromList(classId) {
    account.classIds = account.classIds.filter((id) => {
      if (id === classId) {
        availableClasses[id] = classesById[id];
        return false;
      }
      return true;
    });
  }

  function departmentHelperText(classCount) {
    if (classCount === 1) {
      return '1 class';
    }
    return `${classCount} classes`;
  }

  function classHelperText(classId) {
    const clazz = classesById[classId];
    if (!clazz) {
      return '';
    }

    let instructorFullName = '–';
    if (clazz.instructor) {
      instructorFullName = `${clazz.instructor.firstName} ${clazz.instructor.lastName}`;
    }

    return `Instructor: ${instructorFullName}`;
  }
</script>

<PrimaryContent>
  <h2>New User</h2>
</PrimaryContent>

<SecondaryBackgroundWrapper>
  {#if loading}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <form bind:this={form}>
        <ContentRow>All fields required</ContentRow>
        <TwoColumnSection>
          <div slot="left" class="add-account-left">
            <ContentRow>
              <Textfield
                input$id="first-name-field"
                class="gigxr-input"
                bind:value={account.firstName}
                variant="filled"
                label="First Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="last-name-field"
                class="gigxr-input"
                bind:value={account.lastName}
                variant="filled"
                label="Last Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="email-name-field"
                type="email"
                class="gigxr-input"
                bind:value={account.email}
                variant="filled"
                label="Email"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <h3 class="gigxr-list-header">Departments</h3>
              {#if !departmentsById || Object.keys(departmentsById).length === 0}
                <p>There are no departments to display.</p>
              {:else}
                <List class="gigxr-list" checklist twoLine>
                  {#each Object.values(departmentsById) as department (department.departmentId)}
                    <Item inputId="department-checkbox-{department.departmentId}" title={department.description}>
                      <Graphic>
                        <Checkbox bind:group={account.departmentIds} value={department.departmentId} />
                      </Graphic>
                      <Text>
                        <PrimaryText>{department.departmentName}</PrimaryText>
                        <SecondaryText>{departmentHelperText(department.classCount)}</SecondaryText>
                      </Text>
                    </Item>
                  {/each}
                </List>
              {/if}
            </ContentRow>

            <ContentRow>
              <h3 class="gigxr-list-header">Classes</h3>
              {#if !account.classIds || account.classIds.length === 0}
                <p>There are no classes to display.</p>
              {:else}
                <List class="gigxr-list" twoLine nonInteractive>
                  {#each account.classIds as id (id)}
                    <Item class="gigxr-list__item" title={classesById[id].description}>
                      <Text>
                        <PrimaryText>{classesById[id].className}</PrimaryText>
                        <SecondaryText>{classHelperText(id)}</SecondaryText>
                      </Text>
                      <Meta>
                        <IconButton
                          id="remove-class-button-{id}"
                          type="button"
                          class="material-icons"
                          on:click={() => removeClassFromList(id)}
                        >
                          close
                        </IconButton>
                      </Meta>
                    </Item>
                  {/each}
                </List>
              {/if}

              <Typeahead
                items={Object.values(availableClasses)}
                bind:query
                valueFunction={(clazz) => clazz.className}
                onSelectFunction={(clazz) => addClassToListHandler(clazz)}
                label="Enter Class Name "
              />
            </ContentRow>
          </div>

          <div slot="right">
            <AccountRoleSelect type="add" bind:account />

            <ContentRow>
              <Select
                inputId="user-active-field"
                class="gigxr-input"
                bind:value={account.isActive}
                variant="filled"
                label="Active"
                enhanced
              >
                <Option value={false}>Inactive</Option>
                <Option value={true}>Active</Option>
              </Select>
            </ContentRow>

            <KeyValueRow>
              <div slot="left">Registration:</div>
              <div slot="right">Not Added</div>
            </KeyValueRow>

            <DateAccountComponent label="Added" utcDateString={null} account={null} show_account={false} />

            <DateAccountComponent label="Last Active" utcDateString={null} account={null} show_account={false} />

            <ContentRowCenteredOnMobile>
              <Button
                id="add-user-and-invite-button"
                class="gigxr-button"
                variant="unelevated"
                on:click={addAndInviteAccount}
                disabled={formUnmodifiedOrInvalid}
              >
                <Label>Add and Invite</Label>
              </Button>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                class="gigxr-link {formUnmodified ? 'gigxr-link--disabled' : ''}"
                on:click={() => discardChangesDialog.open()}
              >
                Discard Changes
              </a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                class="gigxr-link {formUnmodifiedOrInvalid ? 'gigxr-link--disabled' : ''}"
                on:click={addAccountHandler}
              >
                Add Without Inviting
              </a>
            </ContentRowCenteredOnMobile>
          </div>
        </TwoColumnSection>
      </form>
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => loadAccountFromLastSaved()} />

<style>
  .add-account-left {
    margin-bottom: 2em;
  }

  @media (min-width: 1100px) {
    .add-account-left {
      margin-bottom: 0;
    }
  }
</style>
