function UnhandledRejectionOverlay(eventId) {
  const div = document.createElement('div');
  div.classList.add('unhandled-rejection-overlay');
  div.innerHTML = `
    <h1>Oops! Something went wrong.</h1>
    <p>Please try reloading the site.</p>
    <p>Exception tracking ID: ${eventId}</p>
    <button class="mdc-button mdc-button--unelevated" onclick="location.href='/'">
      <div class="mdc-button__ripple"></div>
      <span class="mdc-button__label">Reload Site</span>
    </button>
  `;

  return div;
}

export default UnhandledRejectionOverlay;
