<script>
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import { institutionMasquerade } from '../../stores/core-store';
  import GigXrListAnchorItem from '../GigXrListAnchorItem.svelte';
  import GigXrMenu from '../GigXrMenu.svelte';
  import { encodeMasqueradeData } from '../../util/masquerade';

  export let institution;

  let menu;
  let open;

  $: masqueradeLink = `/?m=${encodeMasqueradeData(institution)}`;

  function toggleMenu(event) {
    menu.setOpen(!open);
  }
</script>

<IconButton id="institution-menu-button-{institution.institutionId}" class="material-icons" on:click={toggleMenu}>
  more_vert
</IconButton>

<GigXrMenu bind:open bind:menu anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
  <List>
    <Item
      id="institution-menu-view-{institution.institutionId}"
      on:SMUI:action={() => navigate(`/institutions/view/${institution.institutionId}`)}
    >
      <Text>View</Text>
    </Item>
    <Item
      id="institution-menu-edit-{institution.institutionId}"
      on:SMUI:action={() => navigate(`/institutions/edit/${institution.institutionId}`)}
    >
      <Text>Edit</Text>
    </Item>
    {#if institution.institutionConfig && institution.institutionConfig.allowGigXrAccess}
      <GigXrListAnchorItem id="login-as-admin-link" href={masqueradeLink} target="_blank">
        <Text>Login as Admin</Text>
      </GigXrListAnchorItem>
    {/if}
  </List>
</GigXrMenu>

<style>
</style>
