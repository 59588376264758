<script>
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';

  function returnToSiteHandler(event) {
    if (window.opener) {
      window.close();
      return;
    }
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    <UnauthenticatedPageHeader>Terms of Use Agreement</UnauthenticatedPageHeader>

    <UnauthenticatedPageSubheader><strong>Last Updated Date: September 10 2020</strong></UnauthenticatedPageSubheader>

    <div class="terms-of-use">
      <p>
        PLEASE READ THIS TERMS OF USE AGREEMENT (THE
        <strong>“TERMS OF USE”</strong>) CAREFULLY. THE WEBSITES (“<strong>WEBSITES</strong>”) AND MOBILE AND OTHER
        DEVICE APPLICATIONS (THE “<strong>APPLICATIONS”</strong>) OF GIGXR Inc (“<strong>GIGXR,</strong>” “<strong
        >WE</strong>,” OR “<strong>OUR</strong>”), AND THE INFORMATION ON IT ARE CONTROLLED BY GIGXR. THESE TERMS OF USE
        GOVERN THE USE OF THE WEBSITES AND APPLICATIONS AND APPLY TO ALL USERS USING THE WEBSITE OR APPLICATIONS IN ANY
        WAY, INCLUDING USING THE SERVICES AND RESOURCES AVAILABLE OR ENABLED VIA THE WEBSITE OR APPLICATIONS (EACH A
        <strong>“SERVICE”</strong>
        AND COLLECTIVELY, THE
        <strong>“SERVICES”</strong>). BY CLICKING ON THE “I ACCEPT” BUTTON, COMPLETING THE REGISTRATION PROCESS, AND/OR
        BROWSING THE WEBSITE OR DOWNLOADING AN APPLICATION, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE
        TO BE BOUND BY THE TERMS OF USE, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH GIGXR, AND (3) YOU
        HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY OR ON BEHALF OF THE ENTITY YOU HAVE NAMED AS THE
        USER, AND TO BIND THAT ENTITY TO THE TERMS OF USE. THE TERM
        <strong>“YOU”</strong>
        REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE USER WHEN YOU REGISTERED ON THE
        WEBSITE OR AN APPLICATION. IF YOU ARE USING THE SERVICES ON BEHALF OF AN ENTITY THAT HAS ENTERED INTO A SEPARATE
        AGREEMENT WITH GIGXR IN CONNECTION WITH SUCH SERVICES (a “<strong>CUSTOMER CONTRACT</strong>”), YOUR USE OF THE
        SERVICES IS ALSO GOVERNED BY THAT CUSTOMER CONTRACT, AND, IN THE EVENT OF ANY CONFLICTS BETWEEN THIS AGREEMENT
        AND THE CUSTOMER CONTRACT, THE CUSTOMER CONTRACT SHALL PREVAIL.
        <strong>IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY NOT ACCESS OR USE THE WEBSITES,
          APPLICATIONS OR THE SERVICES</strong>.
      </p>

      <p>
        <strong>ANY DISPUTE, CLAIM OR REQUEST FOR RELIEF RELATING IN ANY WAY TO YOUR USE OF THE SITE WILL BE GOVERNED
          AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT
          PROVIDE FOR THE APPLICATION OF THE LAW OF ANY OTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS
          FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM THIS AGREEMENT.</strong>
      </p>

      <p>
        Your use of, and participation in, certain Services may be subject to additional terms (<strong>“Supplemental
          Terms”</strong>) and such Supplemental Terms will either be listed in the Terms of Use or will be presented to
        you for your acceptance when you sign up to use the supplemental Service. If the Terms of Use are inconsistent
        with the Supplemental Terms, the Supplemental Terms shall control with respect to such Service. The Terms of Use
        and any applicable Supplemental Terms are referred to herein as the
        <strong>“Agreement.”</strong>
      </p>

      <p>You should print a copy of these terms or save them to your computer for future reference.</p>

      <p>
        PLEASE NOTE THAT THE AGREEMENT IS SUBJECT TO CHANGE BY GIGXR IN ITS SOLE DISCRETION AT ANY TIME. When changes
        are made, GIGXR will make a new copy of the Terms of Use available at the Website and within the Application and
        any new Supplemental Terms will be made available from within, or through, the affected Service on the Website
        or within the Application. We will also update the “Last Updated” date at the top of the Terms of Use. If we
        make any material changes, and you have registered with us to create an Account (as defined in Section 2.1
        (Registering Your Account) below) we will also send an e-mail to you at the last e-mail address you provided to
        us pursuant to the Agreement. Any changes to the Agreement will be effective immediately for new users of the
        Website, the Application and/ or Services and will be effective thirty (30) days after posting notice of such
        changes on the Website or within the Application for existing Registered Users, provided that any material
        changes shall be effective for Registered Users who have an Account with us upon the earlier of thirty (30) days
        after posting notice of such changes on the Website or within the Application or thirty (30) days after dispatch
        of an e-mail notice of such changes to Registered Users (defined in Section 2.1 (Registering Your Account)
        below). GIGXR may require you to provide consent to the updated Agreement in a specified manner before further
        use of the Website, Application and/ or the Services is permitted. If you do not agree to any change(s) after
        receiving a notice of such change(s), you shall stop using the Website, Application and/or the Services.
        Otherwise, your continued use of the Website, Application and/or Services constitutes your acceptance of such
        change(s). PLEASE REGULARLY CHECK THE WEBSITE AND APPLICATION TO VIEW THE THEN-CURRENT TERMS.
      </p>

      <ol type="1">
        <li>
          <p><strong>USE OF THE SERVICES AND GIGXR PROPERTIES.</strong></p>
          <ol>
            <li>
              <p>
                <strong>Generally</strong>. GIGXR designs mobile applications that can be integrated with certain
                hardware and devices to be used by teachers and students, such as wearable headsets (“<strong
                >Devices</strong>”), in order to provide 3D learning experiences.
                <span class="smallcaps">Please note that the Services are not intended for use in, and should not be
                  used for, medical diagnosis or treatment purposes.</span>
              </p>
            </li>
            <li>
              <p>
                <strong>GIGXR Properties.</strong>
                The Websites, Applications, the Services, and the information and content available in the Websites,
                Applications and the Services (as these terms are defined herein) (each, a “<strong>GIGXR Property</strong>”
                and collectively, the
                <strong>“GIGXR Properties”</strong>) are protected by copyright laws throughout the world.
              </p>
            </li>
            <li>
              <p>
                <strong>Application License.</strong>
                Subject to your compliance with the Agreement, GIGXR grants you a limited, non-exclusive,
                non-transferable, non-sublicensable, revocable license to download, install and use a copy of the
                Application on a single mobile device, computer, or Device that you own or control and to run such copy
                of the Application solely for your own personal or internal business purposes. Furthermore, with respect
                to any Application accessed through or downloaded from the Apple App Store (an
                <strong>“App Store Sourced Application”</strong>), you will only use the App Store Sourced Application
                (a) on an Apple-branded product that runs the iOS (Apple’s proprietary operating system) and (b) as
                permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service. Notwithstanding the
                first sentence in this section, with respect to any Application accessed through or downloaded from the
                Google Play store (a “<strong>Google Play Sourced Application</strong>”), you may have additional
                license rights with respect to use of the Application on a shared basis within your designated family
                group.
              </p>
            </li>
            <li>
              <p>
                <strong>Updates.</strong>
                You understand that GIGXR Properties are evolving. As a result, GIGXR may require you to accept updates
                to GIGXR Properties that you have installed on your Device, computer, or mobile device. You acknowledge
                and agree that GIGXR may update GIGXR Properties with or without notifying you. You may need to update
                third-party software from time to time in order to use GIGXR Properties.
              </p>
            </li>
            <li>
              <p>
                <strong>Certain Restrictions.</strong>
                The rights granted to you in the Agreement are subject to the following restrictions: : (a) you shall
                not use the GIGXR Properties to harvest, collect, gather or assemble information or data regarding other
                GIGXR customers without their consent; (b) you shall not access or copy any data or information of other
                GIGXR customers without their consent; (c) you shall not knowingly interfere with or disrupt the
                integrity or performance of the GIGXR Properties, or the data contained therein; (d) harass or interfere
                with another GIGXR customer’s use and enjoyment of the GIGXR Properties; (f) reverse engineer,
                disassemble or decompile any component of the GIGXR Properties except to the extent the foregoing
                restrictions are expressly prohibited by applicable law; (g) interfere in any manner with the operation
                of the GIGXR Properties; (h) modify, copy, translate, adapt, merge, or make derivative works based on
                any part of the GIGXR Properties; (i) you shall not license, sell, rent, lease, transfer, assign,
                reproduce, distribute, host or otherwise commercially exploit GIGXR Properties or any portion of GIGXR
                Properties; (j) you shall not access GIGXR Properties in order to build a similar or competitive
                website, application or service; (k) except as expressly stated herein, no part of GIGXR Properties may
                be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any
                form or by any means; (l) you shall not remove or destroy any copyright notices or other proprietary
                markings contained on or in GIGXR Properties; (m) you shall not frame or utilize framing techniques to
                enclose any trademark, logo, or other GIGXR Properties (including images, text, page layout or form) of
                GIGXR; (n) you shall not use any manual or automated software, devices or other processes (including but
                not limited to spiders, robots, scrapers, crawlers, avatars, data mining tools or the like) to “scrape”
                or download data from any web pages contained in the Websites (except that we grant the operators of
                public search engines revocable permission to use spiders to copy materials from the Websites for the
                sole purpose of and solely to the extent necessary for creating publicly available searchable indices of
                the materials, but not caches or archives of such materials); or (o) otherwise use the GIGXR Properties
                in any manner that exceeds the scope of use permitted under this Agreement. Any future release, update
                or other addition to GIGXR Properties shall be subject to the Agreement. GIGXR, its suppliers and
                service providers reserve all rights not granted in the Agreement. Any unauthorized use of any GIGXR
                Property terminates the licenses granted by GIGXR pursuant to the Agreement.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <p><strong>REGISTRATION.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>Registering Your Account.</strong>
                In order to access certain features of GIGXR Properties you may be required to become a Registered User.
                For purposes of the Agreement, a
                <strong>“Registered User”</strong>
                or
                <strong>“User”</strong>
                is a user who has registered an account on the Application (<strong>“Account”</strong>). All Registered
                Users must be authorized by a current customer of GIGXR.
              </p>
            </li>
            <li>
              <p>
                <strong>Registration Data.</strong>
                In registering an account on the Website or an Application, you agree to (a) provide true, accurate,
                current and complete information about yourself as prompted by the registration form (the
                <strong>“Registration Data”</strong>); and (b) maintain and promptly update the Registration Data to
                keep it true, accurate, current and complete. You represent that you are (m) at least thirteen (13)
                years old, (n) of legal age to form a binding contract; and (o) not a person barred from using GIGXR
                Properties under the laws of the United States, your place of residence or any other applicable
                jurisdiction. You are responsible for all activities that occur under your Account. You agree that you
                shall monitor your Account to restrict use by minors, and you will accept full responsibility for any
                unauthorized use of the GIGXR Properties by minors. You may not share your Account or password with
                anyone, and you agree to (y) notify GIGXR immediately of any unauthorized use of your password or any
                other breach of security; and (z) exit from your Account at the end of each session. If you provide any
                information that is untrue, inaccurate, not current or incomplete, or GIGXR has reasonable grounds to
                suspect that any information you provide is untrue, inaccurate, not current or incomplete, GIGXR has the
                right to suspend or terminate your Account and refuse any and all current or future use of GIGXR
                Properties (or any portion thereof). You agree not to create an Account using a false identity or
                information, or on behalf of someone other than yourself. GIGXR reserves the right to remove or reclaim
                any usernames at any time and for any reason, including but not limited to, claims by a third party that
                a username violates the third party’s rights. You agree not to create an Account or use GIGXR Properties
                if you have been previously removed by GIGXR, or if you have been previously banned from any of GIGXR
                Properties.
              </p>
            </li>
            <li>
              <p>
                <strong>Your Account.</strong>
                Notwithstanding anything to the contrary herein, you acknowledge and agree that you shall have no
                ownership or other property interest in your Account, and you further acknowledge and agree that all
                rights in and to your Account are and shall forever be owned by and inure to the benefit of GIGXR.
              </p>
            </li>
            <li>
              <p>
                <strong>Necessary Equipment and Software.</strong>
                You must provide all equipment and software necessary to connect to GIGXR Properties, including but not
                limited to, a mobile device or other Device that is suitable to connect with and use GIGXR Properties,
                in cases where the Services offer a mobile or other hardware component. You are solely responsible for
                any fees, including Internet connection or mobile fees, that you incur when accessing GIGXR Properties.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>OWNERSHIP.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>GIGXR Properties.</strong>
                You agree that GIGXR and its suppliers own all rights, title and interest in GIGXR Properties. You will
                not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices
                incorporated in or accompanying any GIGXR Properties.
              </p>
            </li>
            <li>
              <p>
                <strong>Trademarks.</strong>
                <img
                  src="https://system.gigxr.com/assets/gigxr-logo.svg"
                  style="width:0.52117in;height:0.27826in"
                  alt="GIGXR logo"
                />
                and all related graphics, logos, service marks and trade names used on or in connection with any GIGXR
                Properties or in connection with the Services are the trademarks of GIGXR and may not be used without
                permission in connection with your, or any third-party, products or services. Other trademarks, service
                marks and trade names that may appear on or in GIGXR Properties are the property of their respective
                owners.
              </p>
            </li>
            <li>
              <p>
                <strong>Username.</strong>
                Notwithstanding anything contained herein to the contrary, by using or accessing the GIGXR Properties,
                you hereby expressly permit GIGXR to identify you by your username (which may be a pseudonym) on the
                GIGXR Properties to other Registered Users.
              </p>
            </li>
            <li>
              <p>
                <strong>Feedback.</strong>
                You agree that submission of any ideas, suggestions, documents, and/or proposals to GIGXR through its
                suggestion, feedback, wiki, forum or similar pages (<strong>“Feedback”</strong>) is at your own risk and
                that GIGXR has no obligations (including without limitation obligations of confidentiality) with respect
                to such Feedback. You represent and warrant that you have all rights necessary to submit the Feedback.
                You hereby grant to GIGXR a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive,
                and fully sublicensable right and license to use, reproduce, perform, display, distribute, adapt,
                modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in
                any manner, any and all Feedback, and to sublicense the foregoing rights, in connection with the
                operation and maintenance of GIGXR Properties and/or GIGXR’s business.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>USER CONDUCT.</strong> In connection with your use of GIGXR Properties, you shall not:</p>
          <ol>
            <li>
              <p>
                Take any action that, in GIGXR’s sole discretion, (i) is unlawful, tortious, defamatory, vulgar,
                obscene, libelous, or racially, ethnically or otherwise objectionable; (ii) violates, or encourages any
                conduct that would violate, any applicable law or regulation or would give rise to civil liability;
                (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or
                group; (iv) is violent or threatening, or promotes violence or actions that are threatening to any other
                person; or (v) promotes illegal or harmful activities;
              </p>
            </li>
            <li>
              <p>
                Impersonate any person or entity, including, but not limited to, GIGXR personnel, or falsely state or
                otherwise misrepresent your affiliation with a person or entity;
              </p>
            </li>
            <li>
              <p>
                Intentionally or unintentionally violate any applicable local, state, national or international law or
                regulation, or any order of a court;
              </p>
            </li>
            <li>
              <p>
                Register for more than one Account or register for an Account on behalf of an individual other than
                yourself;
              </p>
            </li>
            <li>
              <p>Stalk or otherwise harass any other user of our GIGXR Properties;</p>
            </li>
            <li>
              <p>
                Interfere with or attempt to interfere with the proper functioning of GGIXR Properties or use GIGXR
                Properties in any way not expressly permitted by this Agreement;
              </p>
            </li>
            <li>
              <p>
                Attempt to engage in or engage in, any potentially harmful acts that are directed against GGIXR
                Properties, including but not limited to violating or attempting to violate any security features of
                GIGXR Properties, using manual or automated software or other means to access, “scrape,” “crawl” or
                “spider” any pages contained in GIGXR Properties, introducing viruses, worms, or similar harmful code
                into GIGXR Properties, or interfering or attempting to interfere with use of GIGXR Properties by any
                other user, host or network, including by means of overloading, “flooding,” “spamming,” “mail bombing,”
                or “crashing” GIGXR Properties; or
              </p>
            </li>
            <li>
              <p>
                Advocate, encourage or assist any third party in doing any of the foregoing activities in this section.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>INVESTIGATIONS.</strong>
            GIGXR may, but is not obligated to, monitor or review GIGXR Properties at any time. Although GIGXR does not
            generally monitor user activity occurring in connection with GIGXR Properties, if GIGXR becomes aware of any
            possible violations by you of any provision of the Agreement, GIGXR reserves the right to investigate such
            violations, and GIGXR may, at its sole discretion, immediately terminate your license to use GIGXR
            Properties without prior notice to you.
          </p>
        </li>
        <li>
          <p><strong>INTERACTIONS WITH OTHER USERS.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>User Responsibility.</strong>
                You are solely responsible for your interactions with other Registered Users and any other parties with
                whom you interact; provided, however, that GIGXR reserves the right, but has no obligation, to intercede
                in such disputes. You agree that GIGXR will not be responsible for any liability incurred as the result
                of such interactions.
              </p>
            </li>
            <li>
              <p>
                <strong>Information Provided by Other Users.</strong>
                GIGXR is not responsible for and does not control the actions of or statements and information provided
                by other Users. GIGXR has no obligation to review or monitor, and does not approve, endorse or make any
                representations or warranties with respect to actions, statements or other information. You use all such
                information and interact with other Registered Users at your own risk.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>Indemnification.</strong>
            You agree to indemnify and hold GIGXR, its parents, subsidiaries, affiliates, officers, employees, agents,
            partners, suppliers, and licensors (each, a “<strong>GIGXR Party</strong>” and collectively, the
            <strong>“GIGXR Parties”</strong>) harmless from any losses, costs, liabilities and expenses (including
            reasonable attorneys’ fees) relating to or arising out of any and all of the following: (a) your use of, or
            inability to use, any GIGXR Property; (b) your violation of the Agreement; (c) your violation of any rights
            of another party, including any Registered Users; or (d) your violation of any applicable laws, rules or
            regulations. GIGXR reserves the right, at its own cost, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, in which event you will fully cooperate with GIGXR in
            asserting any available defenses. This provision does not require you to indemnify any of the GIGXR Parties
            for any unconscionable commercial practice by such party or for such party’s fraud, deception, false
            promise, misrepresentation or concealment, suppression or omission of any material fact in connection with
            the Websites, Applications or any Services provided hereunder. You agree that the provisions in this section
            will survive any termination of your Account, the Agreement and/or your access to GIGXR Properties.
          </p>
        </li>
        <li>
          <p><strong>DISCLAIMER OF WARRANTIES AND CONDITIONS.</strong></p>
          <ol>
            <li>
              <p>
                <strong>As Is.</strong>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF GIGXR
                PROPERTIES IS AT YOUR SOLE RISK, AND GIGXR PROPERTIES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                BASIS, WITH ALL FAULTS. GIGXR PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS
                OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF
                THE GIGXR PROPERTIES.
              </p>
              <ol type="a">
                <li>
                  <p>
                    GIGXR PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT: (1) GIGXR PROPERTIES WILL MEET
                    YOUR REQUIREMENTS; (2) YOUR USE OF GIGXR PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                    ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF GIGXR PROPERTIES WILL BE ACCURATE OR
                    RELIABLE.
                  </p>
                </li>
                <li>
                  <p>
                    ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH GIGXR PROPERTIES IS ACCESSED AT YOUR OWN
                    RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT
                    LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS GIGXR PROPERTIES, OR ANY OTHER
                    LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT.
                  </p>
                </li>
                <li>
                  <p>
                    THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER DISRUPTIONS. GIGXR MAKES NO WARRANTY,
                    REPRESENTATION OR CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE QUALITY,
                    EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF SERVICES.
                  </p>
                </li>
                <li>
                  <p>
                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM GIGXR OR THROUGH GIGXR PROPERTIES
                    WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                  </p>
                </li>
                <li>
                  <p>
                    FROM TIME TO TIME, GIGXR MAY OFFER NEW “BETA” FEATURES OR TOOLS WITH WHICH ITS USERS MAY EXPERIMENT.
                    SUCH FEATURES OR TOOLS ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF ANY
                    KIND, AND MAY BE MODIFIED OR DISCONTINUED AT GIGXR’S SOLE DISCRETION. THE PROVISIONS OF THIS SECTION
                    APPLY WITH FULL FORCE TO SUCH FEATURES OR TOOLS.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                <strong>No Liability for Conduct of Third Parties.</strong>
                YOU ACKNOWLEDGE AND AGREE THAT GIGXR PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD GIGXR
                PARTIES LIABLE, FOR THE CONDUCT OR OMISSIONS OF THIRD PARTIES, INCLUDING THE ACTIONS OF AN EDUCATIONAL
                INSTITUTION OR REGISTERED USER OF THE GIGXR PROPERTIES, AND THAT THE RISK OF INJURY FROM SUCH THIRD
                PARTIES RESTS ENTIRELY WITH YOU.
              </p>
            </li>
            <li>
              <p>
                <strong>Medical Advice Disclaimer.</strong>
                THE SERVICES ARE DESIGNED FOR EDUCATIONAL AND ENTERTAINMENT PURPOSES ONLY. NOTHING STATED OR POSTED ON
                THE WEBSITES OR APPLICATIONS OR MADE AVAILABLE THROUGH ANY SERVICES IS INTENDED TO BE, AND MUST NOT BE
                TAKEN TO BE, THE PRACTICE OF MEDICAL CARE. THE ACCURACY OF THE DATA COLLECTED AND PRESENTED THROUGH THE
                SERVICES IS NOT INTENDED TO MATCH THAT OF MEDICAL DEVICES OR SCIENTIFIC MEASUREMENT DEVICES. THE
                SERVICES ARE NOT INTENDED TO DIAGNOSE, TREAT, CURE, OR PREVENT ANY DISEASE, HEALTH CONDITION, OR
                ILLNESS. THE SERVICES ARE CONTINUALLY UNDER DEVELOPMENT AND YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT
                GIGXR IS NOT RESPONSIBLE OR LIABLE FOR AND GIGXR MAKES NO WARRANTY OF ANY KIND, IMPLIED OR EXPRESS, AS
                TO ITS ACCURACY, COMPLETENESS, APPROPRIATENESS, RELIABILITY, OR EFFECTIVENESS FOR ANY PURPOSE,
                INCLUDING, WITHOUT LIMITATION, MEDICAL DIAGNOSIS OR TREATMENT. DEVELOPMENTS IN MEDICAL RESEARCH MAY
                IMPACT THE MEDICAL INFORMATION THAT APPEARS ON THE WEBSITES, APPLICATIONS OR IN THE SERVICES. NO
                ASSURANCE CAN BE GIVEN THAT THE ADVICE CONTAINED IN THE GIGXR PROPERTIES WILL ALWAYS INCLUDE THE MOST
                RECENT FINDINGS OR DEVELOPMENTS WITH RESPECT TO THE PARTICULAR MATERIAL. DO NOT DISREGARD, AVOID, OR
                DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE FROM YOUR HEALTHCARE PROFESSIONAL BECAUSE OF
                INFORMATION AVAILABLE ON THE GIGXR PROPERTIES OR COMMUNICATED TO YOU THROUGH THE GIGXR PROPERTIES.
              </p>
            </li>
            <li>
              <p>
                <strong>No Liability for Conduct of Other Users.</strong>
                YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF GIGXR
                PROPERTIES. YOU UNDERSTAND THAT GIGXR DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF
                GIGXR PROPERTIES. GIGXR MAKES NO WARRANTY THAT THE GOODS OR SERVICES PROVIDED BY THIRD PARTIES WILL MEET
                YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. GIGXR MAKES NO
                WARRANTY REGARDING THE QUALITY OF ANY SUCH GOODS OR SERVICES, OR THE ACCURACY, TIMELINESS, TRUTHFULNESS,
                COMPLETENESS OR RELIABILITY OF ANY USER CONTENT OBTAINED THROUGH GIGXR PROPERTIES.
              </p>
            </li>
            <li>
              <p>
                <strong>Third-Party Materials.</strong>
                As a part of GIGXR Properties, you may have access to materials that are hosted by another party. You
                agree that it is impossible for GIGXR to monitor such materials and that you access these materials at
                your own risk.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>LIMITATION OF LIABILITY.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>Disclaimer of Certain Damages.</strong>
                YOU UNDERSTAND AND AGREETHAT, TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL GIGXR PARTIES BE
                LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
                DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, BUSINESS INTERRUPTION, PROCUREMENT OF
                SUBSTITUTE GOODS OR SERVICES, IN EACH CASE WHETHER OR NOT GIGXR HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT OR ANY COMMUNICATIONS, INTERACTIONS OR
                MEETINGS WITH OTHER USERS OF GIGXR PROPERTIES, ON ANY THEORY OF LIABILITY, RESULTING FROM: (1) THE USE
                OR INABILITY TO USE GIGXR PROPERTIES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES
                RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED FOR
                TRANSACTIONS ENTERED INTO THROUGH GIGXR PROPERTIES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON GIGXR PROPERTIES; OR (5) ANY
                OTHER MATTER RELATED TO GIGXR PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT
                (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY. GIGXR DOES NOT IN ANY WAY SEEK TO EXCLUDE OR LIMIT
                LIABILITY FOR (i) DEATH OR PERSONAL INJURY CAUSED BY GIGXR’S NEGLIGENCE; (ii) FRAUD OR FRAUDULENT
                MISREPRESENTATION; OR (iii) ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY ENGLISH OR EU LAW.
              </p>
            </li>
            <li>
              <p>
                <strong>Cap on Liability.</strong>
                TO THE FULLEST EXTENT PROVIDED BY LAW, GIGXR PARTIES WILL NOT BE LIABLE TO YOU FOR MORE THAN THE GREATER
                OF (a) $100 OR (b) THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE
                FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A GIGXR PARTY FOR (i) DEATH OR PERSONAL
                INJURY CAUSED BY A GIGXR PARTY’S NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A GIGXR PARTY’S FRAUD OR
                FRAUDULENT MISREPRESENTATION.
              </p>
            </li>
            <li>
              <p>
                <strong>Exclusion of Damages.</strong>
                CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
                TO YOU, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
                ADDITIONAL RIGHTS.
              </p>
            </li>
            <li>
              <p>
                <strong>Basis of the Bargain.</strong>
                THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
                GIGXR AND YOU.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>MONITORING AND ENFORCEMENT.</strong>
            GIGXR reserves the right to: (a) disclose your identity or other information about you to any third party
            who claims that material posted by you violates their rights, including their intellectual property rights
            or their right to privacy; (b) take appropriate legal action, including without limitation, referral to law
            enforcement, for any illegal or unauthorized use of the GIGXR Properties; and/or (c) terminate or suspend
            your access to all or part of the GIGXR Properties for any or no reason, including without limitation, any
            violation of this Agreement.
          </p>
          <p>
            If GIGXR becomes aware of any possible violations by you of the Agreement, GIGXR reserves the right to
            investigate such violations. If, as a result of the investigation, GIGXR believes that criminal activity has
            occurred, GIGXR reserves the right to refer the matter to, and to cooperate with, any and all applicable
            legal authorities. GIGXR is entitled, except to the extent prohibited by applicable law, to disclose any
            information or materials on or in GIGXR Properties in GIGXR’s possession in connection with your use of
            GIGXR Properties, to (i) comply with applicable laws, legal process or governmental request; (ii) enforce
            the Agreement, (iii) respond to your requests for customer service, or (iv) protect the rights, property or
            personal safety of GIGXR, its Registered Users or the public, and all enforcement or other government
            officials, as GIGXR in its sole discretion believes to be necessary or appropriate.
          </p>
        </li>
        <li>
          <p><strong>TERM AND TERMINATION.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>Term.</strong>
                The Agreement commences on the date when you accept them (as described in the preamble above) and remain
                in full force and effect while you use GIGXR Properties, unless terminated earlier in accordance with
                the Agreement.
              </p>
            </li>
            <li>
              <p>
                <strong>Prior Use.</strong>
                Notwithstanding the foregoing, you hereby acknowledge and agree that the Agreement commenced on the
                earlier to occur of (a) the date you first used GIGXR Properties or (b) the date you accepted the
                Agreement and will remain in full force and effect while you use any GIGXR Properties, unless earlier
                terminated in accordance with the Agreement.
              </p>
            </li>
            <li>
              <p>
                <strong>Termination of Services by GIGXR.</strong>
                You acknowledge and agree that your access and use of the Services is conditioned upon authorization
                from GIGXR’s customer. GIGXR reserves the right to suspend and/or terminate the Services provided to you
                under this Agreement at any time, including without limitation upon such GIGXR’s customer’s request, the
                termination of such GIGXR’s agreement with GIGXR’s customer, or the non-payment of fees by such GIGXR’s
                customer.
              </p>
            </li>
            <li>
              <p>
                <strong>Termination of Services by You.</strong>
                If you want to terminate the Services provided by GIGXR, you may do so by (a) notifying GIGXR at any
                time and (b) closing your Account for all of the Services that you use. Your notice should be sent, in
                writing, to GIGXR’s address set forth below.
              </p>
            </li>
            <li>
              <p>
                <strong>Effect of Termination.</strong>
                Termination of any Service includes removal of access to such Service and barring of further use of the
                Service. Termination of all Services also includes deletion of your password and all related
                information, files and content associated with or inside your Account (or any part thereof). Upon
                termination of any Service, your right to use such Service will automatically terminate immediately.
                GIGXR will not have any liability whatsoever to you for any suspension or termination, including for
                deletion of any of your content. All provisions of the Agreement which by their nature should survive,
                shall survive termination of Services, including without limitation, ownership provisions, warranty
                disclaimers, and limitation of liability.
              </p>
            </li>
            <li>
              <p>
                <strong>No Subsequent Registration.</strong>
                If your registration(s) with or ability to access GIGXR Properties, or any other GIGXR community is
                discontinued by GIGXR due to your violation of any portion of the Agreement or for conduct otherwise
                inappropriate for the community, then you agree that you shall not attempt to re-register with or access
                GIGXR Properties or any GIGXR community through use of a different member name or otherwise, and you
                acknowledge that you will not be entitled to receive a refund for fees related to those GIGXR Properties
                to which your access has been terminated. In the event that you violate the immediately preceding
                sentence, GIGXR reserves the right, in its sole discretion, to immediately take any or all of the
                actions set forth herein without any notice or warning to you.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>INTERNATIONAL USERS.</strong>
            GIGXR Properties can be accessed from countries around the world and may contain references to Services and
            Content that are not available in your country. These references do not imply that GIGXR intends to announce
            such Services or Content in your country. GIGXR Properties are controlled and offered by GIGXR from its
            facilities in the United States of America. GIGXR makes no representations that GIGXR Properties are
            appropriate or available for use in other locations. Those who access or use GIGXR Properties from other
            countries do so at their own volition and are responsible for compliance with local law.
          </p>
        </li>
        <li>
          <p><strong>THIRD-PARTY SERVICES.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>Third-Party Websites, Applications and Ads.</strong>
                GIGXR Properties may contain links to third-party websites (<strong>“Third-Party Websites”),</strong>
                applications (<strong>“Third-Party Applications”</strong>) and advertisements for third parties (<strong
                >“Third-Party Ads”</strong>). When you click on a link to a Third-Party Website, Third-Party Application
                or Third-Party Ad, we will not warn you that you have left GIGXR Properties and are subject to the terms
                and conditions (including privacy policies) of another website or destination. Such Third-Party
                Websites, Third-Party Applications and Third-Party Ads are not under the control of GIGXR. GIGXR is not
                responsible for any Third-Party Websites, Third-Party Applications or Third-Party Ads. GIGXR provides
                these Third-Party Websites, Third-Party Applications and Third Party Ads only as a convenience and does
                not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party
                Websites, Third-Party Applications or Third-Party Ads, or any product or service provided in connection
                therewith. You use all links in Third-Party Websites, Third-Party Applications and Third-Party Ads at
                your own risk. When you leave our Website, this Agreement and our policies no longer govern. You should
                review applicable terms and policies, including privacy and data gathering practices, of any Third-Party
                Websites, Third-Party Applications, or Third-Party Ads, and make whatever investigation you feel
                necessary or appropriate before proceeding with any transaction with any third party.
              </p>
            </li>
            <li>
              <p>
                <strong>App Stores.</strong>
                You acknowledge and agree that the availability of the Application and the Services is dependent on the
                third party from whom you received the Application license, e.g., the Microsoft Store, the Apple App
                Store or Google Play (each, an
                <strong>“App Store”</strong>). You acknowledge that the Agreement is between you and GIGXR and not with
                the App Store. GIGXR, not the App Store, is solely responsible for GIGXR Properties, including the
                Application, the content thereof, maintenance, support services, and warranty therefor, and addressing
                any claims relating thereto (e.g., product liability, legal compliance or intellectual property
                infringement). In order to use the Application, you must have access to a wireless network, and you
                agree to pay all fees associated with such access. You also agree to pay all fees (if any) charged by
                the App Store in connection with GIGXR Properties, including the Application. You agree to comply with,
                and your license to use the Application is conditioned upon your compliance with all terms of agreement
                imposed by the applicable App Store when using any GIGXR Property, including the Application. You
                acknowledge that the App Store (and its subsidiaries) are third-party beneficiaries of the Agreement and
                will have the right to enforce it.
              </p>
            </li>
            <li>
              <p>
                <strong>Accessing and Downloading the Application from iTunes.</strong>
                The following applies to any App Store Sourced Application accessed through or downloaded from the Apple
                App Store:
              </p>
              <ol type="a">
                <li>
                  <p>
                    You acknowledge and agree that (i) the Agreement is concluded between you and GIGXR only, and not
                    Apple, and (ii) GIGXR, not Apple, is solely responsible for the App Store Sourced Application and
                    content thereof. Your use of the App Store Sourced Application must comply with the App Store Terms
                    of Service.
                  </p>
                </li>
                <li>
                  <p>
                    You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support
                    services with respect to the App Store Sourced Application.
                  </p>
                </li>
                <li>
                  <p>
                    In the event of any failure of the App Store Sourced Application to conform to any applicable
                    warranty, you may notify Apple, and Apple will refund the purchase price for the App Store Sourced
                    Application to you and to the maximum extent permitted by applicable law, Apple will have no other
                    warranty obligation whatsoever with respect to the App Store Sourced Application. As between GIGXR
                    and Apple, any other claims, losses, liabilities, damages, costs or expenses attributable to any
                    failure to conform to any warranty will be the sole responsibility of GIGXR.
                  </p>
                </li>
                <li>
                  <p>
                    You and GIGXR acknowledge that, as between GIGXR and Apple, Apple is not responsible for addressing
                    any claims you have or any claims of any third party relating to the App Store Sourced Application
                    or your possession and use of the App Store Sourced Application, including, but not limited to: (i)
                    product liability claims; (ii) any claim that the App Store Sourced Application fails to conform to
                    any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection
                    or similar legislation.
                  </p>
                </li>
                <li>
                  <p>
                    You and GIGXR acknowledge that, in the event of any third-party claim that the App Store Sourced
                    Application or your possession and use of that App Store Sourced Application infringes that third
                    party’s intellectual property rights, as between GIGXR and Apple, GIGXR, not Apple, will be solely
                    responsible for the investigation, defense, settlement and discharge of any such intellectual
                    property infringement claim to the extent required by the Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    You and GIGXR acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party
                    beneficiaries of the Agreement as related to your license of the App Store Sourced Application, and
                    that, upon your acceptance of the terms and conditions of the Agreement, Apple will have the right
                    (and will be deemed to have accepted the right) to enforce the Agreement as related to your license
                    of the App Store Sourced Application against you as a third-party beneficiary thereof.
                  </p>
                </li>
                <li>
                  <p>
                    Without limiting any other terms of the Agreement, you must comply with all applicable third-party
                    terms of agreement when using the App Store Sourced Application.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p><strong>GENERAL PROVISIONS.</strong></p>
          <ol type="1">
            <li>
              <p>
                <strong>Electronic Communications.</strong>
                The communications between you and GIGXR may take place via electronic means, whether you visit GIGXR
                Properties or send GIGXR e-mails, or whether GIGXR posts notices on GIGXR Properties or communicates
                with you via e-mail. For contractual purposes, you (a) consent to receive communications from GIGXR in
                an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and
                other communications that GIGXR provides to you electronically satisfy any legal requirement that such
                communications would satisfy if it were to be in writing. The foregoing does not affect your statutory
                rights, including but not limited to the Electronic Signatures in Global and National Commerce Act at 15
                U.S.C. §7001 et seq. (“E-Sign”).
              </p>
            </li>
            <li>
              <p>
                <strong>Release.</strong>
                You hereby release GIGXR Parties and their successors from claims, demands, any and all losses, damages,
                rights, and actions of any kind, including personal injuries, death, and property damage, that is either
                directly or indirectly related to or arises from your use of GIGXR Properties, including but not limited
                to, any interactions with or conduct of other Users or third-party websites of any kind arising in
                connection with or as a result of the Agreement or your use of GIGXR Properties. If you are a California
                resident, you hereby waive California Civil Code Section 1542, which states, “A general release does not
                extend to claims that the creditor or releasing party does not know or suspect to exist in his or her
                favor at the time of executing the release and that, if known by him or her, would have materially
                affected his or her settlement with the debtor or released party.” The foregoing release does not apply
                to any claims, demands, or any losses, damages, rights and actions of any kind, including personal
                injuries, death or property damage for any unconscionable commercial practice by a GIGXR Party or for
                such party’s fraud, deception, false, promise, misrepresentation or concealment, suppression or omission
                of any material fact in connection with the Websites, Applications, or any Services provided hereunder.
              </p>
            </li>
            <li>
              <p>
                <strong>Assignment.</strong>
                The Agreement, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated
                or otherwise transferred by you without GIGXR’s prior written consent, and any attempted assignment,
                subcontract, delegation, or transfer in violation of the foregoing will be null and void.
              </p>
            </li>
            <li>
              <p>
                <strong>Force Majeure.</strong>
                GIGXR shall not be liable for any delay or failure to perform resulting from causes outside its
                reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of
                civil or military authorities, fire, floods, accidents, strikes or shortages of transportation
                facilities, fuel, energy, labor or materials. If a force majeure event occurs that affects GIGXR’s
                performance of its obligations under the Agreement: (a) GIGXR will contact you as soon as reasonably
                possible to notify you; and (b) GIGXR’s obligations under the Agreement will be suspended and the time
                for GIGXR’s performance of its obligations will be extended for the duration of the force majeure event.
                You may cancel the Services affected by a force majeure event which has continued for more than 30 days.
                To cancel please contact GIGXR.
              </p>
            </li>
            <li>
              <p>
                <strong>Questions, Complaints, Claims.</strong>
                If you have any questions, complaints or claims with respect to GIGXR Properties, please contact us at:
                privacy@gigxr.com. We will do our best to address your concerns. If you feel that your concerns have
                been addressed incompletely, we invite you to let us know for further investigation.
              </p>
            </li>
            <li>
              <p>
                <strong>Exclusive Venue.</strong>
                To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you
                and GIGXR agree that all claims and disputes arising out of or relating to the Agreement will be
                litigated exclusively in the state or federal courts located in Los Angeles, California.
              </p>
            </li>
            <li>
              <p>
                <strong>Governing Law.</strong>
                THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE
                STATE OF CALIFORNIA WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW
                OF ANOTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS
                DOES NOT APPLY TO THE AGREEMENT.
              </p>
            </li>
            <li>
              <p>
                <strong>Choice of Language.</strong>
                It is the express wish of the parties that the Agreement and all related documents have been drawn up in
                English. C’est la volonté expresse des parties que la presente convention ainsi que les documents qui
                s’y rattacent soient rediges en anglais.
              </p>
            </li>
            <li>
              <p>
                <strong>Notice.</strong>
                Where GIGXR requires that you provide an e-mail address, you are responsible for providing GIGXR with
                your most current e-mail address. In the event that the last e-mail address you provided to GIGXR is not
                valid, or for any reason is not capable of delivering to you any notices required/ permitted by the
                Agreement, GIGXR’s dispatch of the e-mail containing such notice will nonetheless constitute effective
                notice. You may give notice to GIGXR at the following address: privacy@gigxr.com. Such notice shall be
                deemed given when received by GIGXR by letter delivered by nationally recognized overnight delivery
                service or first class postage prepaid mail at the above address.
              </p>
            </li>
            <li>
              <p>
                <strong>Waiver.</strong>
                Any waiver or failure to enforce any provision of the Agreement on one occasion will not be deemed a
                waiver of any other provision or of such provision on any other occasion.
              </p>
            </li>
            <li>
              <p>
                <strong>Severability.</strong>
                If any portion of this Agreement is held invalid or unenforceable, that portion shall be construed in a
                manner to reflect, as nearly as possible, the original intention of the parties, and the remaining
                portions shall remain in full force and effect.
              </p>
            </li>
            <li>
              <p>
                <strong>Export Control.</strong>
                You may not use, export, import, or transfer GIGXR Properties except as authorized by U.S. law, the laws
                of the jurisdiction in which you obtained GIGXR Properties, and any other applicable laws. In
                particular, but without limitation, GIGXR Properties may not be exported or re-exported (a) into any
                United States embargoed countries, or (b) to anyone on the U.S. Treasury Department’s list of Specially
                Designated Nationals or the U.S. Department of Commerce’s Denied Person’s List or Entity List. By using
                GIGXR Properties, you represent and warrant that (y) you are not located in a country that is subject to
                a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist
                supporting” country and (z) you are not listed on any U.S. Government list of prohibited or restricted
                parties. You also will not use GIGXR Properties for any purpose prohibited by U.S. law, including the
                development, design, manufacture or production of missiles, nuclear, chemical or biological weapons. You
                acknowledge and agree that products, services or technology provided by GIGXR are subject to the export
                control laws and regulations of the United States. You shall comply with these laws and regulations and
                shall not, without prior U.S. government authorization, export, re-export, or transfer GIGXR products,
                services or technology, either directly or indirectly, to any country in violation of such laws and
                regulations.
              </p>
            </li>
            <li>
              <p>
                <strong>Consumer Complaints.</strong>
                In accordance with California Civil Code §1789.3, you may report complaints to the Complaint Assistance
                Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting
                them in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800)
                952-5210.
              </p>
            </li>
            <li>
              <p>
                <strong>Entire Agreement.</strong>
                The Agreement is the final, complete and exclusive agreement of the parties with respect to the subject
                matter hereof and supersedes and merges all prior discussions between the parties with respect to such
                subject matter.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            <strong>INTERNATIONAL PROVISIONS.</strong>
            The following provisions shall apply only if you are located in the countries listed below.
          </p>
          <ol type="1">
            <li>
              <p>
                <strong>United Kingdom.</strong>
                A third party who is not a party to the Agreement has no right under the Contracts (Rights of Third
                Parties) Act 1999 to enforce any provision of the Agreement, but this does not affect any right or
                remedy of such third party which exists or is available apart from that Act.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <UnauthenticatedPageLink id="return-to-site-link" href="/" label="Return to Site" on:click={returnToSiteHandler} />
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  .terms-of-use {
    text-align: justify;
  }

  .smallcaps {
    font-variant: small-caps;
  }

  ol {
    counter-reset: item;
    padding-inline-start: 50px; /* match with `left` below */
  }

  ol li {
    display: block;
    position: relative;
  }

  ol li:before {
    content: counters(item, '.') '.';
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    left: -50px; /* match with `padding-line-start` above */
    white-space: nowrap;
    font-weight: 700;
  }

  ol[type='a'] li:before {
    content: '(' counter(item, lower-alpha) ')';
  }
</style>
