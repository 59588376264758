<script>
  /**
   * errorMessage is a global store, so when navigating between pages the error state persists
   * unless it is manually cleared on each page.
   *
   * This clears the error state on a route change such that each page does not need to clear any
   * previous error messages.
   *
   * This also clears the breadcrumb paths in the same manner.
   */

  import { getContext, onDestroy } from 'svelte';
  import { ROUTER } from 'svelte-routing/src/contexts';
  import { errorMessage, breadcrumbPaths } from '../stores/core-store';
  import { setupGoogleAnalytics, sendGoogleAnalyticsPageView } from '../util/google-analytics';
  import { GA_MEASUREMENT_ID } from '../config';

  const { activeRoute } = getContext(ROUTER);

  setupGoogleAnalytics(GA_MEASUREMENT_ID);

  let lastPath = undefined;

  const unsubscribe = activeRoute.subscribe((route) => {
    // Reset scroll position on navigate
    window.scrollTo(0, 0);

    if (route) {
      if (route.route.path !== lastPath) {
        errorMessage.set('');
        breadcrumbPaths.set([]);
        lastPath = route.route.path;
        sendGoogleAnalyticsPageView(route.uri);
      }
    }
  });

  onDestroy(unsubscribe);
</script>

<style>
</style>
