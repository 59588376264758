<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { setAuthorizedRoles } from '../../util/authorization';
  import { deepCopy } from '../../util/util';
  import { authenticatedPost } from '../../util/api';
  import CountrySelect from '../../components/CountrySelect.svelte';
  import { createModule, fetchModule } from '../../util/api/modules';
  import GigXrHorizontalRule from '../../components/GigXrHorizontalRule.svelte';
  import { GmsError } from '../../util/errors';
  import * as EmailValidator from 'email-validator';
  import {
    RegistrationStatus,
    addAccount,
    createAccountUploadRequest,
    fetchAccountUploadRequest, AccountUploadDtoStatus,
  } from '../../util/api/accounts';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';
  import GigXrDatepicker from '../../components/GigXrDatepicker.svelte';
  import { createLicense, LicenseType, LicenseTypeText } from '../../util/api/licenses';
  import { fetchClientApps, fetchLicensedClientApps } from '../../util/api/client-apps';
  import { Logger } from '../../util/logs';

  setAuthorizedRoles(authorizedRoles);

  title.set('Create Module');
  breadcrumbPaths.set([
    {
      name: 'Modules',
      location: '/modules',
    },
    {
      name: 'Create Module',
      location: '/modules/create',
    },
  ]);

  let form;
  let discardChangesDialog;

  let lastSavedModule = {
    moduleId: '',
     moduleName: '',
  };

  let module = deepCopy(lastSavedModule);
  let licensedClientApps = [];

  let loading = true;

  onMount(async () => {
    [licensedClientApps] = await Promise.all([
      fetchClientApps(),
    ]);
  });

  async function createModuleHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    const newModule = await createModule(module);

    snackbarMessage.set('Module created!');
    $snackbar.open();
    navigate(`/modules`);
  }

  function discardChangesHandler(event) {
    module = deepCopy(lastSavedModule);
  }

</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <form bind:this={form}>
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={module.moduleName}
              variant="filled"
              label="Module Name"
              input$required
            />
          </ContentRow>
          <ContentRow>
            <Select
              inputId="client-app-field"
              class="gigxr-input"
              bind:value={module.clientAppId}
              variant="filled"
              label="App"
              enhanced
            >
              <Option value="" />
              {#each licensedClientApps as app (app.clientAppId)}
                <Option value={app.clientAppId} selected={app.clientAppId === module.clientAppId}>
                  {app.clientAppName}
                </Option>
              {/each}
              {#if licensedClientApps.length == 0}
                <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
              {/if}
            </Select>
          </ContentRow>
        </div>

        <div slot="right">


          <ContentRowCenteredOnMobile>
            <Button
              id="create-module-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={createModuleHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="create-module-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

        </div>
      </TwoColumnSection>

    </form>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
