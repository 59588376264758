import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APPLICATION_INSIGHTS_INSTRUMENTATION_KEY, SENTRY_ENVIRONMENT } from './config';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { name, version } from '../package.json';
import { revision } from '../revision.json';
import { apiVersion } from './stores/core-store';

if (SENTRY_ENVIRONMENT !== 'dev') { // disable Sentry for local dev environment
  Sentry.init({
    dsn: "https://55bcf616cd0047ee8504f30fbb3f43d5@o1205359.ingest.sentry.io/6413147",
    release: `${version}+${revision}`,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  apiVersion.subscribe(ver => {
    Sentry.setTag('api-version', ver);
  });
  
}

console.log(`in main.js, initialized Sentry, about to call captureMessage`);
Sentry.captureMessage("in main.js");


if (APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
      enableAutoRouteTracking: true,
      disableFetchTracking: false,
      maxAjaxCallsPerView: -1,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.loadAppInsights();

  // Manually track first page view, `enableAutoRouteTracking` will handle the rest of the SPA navigation.
  appInsights.trackPageView();
} else {
  console.warn('Application Insights is disabled.');
}

import App from './App.svelte';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

import '../public/global.scss';

import './util/polyfills';



console.debug(`${name} v${version}+${revision}`);

const app = new App({
  target: document.body,
});

export default app;
