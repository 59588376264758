<script>
  import ListPageCardGridCheckbox from './ListPageCardGridCheckbox.svelte';

  export let show = true;
  export let compact = false;
</script>

<ListPageCardGridCheckbox on:change {show} id="checkbox-header" />
<div class="grid-header-card {show ? '' : 'grid-header-card--hide'} {compact ? 'grid-header-card--compact' : ''}">
  All
</div>

<style>
  .grid-header-card {
    width: 291px;
    margin-bottom: 30px;
    margin-right: auto;
    font-weight: 700;
  }

  .grid-header-card--hide {
    display: none;
  }

  .grid-header-card--compact {
    width: 250px;
  }
</style>
