import { DEVELOPMENT } from '../config';

const Logger = {
  log: () => {
    // Do nothing
  },
};

if (DEVELOPMENT) {
  enableLogging();
}

function enableLogging() {
  // https://stackoverflow.com/questions/13815640/a-proper-wrapper-for-console-log-with-correct-line-number
  Logger.log = console.log.bind(window.console);
}

window.enableLogging = () => {
  enableLogging();
  return 'Logging enabled';
};

export { Logger };
