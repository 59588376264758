<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTRUCTOR, AccountRole.DEPARTMENT_ADMIN, AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import List, { Item, Meta, Text, PrimaryText, SecondaryText } from '@smui/list';
  import IconButton, { Icon } from '@smui/icon-button';
  import { title, breadcrumbPaths, snackbarMessage, snackbar } from '../../../stores/core-store';
  import Typeahead from '../../../components/Typeahead.svelte';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import MultiLineText from '../../../components/MultiLineText.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { editClass, fetchClass } from '../../../util/api/classes';
  import { fetchStudentByEmail, fetchStudents } from '../../../util/api/accounts';
  import ListPageViewMoreButton from '../../../components/ListPageViewMoreButton.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import * as EmailValidator from 'email-validator';
  import { getAccountId } from '../../../util/account';
  import { canEditClass } from '../../../util/api/classes';
  import EditButton from '../../../components/EditButton.svelte';
  import RemoveStudentFromClassDialog, {
    openRemoveStudentFromClassDialog,
  } from '../../../components/classes/RemoveStudentFromClassDialog.svelte';

  export let classId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Class Details');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Class Management',
      location: '/classes/list',
    },
    {
      name: 'Class Details',
      location: `/classes/view/${classId}`,
    },
  ]);

  let loading = true;

  let clazz = null;
  let studentFromDepartment = '';
  let studentByEmail = '';
  let studentsById = {};

  // This page generally does not need paging, but with a large number of students it takes a very
  // long time to load which is nearly all time spent rendering.
  const STUDENTS_PER_PAGE = 50;
  let displayedItems = STUDENTS_PER_PAGE;
  let remainingItems = -1;
  $: {
    if (clazz) {
      remainingItems = clazz.studentIds.length - displayedItems;
    }
  }

  let availableStudents = {};
  let availableStudentsInDepartment = {};
  $: {
    availableStudentsInDepartment = { ...availableStudents };

    Object.values(availableStudentsInDepartment).forEach((student) => {
      if (!student.departmentIds.includes(clazz.departmentId)) {
        delete availableStudentsInDepartment[student.accountId];
      }
    });

    availableStudentsInDepartment = availableStudentsInDepartment;
  }

  onMount(async () => {
    let students;
    [clazz, students] = await Promise.all([fetchClass(classId), fetchStudents()]);

    students.forEach((s) => (studentsById[s.accountId] = s));

    availableStudents = { ...studentsById };
    clazz.studentIds.forEach((id) => delete availableStudents[id]);

    clazz = clazz;
    loading = false;
  });

  let studentsInDepartmentQuery;
  let studentsByEmailQuery;

  /** Map<String email, Account student> */
  let studentsByEmailCache = new Map();
  let studentsByEmailMatch = [];

  $: (async () => {
    // Need to create a local copy of this variable so that it doesn't change for the lifetime of
    // this block.
    let email = studentsByEmailQuery;

    // If the email is not valid, then there is no need to send the API call.
    if (!email || !EmailValidator.validate(email)) {
      studentsByEmailMatch = [];
      return;
    }

    let student;
    // This call is pretty spammy, so we check a local cache first.
    if (studentsByEmailCache.has(email)) {
      student = studentsByEmailCache.get(email);
    } else {
      student = await fetchStudentByEmail(email);
      studentsByEmailCache.set(email, student);
    }

    if (student == null) {
      studentsByEmailMatch = [];
      return;
    }

    // Check if the student found is already in the student list. This is needed to prevent adding
    // the same student more than once.
    if (clazz.studentIds.includes(student.accountId)) {
      studentsByEmailMatch = [];
      return;
    }

    studentsById[student.accountId] = student;
    studentsByEmailMatch = [student];
  })();

  async function addStudent(student) {
    clazz.studentIds = [...clazz.studentIds, student.accountId];

    delete availableStudentsInDepartment[student.accountId];
    delete availableStudents[student.accountId];
    availableStudentsInDepartment = availableStudentsInDepartment;
    availableStudents = availableStudents;
    await editClass(clazz);

    studentsInDepartmentQuery = '';
    studentsByEmailQuery = '';
  }

  async function removeStudent(studentId) {
    clazz.studentIds = clazz.studentIds.filter((id) => {
      if (id === studentId) {
        availableStudents[studentId] = studentsById[studentId];
        return false;
      }
      return true;
    });

    await editClass(clazz);
    snackbarMessage.set('Student removed!');
    $snackbar.open();
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{clazz.className}</h2>
      </div>

      <div slot="right">
        {#if canEditClass(clazz)}
          <EditButton on:click={() => navigate(`/classes/edit/${classId}`)} />
        {/if}
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left" class="view-class-left-section">
          <KeyValueRow>
            <div slot="left">Department:</div>
            <div slot="right">{clazz.department.departmentName}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Instructor:</div>
            <div slot="right">
              {#if clazz.instructor}
                <a id="instructor-link" href="mailto:{clazz.instructor.email}">{clazz.instructor.firstName}
                  {clazz.instructor.lastName}</a>
              {:else}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Description:</div>
            <div slot="right">
              {#if clazz.description.trim().length === 0}
                –
              {:else}
                <MultiLineText text={clazz.description} />
              {/if}
            </div>
          </KeyValueRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Students</h3>
            {#if !clazz.studentIds || clazz.studentIds.length === 0}
              <p>This class has no students.</p>
            {:else}
              <List class="gigxr-list" twoLine nonInteractive>
                {#each clazz.studentIds as studentId, index (studentId)}
                  {#if index < displayedItems}
                    <Item class="gigxr-list__item">
                      <Text>
                        <PrimaryText>
                          {studentsById[studentId].firstName}
                          {studentsById[studentId].lastName}
                        </PrimaryText>
                        <SecondaryText>{studentsById[studentId].email}</SecondaryText>
                      </Text>
                      <Meta>
                        {#if canEditClass(clazz)}
                          <IconButton
                            id="remove-student-button-{studentId}"
                            class="material-icons"
                            on:click={() => openRemoveStudentFromClassDialog(studentsById[studentId], () =>
                                removeStudent(studentId),
                              )}
                          >
                            close
                          </IconButton>
                        {/if}
                      </Meta>
                    </Item>
                  {/if}
                {/each}
              </List>
              {#if remainingItems > 0}
                <ListPageViewMoreButton
                  itemName="Students"
                  {remainingItems}
                  on:click={() => (displayedItems += STUDENTS_PER_PAGE)}
                />
              {/if}
            {/if}

            {#if canEditClass(clazz)}
              <Typeahead
                items={Object.values(availableStudentsInDepartment)}
                bind:query={studentsInDepartmentQuery}
                valueFunction={(student) => `${student.firstName} ${student.lastName}`}
                onSelectFunction={(student) => addStudent(student)}
                label="Add Student from {clazz.department.departmentName}
                "
              />

              <Typeahead
                items={studentsByEmailMatch}
                bind:query={studentsByEmailQuery}
                valueFunction={(student) => student.email}
                onSelectFunction={(student) => addStudent(student)}
                label="Add Student by email address "
              />
            {/if}
          </ContentRow>
        </div>

        <div slot="right">
          <KeyValueRow>
            <div slot="left">Status:</div>
            <div slot="right">{clazz.classStatus}</div>
          </KeyValueRow>

          <DateAccountComponent label="Added" utcDateString={clazz.createdOn} account={clazz.createdBy} />
        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<RemoveStudentFromClassDialog />

<style>
  h3 {
    margin-top: 3em;
  }

  .view-class-left-section {
    margin-bottom: 3em;
  }

  @media (min-width: 1100px) {
    .view-class-left-section {
      margin-bottom: 1.5em;
    }
  }
</style>
