<script context="module">
  let dialog;

  let studentId;

  export function openRemoveSessionParticipantDialog(accountId) {
    studentId = accountId;
    dialog.open();
  }
</script>

<script>
  import { createEventDispatcher } from 'svelte';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  const dispatch = createEventDispatcher();
</script>

<GigXrDialog bind:dialog ariaPrefix="remove-session-participant" threeButtons={true}>
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You are about to remove a participant. They will no longer have access to this session.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={() => dispatch('GigXrDialog:removeAndNotifyParticipant', { studentId })}>
      Remove and Notify
    </GigXrDialogButton>
    <GigXrDialogButton on:click={() => dispatch('GigXrDialog:removeParticipant', { studentId })}>
      Remove
    </GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
