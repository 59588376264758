<script>
  import SmuiSelect, { Option as SmuiOption } from '@smui/select';

  export let minuteValue = '00';
  export let disabled = false;

  const minuteValues = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
</script>

<SmuiSelect
  bind:value={minuteValue}
  class="gigxr-minute-timepicker"
  {disabled}
  enhanced
  label="Minute"
  name="minute"
  variant="filled"
>
  {#each minuteValues as value (value)}
    <SmuiOption selected={value === minuteValue} {value}>{value}</SmuiOption>
  {/each}
</SmuiSelect>

<style>
  :global(.gigxr-minute-timepicker) {
    width: 88px;
  }
</style>
