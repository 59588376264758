<script>
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import GigXrListAnchorItem from '../GigXrListAnchorItem.svelte';
  import GigXrMenu from '../GigXrMenu.svelte';

  export let module;

  let menu;
  let open;

  function toggleMenu(event) {
    menu.setOpen(!open);
  }
</script>

<IconButton id="institution-menu-button-{module?.moduleId}" class="material-icons" on:click={toggleMenu}>
  more_vert
</IconButton>

<GigXrMenu bind:open bind:menu anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
  <List>
    <Item
      id="institution-menu-view-{module.moduleId}"
      on:SMUI:action={() => navigate(`/modules/view/${module.moduleId}`)}
    >
      <Text>View</Text>
    </Item>
    <Item
      id="institution-menu-edit-{module.moduleId}"
      on:SMUI:action={() => navigate(`/modules/edit/${module.moduleId}`)}
    >
      <Text>Edit</Text>
    </Item>
  </List>
</GigXrMenu>

<style>
</style>
