<script>
  import { onMount, onDestroy } from 'svelte';
  import { title, errorMessage, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import {
    sendUnityGetClipsMessage,
    subscribeToUnityMessage,
    sendUnityGetContentPreferencesMessage,
    sendUnitySetContentPreferencesMessage,
  } from '../util/unity';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import Select, { Option } from '@smui/select';
  import AppSettingsClipCard from '../components/AppSettingsClipCard.svelte';
  import ListPageNoResultsMessage from '../components/ListPageNoResultsMessage.svelte';
  import { bytesToGigabytes, gigabytesToBytes, humanFileSize } from '../util/util';
  import LoadingView from '../components/LoadingView.svelte';
  import { startAllClipDownloads } from '../util/download-manager';
  import BottomModal, { showBottomModal } from '../components/BottomModal.svelte';
  import { fetchSessionClipAliases } from '../util/api/sessions';

  title.set('App Settings');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'App Settings',
      location: '/app-settings',
    },
  ]);

  let clips;
  let contentPreferences;
  let totalStorageUsed;
  let unsubscribeFunctions = [];

  // Display clips are clips grouped together by the underlying clip file.
  let clipAliasesByClip = new Map();

  $: loading = !contentPreferences || !clips;

  onMount(async () => {
    let [clipAliases] = await Promise.all([fetchSessionClipAliases()]);
    Object.keys(clipAliases).forEach((clipAliasKey) => {
      // Filter out falsey values.
      const clipAliasNames = clipAliases[clipAliasKey].filter((alias) => alias);
      clipAliasesByClip.set(`${clipAliasKey}.mp4`, clipAliasNames);
    });

    unsubscribeFunctions = [
      subscribeToUnityMessage(UnityAction.ASSETS_DOWNLOADED, (payload) => {
        clips = payload.clips;
      }),
      subscribeToUnityMessage(UnityAction.CONTENT_PREFERENCES_RESPONSE, (payload) => {
        contentPreferences = payload;
      }),
    ];

    pollForClips();
    sendUnityGetContentPreferencesMessage();
  });

  let pollForClipsTimeout;
  function pollForClips() {
    sendUnityGetClipsMessage();

    pollForClipsTimeout = setTimeout(() => {
      pollForClips();
    }, 1000);
  }

  // This reactive block is to calculate total storage used.
  $: if (clips) {
    let bytes = 0;
    clips.forEach((file) => {
      if (file.length > 0) {
        bytes += file.length / file.progress;
      }
    });
    totalStorageUsed = bytes;
  }

  // This reactive block is to send content preference updates on change.
  $: if (contentPreferences) {
    const contentPreferencesDto = {
      ...contentPreferences,
      // Coerece to number. There does not appear to be a way to bind to a number type from a select field at this time.
      maximumContentStorage: Number(contentPreferences.maximumContentStorage),
    };

    sendUnitySetContentPreferencesMessage(contentPreferencesDto);

    if (contentPreferencesDto.allowAutomaticBackgroundDownloads) {
      // This will trigger automatically later, but this is so downloads will start right away.
      startAllClipDownloads();
    }
  }

  let maximumContentMessage = '';
  function maximumContentStorageChangeHandler(event) {
    const element = document.getElementById('maximum-content-storage');

    if (bytesToGigabytes(totalStorageUsed) > Number(element.value)) {
      const storageUsingMessage = humanFileSize(totalStorageUsed, true);
      const storageToDeleteMessage = humanFileSize(totalStorageUsed - gigabytesToBytes(element.value), true);
      const storageToSetMessage = humanFileSize(gigabytesToBytes(element.value), true);
      maximumContentMessage = `You are currently using ${storageUsingMessage} of storage. You must delete ${storageToDeleteMessage} of clips before switching to ${storageToSetMessage} of maximum content storage.`;

      element.value = contentPreferences.maximumContentStorage;

      showBottomModal();
    }
  }

  onDestroy(() => {
    clearTimeout(pollForClipsTimeout);
    unsubscribeFunctions.forEach((fn) => fn());
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <h2 class="gigxr-list-header gigxr-list-header--first app-settings-header">Downloaded Clips</h2>

    {#if loading}
      <LoadingView />
    {:else}
      {#each clips as clip}
        <AppSettingsClipCard
          name={clip.name}
          size={clip.length}
          progress={clip.progress}
          displayNames={clipAliasesByClip.get(clip.name) ?? []}
        />
      {/each}
      {#if clips.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no clips to display.</h3>
        </ListPageNoResultsMessage>
      {/if}
    {/if}

    <h2 class="gigxr-list-header app-settings-header">Content Preferences</h2>

    {#if loading}
      <LoadingView />
    {:else}
      <div class="app-setting-preferences">
        <div class="app-setting-preferences__row">
          <div class="app-setting-preferences__description">
            <label for="allow-automatic-background-downloads">
              <div class="app-setting-preferences__description-label">Allow automatic background downloads</div>
            </label>
          </div>
          <div class="app-setting-preferences__control">
            <FormField inputId="allow-automatic-background-downloads-field">
              <Checkbox bind:checked={contentPreferences.allowAutomaticBackgroundDownloads} />
            </FormField>
          </div>
        </div>
        <div class="app-setting-preferences__row">
          <div class="app-setting-preferences__description">
            <label for="maximum-content-storage">
              <div class="app-setting-preferences__description-label">Maximum content storage</div>
              <div class="app-setting-preferences__description-helper-text">
                Currently using
                {#if clips && clips.length > 0}
                  {humanFileSize(totalStorageUsed, true)}
                {:else if clips && clips.length === 0}no storage{:else}–{/if}
              </div>
            </label>
          </div>
          <div class="app-setting-preferences__control">
            <Select
              inputId="maximum-content-storage-field"
              class="gigxr-input gigxr-outlined gigxr-select__no-label"
              variant="outlined"
              label=""
              on:change={maximumContentStorageChangeHandler}
              bind:value={contentPreferences.maximumContentStorage}
            >
              <Option value="2">2 GB</Option>
              <Option value="4">4 GB</Option>
              <Option value="8">8 GB</Option>
              <Option value="16">16 GB</Option>
              <Option value="32">32 GB</Option>
            </Select>
          </div>
        </div>
      </div>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<BottomModal buttonText="OK">
  <p>{maximumContentMessage}</p>
</BottomModal>

<style>
  .app-settings-header {
    margin-bottom: 1em;
  }

  .app-setting-preferences__row {
    display: flex;
    margin-bottom: 1em;
  }

  .app-setting-preferences__description {
    flex: 2;
  }

  .app-setting-preferences__description-label {
    font-weight: 700;
  }

  .app-setting-preferences__control {
    flex: 1;
    text-align: right;
    padding-left: 10px;
  }
</style>
