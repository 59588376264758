<script>
  import GigXrHourTimepicker from './GigXrHourTimepicker.svelte';
  import GigXrMinuteTimepicker from './GigXrMinuteTimepicker.svelte';

  export let hourValue = '0';
  export let minuteValue = '00';
  export let disabled = false;
</script>

<div class="gigxr-timepicker">
  <GigXrHourTimepicker bind:hourValue {disabled} />
  :
  <GigXrMinuteTimepicker bind:minuteValue {disabled} />
</div>

<style>
  .gigxr-timepicker {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    width: 200px;
  }
</style>
