<script>
  import { createEventDispatcher } from 'svelte';
  import IconButton from '@smui/icon-button';

  const dispatch = createEventDispatcher();
</script>

<IconButton class="material-icons gigxr-icon close-dialog-button" on:click={(event) => dispatch('click', event)}>
  close
</IconButton>

<style>
  :global(.close-dialog-button) {
    position: absolute;
    top: 2px;
    right: 2px;
  }
</style>
