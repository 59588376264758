<script>
  import Button, { Label, Icon } from '@smui/button';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import IconButton from '@smui/icon-button';
  import { onDestroy, onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import {
    getClipsFromSession,
    sendUnityStopClipDownloadMessage,
    sendUnityGetClipsMessage,
    sendUnityDownloadClipsForSessionMessage,
    subscribeToUnityMessage,
  } from '../../util/unity';
  import { getMobileOperatingSystem } from '../../util/util';
  import BottomModal, { showBottomModal } from '../BottomModal.svelte';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import LoadingView from '../LoadingView.svelte';
  import SessionContentManagerProgressCircle from './SessionContentManagerProgressCircle.svelte';
  import SessionContentManagerProgressCircleContainer from './SessionContentManagerProgressCircleContainer.svelte';

  const MAX_PROGRESS_CIRCLES_PER_ROW = 10;

  export let session;

  /** @type {'loading', 'noClips', 'needsClips', 'allDownloaded'} */
  let state = 'loading';

  let clips = [];
  let unsubscribeFunctions = [];
  let clipsLoaded = false;
  let clipsById = new Map();
  let evictNeededClipsDialog;
  let storageLimitPreferenceReached = false;

  onMount(async () => {
    unsubscribeFunctions = [
      subscribeToUnityMessage(UnityAction.ASSETS_DOWNLOADED, (payload) => {
        clips = getClipsFromSession(session);
        const map = new Map();
        payload.clips.forEach((clip) => {
          map.set(clip.name, clip);
        });
        clipsById = map;
        clipsLoaded = true;
      }),
      subscribeToUnityMessage(UnityAction.DOWNLOAD_CLIP_SET_INSUFFICIENT_STORAGE, () => {
        storageLimitPreferenceReached = false;
        showBottomModal();
      }),
      subscribeToUnityMessage(UnityAction.DOWNLOAD_CLIP_SET_STORAGE_LIMITED_BY_PREFERENCE , () => {
        storageLimitPreferenceReached = true;
        showBottomModal();
      }),
      subscribeToUnityMessage(UnityAction.CANNOT_EVICT_NEEDED_CLIPS, () => {
        if (evictNeededClipsDialog.isOpen()) {
          return;
        }

        evictNeededClipsDialog.open();
      }),
    ];

    pollForClips();
    clips = getClipsFromSession(session);
  });

  let pollForClipsTimeout;
  function pollForClips() {
    sendUnityGetClipsMessage();

    pollForClipsTimeout = setTimeout(() => {
      pollForClips();
    }, 1000);
  }

  let toDownloadCount = 0;
  let downloadingCount = 0;

  // This reactive block compares the clips needed for a session with the clips available on the device.
  $: if (clipsLoaded) {
    let _toDownloadCount = 0;
    let _downloadingCount = 0;

    // Count clips downloaded and downloading.
    for (let i = 0; i < clips.length; i++) {
      if (!clipsById.has(`${clips[i]}.mp4`)) {
        _toDownloadCount++;
        continue;
      }
      if (clipsById.get(`${clips[i]}.mp4`).progress < 1.0) {
        _downloadingCount++;
      }
    }

    toDownloadCount = _toDownloadCount;
    downloadingCount = _downloadingCount;

    if (clips.length === 0) {
      state = 'noClips';
    } else if (toDownloadCount === 0 && downloadingCount === 0) {
      state = 'allDownloaded';
    } else {
      state = 'needsClips';
    }
  }

  function downloadHandler() {
    sendUnityDownloadClipsForSessionMessage(session);
  }

  function downloadClipsWithEvictionHandler() {
    sendUnityDownloadClipsForSessionMessage(session, true);
  }

  function stopClipDownloadsHandler() {
    for (let i = 0; i < clips.length; i++) {
      const clip = clips[i];

      if (clipsById.has(`${clip}.mp4`) && clipsById.get(`${clip}.mp4`).progress < 1) {
        sendUnityStopClipDownloadMessage(clipsById.get(`${clip}.mp4`).name);
      }
    }
  }

  onDestroy(() => {
    clearTimeout(pollForClipsTimeout);
    unsubscribeFunctions.forEach((fn) => fn());
  });
</script>

<Card class="session-content-manager">
  <Content class="session-content-manager__content">
    <div class="session-content-manager__title">Session Content</div>
    <div class="session-content-manager__settings">
      <IconButton class="material-icons" on:click={() => navigate('/app-settings')}>miscellaneous_services</IconButton>
    </div>
    {#if state === 'needsClips'}
      <div class="session-content-manager__text">
        {#if toDownloadCount === 0}
          {#if downloadingCount === 1}
            {downloadingCount}
            clip downloading
          {:else}{downloadingCount} clips downloading{/if}
        {:else if toDownloadCount === 1}
          {toDownloadCount}
          clip needs to be downloaded
        {:else}{toDownloadCount} clips need to be downloaded{/if}
      </div>
      <div class="session-content-manager__progress">
        <SessionContentManagerProgressCircleContainer>
          {#each clips as clip, index (index)}
            {#if index % MAX_PROGRESS_CIRCLES_PER_ROW === 0}
              <div class="flex-break" />
            {/if}
            <SessionContentManagerProgressCircle
              number={index + 1}
              progress={clipsById.has(`${clip}.mp4`) ? clipsById.get(`${clip}.mp4`).progress : 0.0}
            />
          {/each}
        </SessionContentManagerProgressCircleContainer>
      </div>
      <div class="session-content-manager__button">
        {#if toDownloadCount > 0}
          <Button class="gigxr-button gigxr-button--large" variant="unelevated" on:click={downloadHandler}>
            <Icon class="material-icons">save_alt</Icon>
            <Label>Download</Label>
          </Button>
        {:else if getMobileOperatingSystem() !== 'iOS'}
          <Button class="gigxr-button gigxr-button--large" variant="outlined" on:click={stopClipDownloadsHandler}>
            <Label>Stop Download</Label>
          </Button>
        {/if}
      </div>
    {:else if state === 'noClips'}
      <div class="session-content-manager__text">No clips have been added to this session</div>
      <div class="session-content-manager__download-complete">
        <Icon class="material-icons">tv_off</Icon>
      </div>
    {:else if state === 'allDownloaded'}
      <div class="session-content-manager__text">All clips downloaded</div>
      <div class="session-content-manager__download-complete">
        <Icon class="material-icons">check_circle_outline</Icon>
      </div>
    {:else}
      <LoadingView />
    {/if}
  </Content>
</Card>

<BottomModal buttonText="OK">
  <p>
    {#if storageLimitPreferenceReached === true}
      Unable to download more content: Go to Content Tab, click on Settings icon in Session Content, and increase maximum content storage size.
    {:else}
      There is insufficient storage on this device to download clips for this session. Please free up some storage and try
      again.
    {/if}
  </p>
</BottomModal>

<GigXrDialog bind:dialog={evictNeededClipsDialog} ariaPrefix="evict-needed-clips">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    <p>Downloading clips for this session will delete clips that are needed for future sessions.</p>
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={downloadClipsWithEvictionHandler}>Continue Download</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
  :global(.session-content-manager) {
    font-family: 'Barlow', sans-serif;
    border-radius: 10px !important;
    margin-bottom: 30px;
  }

  .session-content-manager__title {
    font-size: 1.17em;
    font-weight: 700;
    margin-bottom: 14px;
  }

  :global(.session-content-manager__content) {
    position: relative;
  }

  .session-content-manager__settings {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .session-content-manager__text {
    text-align: center;
  }

  .session-content-manager__progress {
    min-height: 30px;
    padding: 12px 20px 10px;
  }

  .session-content-manager__button {
    text-align: center;
  }

  .session-content-manager__download-complete {
    text-align: center;
  }

  .session-content-manager__download-complete :global(.material-icons) {
    color: var(--gigxr-theme-secondary-2a);
    font-size: 48px;
    margin-top: 16px;
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }
</style>
