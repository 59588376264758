import { BASE_API_URL } from '../config';
import { getJwt, getJwtPayload } from '../util/json-web-tokens';
import { GmsError, RedirectError } from './errors';
import {
  isAuthenticated,
  institutionMasquerade,
  snackbar,
  snackbarMessage,
  account,
  errorMessage,
  apiVersion,
} from '../stores/core-store';
import Cookies from 'js-cookie';
import { Logger } from './logs';
import { navigate } from 'svelte-routing';
import { GENERIC_ERROR_MESSAGE } from './constants';
import { stopMasquerading } from '../util/masquerade';
import { get } from 'svelte/store';
import { createAccountObjectFromPayload } from '../util/helpers/account-helpers';
import { sendUnityJsonWebTokenMessage, sendUnityLogoutMessage, sendUnityMinimumLogLevel } from './unity';
import { b64DecodeUnicode } from './util';
import * as Sentry from "@sentry/browser";


/**
 * refreshPromise represents a JSON Web Token refresh in progress.
 */
let refreshPromise = Promise.resolve();

/**
 * REFRESH_RATIO is the percent of the total JSON Web Token valid duration when the client should do a refresh.
 *
 * e.g., Total token valid duration: 5 minutes
 *       REFRESH_RATIO = 0.2;
 *       The token will refresh after 1 minute
 */
const REFRESH_RATIO = 0.25;

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function apiGet(path, headers = defaultHeaders) {
  const response = await fetch(`${BASE_API_URL}${path}`, {
    method: 'GET',
    headers,
  });

  if (!response.headers.has('X-ApiVersion-GMS')) {
    throw new GmsError(`There was a problem identifying the server! ${GENERIC_ERROR_MESSAGE}`);
  }

  return response;
}

async function apiPost(path, body = {}, headers = defaultHeaders) {
  const response = await fetch(`${BASE_API_URL}${path}`, {
    method: 'POST',
    headers,
    body: body instanceof FormData ? body : JSON.stringify(body),
  });

  if (!response.headers.has('X-ApiVersion-GMS')) {
    throw new GmsError(`There was a problem identifying the server! ${GENERIC_ERROR_MESSAGE}`);
  }

  return response;
}

/**
 * Allows authenticated fetches to be aborted.
 * @type {AbortController}
 * */
let controller;

let lastMinimumLogLevel = -1;

// All authenticated requests need to go through this in order to handle middleware errors.
async function authenticatedFetch(resource, init) {
  // If the JSON Web Token is being refreshed, wait on that.
  await refreshPromise;

  controller = new AbortController();
  init = { ...init, signal: controller.signal };

  let isMasquerading = false;
  institutionMasquerade.subscribe((institution) => {
    if (institution !== null) {
      init.headers['X-Masquerade-GMS'] = institution.institutionId;
      isMasquerading = true;
    }
  });

  const response = await fetch(resource, init);

  if (!response.headers.has('X-ApiVersion-GMS')) {
    // Abort all other fetch requests.
    controller.abort();

    throw new GmsError(`There was a problem identifying the server! ${GENERIC_ERROR_MESSAGE}`);
  }

  if (isMasquerading && response.status == 401) {
    // Abort all other fetch requests.
    controller.abort();

    // Masquerading and received 401, likely an institution revoked GIGXR access to its data.
    stopMasquerading();
    sendUnityLogoutMessage();

    const _snackbar = get(snackbar);
    if (!_snackbar.isOpen()) {
      snackbarMessage.set('Masquerading access revoked by institution!');
      _snackbar.open();
    }
    throw new RedirectError('Masquerading access revoked!', '/');
  }

  // No record of one or more required consents!
  if (response.status === 451) {
    // Abort all other fetch requests.
    controller.abort();

    if (response.headers.has('X-SecretToken-GMS')) {
      Logger.log('Consent invalid, redirecting to consent page...');
      Cookies.remove('jwt');
      isAuthenticated.set(false);
      const secretToken = response.headers.get('X-SecretToken-GMS');

      errorMessage.set('');

      // This is for calls that properly implement try/catch
      navigate(`/consent/${secretToken}`);

      // This is for calls that do not implement try/catch so it bubbles to the global handler.
      throw new RedirectError('Consent invalid!', `/consent/${secretToken}`);
    } else {
      // Shouldn't happen during normal application flow.
      Logger.log('Consent invalid, invalid headers, logging out...');
      throw new RedirectError('Consent invalid!', '/logout');
    }
  }

  // JWT is expired or revoked!
  if (response.status === 401) {
    // Abort all other fetch requests.
    controller.abort();

    let redirectUrl = window.location.pathname;

    // Example WWW-Authenticate headers:
    // Bearer error="invalid_token"
    // Bearer error="invalid_token", error_description="The signature is invalid"
    // Bearer error="invalid_token", error_description="The token expired at '07/09/2020 20:05:55'"

    if (response.headers.has('X-AccountRoleChanged-GMS')) {
      Logger.log('Account role has changed (detected server-side), redirecting to login...');
      redirectUrl += '?error=role';
    } else if (response.headers.has('X-AccountInactive-GMS')) {
      Logger.log('Account inactive (detected server-side), redirecting to login...');
      redirectUrl += '?error=inactive';
    } else {
      Logger.log('JWT is expired or revoked (detected server-side), redirecting to login...');
      redirectUrl += '?error=expired';
    }

    Cookies.remove('jwt');
    isAuthenticated.set(false);
    stopMasquerading();
    sendUnityLogoutMessage();

    // We need to hard reload because the dashboard ("/") shares the same path as the login page ("/").
    // If we navigate to the same page via svelte-routing::navigate() it will not really reload the page and it will get stuck.
    window.location = redirectUrl;
  }

  // Generic server error
  if (response.status === 500) {
    throw new GmsError(`A server error has occurred. ${GENERIC_ERROR_MESSAGE}`);
  }

  if (response.headers.has('X-AccountProfile-GMS')) {
    const accountProfileEncoded = response.headers.get('X-AccountProfile-GMS');
    const accountProfile = JSON.parse(b64DecodeUnicode(accountProfileEncoded));

    if (lastMinimumLogLevel !== accountProfile.gigMobileMinimumLogLevel) {
      lastMinimumLogLevel = accountProfile.gigMobileMinimumLogLevel;
      Logger.log('Updating log level');
      sendUnityMinimumLogLevel(lastMinimumLogLevel);
    }

    // Update account, accountrole for sentry tracking
    try {
      Sentry.setUser({ email: accountProfile.email });
      Sentry.setTag('accountRole', accountProfile.accountRole);
    } catch (error) { }

    account.update((a) => {
      a.accountRole = accountProfile.accountRole;
      a.firstName = accountProfile.firstName;
      a.lastName = accountProfile.lastName;
      a.departmentIds = accountProfile.departmentIds;
      a.classIds = accountProfile.classIds;
      return a;
    });
  }

  if (response.headers.has('X-ApiVersion-GMS')) {
    apiVersion.set(response.headers.get('X-ApiVersion-GMS'));
  }

  return response;
}

let jsonWebTokenRefreshTimerId;

async function setJsonWebTokenRefreshTimer() {
  const payload = getJwtPayload(getJwt());
  const refreshAt = ((payload.exp - payload.iat) * REFRESH_RATIO + payload.iat) * 1000;

  const now = Date.now();
  if (now < refreshAt) {
    // No need to refresh yet, schedule a refresh.
    Logger.log(
      `Token Id:   ${payload.jti}\nIssued at:  ${new Date(payload.iat * 1000)}\nExpires at: ${new Date(
        payload.exp * 1000,
      )}\nRefresh at: ${new Date(refreshAt)}`,
    );
    const refreshDelay = refreshAt - now;
    jsonWebTokenRefreshTimerId = setTimeout(async () => {
      Logger.log('Running token refresh job...');
      refreshPromise = await refreshJsonWebToken();
    }, refreshDelay);
    return;
  }

  // Refresh now
  Logger.log('Refreshing JWT now...');
  refreshPromise = await refreshJsonWebToken();
}

function clearJsonWebTokenRefreshTimer() {
  clearTimeout(jsonWebTokenRefreshTimerId);
}

async function refreshJsonWebToken() {
  const response = await authenticatedPost('/accounts/login/refresh');
  const json = await response.json();
  Cookies.set('jwt', json.data.jsonWebToken);
  setJsonWebTokenRefreshTimer();
  const payload = getJwtPayload(json.data.jsonWebToken);
  account.set(createAccountObjectFromPayload(payload));
  sendUnityJsonWebTokenMessage();
}

async function authenticatedGet(path, headers = defaultHeaders) {
  const jwt = getJwt();

  return authenticatedFetch(`${BASE_API_URL}${path}`, {
    method: 'GET',
    headers: {
      ...headers,
      'X-Auth-GMS': `Bearer ${jwt}`,
    },
  });
}

async function authenticatedPost(path, body = {}, headers = defaultHeaders) {
  const jwt = getJwt();

  return authenticatedFetch(`${BASE_API_URL}${path}`, {
    method: 'POST',
    headers: {
      ...headers,
      'X-Auth-GMS': `Bearer ${jwt}`,
    },
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
}

async function authenticatedPut(path, body = {}, headers = defaultHeaders) {
  const jwt = getJwt();

  return authenticatedFetch(`${BASE_API_URL}${path}`, {
    method: 'PUT',
    headers: {
      ...headers,
      'X-Auth-GMS': `Bearer ${jwt}`,
    },
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
}

async function authenticatedDelete(path, headers = defaultHeaders) {
  const jwt = getJwt();

  return authenticatedFetch(`${BASE_API_URL}${path}`, {
    method: 'DELETE',
    headers: {
      ...headers,
      'X-Auth-GMS': `Bearer ${jwt}`,
    },
  });
}

async function authenticatedPatch(path, body = {}, headers = defaultHeaders) {
  const jwt = getJwt();

  return authenticatedFetch(`${BASE_API_URL}${path}`, {
    method: 'PATCH',
    headers: {
      ...headers,
      'X-Auth-GMS': `Bearer ${jwt}`,
    },
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
}


function replaceDoubleSlash(string) {
  // quick fix for url having extra slash.  couldn't find where this extra slash was coming from
  return string.replace('gigxr.com//', 'gigxr.com/');
}

export {
  apiGet,
  apiPost,
  setJsonWebTokenRefreshTimer,
  clearJsonWebTokenRefreshTimer,
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
  authenticatedDelete,
  authenticatedPatch,
  replaceDoubleSlash
};
