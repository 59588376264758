<script>
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import { canEditAccount } from '../../util/api/accounts';
  import { createEventDispatcher } from 'svelte';
  import { getAccountId } from '../../util/account';

  export let account;

  let menu;
  let open;

  const dispatch = createEventDispatcher();

  function toggleMenu(event) {
    menu.setOpen(!open);
  }
</script>

<IconButton id="account-menu-button-{account.accountId}" class="material-icons" on:click={toggleMenu}>
  more_vert
</IconButton>

<Menu bind:open bind:this={menu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
  <List>
    <Item
      id="account-menu-view-link-{account.accountId}"
      on:SMUI:action={() => navigate(`/users/view/${account.accountId}`)}
    >
      <Text>View</Text>
    </Item>
    {#if canEditAccount(account)}
      <Item id="account-menu-edit-link" on:SMUI:action={() => navigate(`/users/edit/${account.accountId}`)}>
        <Text>Edit</Text>
      </Item>
      {#if account.accountId !== getAccountId()}
        <Item id="account-menu-delete-link" on:SMUI:action={() => dispatch('AccountListMenuButton::delete', account)}>
          <Text>Delete</Text>
        </Item>
      {/if}
    {/if}
  </List>
</Menu>

<style>
</style>
