<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import { authenticatedPost, apiPost } from '../util/api';
  import LoadingView from '../components/LoadingView.svelte';

  export let token;

  let loading = true;

  onMount(async () => {
    if (!token) {
      navigate('/');
      return;
    }

    const response = await apiPost(`/accounts/email-verification/${token}`);

    if (!response.ok) {
      navigate('/');
      return;
    }

    loading = false;
  });
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    {#if loading}
      <LoadingView />
    {:else}
      <UnauthenticatedPageHeader>Thanks for verifying your new email address.</UnauthenticatedPageHeader>

      <UnauthenticatedPageSubheader>You've successfully changed your email address.</UnauthenticatedPageSubheader>

      <UnauthenticatedPageLink id="dashboard-link" href="/" label="Dashboard" />
    {/if}
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
</style>
