<script>
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';
  import Icon from '@smui/select/icon/index';
  import Ripple from '@smui/ripple';

  export let id = '';
  export let header = 'Header';
  export let open = false;

  const dispatch = createEventDispatcher();

  function clickHandler(event) {
    open = !open;
    dispatch('click', open);
  }
</script>

<div class="gigxr-accordian">
  <button {id} class="gigxr-accordian__header" on:click={clickHandler} use:Ripple={{ ripple: true, color: 'surface' }}>
    <h2 class="gigxr-accordian__header-label">{header}</h2>
    <Icon class="material-icons">
      {#if open}keyboard_arrow_up{:else}keyboard_arrow_down{/if}
    </Icon>
  </button>
  {#if open}
    <!-- local directive is needed to prevent broken page transitions -->
    <!-- https://github.com/sveltejs/svelte/issues/3685 -->
    <div class="gigxr-accordian__content" transition:slide|local={{ duration: 200 }}>
      <div class="gigxr-accordian__content-inner">
        <slot />
      </div>
    </div>
  {/if}
</div>

<style>
  .gigxr-accordian__header {
    background: #fff;
    border: none;
    border-bottom: 1px solid #eef2f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75em 1em;
    text-align: left;
    width: calc(100%);
  }

  .gigxr-accordian__header:hover {
    cursor: pointer;
  }

  .gigxr-accordian__header-label {
    margin: 0;
  }

  .gigxr-accordian__content {
    background: #eef2f4;
    padding: 1em;
  }

  .gigxr-accordian__content-inner {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 1em;
  }
</style>
