<script>
</script>

<!-- Supress bugged slot warning  -->
<!-- TODO: Remove when this is fixed: https://github.com/sveltejs/svelte/issues/4546 -->
{#if false}
  <slot />
{/if}

<div class="key-value-row">
  <slot name="left" />
  <slot name="right" />
</div>

<style>
  .key-value-row {
    margin: 0 0 1em 0;
  }

  :global(.key-value-row div[slot='left']) {
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  @media (min-width: 1100px) {
    .key-value-row {
      display: flex;
    }

    :global(.key-value-row div[slot='left']) {
      flex: 45%;
    }

    :global(.key-value-row div[slot='right']) {
      margin-left: 15px;
      flex: 55%;
    }
  }
</style>
