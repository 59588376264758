<script>
  import { createEventDispatcher } from 'svelte';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';

  export let dialog = undefined;
  export let count = 0;

  let deleteConfirmation = '';
  const dispatch = createEventDispatcher();

  async function deleteHandler(event) {
    dispatch('DeleteAccountsDialog::deleteConfirmed');
    deleteConfirmation = '';
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="delete-account">
  <GigXrDialogContent>
    <GigXrDialogIcon />

    <p>
      <strong>WARNING:</strong>
      You are about to delete
      <strong>{count} User{count === 1 ? '' : 's'}</strong>.<br /><br />
      <strong>THIS CANNOT BE UNDONE.</strong>
    </p>

    <p>They will lose access to GIGXR and their email address will be available for use again.</p>

    <p>To proceed, type DELETE and click the DELETE USER{count === 1 ? '' : 'S'} button.</p>
    <input type="text" class="gigxr-input" bind:value={deleteConfirmation} />
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={deleteHandler} disabled={deleteConfirmation !== 'DELETE'}>
      Delete User{count === 1 ? '' : 's'}
    </GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
