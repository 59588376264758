<script>
  import { onMount } from 'svelte';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import UnauthenticatedPageErrorBanner from '../components/unauthenticated/UnauthenticatedPageErrorBanner.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import UnauthenticatedPageRow from '../components/unauthenticated/UnauthenticatedPageRow.svelte';
  import Checkbox from '@smui/checkbox';
  import FormField from '@smui/form-field';
  import { fetchConsentDisplayInformation, updateConsentRecords } from '../util/api/accounts';
  import LoadingView from '../components/LoadingView.svelte';
  import { navigate } from 'svelte-routing';
  import { isAuthenticated } from '../stores/core-store';
  import Cookies from 'js-cookie';
  import { Logger } from '../util/logs';
  import { GmsError } from '../util/errors';
  import { link } from 'svelte-routing';
  import { b64EncodeUnicode } from '../util/util';

  export let token;

  let loading = true;
  let termsSelected = false;
  let notificationsSelected = false;
  let consentDisplayInformation = {
    accountId: '',
    registrationStatus: '',
    firstName: '',
    institutionName: '',
    consentsByType: {},
  };
  let consentsSelected = [];
  let isTokenValid = false;
  let form;

  onMount(async () => {
    try {
      Cookies.remove('jwt');
      isAuthenticated.set(false);
      consentDisplayInformation = await fetchConsentDisplayInformation(token);
      isTokenValid = true;
    } catch (error) {
      // Invalid consent token
      Logger.log('Invalid consent token');
    } finally {
      loading = false;
    }
  });

  async function agreeButtonHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      throw new GmsError('You must check both boxes to continue.');
    }

    const consentRecords = Object.values(consentDisplayInformation.consentsByType).map((consent) => {
      return {
        consentId: consent.consentId,
        consentValue: consentsSelected.includes(consent.consentType),
      };
    });

    const secretToken = await updateConsentRecords(token, 'SignUp', consentRecords);

    if (consentDisplayInformation.registrationStatus === 'Registered') {
      navigate(`/complete-registration/${secretToken}?cb=${b64EncodeUnicode(consentDisplayInformation.firstName)}`);
    } else {
      navigate(`/complete-registration/${secretToken}`);
    }
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    {#if loading}
      <LoadingView />
    {:else if isTokenValid}
      <UnauthenticatedPageHeader class="consent-welcome">
        {consentDisplayInformation.firstName}, welcome to
      </UnauthenticatedPageHeader>

      <UnauthenticatedPageLogo class="consent-logo" />

      <UnauthenticatedPageHeader>for {consentDisplayInformation.institutionName}</UnauthenticatedPageHeader>

      <UnauthenticatedPageErrorBanner />

      <UnauthenticatedPageSubheader>
        Before you can use GIGXR you must agree to our Terms of Use and Privacy Policy and accept email notifications
        about your account.
      </UnauthenticatedPageSubheader>

      <!-- Using a real form will submit on enter -->
      <form class="consent-form" bind:this={form}>
        <div class="consent-form__items">
          <UnauthenticatedPageRow>
            <FormField inputId="terms-and-privacy-field">
              <Checkbox bind:group={consentsSelected} value="Terms" input$required />
              <span slot="label">
                I agree to the GIGXR
                <a id="terms-of-use-link" href="/terms-of-use" target="_blank" use:link>Terms of Use</a>
                and
                <a id="privacy-policy-link" href="/privacy-policy" target="_blank" use:link>Privacy Policy</a>
              </span>
            </FormField>
          </UnauthenticatedPageRow>

          <UnauthenticatedPageRow>
            <FormField inputId="email-notifications-field">
              <Checkbox bind:group={consentsSelected} value="Email" input$required />
              <span slot="label">
                I agree to receive email notifications about my account to the email address on file in my account.
              </span>
            </FormField>
          </UnauthenticatedPageRow>
        </div>

        <UnauthenticatedPageButton id="agree-button" label="I Agree" on:click={agreeButtonHandler} />
      </form>
    {:else}
      <UnauthenticatedPageLogo />

      <UnauthenticatedPageHeader>Link Expired!</UnauthenticatedPageHeader>

      <UnauthenticatedPageSubheader>
        This link has expired. Please contact your institution admin to get another one.
      </UnauthenticatedPageSubheader>

      <UnauthenticatedPageButton id="back-to-home-button" label="Back to Home" on:click={() => navigate('/')} />
    {/if}
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  :global(.consent-welcome) {
    margin-top: 2em;
  }

  :global(.consent-logo) {
    margin: 0 !important;
  }

  .consent-form__items {
    text-align: left;
    margin-bottom: 2.5em;
  }

  a {
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    :global(.consent-welcome) {
      margin-top: 20px;
    }
  }
</style>
