import { isAuthenticated } from '../stores/core-store';
import { fetchSessionClips, fetchSessionResources } from './api/sessions';
import {
  getClientAppId,
  isLegacyHoloPatient,
  isUnity,
  sendUnityDownloadClipsMessage,
  sendUnityDownloadResourcesMessage,
  sendUnityGetContentPreferencesMessage,
  subscribeToUnityMessage,
} from './unity';

let pollGetContentPreferencesTimeout;

function setupAutomaticBackgroundDownloads() {
  if (!isUnity()) {
    return;
  }

  let unsubscribeContentPreferences = () => {};

  isAuthenticated.subscribe((isAuth) => {
    if (!isAuth) {
      // Unauthenticated, do nothing.
      unsubscribeContentPreferences();
      clearTimeout(pollGetContentPreferencesTimeout);
      return;
    }

    unsubscribeContentPreferences = subscribeToUnityMessage(UnityAction.CONTENT_PREFERENCES_RESPONSE, (payload) => {
      const contentPreferences = payload;

      if (!contentPreferences.allowAutomaticBackgroundDownloads) {
        // Automatic background downloads off, do nothing.
        return;
      }

      // We are authenticated, and automatic background downloads are on, so start them if needed.

      if (isLegacyHoloPatient(getClientAppId())) {
        // HoloPatient 2.0
        startAllClipDownloads();
      } else {
        // Platform
        startAllResourceDownloads();
      }
    });

    pollGetContentPreferences();
  });
}

function pollGetContentPreferences() {
  const REFRESH_TIME_IN_MINUTES = 5;
  sendUnityGetContentPreferencesMessage();

  pollGetContentPreferencesTimeout = setTimeout(() => {
    pollGetContentPreferences();
  }, 1000 * 60 * REFRESH_TIME_IN_MINUTES);
}

// HoloPatient 2.0
async function startAllClipDownloads() {
  const clips = (await fetchSessionClips()).map((clip) => clip.clip);

  sendUnityDownloadClipsMessage(clips);
}

// Platform
async function startAllResourceDownloads() {
  const resourceIds = (await fetchSessionResources()).map((view) => view.resourceId);

  sendUnityDownloadResourcesMessage(resourceIds);
}

export { setupAutomaticBackgroundDownloads, startAllClipDownloads, startAllResourceDownloads };
