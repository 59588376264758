<script>
  let className = '';
  export { className as class };

  export let center = false;
</script>

<p class="unauthenticated-page__paragraph {center ? 'unauthenticated-page__paragraph--center' : ''} {className}">
  <slot />
</p>

<style>
  .unauthenticated-page__paragraph {
    margin: 1em 0;
    text-align: left;
  }

  .unauthenticated-page__paragraph {
    text-align: center;
  }
</style>
