<script>
</script>

<!-- Supress bugged slot warning  -->
<!-- TODO: Remove when this is fixed: https://github.com/sveltejs/svelte/issues/4546 -->
{#if false}
  <slot />
{/if}

<div class="controls">
  <slot name="left" />
  <slot name="right" />
</div>

<style>
  .controls {
    display: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
  }

  :global(.controls div[slot='left']) {
    display: flex;
    align-items: center;
  }

  /* First-level children */
  :global(.controls div[slot='left'] > *) {
    margin-right: 10px;
  }

  :global(.controls div[slot='right']) {
    display: flex;
    align-items: center;
  }

  /* First-level children */
  :global(.controls__right div[slot='right'] > *) {
    margin-left: 10px;
  }

  @media (min-width: 1100px) {
    .controls {
      display: flex;
    }
  }
</style>
