<script context="module">
  import { writable } from 'svelte/store';

  let dialog;

  let account = writable(null);
  let successCallback = () => {};

  export function openRemoveStudentFromClassDialog(_account, _successCallback = () => {}) {
    account.set(_account);
    successCallback = _successCallback;
    dialog.open();
  }
</script>

<script>
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
</script>

<GigXrDialog bind:dialog ariaPrefix="remove-student-class">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You are about to remove
    {$account ? `${$account.firstName} ${$account.lastName}` : 'a student'}
    from this class. They will continue to have access to all previous work for this class, but will no longer be able
    to participate in this class.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={() => successCallback()}>Remove Student</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
