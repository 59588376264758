import UnhandledRejectionOverlay from './UnhandledRejectionOverlay';
import { getAccountId } from './account';
import { navigate } from 'svelte-routing';
import { Logger } from './logs';
import { GmsError, RedirectError } from './errors';
import { isAuthenticated, errorMessage } from '../stores/core-store';
import { GENERIC_ERROR_MESSAGE } from './constants';
import { b64EncodeUnicode } from './util';
import * as Sentry from "@sentry/browser";

function unhandledRejectionHandler(event) {
  const networkErrors = [
    'fetch', // Chrome
    'NetworkError', // Firefox
    'A server with the specified hostname could not be found.', // Safari and iOS
  ];

  if (
    !event.reason.response &&
    event.reason instanceof TypeError &&
    networkErrors.some((e) => event.reason.message.includes(e))
  ) {
    // Network-related error, e.g., API down, etc.
    errorMessage.set(`There was a problem communicating to the server. ${GENERIC_ERROR_MESSAGE}`);
    return;
  }

  if (event.reason instanceof GmsError) {
    errorMessage.set(event.reason.message);
    return;
  }

  if (event.reason instanceof RedirectError) {
    event.preventDefault();
    Logger.log(`Redirecting to ${event.reason.location} because: ${event.reason.message}`);
    navigate(event.reason.location);
    return;
  }

  if (event.reason.name === 'AbortError') {
    Logger.log('AbortError');
    return;
  }

  // Accessing stores by $ is not available here, so we need to use plain JavaScript in order to
  // get the store value.
  let isAuth;
  isAuthenticated.update((bool) => {
    isAuth = bool;
    return bool;
  });
  const debugText = `datetime: ${new Date().toUTCString()} accountId: ${isAuth ? getAccountId() : 'Unauthenticated'}
   href: ${window.location.href} error: ${event.reason.stack} `;


  document.body.append(UnhandledRejectionOverlay(Sentry.lastEventId()));
  // Maybe provide this on the page directly later for users to give to support.
  console.error(b64EncodeUnicode(debugText));
}

export { unhandledRejectionHandler };
