import { authenticatedGet, authenticatedPatch, authenticatedPost, authenticatedPut } from '../api';
import { getAccountId } from '../account';
import { AccountRole } from './accounts';
import { GmsError, RedirectError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { get } from 'svelte/store';

/**
 * Fetch a list of versions
 * @returns Array a list of versions
 * @TODO currently, the HoloScenarios clientAppId is baked into this method. Eventually, we need to remove it from here.
 */
async function fetchVersions() {

    let data = [];
    // NOTE: hard-coding the HoloScenarios ClientAppId here.
    // TODO: Please remove when we have a better way to doing this
    const clientAppId = '5516eb34-611a-4eac-b2d6-834a8c1d4509';
    // NOTE: hard-coding the API version header here
    // TODO: Please remove when we have a better way to doing this
    const headers = {
        'api-version': '1.1'
      };
    const response = await authenticatedGet(`/client-apps/${clientAppId}/versions`, headers);

    // let's not throw an error. Instead, let's do something about it.
    if (!response.ok) {
        //throw new GmsError(`Error fetching versions! ${GENERIC_ERROR_MESSAGE}`);
        data = [];
        return data;
    } else {
        data = await response.json();
        return data.data;
    }

    //return data;

}

export { fetchVersions };