<script>
  import { onMount } from 'svelte';
  import Textfield from '@smui/textfield';
  import List, { Item, Text, Meta, Graphic } from '@smui/list';
  import HelperText from '@smui/textfield/helper-text/index';
  import FormattedSearchItem from './FormattedSearchItem.svelte';
  import { escapeRegExp } from '../util/util';

  export let items = [];
  export let query = '';
  export let label = '';
  export let onSelectFunction = (item) => {};
  export let valueFunction = (item) => item;
  export let helperText = '';

  let matchedItems = [];
  let matchedItemsRangeMap = new Map();

  $: {
    matchedItemsRangeMap.clear();
    const regExp = new RegExp(escapeRegExp(query), 'i');
    matchedItems = items.filter((item) => {
      const match = valueFunction(item).match(regExp);
      if (!match) {
        return false;
      }

      matchedItemsRangeMap.set(item, {
        start: match.index,
        end: match.index + match[0].length,
      });

      return true;
    });
  }

  let typeaheadWrapper;
  let listOpen = false;

  onMount(async () => {
    // The input has to be binded like this because SMUI doesn't forward on:focus|blur events.
    const input = typeaheadWrapper.querySelector('.mdc-text-field__input');
    input.addEventListener('focus', () => (listOpen = true));
    input.addEventListener('blur', (event) => {
      if (
        !event.relatedTarget ||
        !event.relatedTarget.classList ||
        !event.relatedTarget.classList.contains('typeahead-list__item')
      ) {
        // If not clicking on the menu, then close it.
        listOpen = false;
      }
      // If clicking on the menu, then close needs to be handled by the onClickHandler so the menu doesn't close before
      // the callback finishes.
    });
  });

  function clickHandler(event, item) {
    onSelectFunction(item);
    listOpen = false;
  }
</script>

<div class="typeahead-wrapper" bind:this={typeaheadWrapper}>
  <Textfield bind:value={query} {label} fullwidth />

  {#if listOpen}
    <List class="typeahead-list">
      {#if matchedItems.length > 0}
        {#each matchedItems.slice(0, 5) as item, index (index + query)}
          <Item class="typeahead-list__item" on:click={(event) => clickHandler(event, item)}>
            <Text>
              <FormattedSearchItem value={valueFunction(item)} match={query} />
            </Text>
          </Item>
        {/each}
      {:else}
        <Item>
          <Text>No matches found.</Text>
        </Item>
      {/if}
    </List>
  {/if}

  {#if helperText}
    <HelperText>{helperText}</HelperText>
  {/if}
</div>

<style>
  :global(.typeahead-wrapper .mdc-text-field-helper-line) {
    padding-left: 0;
  }

  :global(.typeahead-list) {
    position: absolute;
    border: thin solid #ddd;
    background: #fff;
    z-index: 1;
  }
</style>
