<script>
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import WorkInProgress from '../components/WorkInProgress.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';

  function returnToSiteHandler(event) {
    if (window.opener) {
      window.close();
      return;
    }
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    <UnauthenticatedPageHeader>Privacy Policy</UnauthenticatedPageHeader>

    <div class="privacy-policy">
      <p>Effective as of [September 10, 2020].</p>

      <p>
        This “Privacy Policy” describes the privacy practices of GIGXR Inc and our subsidiaries and affiliates
        (collectively, “GIGXR”, “we”, “us”, or “our”) in connection with the www.gigxr.com website, the GIGXR’s mobile
        applications, and any other website that we own or control and which posts or links to this Privacy Policy
        (collectively, the “services”), and the rights and choices available to individuals with respect to their
        information.
      </p>

      <p>
        California residents can learn about their additional privacy rights in the “Additional Information for
        consumers located in California” section below.
      </p>

      <p>
        We provide important information for individuals located in the European Union, European Economic Area
        (https://eur-lex.europa.eu/summary/glossary/eea.html), and United Kingdom (collectively, “Europe” or “European”)
        <strong><a href="#notice-to-european-users">below</a>.</strong>
      </p>

      <p><strong>Table of Contents</strong></p>

      <ul>
        <li>
          <p><a href="#personal-information-we-collect"><strong>Personal Information We Collect</strong></a></p>
        </li>

        <li>
          <p>
            <a href="#how-we-use-your-personal-information"><strong>How We Use Your Personal Information</strong></a>
          </p>
        </li>

        <li>
          <p>
            <a href="#how-we-share-your-personal-information"><strong>How We Share your Personal Information</strong></a>
          </p>
        </li>

        <li>
          <p><a href="#your-choices"><strong>Your Choices</strong></a></p>
        </li>

        <li>
          <p>
            <a href="#other-sites-mobile-applications-and-services"><strong>Other sites, mobile applications and
                services</strong></a>
          </p>
        </li>

        <li>
          <p><a href="#security-practices"><strong>Security practices</strong></a></p>
        </li>

        <li>
          <p><a href="#international-data-transfers"><strong>International data transfers</strong></a></p>
        </li>

        <li>
          <p><a href="#children"><strong>Children</strong></a></p>
        </li>

        <li>
          <p><a href="#changes-to-this-privacy-policy"><strong>Changes to this Privacy Policy</strong></a></p>
        </li>

        <li>
          <p><a href="#how-to-contact-us"><strong>How to Contact Us</strong></a></p>
        </li>

        <li>
          <p><a href="#your-california-privacy-rights"><strong>Your California Privacy Rights</strong></a></p>
        </li>

        <li>
          <p><a href="#notice-to-european-users"><strong>Notice to European Users</strong></a></p>
        </li>

        <li>
          <p><a href="#cookie-policy"><strong>Cookie Policy</strong></a></p>
        </li>
      </ul>

      <h3 id="personal-information-we-collect">Personal Information we collect</h3>

      <p
        id="information-you-provide-to-us-or-provided-on-your-behalf-by-the-institution-through-which-you-are-using-our-platform.-personal-information-you-provide-to-us-through-the-service-or-otherwise-includes"
      >
        <strong>Information you provide to us, or provided on your behalf by the institution through which you are using
          our platform.</strong>
        Personal information you provide to us through the Service or otherwise includes:
      </p>

      <ul>
        <li>
          <p>
            <strong>Business and personal contact information</strong>, such as your first and last name, email, mailing
            address, classes, departments and other similar information.
          </p>
        </li>

        <li>
          <p>
            <strong>Content you choose to upload to the Service</strong>, such as text, images, audio, and video, along
            with the metadata associated with the files you upload.
          </p>
        </li>

        <li>
          <p>
            <strong>Profile information,</strong>
            such as your username and password that you may set to establish an online account with us, your photograph,
            interests, and preferences.
          </p>
        </li>

        <li>
          <p>
            <strong>Registration information,</strong>
            such as information that may be related to a service, an account or sessions you create or register for or
            participate in.
          </p>
        </li>

        <li>
          <p>
            <strong>Session Data and Content,</strong>
            such as sessions, content that will be part of those sessions, labels, participants and other data created
            as part of the operation of the Service.
          </p>
        </li>

        <li>
          <p>
            <strong>Feedback or correspondence</strong>, such as information you provide when you contact us with
            questions, feedback, or otherwise correspond with us online.
          </p>
        </li>

        <li>
          <p>
            <strong>Usage information</strong>, such as information about how you use the Service and interact with us,
            including information associated with any content you upload to the websites or otherwise submit to us, and
            information you provide when you use any interactive features of the Service.
          </p>
        </li>

        <li>
          <p>
            <strong>Marketing information</strong>, such as your preferences for receiving communications about our
            activities, events, and publications, and details about how you engage with our communications
          </p>
        </li>

        <li>
          <p>
            <strong>Other information</strong>
            that we may collect which is not specifically listed here, but which we will use in accordance with this
            Privacy Policy or as otherwise disclosed at the time of collection.
          </p>
        </li>
      </ul>

      <p
        id="information-we-obtain-from-third-parties.-we-may-maintain-pages-on-social-media-platforms-such-as-facebook-linkedin-twitter-google-youtube-instagram-and-other-third-party-platforms.-when-you-visit-or-interact-with-our-pages-on-those-platforms-the-platform-providers-privacy-policy-will-apply-to-your-interactions-and-their-collection-use-and-processing-of-your-personal-information.-you-or-the-platforms-may-provide-us-with-information-through-the-platform-and-we-will-treat-such-information-in-accordance-with-this-privacy-policy.-we-may-obtain-your-personal-information-from-other-third-parties-such-as-marketing-partners-publicly-available-sources-and-data-providers."
      >
        <strong>Information we obtain from third parties.</strong>
        We may maintain pages on social media platforms, such as Facebook, LinkedIn, Twitter, Google, YouTube,
        Instagram, and other third party platforms. When you visit or interact with our pages on those platforms, the
        platform provider’s privacy policy will apply to your interactions and their collection, use and processing of
        your personal information. You or the platforms may provide us with information through the platform, and we
        will treat such information in accordance with this Privacy Policy. We may obtain your personal information from
        other third parties, such as marketing partners, publicly-available sources and data providers.
      </p>

      <p
        id="cookies-and-other-information-collected-by-automated-means.-we-our-service-providers-and-our-business-partners-may-use-cookies-browser-web-storage-also-known-as-locally-stored-objects-or-lsos-web-beacons-and-similar-technologies-to-automatically-collect-information-about-your-interaction-with-our-services-through-your-computer-or-mobile-device-including"
      >
        <strong>Cookies and other information collected by automated means.</strong>
        We, our service providers, and our business partners may use cookies, browser web storage (also known as locally
        stored objects, or "LSOs"), web beacons, and similar technologies to automatically collect information about
        your interaction with our services through your computer or mobile device, including:
      </p>

      <ul>
        <li>
          <p
            id="device-data-such-as-your-computer-or-mobile-device-operating-system-type-and-version-number-manufacturer-and-model-device-identifier-browser-type-screen-resolution-ip-address-and-general-location-information-such-as-city-state-or-geographic-area"
          >
            <strong>Device data,</strong>
            such as your computer or mobile device operating system type and version number, manufacturer and model,
            device identifier, browser type, screen resolution, IP address, and general location information such as
            city, state or geographic area;
          </p>
        </li>

        <li>
          <p
            id="online-activity-data-such-as-the-website-you-visited-before-browsing-to-our-website-and-information-about-your-use-of-and-actions-on-our-websites-including-pages-or-screens-you-viewed-how-long-you-spent-on-a-page-or-screen-navigation-paths-between-pages-or-screens-information-about-your-activity-on-a-page-or-screen-access-times-and-length-of-access-and"
          >
            <strong>Online activity data,</strong>
            such as the website you visited before browsing to our website, and information about your use of and
            actions on our websites, including pages or screens you viewed, how long you spent on a page or screen,
            navigation paths between pages or screens, information about your activity on a page or screen, access
            times, and length of access; and
          </p>
        </li>

        <li>
          <p
            id="communications-response-data-such-as-whether-you-open-emails-we-send-you-and-the-links-and-other-actions-you-take-in-response-to-the-emails."
          >
            <strong>Communications response data,</strong>
            such as whether you open emails we send you, and the links and other actions you take in response to the
            emails.
          </p>
        </li>
      </ul>

      <p>See our <a href="#cookie-policy">Cookie Policy</a> for more information.</p>

      <h3 id="how-we-use-your-personal-information"><strong>How we use your Personal Information</strong></h3>

      <p id="to-operate-the-services.-we-use-your-personal-information-to">
        <strong>To operate the services.</strong>
        We use your personal information to:
      </p>

      <ul>
        <li>
          <p>provide, operate, maintain, secure and improve our services</p>
        </li>

        <li>
          <p>provide information about our products and services</p>
        </li>

        <li>
          <p>establish and maintain your user profile on the services</p>
        </li>

        <li>
          <p>
            enable security features of our services, such as by sending you security codes via email or SMS, and
            remembering devices from which you have previously logged in
          </p>
        </li>

        <li>
          <p>
            communicate with you about our services, including by sending you announcements, updates, security alerts,
            and support and administrative messages
          </p>
        </li>

        <li>
          <p>communicate with you about sessions in which you participate</p>
        </li>

        <li>
          <p>
            understand your needs and interests, and personalize your experience with the Service and our communications
          </p>
        </li>

        <li>
          <p>provide support and maintenance for our services</p>
        </li>

        <li>
          <p>respond to your requests, questions and feedback</p>
        </li>
      </ul>

      <p
        id="for-research-and-development.-to-analyze-and-improve-our-services-and-to-develop-new-products-and-services-including-by-studying-use-of-our-services."
      >
        <strong>For research and development.</strong>
        To analyze and improve our services and to develop new products and services, including by studying use of our
        services.
      </p>

      <p
        id="to-comply-with-law.-as-we-believe-necessary-or-appropriate-to-comply-with-applicable-laws-lawful-requests-and-legal-process-such-as-to-respond-to-subpoenas-or-requests-from-government-authorities."
      >
        <strong>To comply with law.</strong>
        As we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such
        as to respond to subpoenas or requests from government authorities.
      </p>

      <p id="compliance-fraud-prevention-and-safety">
        <strong>For compliance, fraud prevention, and safety.</strong>
        To: (a) protect our, your or others’ rights, privacy, safety or property (including by making and defending
        legal claims); (b) enforce the terms and conditions that govern our website and services; and (c) protect,
        investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
      </p>

      <p
        id="to-create-anonymous-data.-to-create-anonymous-data-from-your-personal-information-and-other-individuals-whose-personal-information-we-collect.-we-make-personal-information-into-anonymous-data-by-removing-information-that-makes-the-data-personally-identifiable-to-you."
      >
        <strong>To create anonymous data.</strong>
        To create anonymous data from your personal information and other individuals whose personal information we
        collect. We make personal information into anonymous data by removing information that makes the data personally
        identifiable to you.
      </p>

      <h3 id="how-we-share-your-personal-information">How we share your personal information</h3>

      <p>
        <strong>Service providers.</strong>
        We may share your personal information with third party companies and individuals that provide services on our
        behalf or help us operate our services (such as customer support, hosting, analytics, email delivery, marketing,
        and database management services). These third parties may use your personal information only as directed or
        authorized by us and in a manner consistent with this Privacy Policy, and are prohibited from using or
        disclosing your information for any other purpose.
      </p>

      <p>
        <strong>Other Users of the services and the Public.</strong>
        We may provide functionality that enables you to disclose personal information to other users of the services or
        the public. For instance, you may be able to maintain a user profile with information about yourself that you
        can make available to other users or the public. You may also be able to submit content to the Service (such as
        comments, reviews, surveys, blogs, photos, and videos), and we will display your name along with the content you
        submit. We do not control how other users or third parties use any personal information that you make available
        to other users or the public.
      </p>

      <p>
        <strong>Professional advisors.</strong>
        We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and
        insurers, where necessary in the course of the professional services that they render to us.
      </p>

      <p>
        <strong>For compliance, fraud prevention and safety.</strong>
        We may share your personal information for the compliance, fraud prevention and safety purposes
        <a href="#compliance-fraud-prevention-and-safety">described above</a>.
      </p>

      <p>
        <strong>Business transfers.</strong>
        We may sell, transfer or otherwise share some or all of our business or assets, including your personal
        information, in connection with a business transaction (or potential business transaction) such as a corporate
        divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy
        or dissolution. In such a case, we will make reasonable efforts to require the recipient to honor this Privacy
        Policy
      </p>

      <h3 id="your-choices">Your choices</h3>

      <p>
        In this section, we describe the rights and choices available to all users. Users who are located in California
        or the European Economic Area, Switzerland or the United Kingdom (collectively, “Europe”) can find additional
        information about their rights
        <a href="#notice-to-european-users">below</a>.
      </p>

      <p
        id="access-or-update-your-information.-if-you-have-registered-for-an-account-with-us-you-may-review-and-update-certain-personal-information-in-your-account-profile-by-logging-into-the-account."
      >
        <strong>Access or update your information.</strong>
        If you have registered for an account with us, you may review and update certain personal information in your
        account profile by logging into the account.
      </p>

      <p
        id="privacy-settings.-we-may-make-available-certain-privacy-settings-on-the-services-including-options-to-control-access-to-your-content."
      >
        <strong>Privacy settings.</strong>
        We may make available certain privacy settings on the services, including options to control access to your
        Content.
      </p>

      <p
        id="cookies-browser-web-storage.-most-browsers-let-you-remove-or-reject-cookies.-to-do-this-follow-the-instructions-in-your-browser-settings.-many-browsers-accept-cookies-by-default-until-you-change-your-settings.-please-note-that-if-you-set-your-browser-to-disable-cookies-the-services-may-not-work-properly.-similarly-your-browser-settings-may-allow-you-to-clear-your-browser-web-storage.-for-more-details-see-our-cookie-policy."
      >
        <strong>Cookies &amp; browser web storage.</strong>
        Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings.
        Many browsers accept cookies by default until you change your settings. Please note that if you set your browser
        to disable cookies, the services may not work properly. Similarly, your browser settings may allow you to clear
        your browser web storage. For more details, see our
        <a href="#cookie-policy">Cookie Policy</a>.
      </p>

      <p
        id="do-not-track.-some-internet-browsers-may-be-configured-to-send-do-not-track-signals-to-the-online-services-that-you-visit.-we-currently-do-not-respond-to-do-not-track-or-similar-signals.-to-find-out-more-about-do-not-track-please-visit-httpwww.allaboutdnt.com."
      >
        <strong>Do Not Track.</strong>
        Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit.
        We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please
        visit http://www.allaboutdnt.com.
      </p>

      <h3 id="other-sites-mobile-applications-and-services">Other sites, mobile applications and services</h3>

      <p
        id="our-website-may-contain-links-to-other-websites-mobile-applications-and-other-online-services-operated-by-third-parties.-these-links-are-not-an-endorsement-of-or-representation-that-we-are-affiliated-with-any-third-party.-in-addition-our-content-may-be-included-on-web-pages-or-in-mobile-applications-or-online-services-that-are-not-associated-with-us.-we-do-not-control-third-party-websites-mobile-applications-or-online-services-and-we-are-not-responsible-for-their-actions.-other-websites-and-services-follow-different-rules-regarding-the-collection-use-and-sharing-of-your-personal-information.-we-encourage-you-to-read-the-privacy-policies-of-the-other-websites-and-mobile-applications-and-online-services-you-use."
      >
        Our website may contain links to other websites, mobile applications, and other online services operated by
        third parties. These links are not an endorsement of, or representation that we are affiliated with, any third
        party. In addition, our content may be included on web pages or in mobile applications or online services that
        are not associated with us. We do not control third party websites, mobile applications or online services, and
        we are not responsible for their actions. Other websites and services follow different rules regarding the
        collection, use and sharing of your personal information. We encourage you to read the privacy policies of the
        other websites and mobile applications and online services you use.
      </p>

      <h3 id="security-practices">Security practices</h3>

      <p
        id="we-use-reasonable-organizational-technical-and-administrative-measures-designed-to-protect-against-unauthorized-access-misuse-loss-disclosure-alteration-and-destruction-of-personal-information-we-maintain.-unfortunately-data-transmission-over-the-internet-cannot-be-guaranteed-as-completely-secure.-therefore-while-we-strive-to-protect-your-personal-information-we-cannot-guarantee-the-security-of-personal-information.-in-the-event-that-we-are-required-to-notify-you-about-a-situation-involving-your-data-we-may-do-so-by-email-or-telephone-to-the-extent-permitted-by-law."
      >
        We use reasonable organizational, technical and administrative measures designed to protect against unauthorized
        access, misuse, loss, disclosure, alteration and destruction of personal information we maintain. Unfortunately,
        data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to
        protect your personal information, we cannot guarantee the security of personal information. In the event that
        we are required to notify you about a situation involving your data, we may do so by email or telephone to the
        extent permitted by law.
      </p>

      <h3 id="international-data-transfers">International data transfers</h3>

      <p
        id="we-are-headquartered-in-the-united-states-and-have-service-providers-in-other-countries-and-your-personal-information-may-be-transferred-to-the-united-states-or-other-locations-outside-of-your-state-province-or-country-where-privacy-laws-may-not-be-as-protective-as-those-in-your-state-province-or-country."
      >
        We are headquartered in the United States and have service providers in other countries, and your personal
        information may be transferred to the United States or other locations outside of your state, province, or
        country where privacy laws may not be as protective as those in your state, province, or country.
      </p>

      <p>
        European users should read the important information provided
        <a href="#notice-to-european-users">below</a>
        about transfer of personal information outside of Europe.
      </p>

      <h3 id="children">Children</h3>

      <p
        id="our-website-and-services-are-not-intended-for-children-and-we-do-not-collect-personal-information-from-them.-we-define-children-as-follows"
      >
        Our website and services are not intended for children, and we do not collect personal information from them. We
        define "children" as follows:
      </p>

      <ul>
        <li>
          <p id="residents-outside-of-europe-anyone-under-13-years-old-and">
            Residents outside of Europe: anyone under 13 years old; and
          </p>
        </li>

        <li>
          <p
            id="residents-of-europe-anyone-under-16-years-old-or-the-age-needed-to-consent-to-the-processing-of-personal-information-in-your-country-of-residence."
          >
            Residents of Europe: anyone under 16 years old, or the age needed to consent to the processing of personal
            information in your country of residence.
          </p>
        </li>
      </ul>

      <p
        id="if-we-learn-we-have-collected-or-received-personal-information-from-a-child-without-verification-of-parental-consent-we-will-delete-the-information.-if-you-believe-we-might-have-any-information-from-or-about-a-child-please-contact-use-at-privacygigxr.com."
      >
        If we learn we have collected or received personal information from a child without verification of parental
        consent, we will delete the information. If you believe we might have any information from or about a child,
        please contact use at privacy@gigxr.com.
      </p>

      <h3 id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h3>

      <p
        id="we-reserve-the-right-to-modify-this-privacy-policy-at-any-time.-if-we-make-material-changes-to-this-privacy-policy-we-will-notify-you-by-updating-the-date-of-this-privacy-policy-and-posting-it-on-our-website.-we-may-also-provide-notification-of-changes-in-another-way-that-we-believe-is-reasonably-likely-to-reach-you-such-as-via-e-mail-if-you-have-an-account-where-we-have-your-contact-information-or-another-manner-through-our-services."
      >
        We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy
        Policy, we will notify you by updating the date of this Privacy Policy and posting it on our website. We may
        also provide notification of changes in another way that we believe is reasonably likely to reach you, such as
        via e-mail (if you have an account where we have your contact information) or another manner through our
        services.
      </p>

      <p>
        Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon
        implementation of the new changes on our website (or as otherwise indicated at the time of posting). In all
        cases, your continued use of the services after the posting of any modified Privacy Policy indicates your
        acceptance of the terms of the modified Privacy Policy.
      </p>

      <h3 id="how-to-contact-us">How to contact us</h3>

      <p
        id="please-direct-any-questions-or-comments-about-this-policy-or-privacy-practices-to-privacygigxr.com.-you-may-also-write-to-us-via-postal-mail-at"
      >
        Please direct any questions or comments about this Policy or privacy practices to privacy@gigxr.com. You may
        also write to us via postal mail at:
      </p>

      <address>
        <strong>GIGXR Inc</strong><br />
        Attn: Legal – Privacy<br />
        1318 Pacific Avenue<br />
        Venice, CA 90291
      </address>

      <h3 id="your-california-privacy-rights">Additional information for consumers located in California</h3>

      <p>
        This section applies only to California residents. For purposes of this section, “Personal Information” has the
        meaning given in the California Consumer Privacy Act of 2018 (“CCPA”) but does not include information exempted
        from the scope of the CCPA.
      </p>

      <p>We do not sell personal information.</p>

      <p>
        <strong>Your California privacy rights.</strong>
        The CCPA grants individuals whose information is governed by the CCPA the following rights:
      </p>

      <ul>
        <li>
          <p>
            <strong>Information.</strong>
            You can request information about how we have collected and used your Personal Information during the past
            12 months. We have made this information available to California residents without having to request it by
            including it in this Privacy Policy.
          </p>
        </li>

        <li>
          <p>
            <strong>Access.</strong>
            You can request a copy of the Personal Information that we have collected about you during the past 12
            months.
          </p>
        </li>

        <li>
          <p>
            <strong>Deletion.</strong>
            You can ask us to delete the Personal Information that we have collected from you.
          </p>
        </li>
      </ul>

      <p>You are entitled to exercise the rights described above free from discrimination.</p>

      <p>
        Please note that the CCPA limits these rights by, for example, prohibiting us from providing certain sensitive
        information in response to an access request and limiting the circumstances in which we must comply with a
        deletion request. If we deny your request, we will communicate our decision to you.
      </p>

      <p><strong>How to exercise your rights</strong></p>

      <p>You may exercise your California privacy rights described above as follows:</p>

      <ul>
        <li>
          <p>
            <strong>Right to information, access and deletion.</strong>
            You can request to exercise your information, access and deletion rights by:
          </p>
          <ul>
            <li>
              <p>emailing privacy@gigxr.com</p>
            </li>
            <li>
              <p>mail at Gigxr Inc, Attn: Legal – Privacy, 1318 Pacific Avenue, Venice, CA 90291</p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Identity verification.</strong>
            We will need to confirm your identity and California residency to process your requests to exercise your
            information, access or deletion rights. We cannot process your request if you do not provide us with
            sufficient detail to allow us to understand and respond to it.
          </p>
        </li>
        <li>
          <p>
            <strong>Authorized agents.</strong>
            California residents can empower an “authorized agent” to submit requests on their behalf. We will require
            the authorized agent to have a written authorization confirming that authority.
          </p>
        </li>
      </ul>

      <h3 id="notice-to-european-users">Notice to European users</h3>

      <p>The information provided in this “Notice to European users” section applies only to individuals in Europe.</p>

      <p
        id="personal-information.-references-to-personal-information-in-this-privacy-policy-are-equivalent-to-personal-data-governed-by-european-data-protection-legislation."
      >
        <strong>Personal information.</strong>
        References to “personal information” in this Privacy Policy are equivalent to “personal data” governed by
        European data protection legislation.
      </p>

      <p
        id="controller.-gigxr-is-the-controller-of-your-personal-information-covered-by-this-privacy-policy-for-purposes-of-european-data-protection-legislation."
      >
        <strong>Controller.</strong>
        GIGXR is the controller of your personal information covered by this Privacy Policy for purposes of European
        data protection legislation.
      </p>

      <p
        id="legal-bases-for-processing.-we-use-your-personal-information-only-as-permitted-by-law.-our-legal-bases-for-processing-the-personal-information-described-in-this-privacy-policy-are-described-in-the-table-below."
      >
        <strong>Legal bases for processing.</strong>
        We use your personal information only as permitted by law. Our legal bases for processing the personal
        information described in this Privacy Policy are described in the table below.
      </p>

      <table class="legal-basis-table">
        <thead>
          <tr class="odd">
            <th class="legal-basis-table__column-1">
              <strong>Processing purpose</strong>
              (click link for details)
              <p>
                <em>Details regarding each processing purpose listed below are provided in the section above titled “How
                  we use your personal information”.</em>
              </p>
            </th>
            <th class="legal-basis-table__column-2"><strong>Legal basis</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="even">
            <th><strong>To operate our services<br /> </strong></th>
            <td>
              Processing is necessary to perform the contract governing our provision of our services or to take steps
              that you request prior to signing up for the services. If we have not entered into a contract with you, we
              process your personal information based on our legitimate interest in providing the services you access
              and request.
            </td>
          </tr>
          <tr class="odd">
            <th>
              <ul>
                <li>
                  <p><strong>To administer events and contests</strong></p>
                </li>
                <li>
                  <p><strong>For research and development</strong></p>
                </li>
                <li>
                  <p><strong>For marketing and advertising purposes</strong></p>
                </li>
                <li>
                  <p><strong>For compliance, fraud prevention and safety</strong></p>
                </li>
                <li>
                  <p><strong>To create anonymous data</strong></p>
                </li>
              </ul>
            </th>
            <td>
              These activities constitute our legitimate interests. We do not use your personal information for these
              activities where our interests are overridden by the impact on you (unless we have your consent or are
              otherwise required or permitted to by law).
            </td>
          </tr>
          <tr class="even">
            <th><strong>To comply with law</strong></th>
            <td>Processing is necessary to comply with our legal obligations.</td>
          </tr>
          <tr class="odd">
            <th><strong>With your consent</strong></th>
            <td>
              Processing is based on your consent. Where we rely on your consent you have the right to withdraw it any
              time in the manner indicated when you consent or in the services.
            </td>
          </tr>
        </tbody>
      </table>

      <p
        id="use-for-new-purposes.-we-may-use-your-personal-information-for-reasons-not-described-in-this-privacy-policy-where-permitted-by-law-and-the-reason-is-compatible-with-the-purpose-for-which-we-collected-it.-if-we-need-to-use-your-personal-information-for-an-unrelated-purpose-we-will-notify-you-and-explain-the-applicable-legal-basis."
      >
        <strong>Use for new purposes.</strong>
        We may use your personal information for reasons not described in this Privacy Policy where permitted by law and
        the reason is compatible with the purpose for which we collected it. If we need to use your personal information
        for an unrelated purpose, we will notify you and explain the applicable legal basis.
      </p>

      <p>
        <strong>Sensitive personal information.</strong>
        We ask that you not provide us with any sensitive personal information (e.g., social security numbers,
        information related to racial or ethnic origin, political opinions, religion or other beliefs, health,
        biometrics or genetic characteristics, criminal background or trade union membership) on or through the
        services, or otherwise to us.
      </p>

      <p>
        If you provide us with any sensitive personal information to us when you use the services, you must consent to
        our processing and use of such sensitive personal information in accordance with this Privacy Policy. If you do
        not consent to our processing and use of such sensitive personal information, you must not submit such sensitive
        personal information through our services.
      </p>

      <p id="retention"><strong>Retention</strong></p>

      <p
        id="we-retain-personal-information-for-as-long-as-necessary-to-fulfill-the-purposes-for-which-we-collected-it-including-for-the-purposes-of-satisfying-any-legal-accounting-or-reporting-requirements-to-establish-or-defend-legal-claims-or-for-fraud-prevention-purposes."
      >
        We retain personal information for as long as necessary to fulfill the purposes for which we collected it,
        including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or
        defend legal claims, or for fraud prevention purposes.
      </p>

      <p
        id="to-determine-the-appropriate-retention-period-for-personal-information-we-consider-the-amount-nature-and-sensitivity-of-the-personal-information-the-potential-risk-of-harm-from-unauthorized-use-or-disclosure-of-your-personal-information-the-purposes-for-which-we-process-your-personal-information-and-whether-we-can-achieve-those-purposes-through-other-means-and-the-applicable-legal-requirements."
      >
        To determine the appropriate retention period for personal information, we consider the amount, nature, and
        sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your
        personal information, the purposes for which we process your personal information and whether we can achieve
        those purposes through other means, and the applicable legal requirements.
      </p>

      <p
        id="when-we-no-longer-require-the-personal-information-we-have-collected-about-you-we-will-either-delete-or-anonymize-it-or-if-this-is-not-possible-for-example-because-your-personal-information-has-been-stored-in-backup-archives-then-we-will-securely-store-your-personal-information-and-isolate-it-from-any-further-processing-until-deletion-is-possible.-if-we-anonymize-your-personal-information-so-that-it-can-no-longer-be-associated-with-you-we-may-use-this-information-indefinitely-without-further-notice-to-you."
      >
        When we no longer require the personal information we have collected about you, we will either delete or
        anonymize it or, if this is not possible (for example, because your personal information has been stored in
        backup archives), then we will securely store your personal information and isolate it from any further
        processing until deletion is possible. If we anonymize your personal information (so that it can no longer be
        associated with you), we may use this information indefinitely without further notice to you.
      </p>

      <p id="your-rights"><strong>Your rights</strong></p>

      <p>
        European data protection laws give you certain rights regarding your personal information. If you are located in
        Europe, you may ask us to take the following actions in relation to your personal information that we hold:
      </p>

      <ul>
        <li>
          <p>
            <strong>Access.</strong>
            Provide you with information about our processing of your personal information and give you access to your
            personal information.
          </p>
        </li>
        <li>
          <p><strong>Correct.</strong> Update or correct inaccuracies in your personal information.</p>
        </li>
        <li>
          <p><strong>Delete.</strong> Delete your personal information.</p>
        </li>
        <li>
          <p>
            <strong>Transfer.</strong>
            Transfer a machine-readable copy of your personal information to you or a third party of your choice.
          </p>
        </li>
        <li>
          <p><strong>Restrict.</strong> Restrict the processing of your personal information.</p>
        </li>
        <li>
          <p>
            <strong>Object.</strong>
            Object to our reliance on our legitimate interests as the basis of our processing of your personal
            information that impacts your rights.
          </p>
        </li>
      </ul>

      <p>
        You may submit these requests by email to privacy@gigxr.com or our postal address provided
        <a href="#how-to-contact-us">above</a>. We may request specific information from you to help us confirm your
        identity and process your request. Applicable law may require or permit us to decline your request. If we
        decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a
        complaint about our use of your personal information or our response to your requests regarding your personal
        information, you may
        <a href="#how-to-contact-us">contact us</a>
        or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection
        regulator
        <span class="underline">here</span>
        (https://edpb.europa.eu/about-edpb/board/members_en).
      </p>

      <p id="cross-border-data-transfer"><strong>Cross-border data transfer</strong></p>

      <p>
        If we transfer your personal information out of Europe to a country not deemed by the European Commission to
        provide an adequate level of personal information protection, the transfer will be performed:
      </p>

      <ul>
        <li>
          <p>
            Pursuant to the recipient’s compliance with standard contractual clauses, the Swiss-US Privacy Shield, or
            Binding Corporate Rules,
          </p>
        </li>
        <li>
          <p>Pursuant to the consent of the individual to whom the personal information pertains,</p>
        </li>
        <li>
          <p>As otherwise permitted by applicable European requirements.</p>
        </li>
      </ul>

      <p>
        You may contact us if you want further information on the specific mechanism used by us when transferring your
        personal information out of Europe.
      </p>

      <h3 id="cookie-policy">Cookie Policy</h3>

      <p>
        This Cookie Policy explains how GIGXR Inc. (“<strong>GIGXR</strong>”, “<strong>we</strong>”, “<strong
        >us</strong>” or “<strong>our</strong>”) uses cookies and similar technologies in connection with the
        www.gigxr.com website and any other website or mobile application that we own or control and which posts or
        links to this Cookie Policy (collectively, the “<strong>Sites</strong>”).
      </p>

      <p id="what-are-cookies"><strong>What are cookies?</strong></p>

      <p>
        Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies
        serve different purposes, like helping us understand how a site is being used, letting you navigate between
        pages efficiently, remembering your preferences and generally improving your browsing experience.
      </p>

      <p>
        Our Sites may use both session cookies (which expire once you close your web browser) and persistent cookies
        (which stay on your computer or mobile device until you delete them).
      </p>

      <p>
        We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or
        mobile device, which we use to recognize your computer or mobile device when it revisits our Sites; and (2)
        third party cookies, which are served by service providers or business partners on our Sites, and can be used by
        these parties to recognize your computer or mobile device when it visits other websites. Third party cookies can
        be used for a variety of purposes, including site analytics, advertising and social media features.
      </p>

      <p id="what-types-of-cookies-and-similar-tracking-technologies-does-gigxr-use-on-the-sites">
        <strong>What types of cookies and similar tracking technologies does GIGXR use on the Sites?</strong>
      </p>

      <p>
        On the Sites, we use cookies and other tracking technologies in the following categories described in the table
        below.
      </p>

      <table class="cookie-table">
        <thead>
          <tr class="header">
            <th class="cookie-table__type-header"><em><strong>Type</strong></em></th>
            <th class="cookie-table__description-header"><strong>Description</strong></th>
            <th class="cookie-table__who-header">
              <strong>Who serves the cookies</strong><br />(link to privacy policy/site)
            </th>
            <th class="cookie-table__how-header"><strong>How to control them</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd">
            <td><em>Analytics</em></td>
            <td>
              These cookies help us understand how our services are performing and being used. These cookies may work
              with web beacons included in emails we send to track which emails are opened and which links are clicked
              by recipients.
            </td>
            <td>Google Analytics<br /> (https://policies.google.com/privacy?hl=en)</td>
            <td>
              <p>See ‘your choices’ below.</p>
              <p>
                Google Analytics uses its own cookies. You can find out more information about Google Analytics cookies
                here (https://developers.google.com/analytics/resources/concepts/gaConceptsCookies) and about how Google
                protects your data here (http://www.google.com/analytics/learn/privacy.html). You can prevent the use of
                Google Analytics relating to your use of our Sites by downloading and installing a browser plugin
                available here (http://tools.google.com/dlpage/gaoptout?hl=en-GB).
              </p>
            </td>
          </tr>
          <tr class="even">
            <td><em>Essential</em></td>
            <td>
              These cookies are necessary to allow the technical operation of our services (e.g., they enable you to
              move around on a website and to use its features).
            </td>
            <td>The services</td>
            <td>See ‘your choices’ below.</td>
          </tr>
        </tbody>
      </table>

      <p><strong>Other technologies</strong></p>

      <p>In addition to cookies, our Sites may use other technologies to track information.</p>

      <p><em>Browser Web Storage</em></p>

      <p>
        We may use browser web storage (including via HTML5), also known as locally stored objects (“LSOs”), for similar
        purposes as cookies. Browser web storage enables the storage of a larger amount of data than cookies. Your web
        browser may provide functionality to clear your browser web storage.
      </p>

      <p><em>Mobile Application Software Development Kits (SDKs)</em></p>

      <p>
        We may use third-party software development kits (“SDKs”) in our mobile applications. A SDK is third-party
        computer code that may be used for a variety of purposes, including to provide us with analytics regarding the
        use of our mobile applications, to integrate with social media, add features or functionality to our app, or to
        facilitate online advertising. SDKs may enable third parties to collect information directly via our App.
      </p>

      <p id="your-choices-1"><strong>Your choices</strong></p>

      <p>
        Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings.
        Many browsers accept cookies by default until you change your settings. Please note that if you set your browser
        to disable cookies, the Sites may not work properly.
      </p>

      <p>
        For more information about cookies, including how to see what cookies have been set on your computer or mobile
        device and how to manage and delete them, visit www.allaboutcookies.org. If you do not accept our cookies, you
        may experience some inconvenience in your use of our Sites. For example, we may not be able to recognize your
        computer or mobile device and you may need to log in every time you visit our Sites.
      </p>

      <p>
        Users may opt out of receiving targeted advertising on websites through members of the Network Advertising
        Initiative by clicking here (http://www.networkadvertising.org/choices) or the Digital Advertising Alliance by
        clicking here (http://www.aboutads.info/choices). European users may opt out of receiving targeted advertising
        on websites through members of the European Interactive Digital Advertising Alliance by clicking here
        (https://www.youronlinechoices.eu/), selecting the user’s country, and then clicking “Choices” (or
        similarly-titled link). Users of our mobile applications may opt out of receiving targeted advertising in mobile
        apps through participating members of the Digital Advertising Alliance by installing the AppChoices mobile app,
        available here (https://www.youradchoices.com/appchoices), and selecting the user’s choices. Please note that we
        also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out
        mechanisms that we linked above.
      </p>

      <p>
        If you choose to opt-out of targeted advertisements, you will still see advertisements online but they may not
        be relevant to you. Even if you do choose to opt out, not all companies that serve online behavioral advertising
        are included in this list, and so you may still receive some cookies and tailored advertisements from companies
        that are not listed.
      </p>

      <p>
        For more information about how we collect, use and share your information, see our
        <a href="#top">Privacy Policy</a>.
      </p>

      <p id="changes"><strong>Changes</strong></p>

      <p>
        Information about the cookies we use may be updated from time to time, so please check back on a regular basis
        for any changes.
      </p>

      <p id="questions"><strong>Questions</strong></p>

      <p>If you have any questions about this Cookie Policy, please contact us by email at privacy@gigxr.com.</p>

      <p>Last modified 10 September 2020.</p>
    </div>

    <UnauthenticatedPageLink id="return-to-site-link" href="/" label="Return to Site" on:click={returnToSiteHandler} />
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  .privacy-policy {
    text-align: left;
  }

  a {
    font-weight: 700;
  }

  address {
    margin-left: 3em;
    font-style: normal;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 7px;
    vertical-align: top;
  }

  thead {
    background-color: var(--gigxr-theme-primary-1d);
  }

  .legal-basis-table th {
    font-weight: 400;
  }

  .legal-basis-table tbody th {
    background-color: var(--gigxr-theme-secondary-5);
  }

  .legal-basis-table p {
    margin-bottom: 0;
  }

  .legal-basis-table ul {
    margin: 0;
    padding-left: 1em;
  }

  .legal-basis-table ul p {
    margin: 0;
  }

  .legal-basis-table__column-1 {
    width: 45%;
  }

  .legal-basis-table__column-2 {
    width: 55%;
  }

  .cookie-table__type-header {
    width: 15%;
  }

  .cookie-table__description-header {
    width: 30%;
  }

  .cookie-table__who-header {
    width: 20%;
  }

  .cookie-table__how-header {
    width: 35%;
  }
</style>
