<script>
  import PrimaryContent from './PrimaryContent.svelte';
  import { link } from 'svelte-routing';
  import TestEnvironmentIdentifier from './TestEnvironmentIdentifier.svelte';

  let fullYear = new Date().getFullYear();
</script>

<PrimaryContent>
  <div class="gigxr-footer">
    <div class="gigxr-footer__copyright">©{fullYear} GIGXR, Inc.</div>

    <div class="gigxr-footer__privacy">
      <a id="privacy-policy-link" href="/privacy-policy" target="_blank" use:link>Privacy Policy</a>
    </div>

    <div class="gigxr-footer__terms">
      <a id="terms-of-use-link" href="/terms-of-use" target="_blank" use:link>Terms of Use</a>
    </div>
  </div>

  <TestEnvironmentIdentifier />
</PrimaryContent>

<style>
  .gigxr-footer {
    text-align: center;
    margin: 30px 0;
    color: var(--gigxr-theme-primary-1c);
  }

  .gigxr-footer__copyright,
  .gigxr-footer__privacy,
  .gigxr-footer__terms {
    margin: 0.75em 0;
  }

  @media (min-width: 1100px) {
    .gigxr-footer {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
    }

    .gigxr-footer__copyright {
      flex: 4;
      text-align: left;
    }

    .gigxr-footer__privacy {
      flex: 1;
      text-align: right;
    }

    .gigxr-footer__terms {
      flex: 1;
      text-align: right;
    }
  }
</style>
