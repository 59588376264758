import { navigate } from 'svelte-routing';
import { Logger } from './logs';
import { role } from '../stores/core-store';
import { AccountRole } from './api/accounts';

/**
 * Checks if the current authenticated account's role is in the list of authorized roles.
 *
 * Redirect to the dashboard if not authorized.
 *
 * @param {[]} authorizedRoles
 */
function setAuthorizedRoles(authorizedRoles) {
  let myRole = AccountRole.INVALID;
  role.subscribe((value) => (myRole = value));
  if (!authorizedRoles.includes(myRole)) {
    Logger.log(`Role[${myRole}] is not authorized to view this page, redirecting...`);
    navigate('/', { replace: true });
  }
}

export { setAuthorizedRoles };
