import { readable } from 'svelte/store';
import { authenticatedGet } from '../util/api';
import { GmsError } from '../util/errors';
import { GENERIC_ERROR_MESSAGE } from '../util/constants';

let clientAppsCache = null;

export const clientApps = readable([], function start(set) {
  if (clientAppsCache !== null) {
    set(clientAppsCache);
  } else {
    authenticatedGet('/client-apps')
      .then((response) => {
        if (response.status !== 200) {
          throw new GmsError(`Cannot fetch client apps! ${GENERIC_ERROR_MESSAGE}`);
        }

        return response.json();
      })
      .then((json) => {
        clientAppsCache = json.data;
        set(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return function stop() {
    // Explicitly keep cache across soft-page navigation
    // clientAppsCache = null;
  };
});
