<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [
    AccountRole.INSTRUCTOR,
    AccountRole.DEPARTMENT_ADMIN,
    AccountRole.INSTITUTION_ADMIN,
    AccountRole.GIGXR_ADMIN,
  ];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { v4 as uuidv4 } from 'uuid';
  import Button from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import IconButton from '@smui/icon-button';
  import { role, title, breadcrumbPaths, snackbarMessage, snackbar } from '../../../stores/core-store';
  import { RedirectError } from '../../../util/errors';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import { fetchDepartments } from '../../../util/api/departments';
  import { fetchClasses } from '../../../util/api/classes';
  import LoadingView from '../../../components/LoadingView.svelte';
  import {
    editAccount,
    fetchAccount,
    fetchConsentRecords,
    resendRegistrationInviteEmail,
    canEditAccount,
    deleteAccount,
  } from '../../../util/api/accounts';
  import List, { Item, Graphic, Meta, Label, Text, PrimaryText, SecondaryText } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { Logger } from '../../../util/logs';
  import Typeahead from '../../../components/Typeahead.svelte';
  import { idArraysEqual } from '../../../util/api/array';
  import ConsentRecordList from '../../../components/accounts/ConsentRecordList.svelte';
  import AdminWithdrawConsentDialog from '../../../components/accounts/AdminWithdrawConsentDialog.svelte';
  import GdprDeleteAccountDialog from '../../../components/accounts/GdprDeleteAccountDialog.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import AccountRoleSelect from '../../../components/accounts/AccountRoleSelect.svelte';
  import AdminResetPasswordDialog from '../../../components/accounts/AdminResetPasswordDialog.svelte';
  import { RegistrationStatus } from '../../../util/api/accounts';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import DeleteAccountDialog from '../../../components/accounts/DeleteAccountDialog.svelte';
  import { getAccountId } from '../../../util/account';

  export let accountId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit User');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'User Management',
      location: '/users/list',
    },
    {
      name: 'Edit User',
      location: `/users/edit/${accountId}`,
    },
  ]);

  let loading = true;
  let departmentsById = {};
  let classesById = {};
  let consentRecords = [];

  let availableClasses = {};
  let query = '';
  let form;
  let adminWithdrawConsentDialog;
  let gdprDeleteAccountDialog;
  let adminPasswordResetDialog;
  let discardChangesDialog;
  let deleteAccountDialog;

  let lastSavedAccount = {
    accountId: uuidv4(),
    firstName: '',
    lastName: '',
    email: '',
    departmentIds: [],
    classIds: [],
    accountRole: 'Student',
    isActive: true,
  };

  let account = { ...lastSavedAccount };

  let formUnmodified = false;
  let formUnmodifiedOrInvalid = false;
  $: {
    if (account) {
      formUnmodified =
        account.firstName === lastSavedAccount.firstName &&
        account.lastName === lastSavedAccount.lastName &&
        account.email === lastSavedAccount.email &&
        account.accountRole === lastSavedAccount.accountRole &&
        String(account.isActive) === String(lastSavedAccount.isActive) &&
        idArraysEqual(account.departmentIds, lastSavedAccount.departmentIds) &&
        idArraysEqual(account.classIds, lastSavedAccount.classIds);
    }
  }
  $: {
    account;
    formUnmodifiedOrInvalid =
      formUnmodified ||
      !form.checkValidity() ||
      // Only GIGXR and Institution admins may add accounts without a department.
      (account.departmentIds.length === 0 &&
        $role !== AccountRole.GIGXR_ADMIN &&
        $role !== AccountRole.INSTITUTION_ADMIN);
  }

  $: {
    availableClasses = { ...classesById };
    account.classIds.forEach((id) => delete availableClasses[id]);

    Object.keys(availableClasses).forEach((id) => {
      if (!account.departmentIds.includes(classesById[id].departmentId)) {
        delete availableClasses[id];
      }
    });

    availableClasses = availableClasses;
  }

  onMount(async () => {
    let departments;
    let classes;
    [departments, classes, lastSavedAccount, consentRecords] = await Promise.all([
      fetchDepartments(),
      fetchClasses(),
      fetchAccount(accountId),
      fetchConsentRecords(accountId),
    ]);

    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));

    availableClasses = { ...classesById };
    lastSavedAccount.classIds.forEach((id) => delete availableClasses[id]);
    loadAccountFromLastSaved();

    if (!canEditAccount(lastSavedAccount)) {
      throw new RedirectError('Insufficient access to edit this account.', `/users/view/${accountId}`);
    }

    loading = false;
  });

  async function saveChangesHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    Logger.log('Saving account:', account);
    const editedAccount = await editAccount(account);
    snackbarMessage.set('User edited!');
    $snackbar.open();
    navigate(`/users/view/${editedAccount.accountId}`);
  }

  async function resendInviteHandler(event) {
    await resendRegistrationInviteEmail(account.accountId);
    account.registrationStatus = 'Invited';
    snackbarMessage.set('Invite sent!');
    $snackbar.open();
  }

  function loadAccountFromLastSaved(event) {
    account = {
      ...lastSavedAccount,
      departmentIds: [...lastSavedAccount.departmentIds],
      classIds: [...lastSavedAccount.classIds],
    };
  }

  function addClassToListHandler(clazz) {
    account.classIds = [...account.classIds, clazz.classId];

    delete availableClasses[clazz.classId];
    query = '';
  }

  function removeClassFromList(classId) {
    account.classIds = account.classIds.filter((id) => {
      if (id === classId) {
        availableClasses[id] = classesById[id];
        return false;
      }
      return true;
    });
  }

  async function deleteAccountHandler(event) {
    await deleteAccount(account.accountId);
    snackbarMessage.set('User deleted!');
    $snackbar.open();
    navigate('/users/list');
  }

  function isDepartmentAdminFor(department) {
    return department.departmentAdminIds.includes(account.accountId);
  }

  function departmentHelperText(classCount) {
    if (classCount === 1) {
      return '1 class';
    }
    return `${classCount} classes`;
  }

  function classHelperText(classId) {
    const clazz = classesById[classId];
    if (!clazz) {
      return '';
    }

    let instructorFullName = '–';
    if (clazz.instructor) {
      instructorFullName = `${clazz.instructor.firstName} ${clazz.instructor.lastName}`;
    }

    return `Instructor: ${instructorFullName}`;
  }
</script>

{#if account}
  <PrimaryContent>
    <h2>{`${account.firstName} ${account.lastName}`}</h2>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  {#if loading}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <form bind:this={form}>
        <TwoColumnSection>
          <div slot="left">
            <ContentRow>
              <Textfield
                input$id="first-name-field"
                class="gigxr-input"
                bind:value={account.firstName}
                variant="filled"
                label="First Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="last-name-field"
                class="gigxr-input"
                bind:value={account.lastName}
                variant="filled"
                label="Last Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="email-field"
                type="email"
                class="gigxr-input"
                bind:value={account.email}
                variant="filled"
                label="Email"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <h3 class="gigxr-list-header">Departments</h3>
              {#if !departmentsById || Object.keys(departmentsById).length === 0}
                <p>There are no departments to display.</p>
              {:else}
                <List class="gigxr-list" checklist twoLine>
                  {#each Object.values(departmentsById) as department (department.departmentId)}
                    <!-- Hacks here because Svelte Material UI does not have a way to disable checkbox lists -->
                    <Item
                      id="department-checkbox-{department.departmentId}"
                      title={department.description}
                      on:click={(event) => {
                        if (isDepartmentAdminFor(department)) event.stopPropagation();
                      }}
                      disabled={isDepartmentAdminFor(department)}
                    >
                      <Graphic>
                        {#if isDepartmentAdminFor(department)}
                          <span
                            class="edit-account-dept-admin"
                            title="Department Admins cannot be removed from Department. Go to department to change Department Admin."
                          >DEPT<br />ADMIN</span>
                          <Checkbox bind:group={account.departmentIds} value={department.departmentId} hidden />
                        {:else}
                          <Checkbox bind:group={account.departmentIds} value={department.departmentId} />
                        {/if}
                      </Graphic>
                      <Text>
                        <PrimaryText>{department.departmentName}</PrimaryText>
                        <SecondaryText>{departmentHelperText(department.classCount)}</SecondaryText>
                      </Text>
                    </Item>
                  {/each}
                </List>
              {/if}
            </ContentRow>

            <ContentRow>
              <h3 class="gigxr-list-header">Classes</h3>
              {#if !account.classIds || account.classIds.length === 0}
                <p>There are no classes to display.</p>
              {:else}
                <List class="gigxr-list" twoLine nonInteractive>
                  {#each account.classIds as id (id)}
                    <Item class="gigxr-list__item" title={classesById[id].description}>
                      <Text>
                        <PrimaryText>{classesById[id].className}</PrimaryText>
                        <SecondaryText>{classHelperText(id)}</SecondaryText>
                      </Text>
                      <Meta>
                        <IconButton
                          id="remove-class-button-{id}"
                          type="button"
                          class="material-icons"
                          on:click={() => removeClassFromList(id)}
                        >
                          close
                        </IconButton>
                      </Meta>
                    </Item>
                  {/each}
                </List>
              {/if}

              <Typeahead
                items={Object.values(availableClasses)}
                bind:query
                valueFunction={(clazz) => clazz.className}
                onSelectFunction={(clazz) => addClassToListHandler(clazz)}
                label="Enter Class Name "
              />
            </ContentRow>

            <ConsentRecordList {consentRecords} />

            <div class="edit-account__gdpr-links">
              <ContentRowCenteredOnMobile>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a
                  id="withdraw-consent-link"
                  class="gigxr-link"
                  on:click={() => adminWithdrawConsentDialog.open()}
                >Withdraw Consent</a>
                <span class="gigxr-link-note">They will lose access to GIGXR once you withdraw consent.</span>
              </ContentRowCenteredOnMobile>

              <ContentRowCenteredOnMobile>
                <div class="gdpr-delete-link">
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a
                    id="remove-personal-data-link"
                    class="gigxr-link"
                    on:click={() => gdprDeleteAccountDialog.open()}
                  >Remove User's Personal Data</a>
                  <span class="gigxr-link-note">
                    This will remove all personal information from this user's record and remove their access to GIGXR.
                    The user's name and email will be retained as a record of this removal, but will no longer be
                    visible.
                  </span>
                </div>
              </ContentRowCenteredOnMobile>
            </div>
          </div>

          <div slot="right">
            <AccountRoleSelect type="edit" bind:account />

            <ContentRow>
              <Select
                inputId="user-active-field"
                class="gigxr-input"
                bind:value={account.isActive}
                variant="filled"
                label="Active"
                enhanced
              >
                <Option value={false}>Inactive</Option>
                <Option value={true}>Active</Option>
              </Select>
            </ContentRow>

            <KeyValueRow>
              <div slot="left">Registration:</div>
              <div slot="right">{account.registrationStatus}</div>
            </KeyValueRow>

            <DateAccountComponent label="Added" utcDateString={account.createdOn} account={account.createdBy} />

            <DateAccountComponent label="Last Active" utcDateString={account.lastActive} account={null} show_account={false} />

            <ContentRowCenteredOnMobile>
              <Button
                id="edit-user-save-button"
                class="gigxr-button"
                variant="unelevated"
                on:click={saveChangesHandler}
                disabled={formUnmodifiedOrInvalid}
              >
                <Label>Save Changes</Label>
              </Button>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                class="gigxr-link {formUnmodified ? 'gigxr-link--disabled' : ''}"
                on:click={() => discardChangesDialog.open()}
              >
                Discard Changes
              </a>
            </ContentRowCenteredOnMobile>

            {#if account.registrationStatus === RegistrationStatus.ADDED}
              <ContentRowCenteredOnMobile>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="send-invite-link" class="gigxr-link" on:click={resendInviteHandler}>Send Invite</a>
              </ContentRowCenteredOnMobile>
            {/if}

            {#if account.registrationStatus === RegistrationStatus.INVITED}
              <ContentRowCenteredOnMobile>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="resend-invite-link" class="gigxr-link" on:click={resendInviteHandler}>Resend Invite</a>
              </ContentRowCenteredOnMobile>
            {/if}

            {#if account.registrationStatus === RegistrationStatus.REGISTERED}
              <ContentRowCenteredOnMobile>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="reset-password-link" class="gigxr-link" on:click={() => adminPasswordResetDialog.open()}>Reset
                  Password</a>
              </ContentRowCenteredOnMobile>
            {/if}

            {#if account.accountId !== getAccountId()}
              <ContentRowCenteredOnMobile class="delete-account-link">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="delete-user-link" class="gigxr-link" on:click={() => deleteAccountDialog.open()}>Delete User</a>
              </ContentRowCenteredOnMobile>
            {/if}
          </div>
        </TwoColumnSection>
      </form>
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<AdminWithdrawConsentDialog bind:dialog={adminWithdrawConsentDialog} {account} />
<GdprDeleteAccountDialog bind:dialog={gdprDeleteAccountDialog} {account} />
<AdminResetPasswordDialog bind:dialog={adminPasswordResetDialog} {account} />
<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => loadAccountFromLastSaved()} />
<DeleteAccountDialog
  bind:dialog={deleteAccountDialog}
  {account}
  on:DeleteAccountDialog::deleteConfirmed={deleteAccountHandler}
/>

<style>
  .edit-account__gdpr-links {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  .gdpr-delete-link {
    margin-top: 2em;
  }

  :global(.delete-account-link) {
    margin-top: 2em !important;
  }

  .edit-account-dept-admin {
    white-space: nowrap;
    text-align: center;
    font-weight: 700;
    font-size: 0.7em;
    line-height: 1.5em;
  }

  @media (min-width: 1100px) {
    .edit-account__gdpr-links {
      margin-bottom: 0;
    }
  }
</style>
