<script>
  import Textfield from '@smui/textfield';
  import IconButton from '@smui/icon-button';
  import Select, { Option } from '@smui/select';

  export let key;
  export let value;
  export let defaultValue = undefined;
  export let disabled = false;

  const presetValues = {
    '0': 'None',
    '1': 'Normal Sinus',
    '2': 'Sinus Tachycardia (Elevated ST)',
    '3': 'Sinus Tachycardia',
    '4': 'Atrial Fibrillation with PVC',
    '5': 'Atrial Fibrillation',
    '6': 'Supraventricular Tachycardia',
  };

  // This reactive block updates the tooltip value.
  let tooltip = '';
  $: {
    let readableDefaultValue = '';
    switch (key) {
      case 'Preset':
        readableDefaultValue = presetValues[defaultValue];
        break;

      default:
        readableDefaultValue = defaultValue;
    }

    tooltip = `Reset to default value (${readableDefaultValue})`;
  }

  // Temporary patch to HoloPatient 2.0. Not needed for HP 2.1 where the underlying JSON structure is different and contains a display name.
  function keyLabelKludgeFilter(key) {
    if (key === 'Preset') {
      return 'ECG/EKG';
    }
    else if (key == 'BloodPressureHigh') {
      return 'BloodPressureSYS';
    }
    else if (key == 'BloodPressureLow') {
      return 'BloodPressureDIA';
    }
    return key;
  }
</script>

<div class="additional-information-field">
  {#if key === 'Preset'}
    <Select class="gigxr-input" bind:value variant="filled" label={keyLabelKludgeFilter(key)} {disabled} enhanced>
      {#each Object.keys(presetValues) as presetValue}
        <Option value={presetValue}>{presetValues[presetValue]}</Option>
      {/each}
    </Select>
  {:else}
    <Textfield class="gigxr-input" bind:value variant="filled" label={keyLabelKludgeFilter(key)} {disabled} />
  {/if}

  <IconButton
    id="additional-information-reset-button-{key}"
    class="material-icons {disabled || !defaultValue || String(value) === String(defaultValue) ? 'additional-information-field--is-hidden' : ''}"
    on:click={() => (value = defaultValue)}
    title={tooltip}
  >
    replay
  </IconButton>
</div>

<style>
  .additional-information-field {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  :global(.additional-information-field--is-hidden) {
    visibility: hidden;
  }
</style>
