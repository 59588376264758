<script>
  import { onMount } from 'svelte';
  import IconButton, { Icon } from '@smui/icon-button';
  import { createEventDispatcher } from 'svelte';
  import { fly } from 'svelte/transition';
  import { mobileView } from '../stores/core-store';
  import { escapeRegExp } from '../util/util';

  /**
   * A raw list of T for which to filter.
   *
   * @template T
   * @type {[T]}
   */
  export let unfilteredList;

  /**
   * A filtered list of T where a value from `valueFunctions` matches `query`.
   *
   * @template T
   * @type {[T]}
   */
  export let filteredList = [...unfilteredList];

  /**
   * Functions that operate on T and return a value for which to search against.
   *
   * These must be named functions. Anonymous functions will not work.
   *
   * @type {[Function]}
   */
  export let valueFunctions = [];

  export let placeholder = 'Search';

  export let query = '';

  export let disabled = false;

  let input;

  const dispatch = createEventDispatcher();

  $: {
    const regExp = new RegExp(escapeRegExp(query), 'i');
    filteredList = unfilteredList.filter((item) => {
      console.log(`in ListPageSearch, item: ${JSON.stringify(item)}`)
      let itemMatched = false;
      Object.keys(valueFunctions).forEach((key) => {
        console.log(`in ListPageSearch, key: ${JSON.stringify(key)}`)
        const valueFunction = valueFunctions[key];
        const value = valueFunction(item);
        const match = value ? value.match(regExp) : null;
        if (match) {
          itemMatched = true;
        }
      });
      return itemMatched;
    });
  }

  onMount(async () => {
    input.focus();
  });

  function clearHandler(event) {
    query = '';
    dispatch('gigxr::clear', event);
  }
</script>

<!--
This uses a custom input instead of one from Svelte Material UI (SMUI) because:
1. At the time the SMUI library's element was broken for a condensed, outlined input.
2. The design comp was different enough from material design that adding a material design component
   took up too much space compared to the surrounding elements.
-->
<div
  class="list-page-search__wrapper {$mobileView ? 'list-page-search--full-width' : ''}
  {disabled ? 'list-page-search__wrapper--disabled' : ''}"
  in:fly={$mobileView ? { x: -100, duration: 200 } : { duration: 0 }}
>
  <label>
    <Icon class="material-icons list-page-search__icon list-page-search__icon--leading">search</Icon>
    <input
      class="list-page-search__input {$mobileView ? 'list-page-search--full-width' : ''}"
      bind:value={query}
      bind:this={input}
      {placeholder}
      {disabled}
    />
  </label>
  {#if $mobileView || query.length > 0}
    <IconButton
      id="list-page-search-button"
      class="material-icons list-page-search__icon list-page-search__icon--trailing list-page-search__icon-button"
      ripple={false}
      on:click={clearHandler}
    >
      cancel
    </IconButton>
  {/if}
</div>

<style>
  .list-page-search__wrapper {
    position: relative;
    margin-right: -10px !important;
  }

  .list-page-search__wrapper--disabled {
    opacity: 0.38;
  }

  .list-page-search--full-width {
    width: 100%;
  }

  :global(.material-icons.list-page-search__icon) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: var(--gigxr-theme-secondary-2c);
  }

  :global(.list-page-search__icon--leading) {
    left: 10px;
  }

  :global(.list-page-search__icon--trailing) {
    right: 10px;
  }

  :global(.list-page-search__icon-button) {
    width: initial;
    height: initial;
    padding: 0;
  }

  :global(.list-page-search__icon-button:hover) {
    color: var(--gigxr-theme-secondary-2b);
  }

  .list-page-search__input {
    border-radius: 28px;
    padding-left: 32px;
    padding-right: 32px;
    border-color: var(--gigxr-theme-secondary-2c);
  }

  .list-page-search__input:focus {
    outline: none;
    box-shadow: 0 0 2px var(--gigxr-theme-secondary-2a);
  }
</style>
