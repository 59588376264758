import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../filters';

function ClassSortBySet() {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: 'Class Name (A-Z)',
    sortOptions: new Map([
      [
        'Class Name (A-Z)',
        (a, b) => {
          const classNameA = a.className.toLowerCase();
          const classNameB = b.className.toLowerCase();
          if (classNameA < classNameB) return -1;
          if (classNameA > classNameB) return 1;
          return 0;
        },
      ],
      [
        'Class Name (Z-A)',
        (b, a) => {
          const classNameA = a.className.toLowerCase();
          const classNameB = b.className.toLowerCase();
          if (classNameA < classNameB) return -1;
          if (classNameA > classNameB) return 1;
          return 0;
        },
      ],

      [
        'Date Added (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Added (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
    ]),
  });
}

function ClassFilterByDepartmentSet(departments) {
  const filterByDepartmentSet = new FilterBySelectSet({
    description: 'Filter by Department',
    label: 'Department',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Departments',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.departmentId === selectedValue);
    },
  });

  departments
    .sort((a, b) => {
      const nameA = a.departmentName.toLowerCase();
      const nameB = b.departmentName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
    .forEach(
      (department) => (filterByDepartmentSet.filterOptions[department.departmentId] = department.departmentName),
    );

  return filterByDepartmentSet;
}

function ClassFilterByInstructorSet(instructors) {
  const filterByInstructorSet = new FilterBySelectSet({
    description: 'Filter by Instructor',
    label: 'Instructor',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Instructors',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.instructorId === selectedValue);
    },
  });

  instructors
    .sort((a, b) => {
      if (a.instructorName < b.instructorName) return -1;
      if (a.instructorName > b.instructorName) return 1;
      return 0;
    })
    .forEach(
      (instructor) =>
        (filterByInstructorSet.filterOptions[instructor.accountId] = `${instructor.firstName} ${instructor.lastName}`),
    );

  return filterByInstructorSet;
}

function ClassFilterIncludeInactiveSet() {
  return new FilterByCheckboxSet({
    description: 'Inactive Classes',
    defaultValue: false,
    filterOptions: {
      option: 'Include Inactive',
    },
    filterCallback: (array, includeInactive) => {
      if (includeInactive) return array;
      return array.filter((element) => element.classStatus !== 'Inactive');
    },
  });
}

export { ClassSortBySet, ClassFilterByDepartmentSet, ClassFilterByInstructorSet, ClassFilterIncludeInactiveSet };
