<script>
  import Checkbox from '@smui/checkbox';

  export let group = [];
  export let value = '';
  export let show = false;
  export let id = '';
</script>

<Checkbox
  class="list-page-card-grid-checkbox {show ? '' : 'list-page-card-grid-checkbox--hide'}"
  {value}
  bind:group
  on:change
  input$id={id}
/>

<style>
  :global(.list-page-card-grid-checkbox) {
    margin-left: auto;
    margin-bottom: 30px;
  }

  :global(.list-page-card-grid-checkbox--hide) {
    display: none;
  }
</style>
