<script>
  import { utcStringToLocalDate } from '../util/dates';
  import { EARLIEST_VALID_TIMESTAMP } from '../util/constants';
  import KeyValueRow from './KeyValueRow.svelte';
  import { fetchAccount } from '../util/api/accounts';

  export let label = '';
  export let utcDateString = null;
  export let account;
  export let show_account = true;

  $: localDate = utcStringToLocalDate(utcDateString);

  let isDateValid = false;

  $: {
    if (localDate && localDate.getTime() > EARLIEST_VALID_TIMESTAMP) {
      isDateValid = true;
    }
  }
</script>

<KeyValueRow>
  <div slot="left">{label}:</div>
  <div slot="right" class="date-account-right">
    {#if isDateValid}
      {localDate.toLocaleDateString()}
      {#if show_account}
        {#if account}
        <br /><span title={`${account.firstName} ${account.lastName}`}>{account.firstName.substring(0, 1)}
          {account.lastName}</span>
      {:else}
        <br /><span title={`Deleted User`}>Deleted User</span>
      {/if}
      {/if}
    {:else}–{/if}
  </div>
</KeyValueRow>

<style>
  .date-account-right {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 55%;
  }
</style>
