<script>
  import Select, { Option } from '@smui/select';
  import ContentRow from '../ContentRow.svelte';
  import { SessionPermission, SessionPermissionText, permissionsThatRequireClasses } from '../../util/api/sessions';
  import { role } from '../../stores/core-store';
  import { AccountRole } from '../../util/api/accounts';

  /** @type {SessionDetailedView} */
  export let session;

  /** @type {boolean} */
  export let disabled = false;

  $: {
    // Reset session permission to private if someone deselects a class.
    if (session && !session.classId && permissionsThatRequireClasses.has(session.sessionPermission)) {
      session.sessionPermission = SessionPermission.PRIVATE;
    }
  }

  const validPermissions = Object.values(SessionPermission).filter(
    (permission) =>
      // Regular visibility rules
      permission !== SessionPermission.OPEN_TO_INSTITUTION_NON_STUDENTS ||
      // If a student and they have been explicitly invited to the Session, they should be able to see the Permission.
      session.sessionPermission === SessionPermission.OPEN_TO_INSTITUTION_NON_STUDENTS ||
      // If a student, they cannot see this role.
      (permission == SessionPermission.OPEN_TO_INSTITUTION_NON_STUDENTS && $role !== AccountRole.STUDENT),
  );

  /**
   * Disable click events for an enhanced select dropdown list.
   *
   * This should not be necessary, but SMUI does not disable event propagation when the element is
   * disabled. This is probably a bug.
   *
   * @param {Event} event
   * @param {SessionPermission} permission
   */
  function disableOptionHandler(event, permission) {
    if (!session.classId && permissionsThatRequireClasses.has(permission)) {
      event.stopPropagation();
    }
  }
</script>

<ContentRow>
  <Select
    inputId="session-permission-field"
    class="gigxr-input gigxr-input-select"
    bind:value={session.sessionPermission}
    variant="filled"
    label="Permission"
    title={SessionPermissionText[session.sessionPermission]}
    enhanced
    {disabled}
  >
    {#each validPermissions as permission (permission)}
      <Option
        value={permission}
        on:click={(event) => disableOptionHandler(event, permission)}
        disabled={!session.classId && permissionsThatRequireClasses.has(permission)}
      >
        {SessionPermissionText[permission]}
        {!session.classId && permissionsThatRequireClasses.has(permission) ? '(Select Class)' : ''}
      </Option>
    {/each}
  </Select>
</ContentRow>

<style>
  :global(.gigxr-input-select .mdc-select__selected-text) {
    padding-right: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
