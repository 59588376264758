<script>
  import { utcStringToLocalDate } from '../../util/dates';
  import { ConsentTypeHtml } from '../../util/api/accounts';
  import ContentRow from '../ContentRow.svelte';
  import List, {
    Group,
    Item,
    Graphic,
    Meta,
    Label,
    Separator,
    Subheader,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import { ConsentLocations } from '../../util/api/accounts';
  import ListPageViewMoreButton from '../ListPageViewMoreButton.svelte';

  export let consentRecords = [];

  const CONSENT_PAGE_SIZE = 10;
  let numberDisplayedConsents = CONSENT_PAGE_SIZE;
</script>

<ContentRow>
  <h3 class="gigxr-list-header">Consent Records</h3>

  {#if consentRecords.length === 0}
    <p>This user does not have any consent records.</p>
  {:else}
    <List class="gigxr-list" threeLine nonInteractive>
      {#each consentRecords.slice(0, numberDisplayedConsents) as consentRecord (consentRecord.consentRecordId)}
        <Item class="gigxr-list__item">
          <Text>
            <PrimaryText>
              {@html ConsentTypeHtml[consentRecord.consent.consentType]}
            </PrimaryText>
            <SecondaryText>
              {utcStringToLocalDate(consentRecord.createdOn).toLocaleString()}
              ({ConsentLocations[consentRecord.whereConsented]})
            </SecondaryText>
            <SecondaryText>
              Old Value:
              {consentRecord.oldValue ? 'YES' : 'NO'}, New Value:
              {consentRecord.newValue ? 'YES' : 'NO'}
            </SecondaryText>
          </Text>
          <Meta />
        </Item>
      {/each}
    </List>

    {#if numberDisplayedConsents < consentRecords.length}
      <ListPageViewMoreButton
        itemName="Consent Records"
        remainingItems={consentRecords.length - numberDisplayedConsents}
        on:click={() => (numberDisplayedConsents += CONSENT_PAGE_SIZE)}
      />
    {/if}
  {/if}
</ContentRow>

<style>
</style>
