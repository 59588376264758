<script>
  import { mobileView } from '../stores/core-store';
  import TextButton from './TextButton.svelte';

  export let totalCount;
  export let shownCount;
  export let selectedCount;

  export let singularName = 'Item';
  export let pluralName = 'Items';

  export let allSelectedIncludingNotShown = false;
</script>

{#if selectedCount >= shownCount && totalCount > shownCount}
  {#if allSelectedIncludingNotShown}
    <p>
      All
      {totalCount}
      {selectedCount === 1 ? singularName : pluralName}
      matching your current filters are selected.
      <TextButton on:click={() => (allSelectedIncludingNotShown = false)}>Click here</TextButton>
      to select all
      {shownCount}
      {selectedCount === 1 ? singularName : pluralName}
      on this page.
    </p>
  {:else}
    <p>
      All
      {shownCount}
      {selectedCount === 1 ? singularName : pluralName}
      <!-- Different language here per the wireframes based on desktop/mobile -->
      {#if $mobileView}currently displayed are selected.{:else}on this page are selected{/if}
      <TextButton on:click={() => (allSelectedIncludingNotShown = true)}>Click here</TextButton>
      to select all
      {totalCount}
      {selectedCount === 1 ? singularName : pluralName}
      matching your current filters.
    </p>
  {/if}
{:else}
  <p>{selectedCount} {selectedCount === 1 ? singularName : pluralName} Selected</p>
{/if}

<style>
</style>
