<script>
  import { createEventDispatcher } from 'svelte';
  import IconButton from '@smui/icon-button';

  export let disabled = false;

  const dispatch = createEventDispatcher();
</script>

<IconButton
  id="list-page-mobile-search-button"
  class="material-icons gigxr-icon list-page-mobile-search-button"
  on:click={(event) => dispatch('click', event)}
  {disabled}
>
  search
</IconButton>

<style>
  :global(.list-page-mobile-search-button:disabled) {
    opacity: 0.38;
  }
</style>
