<script>
  import { account } from '../stores/core-store.js';
  import Menu from '@smui/menu';
  import List, { Item, Text } from '@smui/list';
  import { navigate } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
  import { openLogoutDialog } from '../components/my-account/LogoutDialog.svelte';
  import { isUnity } from '../util/unity.js';

  let menu;
</script>

<div class="account-welcome">
  <Button id="account-welcome-button" on:click={() => menu.setOpen(true)} class="account-welcome-button">
    <Icon class="material-icons">person</Icon>
    <Label>
      {$account.firstName}
      <div class="account-welcome-not-you"><span class="gigxr-link">Not you?</span></div>
    </Label>
  </Button>
  <Menu bind:this={menu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
    <List>
      <Item id="account-welcome-my-account-link" on:SMUI:action={() => navigate('/my-account/view')}>
        <Text>My Account</Text>
      </Item>

      {#if isUnity()}
        <Item id="account-welcome-app-settings-link" on:SMUI:action={() => navigate('/app-settings')}>
          <Text>App Settings</Text>
        </Item>
      {/if}

      <Item id="account-welcome-logout-link" on:SMUI:action={() => openLogoutDialog()}>
        <Text>Logout</Text>
      </Item>
    </List>
  </Menu>
</div>

<style>
  :global(.account-welcome-button .material-icons) {
    height: 30px;
    width: 30px;
    font-size: 30px;
  }

  :global(.account-welcome-button) {
    text-transform: none;
    white-space: nowrap;
  }

  :global(.account-welcome-button .mdc-button__label) {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  @media (min-width: 1100px) {
    :global(.account-welcome-button) {
      margin-right: -8px;
    }
  }

  .account-welcome-not-you {
    margin-top: -3px;
    font-size: 13px;
  }
</style>
