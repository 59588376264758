<script>
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import IconButton from '@smui/icon-button';
  import LinearProgress from '@smui/linear-progress';
  import { sendUnityDeleteDownloadMessage, sendUnityStopDownloadMessage } from '../util/unity';
  import { getMobileOperatingSystem, humanFileSize } from '../util/util';

  export let resourceId;

  /**
   * The download name.
   */
  export let name = 'Unknown file name';

  /**
   * The download display names.
   */
  export let displayNames = [];

  /**
   * The size of the download in bytes.
   */
  export let size = -1;

  /**
   * A date to display with the download.
   */
  export let date = new Date();

  /**
   * Progress of the download. 0.0 = 0%, 1.0 = 100%
   */
  export let progress = 0.0;

  function deleteClickHandler() {
    sendUnityDeleteDownloadMessage(resourceId);
  }

  function cancelClickHandler() {
    sendUnityStopDownloadMessage(resourceId);
  }
</script>

<Card class="app-settings-resource-card">
  <Content class="app-settings-resource-card-content">
    <div class="app-settings-resource-card__information">
      <div class="app-settings-resource-card__name">
        {name}<br />
        {#each displayNames as name (name)}{name}<br />{/each}
      </div>
      <LinearProgress {progress} closed={progress >= 1} />
      <div class="app-settings-resource-card__details-row">
        <div class="app-settings-resource-card__details-date">{date.toLocaleDateString()}</div>
        <div class="app-settings-resource-card__details-size">
          {size === -1 ? 'Unknown size' : humanFileSize(size, true)}
        </div>
      </div>
    </div>
    <div class="app-settings-resource-card__controls">
      {#if progress >= 1}
        <IconButton class="material-icons" on:click={deleteClickHandler}>delete</IconButton>
      {:else if getMobileOperatingSystem() !== 'iOS'}
        <IconButton class="material-icons" on:click={cancelClickHandler}>clear</IconButton>
      {/if}
    </div>
  </Content>
</Card>

<style>
  :global(.app-settings-resource-card) {
    font-family: 'Barlow', sans-serif;
    border-radius: 10px !important;
    margin-bottom: 0.5em;
  }

  :global(.app-settings-resource-card-content) {
    display: flex;
    align-items: center;
  }

  .app-settings-resource-card__information {
    flex: 4;
  }

  .app-settings-resource-card__details-row {
    font-size: 0.83em;
  }

  .app-settings-resource-card__details-date {
    display: inline-block;
    color: var(--gigxr-theme-secondary-2c);
    font-weight: 700;
    min-width: 75px;
  }

  .app-settings-resource-card__details-size {
    display: inline-block;
  }

  .app-settings-resource-card__controls {
    flex: 1;
    text-align: right;
    align-items: center;
  }
</style>
