<script>
  import { snackbarMessage, snackbar, isAuthenticated, errorMessage } from '../../stores/core-store';
  import { sendGdprInformationRequest } from '../../util/api/accounts';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  export let dialog = undefined;
  export let account;

  async function gdprInformationHandler(event) {
    event.preventDefault();
    await sendGdprInformationRequest(account.accountId);
    snackbarMessage.set('Personal information request sent!');
    $snackbar.open();
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="request-gdpr-info">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    All personal information collected by GIGXR will be sent to your email address in your account.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={gdprInformationHandler}>Request Information</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
