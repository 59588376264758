<script>
  import { setContext } from 'svelte';
  import Dialog from '@smui/dialog';
  import GigXrDialogCloseButton from './GigXrDialogCloseButton.svelte';

  export let ariaPrefix = '';
  export let dialog = undefined;
  export let threeButtons = false;

  setContext('GigXrDialog:ariaPrefix', ariaPrefix);
</script>

<Dialog
  class="gigxr-dialog {threeButtons ? 'gigxr-dialog--three-buttons' : ''}"
  bind:this={dialog}
  aria-labeledby={`${ariaPrefix}-title`}
  aria-describedby={`${ariaPrefix}-content`}
>
  <slot />
  <GigXrDialogCloseButton on:click={() => dialog.close()} />
</Dialog>

<style>
  :global(.gigxr-dialog) {
    white-space: normal;
  }

  :global(.gigxr-dialog .mdc-dialog__surface) {
    position: relative;
  }

  :global(.gigxr-dialog--three-buttons .mdc-dialog__surface) {
    max-width: 820px;
  }

  @media (max-width: 1099px) {
    /* Mobile full-screen view of dialogs. */
    :global(.gigxr-dialog) {
      z-index: 12;
    }

    :global(.gigxr-dialog .mdc-dialog__container) {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
    }

    :global(.gigxr-dialog .mdc-dialog__surface) {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
      display: flex !important;
      border-radius: 0;
      align-items: center;
      justify-content: center;
    }

    :global(.gigxr-dialog .mdc-dialog__button) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media (min-width: 1100px) {
    :global(.gigxr-dialog .mdc-dialog__surface) {
      padding: 10px;
    }
  }
</style>
