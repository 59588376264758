<script>
  import { onMount } from 'svelte';
  import Textfield from '@smui/textfield';
  import ContentRow from '../ContentRow.svelte';
  import LabelList from './SceneLabelList.svelte';
  import { getAccountId } from '../../util/account';
  import { objectSortedKeys } from '../../util/util';
  import { fetchPathologies } from '../../util/api/client-apps';
  import Select, { Option } from '@smui/select';
  import Button, { Label } from '@smui/button';
  import HelperText from '@smui/textfield/helper-text';
  import { fade } from 'svelte/transition';
  import AdditionalInformationField from './AdditionalInformationField.svelte';

  export let clientAppId;
  export let sessionCreatedById;
  export let hmdJson;

  // localHmdJson is used to be able to update the underlying hmdJson without triggering reactivity in this component.
  let localHmdJson;

  // E.g., "Sandra Burke"
  let clipsByPatient = new Map();

  // E.g., "Sandra1"
  let clipsByName = new Map();

  let usedClips = new Set();

  let pathologies = [];
  let newPatient = '';
  let newClip = '';
  let addingClip = false;

  let editedClipNames = {};

  // Build local representation of clip names to allow them to be edited.
  $: if (hmdJson && hmdJson.scenes) {
    localHmdJson = hmdJson;
    buildEditedClipNames();
  }

  function buildEditedClipNames() {
    hmdJson.scenes.forEach((scene) => {
      let patient, clip;
      if (scene.extraContent) {
        [patient, clip] = scene.extraContent.split('/');
      } else {
        [patient, clip] = scene.title.split('/');
      }
      editedClipNames[scene.title] = {
        patient,
        clip,
      };
    });
  }

  // This reactive block is used to update the underlying hmdJson when a clip is edited.
  $: localHmdJson.scenes.forEach((scene) => {
    if (editedClipNames[scene.title]) {
      const { patient, clip } = editedClipNames[scene.title];
      scene.extraContent = `${patient}/${clip}`;
    }
  });

  // This reactive block is to replace on:change={() => newClip = ''} on the patient select.
  // Svelte Material UI is not triggering change events.
  // https://github.com/hperrin/svelte-material-ui/issues/167
  let lastNewPatient = '';
  $: if (newPatient !== lastNewPatient) {
    newClip = '';
    lastNewPatient = newPatient;
  }

  // Always show the add clip interface if there are no clips.
  $: if (hmdJson.scenes.length === 0) {
    addingClip = true;
  }

  // This reactive block is to update the set of used clips based upon clip changes.
  $: {
    const set = new Set();
    hmdJson.scenes.forEach((scene) => set.add(scene.title));
    usedClips = set;
  }

  onMount(async () => {
    pathologies = await fetchPathologies(clientAppId);

    // Build indexes
    pathologies.forEach((pathology) => {
      clipsByPatient.set(pathology.Name, pathology.Clips);
      pathology.Clips.forEach((clip) => {
        clipsByName.set(clip.Name, clip);
      });
    });
    clipsByPatient = clipsByPatient;
    clipsByName = clipsByName;
  });

  function addClip() {
    const clipName = `${newPatient}/${newClip}`;

    if (usedClips.has(clipName)) {
      return;
    }

    const clip = clipsByName.get(newClip);
    const vitals = { ...clip.Vitals };

    hmdJson.scenes = [
      ...hmdJson.scenes,
      {
        title: clipName,
        sceneLabels: [],
        aux: {
          editable: {
            ...vitals,
          },
        },
      },
    ];

    addingClip = false;
    newPatient = '';
    newClip = '';
  }

  function removeClip(clipName) {
    hmdJson.scenes = hmdJson.scenes.filter((scene) => scene.title !== clipName);
  }

  const fadeConfig = {
    duration: 200,
  };

  /**
   * Disable click events for an enhanced select dropdown list.
   *
   * This should not be necessary, but SMUI does not disable event propagation when the element is
   * disabled. This is probably a bug.
   *
   * @param {Event} event
   * @param {string} clipName
   */
  function disableOptionHandler(event, clipName) {
    if (usedClips.has(clipName)) {
      event.stopPropagation();
    }
  }
</script>

{#if sessionCreatedById === getAccountId()}
  <p class="session-scenes-panel-description">
    Select at least one clip for this session. Save Changes when you are done.
  </p>
{:else if hmdJson.scenes.length === 0}
  <p class="session-scenes-panel-description">There are no clips for this session.</p>
{/if}

{#if hmdJson.scenes && hmdJson.scenes.length > 0}
  {#each hmdJson.scenes as scene, index (scene.title)}
    <div class="session-scene">
      <p class="clip-number">Clip {index + 1}</p>

      <ContentRow class="session-scenes-panel-patient-clip">
        <div class="session-scenes-panel-patient-clip__patients" in:fade|local={fadeConfig}>
          <Textfield
            class="gigxr-input"
            bind:value={editedClipNames[scene.title].patient}
            variant="filled"
            label="Patient"
            disabled={sessionCreatedById !== getAccountId()}
          />
          <HelperText persistent={false}>You may change the patient name</HelperText>
        </div>
        <div class="session-scenes-panel-patient-clip__clips" in:fade|local={fadeConfig}>
          <Textfield
            class="gigxr-input"
            bind:value={editedClipNames[scene.title].clip}
            variant="filled"
            label="Clip"
            disabled={sessionCreatedById !== getAccountId()}
          />
          <HelperText persistent={false}>You may change the clip name</HelperText>
        </div>
        <div
          class="session-scenes-panel-patient-clip__controls session-scenes-panel-patient-clip__controls--with-helper-text"
          in:fade|local={fadeConfig}
        >
          {#if sessionCreatedById === getAccountId()}
            <Button
              class="gigxr-button-link session-scenes-remove-clip-button"
              on:click={() => removeClip(scene.title)}
            >
              <Label>Remove Clip</Label>
            </Button>
          {/if}
        </div>
      </ContentRow>

      <!-- <div class="session-scenes-section" in:fade|local={fadeConfig}>
      <div class="session-scenes-section__left">Labels</div>
      <div class="session-scenes-section__right">
        <LabelList {scene} {sessionCreatedById} />
      </div>
    </div> -->

      {#if scene.aux}
        <div class="session-scenes-section" in:fade|local={fadeConfig}>
          <div class="session-scenes-section__left">Additional Information</div>
          <div class="session-scenes-section__right">
            {#if scene.aux.visible && Object.keys(scene.aux.visible).length > 0}
              {#each objectSortedKeys(scene.aux.visible) as key (key)}
                <ContentRow>
                  <AdditionalInformationField {key} bind:value={scene.aux.visible[key]} disabled={true} />
                </ContentRow>
              {/each}
            {/if}

            {#if scene.aux.editable && Object.keys(scene.aux.editable).length > 0}
              {#each objectSortedKeys(scene.aux.editable) as key (key)}
                <ContentRow>
                  <AdditionalInformationField
                    {key}
                    bind:value={scene.aux.editable[key]}
                    defaultValue={clipsByName.get(scene.title.split('/')[1])?.Vitals[key]}
                    disabled={sessionCreatedById !== getAccountId()}
                  />
                </ContentRow>
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  {/each}
{/if}

{#if sessionCreatedById === getAccountId()}
  {#if addingClip}
    <p class="clip-number" in:fade|local={fadeConfig}>Clip {hmdJson.scenes.length + 1}</p>

    <ContentRow class="session-scenes-panel-patient-clip">
      <div class="session-scenes-panel-patient-clip__patients" in:fade|local={fadeConfig}>
        <Select class="gigxr-input" bind:value={newPatient} variant="filled" label="Patient" enhanced>
          <Option />
          {#each pathologies as pathology (pathology.Name)}
            <Option value={pathology.Name}>{pathology.Name}</Option>
          {/each}
        </Select>
      </div>
      <div class="session-scenes-panel-patient-clip__clips" in:fade|local={fadeConfig}>
        <Select
          class="gigxr-input"
          bind:value={newClip}
          variant="filled"
          label="Clip"
          disabled={newPatient === ''}
          enhanced
        >
          <Option />
          {#if clipsByPatient.has(newPatient)}
            {#each clipsByPatient.get(newPatient) as clip (clip.Name)}
              <Option
                value={clip.Name}
                on:click={(event) => disableOptionHandler(event, `${newPatient}/${clip.Name}`)}
                disabled={usedClips.has(`${newPatient}/${clip.Name}`)}
              >
                {clip.Name}
              </Option>
            {/each}
          {/if}
        </Select>
      </div>
      <div class="session-scenes-panel-patient-clip__controls" in:fade|local={fadeConfig}>
        <Button variant="unelevated" on:click={() => addClip()} disabled={newPatient === '' || newClip === ''}>
          <Label>SET</Label>
        </Button>
      </div>
    </ContentRow>
  {:else}
    <Button class="gigxr-button-link session-scenes-add-clip-button" on:click={() => (addingClip = true)}>
      <Label>Add Another Clip</Label>
    </Button>
  {/if}
{/if}

<style>
  .session-scenes-panel-description {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .clip-number {
    font-weight: 700;
    margin-top: 0;
    font-size: 1.17em;
  }

  .session-scene {
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5em;
  }

  :global(.mdc-select__menu .mdc-list-item) {
    white-space: nowrap;
  }

  .session-scenes-panel-patient-clip__controls--with-helper-text {
    margin-bottom: 19px;
  }

  .session-scenes-section__left {
    font-weight: 700;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  @media (min-width: 1100px) {
    .session-scenes-section {
      display: flex;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    .session-scenes-section__left {
      flex: 1;
      border-right: thin solid rgba(0, 0, 0, 0.2);
      padding-right: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    .session-scenes-section__right {
      flex: 3;
      padding-left: 1em;
    }
  }

  :global(.session-scenes-remove-clip-button) {
    margin-left: -8px;
  }

  :global(.session-scenes-add-clip-button) {
    margin-left: -8px;
  }

  .session-scenes-panel-patient-clip__patients,
  .session-scenes-panel-patient-clip__clips {
    margin-bottom: 1em;
  }

  @media (min-width: 1100px) {
    .session-scenes-panel-patient-clip__patients,
    .session-scenes-panel-patient-clip__clips {
      margin-bottom: 0;
    }

    :global(.session-scenes-panel-patient-clip) {
      display: flex;
    }

    .session-scenes-panel-patient-clip__patients {
      flex: 4;
      padding-right: 1em;
    }

    .session-scenes-panel-patient-clip__clips {
      flex: 4;
      padding-right: 1em;
    }

    .session-scenes-panel-patient-clip__controls {
      flex: 2;
      display: flex;
      align-items: center;
    }
  }
</style>
