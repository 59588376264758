<script>
  import { getContext } from 'svelte';
  import { Content as DialogContent } from '@smui/dialog';

  const ariaPrefix = getContext('GigXrDialog:ariaPrefix');
</script>

<DialogContent id={`${ariaPrefix}-content`}>
  <slot />
</DialogContent>

<style>
  :global(.gigxr-dialog .mdc-dialog__content) {
    color: #000;
    text-align: center;
  }

  @media (max-width: 1099px) {
    :global(.gigxr-dialog .mdc-dialog__content) {
      flex: initial;
    }
  }
</style>
