<script>
  import Icon from '@smui/textfield/icon/index';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import UnauthenticatedPageTextField from '../components/unauthenticated/UnauthenticatedPageTextField.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import { getQueryParameter } from '../util/util';
  import LoadingView from '../components/LoadingView.svelte';
  import UnauthenticatedPageErrorBanner from '../components/unauthenticated/UnauthenticatedPageErrorBanner.svelte';
  import { resetPasswordByToken, validatePassword } from '../util/api/accounts';
  import { GmsError } from '../util/errors';
  import { errorMessage } from '../stores/core-store';

  /** @type {string} */
  export let token;

  /** @type {number} Time since unix epoch in milliseconds */
  const expires = Number(getQueryParameter('expires'));

  /** @type {'loading', 'success', 'expired', 'default'} */
  let pageState = 'loading';
  let password = '';
  let passwordConfirmation = '';
  let form;

  if (!expires || expires < Date.now()) {
    pageState = 'expired';
  } else {
    pageState = 'default';
  }

  async function clickHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      throw new GmsError('Please fill out both fields.');
    }

    if (password !== passwordConfirmation) {
      throw new GmsError('Your passwords do not match. Please try again.');
    }

    validatePassword(password);

    try {
      await resetPasswordByToken(password, token);
      pageState = 'success';
    } catch (e) {
      pageState = 'expired';
    }
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    {#if pageState === 'loading'}
      <LoadingView />
    {:else if pageState === 'success'}
      <UnauthenticatedPageHeader>Success!</UnauthenticatedPageHeader>

      <UnauthenticatedPageSubheader>
        You've successfully reset your password. Return to the login screen to login with your new password.
      </UnauthenticatedPageSubheader>

      <UnauthenticatedPageLink id="return-to-login-link" href="/" label="Return to Login" />
    {:else if pageState === 'expired'}
      <UnauthenticatedPageHeader>Reset link expired.</UnauthenticatedPageHeader>

      <UnauthenticatedPageSubheader>
        The reset link has expired. Please return to the login screen to try logging in again or to get another password
        reset link.
      </UnauthenticatedPageSubheader>

      <UnauthenticatedPageLink id="return-to-login-link" href="/" label="Return to Login" />
    {:else}
      <UnauthenticatedPageHeader>Reset password.</UnauthenticatedPageHeader>

      <UnauthenticatedPageErrorBanner />

      <UnauthenticatedPageSubheader>
        Please create a new password of at least 8 characters including at least one number and one capital letter.
      </UnauthenticatedPageSubheader>

      <!-- Using a real form will submit on enter -->
      <form bind:this={form}>
        <UnauthenticatedPageTextField
          id="password-field"
          type="password"
          bind:value={password}
          label="New Password"
          invalid={Boolean($errorMessage)}
          required
          withLeadingIcon
        >
          <Icon class="material-icons">person</Icon>
        </UnauthenticatedPageTextField>

        <UnauthenticatedPageTextField
          id="repeat-password-field"
          type="password"
          bind:value={passwordConfirmation}
          required
          label="Repeat Password"
          invalid={Boolean($errorMessage)}
          withLeadingIcon
        >
          <Icon class="material-icons">person</Icon>
        </UnauthenticatedPageTextField>

        <UnauthenticatedPageButton id="reset-password-button" label="Reset Password" on:click={clickHandler} />
      </form>
    {/if}
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
</style>
