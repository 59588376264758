<script>
  import { link } from 'svelte-routing';
  import { createEventDispatcher } from 'svelte';

  export let id = '';
  export let label;
  export let href;
  export let capitalize = false;

  const dispatch = createEventDispatcher();

  function clickHandler(event) {
    dispatch('click', event);
  }
</script>

<div class="unauthenticated-page__link">
  <a
    {id}
    {href}
    class="gigxr-link {capitalize ? 'gigxr-link--capitalize' : ''}"
    on:click={clickHandler}
    use:link
  >{label}</a>
</div>

<style>
  .unauthenticated-page__link {
    margin: 3em 0;
  }

  .gigxr-link--capitalize {
    text-transform: capitalize;
  }
</style>
