<script context="module">
  import { AccountRole } from '../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Select, { Option } from '@smui/select';
  import { title, errorMessage, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import WorkInProgress from '../components/WorkInProgress.svelte';
  import { setAuthorizedRoles } from '../util/authorization';
  import { fetchModules } from '../util/api/modules';
  import LoadingView from '../components/LoadingView.svelte';
  import ListPageTable, { rowNavigate } from '../components/ListPageTable.svelte';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import ModuleListItemMenuButton from '../components/modules/ModuleListItemMenuButton.svelte';
  import MobileListPageControls from '../components/MobileListPageControls.svelte';
  import DesktopListPageControls from '../components/DesktopListPageControls.svelte';
  import AddModuleButton from '../components/modules/AddModuleButton.svelte';
  import { LIST_VIEW_COUNT_PER_PAGE } from '../util/constants';
  import ListPageCount from '../components/ListPageCount.svelte';
  import ListPageViewMoreButton from '../components/ListPageViewMoreButton.svelte';
  import FilterComponent from '../components/FilterComponent.svelte';
  import FilterButton from '../components/FilterButton.svelte';
  import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../util/filters';
  import { Countries } from '../util/countries';
  import ListPageSearch from '../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../components/ListPageMobileSearchButton.svelte';
  import {
    ModulesSortBySet,
  } from '../util/filters/module-list-filters';
  import ListPageNoResultsMessage from '../components/ListPageNoResultsMessage.svelte';
  import { fetchLicenses } from '../util/api/licenses';
  import { fetchClientApps } from '../util/api/client-apps';
  import { utcStringToLocalDate } from '../util/dates';
  import FormattedSearchItem from '../components/FormattedSearchItem.svelte';
  import ListPageCardGrid from '../components/ListPageCardGrid.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Modules');
  breadcrumbPaths.set([]);

  let loading = true;
  let showMobileSearch = false;
  let filterComponent;
  let searchQuery = '';

  /** @type {[ModuleListView]} */
  let modules = [];

  /** @type {[ModuleListView]} */
  let filteredModules= [];

  /** @type {[ModuleListView]} */
  let filteredAndSearchedModules= [];

  let displayedItems = LIST_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedModules.length - displayedItems;

  let filterBySets = [];
  let clientApps = [];

  let licensesById = new Map();

  onMount(async () => {
    let licenses;
    [modules, licenses, clientApps] = await Promise.all([fetchModules(), fetchLicenses(), fetchClientApps()]);
    console.log(`got modules: ${modules.length}`);
    filteredModules = [...modules];
    console.log(`got modules: ${filteredModules.length}`);

    // Index licenses by licenseId
    licenses.forEach((license) => licensesById.set(license.licenseId, license));

    loading = false;
  });

  const searchValueFunctions = {
    moduleName: (module) => module.moduleName,
  };

</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredModules}
          bind:filteredList={filteredAndSearchedModules}
          bind:query={searchQuery}
          valueFunctions={searchValueFunctions}
          placeholder="Search modules"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageCount
          {loading}
          count={filteredAndSearchedModules.length}
          singularLabel="Module"
          pluralLabel="Modules"
        />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddModuleButton />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} />
        <FilterButton on:click={filterComponent.toggle} />
        <AddModuleButton />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageCount
        {loading}
        count={filteredAndSearchedModules.length}
        singularLabel="Module"
        pluralLabel="Modules"
      />
      <FilterButton on:click={filterComponent.toggle} />
      <ListPageSearch
        unfilteredList={filteredModules}
        bind:filteredList={filteredAndSearchedModules}
        bind:query={searchQuery}
        valueFunctions={searchValueFunctions}
        placeholder="Search modules"
      />
    </div>
    <div slot="right">
      <AddModuleButton />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={modules}
  bind:filteredList={filteredModules}
  sortBySet={ModulesSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <ListPageCardGrid>
        {#each filteredAndSearchedModules as module, index (module.moduleId + searchQuery)}
          {#if index < displayedItems}
            <!--AssetMobileListCard asset={asset} match={searchQuery} {licensesById} /-->
          {/if}
        {/each}
      </ListPageCardGrid>

      {#if filteredAndSearchedModules.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no modules to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <ListPageTable ariaLabel="Assets" class="institutions-data-table">
          <Head>
            <Row>
              <Cell class="institutions-data-table__name-column">Module Name</Cell>
              <Cell class="institutions-data-table__contact-column">Module Id</Cell>
              <Cell class="institutions-data-table__country-column">Client App</Cell>

              <Cell class="institutions-data-table__menu-column" />
            </Row>
          </Head>
          <Body>
            {#each filteredAndSearchedModules as module, index (module.moduleId + searchQuery)}
              {#if index < displayedItems}
                <Row on:click={(event) => rowNavigate(event, `modules/view/${module.moduleId}`)}>
                  <Cell title={module.moduleName}>
                    <FormattedSearchItem value={module.moduleName} match={searchQuery} />
                  </Cell>
                    <Cell>{module.moduleId}</Cell>
                    <Cell>
                        {#each clientApps as app (app.clientAppId)}
                          {#if app.clientAppId === module.clientAppId}
                            {app.clientAppName}
                          {/if}
                        {/each}
                    </Cell>




                  <Cell class="overflow-visible" numeric>
                    <ModuleListItemMenuButton module={module} />
                  </Cell>
                </Row>
              {/if}
            {/each}
          </Body>
        </ListPageTable>
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Modules"
          {remainingItems}
          on:click={() => (displayedItems += LIST_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .usage-warning small {
    margin-right: 1em;
    text-transform: uppercase;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  :global(.institutions-data-table__name-column) {
    width: 19%;
  }

  :global(.institutions-data-table__contact-column) {
    width: 15%;
  }

  :global(.institutions-data-table__country-column) {
    width: 10%;
  }

  :global(.institutions-data-table__licenses-column) {
    width: 15%;
    white-space: normal;
  }

  :global(.institutions-data-table__users-column) {
    width: 11%;
    white-space: normal;
  }

  :global(.institutions-data-table__licensed-apps-column) {
    width: 13%;
    white-space: normal;
  }

  :global(.institutions-data-table__demo-column) {
    width: 7%;
  }

  :global(.institutions-data-table__menu-column) {
    width: 10%;
  }
</style>
