<script>
  import { navigate } from 'svelte-routing';
  import GigXrDialog from './gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from './gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from './gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogCancelButton from './gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogActions from './gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from './gigxr-dialog/GigXrDialogButton.svelte';

  export let href = '';
  export let dialog = undefined;

  export let clickHandler = function (event) {
    event.preventDefault();
    navigate(href);
  };
</script>

<GigXrDialog bind:dialog ariaPrefix="discard-changes">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Are you sure you want to discard all changes?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={clickHandler}>Discard Changes</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
