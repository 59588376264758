<script>
  import Textfield from '@smui/textfield';
  import UnauthenticatedPageRow from './UnauthenticatedPageRow.svelte';

  export let id = '';
  export let invalid = false;
  export let value = '';
  export let label = '';
  export let type = 'text';
  export let required = false;
  export let disabled = false;
  export let withLeadingIcon = false;
</script>

<UnauthenticatedPageRow>
  <Textfield
    class="gigxr-input unauthenticated-page__text-field"
    variant="filled"
    bind:value
    {invalid}
    updateInvalid
    {label}
    {type}
    {disabled}
    {withLeadingIcon}
    input$required={required}
    input$id={id}
    on:invalid
    on:change
    on:input
  >
    <slot />
  </Textfield>
</UnauthenticatedPageRow>

<style>
  :global(.unauthenticated-page__text-field .mdc-line-ripple) {
    background: var(--gigxr-theme-primary-1c);
  }

  :global(.unauthenticated-page__text-field.mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-floating-label) {
    color: var(--gigxr-theme-primary-1c);
  }

  :global(.gigxr-input.unauthenticated-page__text-field) {
    background: #eff2f5;
    border-radius: 10px 10px 0 0;
  }

  :global(.unauthenticated-page__text-field.mdc-text-field--invalid .mdc-text-field__icon) {
    color: var(--gigxr-theme-secondary-4);
  }

  @media (min-width: 1100px) {
    :global(.gigxr-input.unauthenticated-page__text-field) {
      width: 370px;
    }
  }
</style>
