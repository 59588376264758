<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import * as EmailValidator from 'email-validator';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Switch from '@smui/switch';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { title, breadcrumbPaths, snackbar, snackbarMessage } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { deepCopy } from '../../../util/util';
  import { editInstitution, fetchInstitution } from '../../../util/api/institutions';
  import { createLicense, editLicense, fetchLicenses, LicenseType, LicenseTypeText } from '../../../util/api/licenses';
  import CountrySelect from '../../../components/CountrySelect.svelte';
  import GigXrDialogIcon from '../../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialog from '../../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogActions from '../../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogContent from '../../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogCancelButton from '../../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrHorizontalRule from '../../../components/GigXrHorizontalRule.svelte';
  import { GmsError } from '../../../util/errors';
  import { RegistrationStatus, addAccount } from '../../../util/api/accounts';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import GigXrDatepicker from '../../../components/GigXrDatepicker.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import { fetchModules, fetchInstitutionModules } from '../../../util/api/modules';

  export let institutionId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit Institution');
  breadcrumbPaths.set([
    {
      name: 'System Dashboard',
      location: '/',
    },
    {
      name: 'Edit Institution',
      location: `/institutions/edit/${institutionId}`,
    },
  ]);

  let loading = true;
  let form;
  let dialog;
  let discardChangesDialog;

  let lastSavedInstitution = {
    institutionId: '',
    institutionName: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
    },
    address: {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNumber: {
      number: '',
    },
  };

  let institution = deepCopy(lastSavedInstitution);

  let newInstitutionAdmin = {
    firstName: '',
    lastName: '',
    email: '',
  };

  let lastSavedLicense = {
    licenseId: '',
    expirationDate: '',
    licenseType: '',
    licensedActiveUsers: 0,
    institutionId,
    clientAppIds: [],
  };

  let licenseExpirationValue = new Date();
  let license = deepCopy(lastSavedLicense);
  let clientApps = [];
  let allModules = [];
  let institutionModules = []

  onMount(async () => {
    let licenses;
    [lastSavedInstitution, licenses, clientApps, allModules, institutionModules] = await Promise.all([
      fetchInstitution(institutionId),
      fetchLicenses(institutionId),
      fetchClientApps(),
      fetchModules(),
      fetchInstitutionModules(institutionId),
    ]);

    if (!lastSavedInstitution.contact) {
      lastSavedInstitution.contact = {
        firstName: '',
        lastName: '',
        email: '',
      };
    }
    institution = deepCopy(lastSavedInstitution);

    if (licenses.length > 0) {
      // There is infrastructure for more than one license per institution, but only one is being used at the moment.
      lastSavedLicense = licenses[0];
      license = deepCopy(lastSavedLicense);
      license.clientAppIds = lastSavedLicense.clientApps?.map((ca) => ca.clientAppId) ?? [];
      licenseExpirationValue = utcStringToLocalDate(license.expirationDate);
    }

    loading = false;
  });

  async function editInstitutionHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    const [editedInstitution] = await Promise.all([
      editInstitution(institution),
      createNewInstitutionAdminIfProvided(institution.institutionId),
      createOrEditLicense(),
    ]);

    snackbarMessage.set('Institution edited!');
    $snackbar.open();
    navigate(`/institutions/view/${editedInstitution.institutionId}`);
  }

  async function convertCustomerHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    institution.isDemoAccount = false;
    const editedInstitution = await editInstitution(institution);

    snackbarMessage.set('Institution converted to customer account!');
    $snackbar.open();
    navigate(`/institutions/view/${editedInstitution.institutionId}`);
  }

  async function createNewInstitutionAdminIfProvided(institutionId) {
    if (
      newInstitutionAdmin.firstName.trim() === '' &&
      newInstitutionAdmin.lastName.trim() === '' &&
      newInstitutionAdmin.email.trim() === ''
    ) {
      // All fields blank, ok!
      return;
    }

    if (newInstitutionAdmin.firstName.trim() === '') {
      throw new GmsError('Institution admin first name is empty!');
    }

    if (newInstitutionAdmin.lastName.trim() === '') {
      throw new GmsError('Institution admin last name is empty!');
    }

    if (newInstitutionAdmin.email.trim() === '') {
      throw new GmsError('Institution admin email is empty!');
    }

    if (!EmailValidator.validate(newInstitutionAdmin.email)) {
      throw new GmsError('Institution admin email is not valid!');
    }

    const newAccount = {
      ...newInstitutionAdmin,
      accountRole: AccountRole.INSTITUTION_ADMIN,
      isActive: true,
      institutionId,
      registrationStatus: RegistrationStatus.INVITED,
      departmentIds: [],
      classIds: [],
    };

    return await addAccount(newAccount);
  }

  async function createOrEditLicense() {
    if (!license.licenseType) {
      return;
    }

    license.expirationDate = licenseExpirationValue.toISOString();

    if (!license.licenseId) {
      await createLicense(license);
      return;
    }

    var moduleList = []

    for (var i = 0; i < allModules.length; i++) {
      if (institutionModules.includes(allModules[i].moduleId)) {
        moduleList.push(allModules[i]);
      }
    }
    license.moduleIds = institutionModules;
    license.modules = moduleList;
    await editLicense(license);
  }

  function discardChangesHandler(event) {
    institution = deepCopy(lastSavedInstitution);
    newInstitutionAdmin = {
      firstName: '',
      lastName: '',
      email: '',
    };
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <form bind:this={form}>
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={institution.institutionName}
              variant="filled"
              label="Institution Name"
              input$required
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Add an Institution Admin (optional)</h3>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-first-name-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.firstName}
              variant="filled"
              label="Institution Admin First Name"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-last-name-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.lastName}
              variant="filled"
              label="Institution Admin Last Name"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-email-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.email}
              variant="filled"
              label="Institution Admin Email"
              type="email"
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Institution Contact</h3>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="contact-first-name-field"
              class="gigxr-input"
              bind:value={institution.contact.firstName}
              variant="filled"
              label="Contact First Name"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="contact-last-name-field"
              class="gigxr-input"
              bind:value={institution.contact.lastName}
              variant="filled"
              label="Contact Last Name"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="email-field"
              class="gigxr-input"
              bind:value={institution.contact.email}
              variant="filled"
              label="Contact Email"
              input$required
              type="email"
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <Textfield
              input$id="address1-field"
              class="gigxr-input"
              bind:value={institution.address.address1}
              variant="filled"
              label="Address1"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="address2-field"
              class="gigxr-input"
              bind:value={institution.address.address2}
              variant="filled"
              label="Address2"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="address3-field"
              class="gigxr-input"
              bind:value={institution.address.address3}
              variant="filled"
              label="Address3"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="city-field"
              class="gigxr-input"
              bind:value={institution.address.city}
              variant="filled"
              label="City"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="state-field"
              class="gigxr-input"
              bind:value={institution.address.state}
              variant="filled"
              label="State/Province"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <CountrySelect bind:value={institution.address.country} />
          </ContentRow>

          <ContentRow>
            <Textfield
              class="gigxr-input"
              bind:value={institution.address.postalCode}
              variant="filled"
              label="Postal Code"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="phone-field"
              class="gigxr-input"
              bind:value={institution.phoneNumber.number}
              variant="filled"
              label="Phone"
              input$required
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Other Institution Settings</h3>
          </ContentRow>

          <ContentRow>
            The following setting is primarily for app store submission. It allows accounts under this institution to
            use GIG Mobile to start sessions without a Microsoft HoloLens device. It should
            <strong>not</strong>
            be enabled for real customers.
          </ContentRow>

          <KeyValueRow>
            <div slot="left">Can Mobile Create Sessions:</div>
            <div slot="right">
              <Switch bind:checked={institution.canMobileCreateSessions} class="gigxr-switch" />
            </div>
          </KeyValueRow>
        </div>

        <div slot="right">
          <ContentRow>
            <Select
              inputId="license-type-field"
              class="gigxr-input"
              bind:value={license.licenseType}
              variant="filled"
              label="License Type"
              enhanced
            >
              {#if !license.licenseType}
                <Option value="" disabled on:click={(event) => event.stopPropagation()} />
              {/if}
              {#each Object.values(LicenseType) as licenseType (licenseType)}
                <Option value={licenseType}>{LicenseTypeText[licenseType]}</Option>
              {/each}
            </Select>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="licensed-active-users-field"
              type="number"
              class="gigxr-input"
              bind:value={license.licensedActiveUsers}
              variant="filled"
              label="Licensed Active Users"
            />
          </ContentRow>

          <ContentRow>
            <GigXrDatepicker
              id="license-expiration-date-field"
              label="Expiry Date"
              bind:dateValue={licenseExpirationValue}
            />
          </ContentRow>

          <!-- The entire list must be rendered at once, otherwise clicking on a row will not trigger toggling the checkbox. -->
          {#if !loading}
            <ContentRow>
              {#if license.licenseType}
                <List class="gigxr-list" checklist>
                  {#each clientApps as clientApp (clientApp.clientAppId)}
                    <Item id="license-client-app-checkbox-{clientApp.clientAppId}">
                      <Graphic>
                        <Checkbox bind:group={license.clientAppIds} value={clientApp.clientAppId} />
                      </Graphic>
                      <Label>{clientApp.clientAppName}</Label>
                    </Item>
                  {/each}
                </List>
              {/if}
            </ContentRow>
          {/if}

          <ContentRowCenteredOnMobile>
            <Button
              id="edit-institution-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={editInstitutionHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="edit-institution-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

          {#if institution.isDemoAccount}
            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="edit-institution-convert-to-customer-link"
                class="gigxr-link"
                on:click={() => dialog.open()}
              >Convert to Customer Account</a>
            </ContentRowCenteredOnMobile>
          {/if}
        </div>
      </TwoColumnSection>
    </form>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<GigXrDialog bind:dialog ariaPrefix="convert-demo">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    <p>You are about to convert {institution.institutionName} to a customer.</p>
    <p>This cannot be undone.</p>
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={convertCustomerHandler}>Convert to Customer</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
