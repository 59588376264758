<script>
  import { Row, Cell } from '@smui/data-table';
  import Checkbox from '@smui/checkbox';
  import AccountListMenuButton from './accounts/AccountListMenuButton.svelte';
  import ListPageActiveIndicator from './ListPageActiveIndicator.svelte';
  import { AccountRoleText } from '../util/api/accounts';
  import { rowNavigate } from './ListPageTable.svelte';
  import FormattedSearchItem from './FormattedSearchItem.svelte';
  import DeleteAccountDialog from './accounts/DeleteAccountDialog.svelte';
  import { createEventDispatcher } from 'svelte';
  import ListPageCheckboxCell from './ListPageCheckboxCell.svelte';

  export let account = null;
  export let departmentsById;
  export let match = '';

  let menuVisible = false;
  let deleteAccountDialog;

  let departmentTooltip = '';

  const dispatch = createEventDispatcher();

  $: {
    if (account) {
      departmentTooltip = account.departmentIds.map((id) => departmentsById[id].departmentName).join('\n');
    }
  }

  function toggleMenu() {
    menuVisible = !menuVisible;
  }
</script>

<Row on:click={(event) => rowNavigate(event, `/users/view/${account.accountId}`)}>
  <ListPageCheckboxCell value={account.accountId} />
  <Cell title={`${account.firstName} ${account.lastName}`}>
    <FormattedSearchItem value={`${account.firstName} ${account.lastName}`} {match} />
  </Cell>
  <Cell title={account.email}>
    <FormattedSearchItem value={account.email} {match} />
  </Cell>
  <Cell title={AccountRoleText[account.accountRole]}>{AccountRoleText[account.accountRole]}</Cell>
  <Cell title={departmentTooltip}>
    <div class="departments">
      {#each account.departmentIds as id (id)}
        <div class="department">{departmentsById[id].departmentName}</div>
      {/each}
    </div>
  </Cell>
  <Cell title={account.registrationStatus}>{account.registrationStatus}</Cell>
  <Cell numeric>
    {#if account.isActive}
      <ListPageActiveIndicator />
    {:else}Inactive{/if}
  </Cell>
  <Cell class="overflow-visible" numeric>
    <AccountListMenuButton {account} on:AccountListMenuButton::delete={() => deleteAccountDialog.open()} />
  </Cell>
</Row>

<DeleteAccountDialog
  bind:dialog={deleteAccountDialog}
  {account}
  on:DeleteAccountDialog::deleteConfirmed={() => dispatch('AccountRow::deleted', account)}
/>

<style>
  .departments {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .department {
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
