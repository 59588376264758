<script>
  import { snackbarMessage, snackbar, isAuthenticated, errorMessage } from '../../stores/core-store';
  import { sendGdprDeleteRequest } from '../../util/api/accounts';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  export let dialog = undefined;
  export let account;

  let deleteConfirmation = '';

  async function gdprDeleteHandler(event) {
    event.preventDefault();
    await sendGdprDeleteRequest(account.accountId);
    snackbarMessage.set('Removal request sent!');
    $snackbar.open();
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="request-gdpr-delete-account">
  <GigXrDialogContent>
    <GigXrDialogIcon />

    <p>
      <strong>IMPORTANT:</strong>
      An email will be sent to support requesting that your personal data be removed. Once removed, this cannot be
      undone and you will lose access to GIGXR.
    </p>

    <p>To proceed, type REMOVE and click the REQUEST REMOVAL button.</p>
    <input type="text" class="gigxr-input" bind:value={deleteConfirmation} />
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={gdprDeleteHandler} disabled={deleteConfirmation !== 'REMOVE'}>
      Request Removal
    </GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
