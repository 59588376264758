<script>
  import { createEventDispatcher } from 'svelte';
  import Ripple from '@smui/ripple';

  const dispatch = createEventDispatcher();

  export let readonly = false;
</script>

<div
  class="mobile-list-card-content {readonly ? '' : 'mobile-list-card-content--interactive'}"
  use:Ripple={{ ripple: !readonly, color: 'surface' }}
  on:click={(event) => dispatch('click', event)}
>
  <slot />
</div>

<style>
  .mobile-list-card-content {
    padding: 16px;
    border-radius: 10px;
  }

  .mobile-list-card-content--interactive:hover {
    cursor: pointer;
  }
</style>
