<script>
  import MobileListCard from '../MobileListCard.svelte';
  import MobileListCardContent from '../MobileListCardContent.svelte';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import { LicenseTypeText } from '../../util/api/licenses';
  import { utcStringToLocalDate } from '../../util/dates';

  export let license;
</script>

<MobileListCard>
  <MobileListCardContent readonly={true}>
    <h2>{LicenseTypeText[license.licenseType]}</h2>

    <dl>
      <dt>Licensed Active Users:</dt>

      <dd>{license.licensedActiveUsers}</dd>

      <dt>Current Active Users:</dt>
      <dd>
        {#if license.currentActiveUsers > license.licensedActiveUsers}
          <span class="usage-warning">{license.currentActiveUsers}</span>
        {:else}{license.currentActiveUsers}{/if}
      </dd>

      <dt>Expiry Date:</dt>
      <dd>
        {#if utcStringToLocalDate(license.expirationDate).getTime() < Date.now()}
          <span class="usage-warning"> {utcStringToLocalDate(license.expirationDate).toLocaleDateString()} </span>
        {:else}{utcStringToLocalDate(license.expirationDate).toLocaleDateString()}{/if}
      </dd>

      <dt>Licensed Apps:</dt>
      <dd>
        <ul class="simple-list">
          {#each license.clientApps as clientApp (clientApp.clientAppId)}
            <li>{clientApp.clientAppName}</li>
          {/each}
        </ul>
      </dd>
    </dl>
  </MobileListCardContent>
  <ActionIcons />
</MobileListCard>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  h2 {
    margin: 0 0 16px;
    text-align: center;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 0;
  }

  dt {
    flex: 60%;
    font-weight: 500;
  }

  dd {
    margin-left: 0;
    margin-bottom: 0.2em;
    flex: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.gigxr-mobile-list-card .mdc-card__action-icons) {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
