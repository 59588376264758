<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button, { Label } from '@smui/button';
  import { title, breadcrumbPaths, snackbar, snackbarMessage } from '../../../stores/core-store';
  import { resendRegistrationInviteEmail } from '../../../util/api/accounts';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { deepCopy } from '../../../util/util';
  import { fetchInstitution } from '../../../util/api/institutions';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { fetchLicenses, LicenseTypeText } from '../../../util/api/licenses';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import EditButton from '../../../components/EditButton.svelte';
  import { encodeMasqueradeData } from '../../../util/masquerade';
  import GigXrHorizontalRule from '../../../components/GigXrHorizontalRule.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';
  import { fetchInstitutionModules } from '../../../util/api/modules';

  export let institutionId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Institution Details');
  breadcrumbPaths.set([
    {
      name: 'System Dashboard',
      location: '/',
    },
    {
      name: 'Institution Details',
      location: `/institutions/view/${institutionId}`,
    },
  ]);

  let loading = true;

  let licenses = [];
  let clientApps = [];
  let lastSavedInstitution = {
    institutionId: '',
    institutionName: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
    },
    address: {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNumber: {
      number: '',
    },
  };
  let lastSavedLicense = {
    licenseId: '',
    expirationDate: '',
    licenseType: '',
    licensedActiveUsers: 0,
    institutionId,
    clientAppIds: [],
  };

  let institution = deepCopy(lastSavedInstitution);
  let resentInviteAccountIds = new Set();
  let license = deepCopy(lastSavedLicense);
  let licenseExpirationValue = new Date();
  let institutionModules = [];
  let modulesByApp = {};

  $: masqueradeLink = `/?m=${encodeMasqueradeData(institution)}`;

  onMount(async () => {
    [licenses, clientApps, lastSavedInstitution, institutionModules] = await Promise.all([
      fetchLicenses(institutionId),
      fetchClientApps(),
      fetchInstitution(institutionId),
      fetchInstitutionModules(institutionId),
    ]);
    institution = deepCopy(lastSavedInstitution);
    if (licenses.length > 0) {
      // There is infrastructure for more than one license per institution, but only one is being used at the moment.
      lastSavedLicense = licenses[0];
      license = deepCopy(lastSavedLicense);
      license.clientAppIds = lastSavedLicense.clientApps?.map((ca) => ca.clientAppId) ?? [];
      licenseExpirationValue = utcStringToLocalDate(license.expirationDate);
      for (let i = 0; i < license.clientAppIds.length; i++) {
        modulesByApp[license.clientAppIds[i]] = [];
      }
      for (let i = 0; i < institutionModules.length; i++) {
        const moduleClientAppId = institutionModules[i].clientAppId;
        modulesByApp[moduleClientAppId].push(institutionModules[i]);
      }
    }
    // console.log(`***** institutionModules: ${JSON.stringify(institutionModules)}`);
    // console.log(`***** modulesByApp: ${JSON.stringify(modulesByApp)}`);
    loading = false;
  });

  async function resendRegistrationInviteHandler(event, accountId) {
    await resendRegistrationInviteEmail(accountId);

    snackbarMessage.set('Invitation resent!');
    $snackbar.open();

    resentInviteAccountIds.add(accountId);
    resentInviteAccountIds = resentInviteAccountIds;
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>
          {institution.institutionName}
          {#if institution.isDemoAccount}<small class="institution-demo-account">Demo Account</small>{/if}
        </h2>
      </div>

      <div slot="right">
        <EditButton on:click={() => navigate(`/institutions/edit/${institutionId}`)} />
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left" class="view-institution-left-section">
          <KeyValueRow>
            <div slot="left">Institution Admins:</div>
            <div slot="right">
              {#if institution.institutionAdmins.length > 0}
                <ul class="simple-list">
                  {#each institution.institutionAdmins as admin, index (admin.accountId)}
                    <li>
                      <a href="mailto:{admin.email}">{admin.firstName} {admin.lastName}</a>
                      {#if admin.registrationStatus === 'Invited' && !resentInviteAccountIds.has(admin.accountId)}
                        <Button
                          id="resend-registration-invite-button-{admin.accountId}"
                          on:click={(event) => resendRegistrationInviteHandler(event, admin.accountId)}
                        >
                          <Label>Resend Invite</Label>
                        </Button>
                      {/if}
                    </li>
                  {/each}
                </ul>
              {:else}<span title="None created">–</span>{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Contact:</div>
            <div slot="right">
              {#if institution.contact}
                <a id="institution-contact-link" href="mailto:{institution.contact.email}">
                  {institution.contact.firstName}
                  {institution.contact.lastName}
                </a>
              {:else}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Address:</div>
            <div slot="right">
              {institution.address.address1}
              <br />

              {#if institution.address.address2}{institution.address.address2} <br />{/if}

              {#if institution.address.address3}{institution.address.address3} <br />{/if}
              {institution.address.city},
              {institution.address.state}
              {institution.address.postalCode}
              {institution.address.country}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Phone:</div>
            <div slot="right">{institution.phoneNumber.number}</div>
          </KeyValueRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Other Institution Settings</h3>
          </ContentRow>

          <ContentRow>
            The following setting is primarily for app store submission. It allows accounts under this institution to
            use GIG Mobile to start sessions without a Microsoft HoloLens device. It should
            <strong>not</strong>
            be enabled for real customers.
          </ContentRow>

          <KeyValueRow>
            <div slot="left">Can Mobile Create Sessions:</div>
            <div slot="right">{institution.canMobileCreateSessions ? 'Yes' : 'No'}</div>
          </KeyValueRow>

          <!--<LicenseList {licenses} {clientApps}/>-->
        </div>

        <div slot="right">
          <KeyValueRow>
            <div slot="left">License Type:</div>
            <div slot="right">
              {#if licenses[0]}{LicenseTypeText[licenses[0].licenseType]}{:else}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Licensed Active Users:</div>
            <div slot="right">
              {#if licenses[0]}{licenses[0].licensedActiveUsers}{:else}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Current Active Users:</div>
            <div slot="right">
              {#if licenses[0] && institution.accountCount > licenses[0].licensedActiveUsers}
                <span class="usage-warning"> {institution.accountCount} </span>
              {:else}{institution.accountCount}{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Expiry Date:</div>
            <div slot="right">
              {#if licenses[0]}
                {#if utcStringToLocalDate(licenses[0].expirationDate).getTime() < Date.now()}
                  <span class="usage-warning">
                    {utcStringToLocalDate(licenses[0].expirationDate).toLocaleDateString()}
                  </span>
                {:else}{utcStringToLocalDate(licenses[0].expirationDate).toLocaleDateString()}{/if}
              {:else}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Licensed Apps:</div>
            <div slot="right">
              {#if licenses[0] && licenses[0].clientApps && licenses[0].clientApps.length > 0}
                <ul class="simple-list">
                  {#each licenses[0].clientApps as clientApp (clientApp.clientAppId)}
                    <li>{clientApp.clientAppName}<br/>
                      - Modules: <a on:click={() => navigate(`/licenses/edit/${institutionId}_${license.licenseId}_${clientApp.clientAppId}`)} >[Edit]</a><br/>
                      {#if modulesByApp[clientApp.clientAppId].length > 0}
                        <ul class="bulleted-list">
                          {#each modulesByApp[clientApp.clientAppId] as module}
                            <li>{module.moduleName}</li>
                          {/each}
                      </ul>
                      {:else}
                        (no modules)
                      {/if}
                    </li>

                  {/each}
                </ul>
              {:else}–{/if}
            </div>
          </KeyValueRow>

          <GigXrHorizontalRule />

          <KeyValueRow>
            <div slot="left">Sessions in Progress:</div>
            <div slot="right">{institution.sessionsInProgressCount}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Users in Sessions:</div>
            <div slot="right">{institution.accountsInSessionsCount}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Total Saved Sessions:</div>
            <div slot="right">{institution.totalSavedSessions}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Total Sessions Run:</div>
            <div slot="right">{institution.totalSessionsRun}</div>
          </KeyValueRow>

          <DateAccountComponent label="Added" utcDateString={institution.createdOn} account={institution.createdBy} />

          {#if institution.institutionConfig && institution.institutionConfig.allowGigXrAccess}
            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="login-as-admin-link" class="gigxr-link" href={masqueradeLink} target="_blank">Login as Admin</a>
            </ContentRowCenteredOnMobile>
          {/if}
        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .institution-demo-account {
    color: var(--gigxr-theme-secondary-3c);
    display: block;
    margin-top: 0.5em;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .bulleted-list {
      margin-left: 1.5em;
      padding: 0;
      list-style-type: circle;
  }

  @media (min-width: 1100px) {
    .institution-demo-account {
      display: inline;
      margin-left: 1em;
    }
  }
</style>
