import { authenticatedDelete, authenticatedGet, authenticatedPost } from '../api';
import { GmsError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { errorMessage } from '../../stores/core-store';

async function fetchNotifications() {
  const response = await authenticatedGet('/notifications');
  if (!response.ok) {
    throw new GmsError(`Error fetching notifications! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function createNotification(notification) {
  const requestPayload = {
    ...notification,
  };

  const response = await authenticatedPost('/notifications', requestPayload);

  if (response.status !== 201) {
    throw new GmsError(`Error creating notification! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newNotification = json.data;

  return newNotification;
}

async function removeNotification(notificationId) {
  const response = await authenticatedDelete(`/notifications/${notificationId}`);

  if (response.status !== 204) {
    throw new GmsError(`Error removing notification! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

export { fetchNotifications, createNotification, removeNotification };
