import { getJwtPayload, getJwt } from './json-web-tokens.js';

function getAccountId() {
  const payload = getJwtPayload(getJwt());
  return payload.nameid;
}

function getAccountRole() {
  const payload = getJwtPayload(getJwt());
  return payload.role;
}

export { getAccountId, getAccountRole };
