<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Icon from '@smui/textfield/icon/index';
  import { isAuthenticated, errorMessage, snackbar, snackbarMessage } from '../stores/core-store';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import UnauthenticatedPageTextField from '../components/unauthenticated/UnauthenticatedPageTextField.svelte';
  import UnauthenticatedPageRow from '../components/unauthenticated/UnauthenticatedPageRow.svelte';
  import {
    completeRegistrationByToken,
    fetchRegistrationInformation,
    validatePassword,
    sendAppStoreEmail,
  } from '../util/api/accounts';
  import LoadingView from '../components/LoadingView.svelte';
  import Cookies from 'js-cookie';
  import { Logger } from '../util/logs';
  import UnauthenticatedPageErrorBanner from '../components/unauthenticated/UnauthenticatedPageErrorBanner.svelte';
  import { getQueryParameter, getMobileOperatingSystem, b64DecodeUnicode } from '../util/util';
  import { GmsError } from '../util/errors';
  import Button, { Label } from '@smui/button';
  import ButtonCircularProgress from '../components/ButtonCircularProgress.svelte';

  export let token;
  let loading = true;
  let state = 'pending';
  let form;
  let tokenForEmail = null;
  let sendingTokenEmail = false;
  let sentTokenEmail = false;

  let completeBypass = getQueryParameter('cb');

  let account = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };
  let passwordConfirmation = '';

  /** @type {'loading', 'completeRegistration', 'registrationComplete', 'invalidToken'} */
  let pageState = 'loading';

  const mobileOperatingSystem = getMobileOperatingSystem();

  onMount(async () => {
    try {
      if (completeBypass) {
        pageState = 'registrationComplete';
        return;
      }

      Cookies.remove('jwt');
      isAuthenticated.set(false);
      account = await fetchRegistrationInformation(token);
      if (account.registrationStatus === 'Added' || account.registrationStatus === 'Invited') {
        pageState = 'completeRegistration';
      } else {
        pageState = 'registrationComplete';
      }
    } catch (error) {
      // Invalid token
      Logger.log('Invalid consent token!');
      pageState = 'invalidToken';
    }
  });

  async function completeRegistrationHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      throw new GmsError('You must fill out all fields to continue.');
    }

    if (account.password !== passwordConfirmation) {
      throw new GmsError('Your passwords do not match. Please try again.');
    }

    validatePassword(account.password);

    tokenForEmail = await completeRegistrationByToken(token, account);
    errorMessage.set('');
    pageState = 'registrationComplete';
  }

  function validateEmailHandler(event) {
    const textfield = event.target;
    try {
      validatePassword(textfield.value);
      textfield.setCustomValidity('');
    } catch (error) {
      textfield.setCustomValidity(error.message);
    }
  }

  function validateRepeatPasswordHandler(event) {
    const textfield = event.target;
    if (textfield.value === account.password) {
      textfield.setCustomValidity('');
    } else {
      textfield.setCustomValidity('Your passwords do not match. Please try again.');
    }
  }

  async function appStoreHandler(event) {
    sendingTokenEmail = true;
    errorMessage.set('');
    try {
      await sendAppStoreEmail(tokenForEmail ?? token);
      sentTokenEmail = true;
    } finally {
      sendingTokenEmail = false;
    }
    snackbarMessage.set('Email sent!');
    $snackbar.open();
  }
</script>

<UnauthenticatedPage>
  <div class="center-container">
    <UnauthenticatedPageContent>
      <UnauthenticatedPageLogo />

      {#if pageState === 'loading'}
        <LoadingView />
      {:else if pageState === 'completeRegistration'}
        <UnauthenticatedPageHeader>Complete Your Registration</UnauthenticatedPageHeader>

        <UnauthenticatedPageSubheader>
          To access your account, please complete your registration below:
        </UnauthenticatedPageSubheader>

        <UnauthenticatedPageErrorBanner />

        <UnauthenticatedPageSubheader>Confirm or update your contact information:</UnauthenticatedPageSubheader>

        <!-- Using a real form will submit on enter -->
        <form bind:this={form}>
          <UnauthenticatedPageTextField
            id="first-name-field"
            bind:value={account.firstName}
            label="First Name"
            required
          />

          <UnauthenticatedPageTextField id="last-name-field" bind:value={account.lastName} label="Last Name" required />

          <UnauthenticatedPageTextField
            id="email-field"
            bind:value={account.email}
            label="Email Address"
            withLeadingIcon
            disabled
            required
          >
            <Icon class="material-icons">email</Icon>
          </UnauthenticatedPageTextField>

          <!--        <UnauthenticatedPageRow class="complete-registration__password-section">-->
          <UnauthenticatedPageSubheader class="complete-registration__password-section">
            Passwords must be at least 8 characters including at least one number and one capital letter:
          </UnauthenticatedPageSubheader>
          <!--        </UnauthenticatedPageRow>-->

          <UnauthenticatedPageTextField
            id="password-field"
            type="password"
            bind:value={account.password}
            label="Password"
            on:input={validateEmailHandler}
            withLeadingIcon
            required
          >
            <Icon class="material-icons">lock</Icon>
          </UnauthenticatedPageTextField>

          <UnauthenticatedPageTextField
            id="repeat-password-field"
            type="password"
            bind:value={passwordConfirmation}
            required
            label="Repeat Password"
            withLeadingIcon
            on:input={validateRepeatPasswordHandler}
          >
            <Icon class="material-icons">lock</Icon>
          </UnauthenticatedPageTextField>

          <UnauthenticatedPageButton
            id="complete-registration-button"
            class="complete-registration__button"
            label="Complete Registration"
            on:click={completeRegistrationHandler}
          />
        </form>
      {:else if pageState === 'registrationComplete'}
        <UnauthenticatedPageHeader>
          {completeBypass ? b64DecodeUnicode(completeBypass) : account.firstName}, you're all set!
        </UnauthenticatedPageHeader>

        <UnauthenticatedPageSubheader>
          You can begin using GIGXR immediately. Check your inbox for some important information on how to use GIGXR.
        </UnauthenticatedPageSubheader>

        <UnauthenticatedPageSubheader>Login and check out your dashboard.</UnauthenticatedPageSubheader>

        <UnauthenticatedPageButton id="get-started-button" label="Get Started" on:click={() => navigate('/')} />
      {:else}
        <UnauthenticatedPageHeader>Link Expired!</UnauthenticatedPageHeader>

        <UnauthenticatedPageSubheader>
          This link has expired. Please contact your institution admin to get another one.
        </UnauthenticatedPageSubheader>

        <UnauthenticatedPageButton id="back-to-home-button" label="Back to Home" on:click={() => navigate('/')} />
      {/if}
    </UnauthenticatedPageContent>

    <!-- 
      Disabled at this time. This is to display a link to the app store after the complete registration process, but in
      platform this does not make sense in the multiple app context.
    -->
    {#if pageState === 'registrationComplete' && false}
      <div class="background-wrapper">
        <div class="background-wrapper__content">
          <div class="complete-registration__app-download">
            <div class="complete-registration__app-download__left">
              <img src="/assets/gigxr-mobile-phone.png" width="54" height="107" alt="GIGXR logo on mobile phone" />
            </div>
            <div class="complete-registration__app-download__right">
              <UnauthenticatedPageHeader>
                Are you looking to use your mobile device to participate in sessions?
              </UnauthenticatedPageHeader>
              <p>Download the GIGXR App and follow the instructions in the app to use your phone for GIGXR Sessions.</p>

              {#if sentTokenEmail}
                <UnauthenticatedPageRow>Email sent!</UnauthenticatedPageRow>
              {:else}
                <div class="email-me-app-link-wrapper">
                  <Button
                    id="email-me-app-link"
                    class="email-me-app-link"
                    on:click={appStoreHandler}
                    disabled={sendingTokenEmail && !sentTokenEmail}
                  >
                    <Label>Email me App Store Links</Label>
                  </Button>
                  {#if sendingTokenEmail && !sentTokenEmail}
                    <ButtonCircularProgress />
                  {/if}
                </div>
                <p class="app-store-error">{$errorMessage}</p>
              {/if}
            </div>
          </div>

          {#if mobileOperatingSystem !== 'Android'}
            <p class="complete-registration__app-store-badges">
              <!-- https://developer.apple.com/app-store/marketing/guidelines/ -->
              <!-- svelte-ignore a11y-missing-content -->
              <a
                href="https://apps.apple.com/app/gig-mobile/id1530966549"
                style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-11-19&kind=iossoftware&bubble=ios_apps)
                no-repeat;width:175px;height:59px;background-size:175px 59px;"
              />
            </p>
          {/if}

          {#if mobileOperatingSystem !== 'iOS'}
            <p class="complete-registration__app-store-badges">
              <!-- https://play.google.com/intl/en_us/badges/ -->
              <a
                href="https://play.google.com/store/apps/details?id=com.gigxr.gigmobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              ><img
                  class="complete-registration__store-badge"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                /></a>
            </p>
          {/if}

          {#if mobileOperatingSystem !== 'Android'}
            <footer>Apple® and the Apple logo® are trademarks of Apple Inc.</footer>
          {/if}

          {#if mobileOperatingSystem !== 'iOS'}
            <footer>Google Play and the Google Play logo are trademarks of Google LLC.</footer>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</UnauthenticatedPage>

<style>
  .email-me-app-link-wrapper {
    position: relative;
    text-align: left;
    display: none;
    margin-left: -8px;
  }

  :global(.email-me-app-link:not(:disabled)) {
    color: #000 !important;
  }

  :global(.email-me-app-link:disabled) {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  footer {
    margin-bottom: 1em;
  }

  .app-store-error {
    display: none;
    color: var(--gigxr-theme-secondary-4);
  }

  :global(.complete-registration__password-section) {
    margin-top: 2.5em !important;
  }

  :global(.complete-registration__button) {
    margin-bottom: 3em;
  }

  .complete-registration__app-download {
    margin: 2em 0 0;
  }

  .complete-registration__app-download__left {
    display: none;
  }

  .complete-registration__app-download__right :global(h2) {
    font-size: 1em;
    font-weight: 700;
    margin-top: 0;
  }

  .complete-registration__store-badge {
    width: 200px;
  }

  .center-container {
    width: 100%;
  }

  .background-wrapper {
    margin-top: 2em;
    width: 100%;
    background: #edf1f3;
  }

  .background-wrapper__content {
    text-align: center;
    margin: 0 auto;
    padding: 5px 20px 20px 20px;
    max-width: 650px;
  }

  @media (min-width: 1100px) {
    .complete-registration__app-download {
      display: flex;
      justify-content: space-between;
    }

    .complete-registration__app-download :global(h2) {
      margin-top: 0;
    }

    .complete-registration__app-download__left {
      display: initial;
      margin-right: 2em;
      margin-top: 0.5em;
    }

    .complete-registration__app-download__right {
      text-align: left;
    }

    .complete-registration__app-download__right :global(h2) {
      font-size: 1.5em;
      font-weight: 400;
    }

    .email-me-app-link-wrapper {
      display: inline-block;
    }

    .app-store-error {
      display: block;
    }

    footer,
    .complete-registration__app-store-badges {
      display: none;
    }
  }
</style>
