<script>
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import { authorizedRoles } from '../../routes/classes/edit/[classId].svelte';
  import { role } from '../../stores/core-store';

  export let disabled = false;
</script>

{#if authorizedRoles.includes($role)}
  <IconButton
    id="add-class-button"
    title="Add Class"
    class="material-icons gigxr-icon-button add-class-button"
    on:click={() => navigate('/classes/create')}
    {disabled}
  >
    add_box
  </IconButton>
{/if}

<style>
  :global(.add-class-button:disabled) {
    opacity: 0.38;
  }
</style>
