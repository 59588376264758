<script>
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
</script>

<IconButton
  id="add-institution-button"
  title="Add Module"
  class="material-icons gigxr-icon-button"
  on:click={() => navigate('/modules/create')}
>
  add_box
</IconButton>

<style>
</style>
