<script>
  import { onDestroy } from 'svelte';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageParagraph from '../components/unauthenticated/UnauthenticatedPageParagraph.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import IconButton, { Icon } from '@smui/icon-button';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import {
    UnityAction,
    sendUnityEnableNotificationsMessage,
    subscribeToUnityMessage,
    isUnity,
    sendUnityFirstTimeExperienceFinishedMessage,
  } from '../util/unity';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import { getMobileOperatingSystem } from '../util/util';
  import { navigate } from 'svelte-routing';
  import Carousel from '@beyonk/svelte-carousel';
  import Button, { Label } from '@smui/button';
  import UnauthenticatedPageRow from '../components/unauthenticated/UnauthenticatedPageRow.svelte';

  function getStartedHandler(event) {
    const os = getMobileOperatingSystem();

    if (os === 'iOS') {
      // On iOS we need to explicitly ask for notification permissions.
      navigate('/turn-on-notifications', { replace: true });
      return;
    }

    sendUnityFirstTimeExperienceFinishedMessage();
    navigate('/', { replace: true });
  }

  let carousel;
  let currentSlide = 0;
  let slideCount = 3;

  function onCarouselChangeHandler(event) {
    ({ currentSlide, slideCount } = event.detail);
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <div class="carousel-wrapper">
      <Carousel bind:this={carousel} on:change={onCarouselChangeHandler} loop={false} perPage="1">
        <div class="slide-content slide-content-1">
          <UnauthenticatedPageHeader>
            <strong>Welcome to the GIGXR Immersive Learning System</strong>
          </UnauthenticatedPageHeader>
        </div>

        <div class="slide-content slide-content-2">
          <UnauthenticatedPageRow>
            <img src="/assets/first-time-experience-2.png" alt="Pointer clicking available session card" />
          </UnauthenticatedPageRow>

          <UnauthenticatedPageHeader><strong>Get started with just one tap.</strong></UnauthenticatedPageHeader>

          <UnauthenticatedPageSubheader>
            Simply tap any
            <span>available session</span>
            to enter the session lobby. There you'll select your location and see who else is participating.
          </UnauthenticatedPageSubheader>
        </div>

        <div class="slide-content slide-content-3">
          <UnauthenticatedPageRow>
            <img src="/assets/first-time-experience-3.png" alt="Phone showing placed patient and push to talk button" />
          </UnauthenticatedPageRow>

          <UnauthenticatedPageSubheader>
            <strong>Place the virtual experience</strong>
            in your environment, move around it, and
            <strong>tap to talk</strong>
            to others in the session.
          </UnauthenticatedPageSubheader>

          <UnauthenticatedPageButton
            id="get-started-button"
            class="first-time-experience-get-started"
            label="Get Started"
            on:click={getStartedHandler}
          />
        </div>
      </Carousel>

      <div class="carousel-wrapper__left">
        {#if currentSlide !== slideCount - 1}
          <Button id="first-time-experience-skip-button" on:click={getStartedHandler}>
            <Label>Skip</Label>
          </Button>
        {/if}
      </div>

      <div class="carousel-wrapper__right">
        {#if currentSlide !== slideCount - 1}
          <Button id="first-time-experience-next-button" on:click={() => carousel.right()}>
            <Label>Next</Label>
          </Button>
        {/if}
      </div>
    </div>
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  .carousel-wrapper {
    position: relative;
  }

  .carousel-wrapper :global(ul) {
    bottom: 0;
    margin: 0;
    height: 36px;
    align-items: center;
  }

  .carousel-wrapper__left {
    position: absolute;
    bottom: 0;
    left: 30px;
  }

  .carousel-wrapper__right {
    position: absolute;
    bottom: 0;
    right: 30px;
  }

  :global(.carousel li) {
    background-color: var(--gigxr-theme-secondary-5) !important;
    width: 14px !important;
    height: 14px !important;
  }

  :global(.carousel li.active) {
    background-color: var(--gigxr-theme-primary-1d) !important;
  }

  .carousel-wrapper__left :global(.mdc-button__label),
  .carousel-wrapper__right :global(.mdc-button__label) {
    color: var(--gigxr-theme-primary-1a);
  }

  .slide-content {
    margin-bottom: 3em;
  }

  .slide-content-1 {
    padding-top: 50px;
  }

  .slide-content-2 img {
    width: 300px;
  }

  .slide-content-3 img {
    width: 200px;
    margin-top: 50px;
  }

  .slide-content-2 span {
    color: var(--gigxr-theme-secondary-3c);
    font-weight: 700;
  }

  :global(.first-time-experience-get-started) {
    font-size: 1rem;
  }
</style>
