<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic, Label, Text, PrimaryText, SecondaryText } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { title, breadcrumbPaths, snackbarMessage, snackbar } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import { editDepartment, fetchDepartment } from '../../../util/api/departments';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { fetchDepartmentAdmins } from '../../../util/api/accounts';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import { deepCopy } from '../../../util/util';

  export let departmentId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit Department');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Department Management',
      location: '/departments/list',
    },
    {
      name: 'Edit Department',
      location: `/departments/edit/${departmentId}`,
    },
  ]);

  let loading = true;

  let departmentAdmins;
  let departmentAdminsById = {};

  let lastSavedDepartment = {
    departmentName: '',
    departmentAdminIds: [],
    description: '',
    departmentStatus: 'Active',
  };

  let department = { ...lastSavedDepartment };
  let discardChangesDialog;

  onMount(async () => {
    [departmentAdmins, lastSavedDepartment] = await Promise.all([
      fetchDepartmentAdmins(),
      fetchDepartment(departmentId),
    ]);
    departmentAdmins.forEach((da) => (departmentAdminsById[da.accountId] = da));

    department = deepCopy(lastSavedDepartment);
    loading = false;
  });

  async function editDepartmentHandler(event) {
    event.preventDefault();
    await editDepartment(department);
    snackbarMessage.set('Department saved!');
    $snackbar.open();
    navigate(`/departments/view/${departmentId}`);
  }

  function discardChangesHandler(event) {
    department = deepCopy(lastSavedDepartment);
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{department.departmentName ? department.departmentName : 'Edit Department'}</h2>
      </div>
      <div slot="right" />
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="department-name-field'"
              class="gigxr-input"
              bind:value={department.departmentName}
              variant="filled"
              label="Department Name"
            />
          </ContentRow>

          <ContentRow>
            <h3 class="gigxr-list-header gigxr-list-header--first">Department Admins</h3>
            {#if !departmentAdmins || departmentAdmins.length === 0}
              <p>There are no department admins.</p>
            {:else}
              <List class="gigxr-list" twoLine checklist>
                {#each departmentAdmins as da (da.accountId)}
                  <Item inputId="department-admin-checkbox-{da.accountId}">
                    <Graphic>
                      <Checkbox bind:group={department.departmentAdminIds} value={da.accountId} />
                    </Graphic>
                    <Text>
                      <PrimaryText>{da.firstName} {da.lastName}</PrimaryText>
                      <SecondaryText>{da.email}</SecondaryText>
                    </Text>
                  </Item>
                {/each}
              </List>
            {/if}
          </ContentRow>

          <ContentRow>
            <FilledTextArea
              id="department-description-field"
              bind:value={department.description}
              label="Description"
              ariaLabel="department-description"
            />
          </ContentRow>
        </div>

        <div slot="right">
          <ContentRow>
            <Select
              inputId="department-status-field"
              class="gigxr-input"
              bind:value={department.departmentStatus}
              variant="filled"
              label="Status"
              enhanced
            >
              <Option value="Inactive">Inactive</Option>
              <Option value="Active">Active</Option>
            </Select>
          </ContentRow>

          <DateAccountComponent label="Added" utcDateString={department.createdOn} account={department.createdBy} />

          <ContentRowCenteredOnMobile>
            <Button
              id="edit-department-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={editDepartmentHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="edit-department-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>
        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
