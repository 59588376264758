<script>
  import { onMount } from 'svelte';
  import Textfield from '@smui/textfield';
  import ContentRow from '../ContentRow.svelte';
  import StageLabelList from './StageLabelList.svelte';
  import { getAccountId } from '../../util/account';
  import { objectSortedKeys } from '../../util/util';
  import Button, { Label } from '@smui/button';
  import HelperText from '@smui/textfield/helper-text';
  import { fade } from 'svelte/transition';
  import AdditionalInformationField from './AdditionalInformationField.svelte';

  export let sessionCreatedById;
  export let hmdJson;

  onMount(async () => {
    // hmdJson.stages = hmdJson.stages ?? [];
    if (!hmdJson.stages) {
      hmdJson.stages = [];
    }
  });

  function addStage() {
    hmdJson.stages = [
      ...hmdJson.stages,
      {
        title: '',
        stageLabels: [],
        aux: {
          editable: {
            Notes: '',
          },
        },
      },
    ];
  }

  function removeStage(index) {
    hmdJson.stages = hmdJson.stages.filter((_, i) => i !== index);
  }

  const fadeConfig = {
    duration: 200,
  };
</script>

<h3>Stages</h3>

{#if hmdJson.stages?.length === 0}
  {#if sessionCreatedById === getAccountId()}
    <p class="session-stages-panel-description">
      This session has no stages yet. Add one below and Save Changes when you are done.
    </p>
  {:else}
    <p class="session-stages-panel-description">There are no stages for this session.</p>
  {/if}
{/if}

{#if hmdJson.stages?.length > 0}
  {#each hmdJson.stages as stage, index (index)}
    <div class="session-stage">
      <ContentRow class="session-stages-panel-patient-clip">
        <div class="session-stages-panel-patient-clip__patients" in:fade|local={fadeConfig}>
          <Textfield
            class="gigxr-input"
            bind:value={stage.title}
            variant="filled"
            label="Stage"
            disabled={sessionCreatedById !== getAccountId()}
          />
          <HelperText persistent={false}>You may change the stage name</HelperText>
        </div>
        <div
          class="session-stages-panel-patient-clip__controls session-stages-panel-patient-clip__controls--with-helper-text"
          in:fade|local={fadeConfig}
        >
          {#if sessionCreatedById === getAccountId()}
            <Button class="gigxr-button-link session-stages-remove-clip-button" on:click={() => removeStage(index)}>
              <Label>Remove Stage</Label>
            </Button>
          {/if}
        </div>
      </ContentRow>

      <!-- <div class="session-stages-section" in:fade|local={fadeConfig}>
        <div class="session-stages-section__left">Labels</div>
        <div class="session-stages-section__right">
          <StageLabelList {stage} {sessionCreatedById} />
        </div>
      </div> -->

      {#if stage.aux}
        <div class="session-stages-section" in:fade|local={fadeConfig}>
          <div class="session-stages-section__left">Additional Information</div>
          <div class="session-stages-section__right">
            {#if stage.aux.visible && Object.keys(stage.aux.visible).length > 0}
              {#each objectSortedKeys(stage.aux.visible) as key (key)}
                <ContentRow>
                  <AdditionalInformationField {key} bind:value={stage.aux.visible[key]} disabled={true} />
                </ContentRow>
              {/each}
            {/if}

            {#if stage.aux.editable && Object.keys(stage.aux.editable).length > 0}
              {#each objectSortedKeys(stage.aux.editable) as key (key)}
                <ContentRow>
                  <AdditionalInformationField
                    {key}
                    bind:value={stage.aux.editable[key]}
                    defaultValue={''}
                    disabled={sessionCreatedById !== getAccountId()}
                  />
                </ContentRow>
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  {/each}
{/if}

{#if sessionCreatedById === getAccountId()}
  <Button class="gigxr-button-link session-stages-add-clip-button" on:click={() => addStage()}>
    <Label>Add new Stage</Label>
  </Button>
{/if}

<style>
  .session-stages-panel-description {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .session-stage {
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5em;
    margin-top: 1em;
  }

  :global(.mdc-select__menu .mdc-list-item) {
    white-space: nowrap;
  }

  .session-stages-panel-patient-clip__controls--with-helper-text {
    margin-bottom: 19px;
  }

  .session-stages-section__left {
    font-weight: 700;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  @media (min-width: 1100px) {
    .session-stages-section {
      display: flex;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    .session-stages-section__left {
      flex: 1;
      border-right: thin solid rgba(0, 0, 0, 0.2);
      padding-right: 1em;
      margin-top: 0;
      margin-bottom: 0;
    }

    .session-stages-section__right {
      flex: 3;
      padding-left: 1em;
    }
  }

  :global(.session-stages-remove-clip-button) {
    margin-left: -8px;
  }

  :global(.session-stages-add-clip-button) {
    margin-left: -8px;
  }

  .session-stages-panel-patient-clip__patients {
    margin-bottom: 1em;
  }

  @media (min-width: 1100px) {
    .session-stages-panel-patient-clip__patients {
      margin-bottom: 0;
    }

    :global(.session-stages-panel-patient-clip) {
      display: flex;
    }

    .session-stages-panel-patient-clip__patients {
      flex: 4;
      padding-right: 1em;
      width: 100%;
    }

    .session-stages-panel-patient-clip__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }
</style>
