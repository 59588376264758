<script>
  import { onDestroy } from 'svelte';
  import { name, version } from '../../package.json';
  import { revision } from '../../revision.json';
  import { DEVELOPMENT, BASE_API_URL } from '../config';
  import { apiVersion } from '../stores/core-store';
  import { isUnity, subscribeToUnityMessage, UnityAction } from '../util/unity';
  import { getMobileOperatingSystem } from '../util/util';

  let logs = '';

  const unsubscribeLog = subscribeToUnityMessage(UnityAction.LOG, (payload) => {
    logs += `${payload.message}\n`;
  });

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  onDestroy(() => {
    unsubscribeLog();
  });
</script>

{#if DEVELOPMENT}
  <div class="development">
    <small>
      <span>Test Environment {isUnity() ? '(UNITY)' : ''}</span><br />
      {window.location.origin}
      (v{version}+{revision})<br />
      {BASE_API_URL}
      {#if $apiVersion}(v{$apiVersion}){/if}<br />
      User Agent:
      {userAgent}<br />
      Mobile Detection:
      {getMobileOperatingSystem()}
    </small>
  </div>

  <!-- <pre class="logs">
    {logs}
  </pre> -->
{/if}

<style>
  .development {
    text-align: center;
    margin-bottom: 30px;
    color: var(--gigxr-theme-primary-1c);
  }

  .development span {
    text-transform: uppercase;
    font-weight: 700;
  }

  /* .logs {
    text-align: left;
  } */
</style>
