<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import { title, breadcrumbPaths, snackbar, snackbarMessage, mobileView } from '../../stores/core-store';
  import { LIST_VIEW_COUNT_PER_PAGE } from '../../util/constants';
  import FilterComponent from '../../components/FilterComponent.svelte';
  import DesktopListPageControls from '../../components/DesktopListPageControls.svelte';
  import ListPageCount from '../../components/ListPageCount.svelte';
  import FilterButton from '../../components/FilterButton.svelte';
  import MobileListPageControls from '../../components/MobileListPageControls.svelte';
  import AddDepartmentButton from '../../components/departments/AddDepartmentButton.svelte';
  import ListPageTable, { rowNavigate } from '../../components/ListPageTable.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import DepartmentListItemMenuButton from '../../components/departments/DepartmentListItemMenuButton.svelte';
  import ListPageViewMoreButton from '../../components/ListPageViewMoreButton.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import DepartmentMobileListCard from '../../components/departments/DepartmentMobileListCard.svelte';
  import { fetchDepartmentAdmins } from '../../util/api/accounts';
  import { fetchDepartments, patchDepartments } from '../../util/api/departments';
  import { setAuthorizedRoles } from '../../util/authorization';
  import ListPageSearch from '../../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../../components/ListPageMobileSearchButton.svelte';
  import {
    DepartmentFilterByDepartmentAdminSet,
    DepartmentFilterIncludeInactiveSet,
    DepartmentSortBySet,
  } from '../../util/filters/department-list-filters';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import ListPageActiveIndicator from '../../components/ListPageActiveIndicator.svelte';
  import FormattedSearchItem from '../../components/FormattedSearchItem.svelte';
  import ListPageCardGrid from '../../components/ListPageCardGrid.svelte';
  import ListPageBatchSelect from '../../components/ListPageBatchSelect.svelte';
  import ListPageActionControls from '../../components/ListPageActionControls.svelte';
  import ListPageSelectionText from '../../components/ListPageSelectionText.svelte';
  import ListPageCardGridCheckbox from '../../components/ListPageCardGridCheckbox.svelte';
  import ListPageCardGridItem from '../../components/ListPageCardGridItem.svelte';
  import ListPageCardGridHeader from '../../components/ListPageCardGridHeader.svelte';
  import ListPageHeaderCheckboxCell from '../../components/ListPageHeaderCheckboxCell.svelte';
  import ListPageCheckboxCell from '../../components/ListPageCheckboxCell.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Department Management');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Department Management',
      location: '/departments/list',
    },
  ]);

  let loading = true;
  let showMobileSearch = false;
  let searchQuery = '';
  let departments = [];
  let departmentAdminsById = new Map();
  let filteredDepartments = [];
  let filteredAndSearchedDepartments = [];
  let filterComponent;
  let filterBySets = [];

  // Batch select
  let showBatchActions = false;
  let selectedValues = [];
  let mobileSelectedValues = [];
  let allSelected = false;
  let allSelectedIncludingNotShown = false;
  let listPageActionControlsConfig = {};

  let displayedItems = LIST_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedDepartments.length - displayedItems;

  onMount(async () => {
    let departmentAdmins;
    [departments, departmentAdmins] = await Promise.all([fetchDepartments(), fetchDepartmentAdmins()]);

    departmentAdmins.forEach((da) => departmentAdminsById.set(da.accountId, da));

    filterBySets = [DepartmentFilterByDepartmentAdminSet(departmentAdmins), DepartmentFilterIncludeInactiveSet()];

    initializeListPageActionControlsConfig();

    loading = false;
  });

  // Desktop and mobile uses two different selection methods, so return whichever one is being used based upon browser width.
  let realSelectedDepartments = [];
  $: {
    const realSelectedValues = $mobileView ? mobileSelectedValues : selectedValues;
    const totalCount = filteredAndSearchedDepartments.length;
    const shownCount = Math.min(displayedItems, filteredAndSearchedDepartments.length);

    if (totalCount > shownCount && realSelectedValues.length > shownCount && allSelectedIncludingNotShown) {
      // All values are selected AND the option to include hidden rows is checked.
      realSelectedDepartments = filteredAndSearchedDepartments.map((department) => department.departmentId);
    } else {
      realSelectedDepartments = realSelectedValues;
    }
  }

  // This reactive block will clear the mobile batch selections when it is closed.
  $: if (!showBatchActions) {
    mobileSelectedValues = [];
  }

  async function bulkDepartmentPatch(patchToApply) {
    const patches = [];
    const patchesById = new Map();

    const departmentIdsToPatch = realSelectedDepartments;

    departmentIdsToPatch.forEach((departmentId) => {
      const patch = {
        departmentId,
        ...patchToApply,
      };
      patches.push(patch);
      patchesById.set(departmentId, patch);
    });

    await patchDepartments(patches);

    // Update data on the client side so we don't have to fetch again.
    departments.forEach((department) => {
      if (patchesById.has(department.departmentId)) {
        Object.assign(department, patchesById.get(department.departmentId));
      }
    });
    filteredAndSearchedDepartments = filteredAndSearchedDepartments;

    return patches.length;
  }

  function initializeListPageActionControlsConfig() {
    listPageActionControlsConfig = {
      'Make Inactive': {
        callback: async () => {
          const count = await bulkDepartmentPatch({
            departmentStatus: 'Inactive',
          });
          snackbarMessage.set(`${count} Department${count === 1 ? '' : 's'} changed to inactive!`);
          $snackbar.open();
        },
      },
      'Make Active': {
        callback: async () => {
          const count = await bulkDepartmentPatch({
            departmentStatus: 'Active',
          });
          snackbarMessage.set(`${count} Department${count === 1 ? '' : 's'} changed to active!`);
          $snackbar.open();
        },
      },
    };
  }

  const searchValueFunctions = {
    departmentName: (department) => department.departmentName,
  };

  // This reactive block controls the header checkbox for mobile.
  $: {
    const headerCheckbox = document.getElementById('checkbox-header');
    if (headerCheckbox) {
      if (mobileSelectedValues.length >= Math.min(displayedItems, filteredAndSearchedDepartments.length)) {
        headerCheckbox.checked = true;
        headerCheckbox.indeterminate = false;
      } else if (mobileSelectedValues.length === 0) {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = false;
      } else {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = true;
      }
    }
  }
</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredDepartments}
          bind:filteredList={filteredAndSearchedDepartments}
          bind:query={searchQuery}
          valueFunctions={searchValueFunctions}
          placeholder="Search departments"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
        <ListPageCount
          {loading}
          count={filteredAndSearchedDepartments.length}
          singularLabel="Department"
          pluralLabel="Departments"
        />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddDepartmentButton disabled={showBatchActions} />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} disabled={showBatchActions} />
        <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
        <AddDepartmentButton disabled={showBatchActions} />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount
        {loading}
        count={filteredAndSearchedDepartments.length}
        singularLabel="Department"
        pluralLabel="Departments"
      />
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
      <ListPageSearch
        unfilteredList={filteredDepartments}
        bind:filteredList={filteredAndSearchedDepartments}
        bind:query={searchQuery}
        valueFunctions={searchValueFunctions}
        placeholder="Search departments"
        disabled={showBatchActions}
      />
    </div>
    <div slot="right">
      <AddDepartmentButton disabled={showBatchActions} />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={departments}
  bind:filteredList={filteredDepartments}
  sortBySet={DepartmentSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      {#if showBatchActions}
        <ListPageActionControls
          actions={listPageActionControlsConfig}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
        />

        <ListPageSelectionText
          totalCount={filteredAndSearchedDepartments.length}
          shownCount={Math.min(displayedItems, filteredAndSearchedDepartments.length)}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
          singularName="Department"
          pluralName="Departments"
          bind:allSelectedIncludingNotShown
        />
      {/if}

      <ListPageCardGrid>
        <ListPageCardGridItem hide={!showBatchActions}>
          <ListPageCardGridHeader
            on:change={(event) => {
              if (event.target.checked) {
                mobileSelectedValues = filteredAndSearchedDepartments
                  .slice(0, displayedItems)
                  .map((department) => department.departmentId);
              } else {
                mobileSelectedValues = [];
              }
            }}
          />
        </ListPageCardGridItem>
        {#each filteredAndSearchedDepartments as department, index (department.departmentId + searchQuery)}
          {#if index < displayedItems}
            <ListPageCardGridItem>
              <ListPageCardGridCheckbox
                value={department.departmentId}
                bind:group={mobileSelectedValues}
                show={showBatchActions}
              />
              <DepartmentMobileListCard
                {department}
                {departmentAdminsById}
                match={searchQuery}
                compact={showBatchActions}
                bind:mobileSelectedValues
              />
            </ListPageCardGridItem>
          {/if}
        {/each}
      </ListPageCardGrid>

      {#if filteredAndSearchedDepartments.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no departments to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <ListPageTable
          ariaLabel="Departments"
          class="departments-data-table"
          showCheckboxes={showBatchActions}
          bind:selectedValues
        >
          <Head>
            <Row>
              <ListPageHeaderCheckboxCell bind:value={allSelected} />
              <Cell class="departments-data-table__name-column">Name</Cell>
              <Cell class="departments-data-table__dept-admin-column">Department Admins</Cell>
              <Cell
                class="departments-data-table__classes-column mdc-data-table__header-cell--numeric {showBatchActions ? 'departments-data-table__classes-column--batch-select' : ''}"
              >
                Classes
              </Cell>
              <Cell
                class="departments-data-table__students-column mdc-data-table__header-cell--numeric {showBatchActions ? 'departments-data-table__students-column--batch-select' : ''}"
              >
                Students
              </Cell>
              <Cell class="departments-data-table__inactive-column mdc-data-table__header-cell--numeric">Active</Cell>
              <Cell class="departments-data-table__menu-column" />
            </Row>
          </Head>
          <Body>
            {#each filteredAndSearchedDepartments as department, index (department.departmentId + searchQuery)}
              {#if index < displayedItems}
                <Row on:click={(event) => rowNavigate(event, `/departments/view/${department.departmentId}`)}>
                  <ListPageCheckboxCell value={department.departmentId} />
                  <Cell title={department.departmentName}>
                    <FormattedSearchItem value={department.departmentName} match={searchQuery} />
                  </Cell>
                  <Cell>
                    {#each department.departmentAdminIds as id (id)}
                      <div
                        class="departments__department-admin"
                        title={`${departmentAdminsById.get(id).firstName} ${departmentAdminsById.get(id).lastName}`}
                      >
                        {departmentAdminsById.get(id).firstName}
                        {departmentAdminsById.get(id).lastName}
                      </div>
                    {/each}
                    {#if department.departmentAdminIds.length === 0}–{/if}
                  </Cell>
                  <Cell numeric>{department.classCount}</Cell>
                  <Cell numeric>{department.studentCount}</Cell>
                  <Cell numeric>
                    {#if department.departmentStatus === 'Active'}
                      <ListPageActiveIndicator />
                    {:else}Inactive{/if}
                  </Cell>
                  <Cell class="overflow-visible" numeric>
                    <DepartmentListItemMenuButton departmentId={department.departmentId} />
                  </Cell>
                </Row>
              {/if}
            {/each}
          </Body>
        </ListPageTable>
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Departments"
          {remainingItems}
          on:click={() => (displayedItems += LIST_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  :global(.departments-data-table__name-column) {
    width: 25%;
  }

  :global(.departments-data-table__dept-admin-column) {
    width: 20%;
  }

  :global(.departments-data-table__classes-column) {
    width: 15%;
  }

  :global(.departments-data-table__classes-column--batch-select) {
    width: 12%;
  }

  :global(.departments-data-table__students-column) {
    width: 15%;
  }

  :global(.departments-data-table__students-column--batch-select) {
    width: 12%;
  }

  :global(.departments-data-table__inactive-column) {
    width: 15%;
  }

  :global(.departments-data-table__menu-column) {
    width: 10%;
  }

  .departments__department-admin {
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
