<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [
    AccountRole.INSTRUCTOR,
    AccountRole.DEPARTMENT_ADMIN,
    AccountRole.INSTITUTION_ADMIN,
    AccountRole.GIGXR_ADMIN,
  ];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import List, { Item, Meta, Label, Text, PrimaryText, SecondaryText } from '@smui/list';
  import Button from '@smui/button';
  import { title, breadcrumbPaths, role } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { NON_BREAKING_SPACE } from '../../../util/constants';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { AccountRoleText, fetchAccount, fetchConsentRecords } from '../../../util/api/accounts';
  import { fetchDepartments } from '../../../util/api/departments';
  import { fetchClasses } from '../../../util/api/classes';
  import ConsentRecordList from '../../../components/accounts/ConsentRecordList.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { canEditAccount } from '../../../util/api/accounts';
  import EditButton from '../../../components/EditButton.svelte';

  export let accountId;

  setAuthorizedRoles(authorizedRoles);

  let account = null;
  let departmentsById = {};
  let classesById = {};
  let consentRecords = [];

  title.set('User Details');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'User Management',
      location: '/users/list',
    },
    {
      name: 'User Details',
      location: `/users/view/${accountId}`,
    },
  ]);

  onMount(async () => {
    let departments;
    let classes;
    [departments, classes, account, consentRecords] = await Promise.all([
      fetchDepartments(),
      fetchClasses(),
      fetchAccount(accountId),
      fetchConsentRecords(accountId),
    ]);
    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));
  });

  function departmentHelperText(classCount) {
    if (classCount === 1) {
      return '1 class';
    }
    return `${classCount} classes`;
  }

  function classHelperText(classId) {
    const clazz = classesById[classId];
    if (!clazz) {
      return '';
    }

    let instructorFullName = '–';
    if (clazz.instructor) {
      instructorFullName = `${clazz.instructor.firstName} ${clazz.instructor.lastName}`;
    }

    return `Instructor: ${instructorFullName}`;
  }
</script>

{#if account}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{account ? `${account.firstName} ${account.lastName}` : NON_BREAKING_SPACE}</h2>
      </div>

      <div slot="right">
        {#if canEditAccount(account)}
          <EditButton on:click={() => navigate(`/users/edit/${accountId}`)} />
        {/if}
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  {#if account}
    <PrimaryContent>
      <TwoColumnSection>
        <div slot="left" class="view-account-left">
          <KeyValueRow>
            <div slot="left">Email:</div>
            <div slot="right"><a id="email-link" href="mailto:{account.email}">{account.email}</a></div>
          </KeyValueRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Departments</h3>
            {#if !account.departmentIds || account.departmentIds.length === 0}
              <p>This user does not belong to any departments.</p>
            {:else if $role === AccountRole.INSTITUTION_ADMIN}
              <List class="gigxr-list" twoLine>
                {#each account.departmentIds as id (id)}
                  <Item on:click={() => navigate(`/departments/view/${id}`)} class="gigxr-list__item">
                    <Text>
                      <PrimaryText>
                        {departmentsById[id].departmentName}
                        <span class="view-account-dept-admin">
                          {#if departmentsById[id].departmentAdminIds.includes(account.accountId)}DEPT ADMIN{/if}
                        </span>
                      </PrimaryText>
                      <SecondaryText>{departmentHelperText(departmentsById[id].classCount)}</SecondaryText>
                    </Text>
                    <Meta>
                      <Button disabled>
                        <Label>View</Label>
                      </Button>
                    </Meta>
                  </Item>
                {/each}
              </List>
            {:else}
              <List class="gigxr-list" twoLine nonInteractive>
                {#each account.departmentIds as id (id)}
                  <Item class="gigxr-list__item">
                    <Text>
                      <PrimaryText>
                        {departmentsById[id].departmentName}
                        <span class="view-account-dept-admin">
                          {#if departmentsById[id].departmentAdminIds.includes(account.accountId)}DEPT ADMIN{/if}
                        </span>
                      </PrimaryText>
                      <SecondaryText>{departmentHelperText(departmentsById[id].classCount)}</SecondaryText>
                    </Text>
                    <Meta />
                  </Item>
                {/each}
              </List>
            {/if}
          </ContentRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Classes</h3>
            {#if !account.classIds || account.classIds.length === 0}
              <p>This user does not belong to any classes.</p>
            {:else}
              <List class="gigxr-list" twoLine>
                {#each account.classes as clazz (clazz.classId)}
                  {#if classesById[clazz.classId]}
                    <Item on:click={() => navigate(`/classes/view/${clazz.classId}`)} class="gigxr-list__item">
                      <Text>
                        <PrimaryText>{classesById[clazz.classId].className}</PrimaryText>
                        <SecondaryText>{classHelperText(clazz.classId)}</SecondaryText>
                      </Text>
                      <Meta>
                        <Button disabled>
                          <Label>View</Label>
                        </Button>
                      </Meta>
                    </Item>
                  {:else}
                    <Item class="gigxr-list__item" disabled>
                      <Text>
                        <PrimaryText>{clazz.className}</PrimaryText>
                        <SecondaryText>Instructor: –</SecondaryText>
                      </Text>
                      <Meta />
                    </Item>
                  {/if}
                {/each}
              </List>
            {/if}
          </ContentRow>

          <ConsentRecordList {consentRecords} />
        </div>

        <div slot="right">
          <KeyValueRow>
            <div slot="left">Role:</div>
            <div slot="right">{AccountRoleText[account.accountRole]}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Status:</div>
            <div slot="right">{account.isActive ? 'Active' : 'Inactive'}</div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Registration:</div>
            <div slot="right">{account.registrationStatus}</div>
          </KeyValueRow>

          <DateAccountComponent label="Added" utcDateString={account.createdOn} account={account.createdBy} />

          <DateAccountComponent label="Last Active" utcDateString={account.lastActive} account={null} show_account={false} />
        </div>
      </TwoColumnSection>
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<style>
  .view-account-left {
    margin-bottom: 2em;
  }

  .view-account-dept-admin {
    margin-left: 2em;
    font-weight: 700;
    font-size: 0.7em;
  }

  @media (min-width: 1100px) {
    .view-account-left {
      margin-bottom: 0;
    }
  }
</style>
