<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label as ButtonLabel } from '@smui/button';
  import { createNotification } from '../../util/api/notifications';
  import { snackbar, snackbarMessage } from '../../stores/core-store';
  import { sendSessionInvite } from '../../util/api/sessions';

  export let studentId;
  export let sessionId;

  const dispatch = createEventDispatcher();

  async function clickHandler(event) {
    dispatch('GigXr:participantInvited', {
      accountId: studentId,
      sessionId,
    });
    // await sendSessionInvite(sessionId, studentId);
    // snackbarMessage.set('Invitation sent!');
    // $snackbar.open();
  }
</script>

<Button id="session-participant-invite-button-{sessionId}-{studentId}" on:click={clickHandler}>
  <ButtonLabel>Send Invite</ButtonLabel>
</Button>

<style>
</style>
