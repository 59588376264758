<script>
  import Icon from '@smui/textfield/icon/Icon.svelte';
  import { fade } from 'svelte/transition';
  import { cubicOut, cubicIn } from 'svelte/easing';
  import LinearProgress from '@smui/linear-progress';

  /**
   * The number to display on the progress circle.
   */
  export let number = 1;

  /**
   * The state of the progress cirlce. 0.0 = 0%, 1.0 = 100%
   */
  export let progress = 0.0;

  const transitionInConfig = {
    duration: 1000,
    easing: cubicOut,
  };

  const transitionOutConfig = {
    duration: 1000,
    easing: cubicIn,
  };
</script>

<div class="session-content-manager-progress-circle-wrapper">
  {#if progress >= 1}
    <div
      class="session-content-manager-progress-circle session-content-manager-progress-circle--downloaded"
      in:fade|local={transitionInConfig}
      out:fade|local={transitionOutConfig}
    >
      <Icon class="material-icons">check_circle_outline</Icon>
    </div>
  {:else}
    <div
      class="session-content-manager-progress-circle"
      in:fade|local={transitionInConfig}
      out:fade|local={transitionOutConfig}
    >
      {number}
    </div>
  {/if}

  <div class="session-content-manager-progress-circle__linear-progress">
    <LinearProgress {progress} closed={progress === 0.0 || progress === 1.0} />
  </div>
</div>

<style>
  .session-content-manager-progress-circle-wrapper {
    position: relative;
    width: 30px;
    height: 40px;
  }

  .session-content-manager-progress-circle {
    display: grid;
    background: var(--gigxr-theme-secondary-2e);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    place-items: center;
    font-weight: 700;
    color: var(--gigxr-theme-secondary-2a);
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 10px;
    left: 0;
    margin: auto;
  }

  .session-content-manager-progress-circle--downloaded {
    background: #fff;
    color: var(--gigxr-theme-secondary-2a);
    width: 30px;
    height: 30px;
  }

  .session-content-manager-progress-circle__linear-progress {
    margin-top: 30px;
  }

  .session-content-manager-progress-circle :global(.material-icons) {
    font-size: 16px;
  }

  .session-content-manager-progress-circle--downloaded :global(.material-icons) {
    font-size: 30px;
  }
</style>
