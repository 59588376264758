<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import {
    role,
    title,
    errorMessage,
    breadcrumbPaths,
    snackbarMessage,
    snackbar,
    isAuthenticated,
    isMasquerading,
  } from '../../stores/core-store';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import WorkInProgress from '../../components/WorkInProgress.svelte';
  import { fetchClasses } from '../../util/api/classes';
  import { getAccountId } from '../../util/account';
  import {
    fetchAccount,
    fetchConsentRecords,
    WhereConsent,
    withdrawAllConsent,
    AccountRole,
  } from '../../util/api/accounts';
  import PageControlsRow from '../../components/PageControlsRow.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import List, {
    Group,
    Item,
    Graphic,
    Meta,
    Label,
    Separator,
    Subheader,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import Button from '@smui/button';
  import KeyValueRow from '../../components/KeyValueRow.svelte';
  import { AccountRoleText } from '../../util/api/accounts';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import { NON_BREAKING_SPACE } from '../../util/constants';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import { fetchDepartments } from '../../util/api/departments';
  import ConsentRecordList from '../../components/accounts/ConsentRecordList.svelte';
  import Cookies from 'js-cookie';
  import WithdrawConsentDialog from '../../components/my-account/WithdrawConsentDialog.svelte';
  import RequestGdprDeleteDialog from '../../components/my-account/RequestGdprDeleteDialog.svelte';
  import RequestGdprInformationDialog from '../../components/my-account/RequestGdprInformationDialog.svelte';
  import EditButton from '../../components/EditButton.svelte';
  import { fetchLicensedClientApps } from '../../util/api/client-apps';

  title.set('My Account');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'My Account',
      location: '/my-account/view',
    },
  ]);

  let loading = true;
  let account = null;
  let departmentsById = {};
  let classesById = {};
  let consentRecords = [];
  let withdrawConsentDialog;
  let requestInformationDialog;
  let gdprDeleteDialog;
  let licensedClientApps = [];

  onMount(async () => {
    let departments;
    let classes;
    [departments, classes, account, consentRecords, licensedClientApps] = await Promise.all([
      fetchDepartments(),
      fetchClasses(),
      fetchAccount(getAccountId()),
      fetchConsentRecords(getAccountId()),
      fetchLicensedClientApps(),
    ]);

    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));

    loading = false;
  });

  function departmentHelperText(classCount) {
    if (classCount === 1) {
      return '1 class';
    }
    return `${classCount} classes`;
  }

  function classHelperText(classId) {
    const clazz = classesById[classId];
    if (!clazz) {
      return '';
    }

    let instructorFullName = '–';
    if (clazz.instructor) {
      instructorFullName = `${clazz.instructor.firstName} ${clazz.instructor.lastName}`;
    }

    return `Instructor: ${instructorFullName}`;
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{account ? `${account.firstName} ${account.lastName}` : NON_BREAKING_SPACE}</h2>
      </div>
      <div slot="right">
        {#if !$isMasquerading}
          <EditButton on:click={() => navigate('/my-account/edit')} />
        {/if}
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  {#if loading}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <TwoColumnSection>
        <div slot="left" class="my-account-left">
          <KeyValueRow>
            <div slot="left">Email:</div>
            <div slot="right"><a id="email-link" href="mailto:{account.email}">{account.email}</a></div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Role:</div>
            <div slot="right">{AccountRoleText[account.accountRole]}</div>
          </KeyValueRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Departments</h3>
            {#if !account.departmentIds || account.departmentIds.length === 0}
              <p>This user does not belong to any departments.</p>
            {:else if $role === AccountRole.STUDENT}
              <List class="gigxr-list" twoLine nonInteractive>
                {#each account.departmentIds as id (id)}
                  <Item class="gigxr-list__item">
                    <Text>
                      <PrimaryText>{departmentsById[id].departmentName}</PrimaryText>
                      <SecondaryText>{departmentHelperText(departmentsById[id].classCount)}</SecondaryText>
                    </Text>
                    <Meta>
                      {#if $role !== AccountRole.STUDENT}
                        <Button disabled>
                          <Label>View</Label>
                        </Button>
                      {/if}
                    </Meta>
                  </Item>
                {/each}
              </List>
            {:else}
              <List class="gigxr-list" twoLine>
                {#each account.departmentIds as id (id)}
                  <Item on:SMUI:action={() => navigate(`/departments/view/${id}`)} class="gigxr-list__item">
                    <Text>
                      <PrimaryText>
                        {departmentsById[id].departmentName}
                        <span class="my-account-dept-admin">
                          {#if departmentsById[id].departmentAdminIds.includes(account.accountId)}DEPT ADMIN{/if}
                        </span>
                      </PrimaryText>
                      <SecondaryText>{departmentHelperText(departmentsById[id].classCount)}</SecondaryText>
                    </Text>
                    <Meta>
                      {#if $role !== AccountRole.STUDENT}
                        <Button disabled>
                          <Label>View</Label>
                        </Button>
                      {/if}
                    </Meta>
                  </Item>
                {/each}
              </List>
            {/if}
          </ContentRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Classes</h3>
            {#if !account.classIds || account.classIds.length === 0}
              <p>This user does not belong to any classes.</p>
            {:else if $role === AccountRole.STUDENT}
              <List class="gigxr-list" twoLine nonInteractive>
                {#each account.classIds as id (id)}
                  <Item class="gigxr-list__item">
                    <Text>
                      <PrimaryText>{classesById[id].className}</PrimaryText>
                      <SecondaryText>{classHelperText(id)}</SecondaryText>
                    </Text>
                    <Meta>
                      {#if $role !== AccountRole.STUDENT}
                        <Button disabled>
                          <Label>View</Label>
                        </Button>
                      {/if}
                    </Meta>
                  </Item>
                {/each}
              </List>
            {:else}
              <List class="gigxr-list" twoLine>
                {#each account.classIds as id (id)}
                  <Item on:SMUI:action={() => navigate(`/classes/view/${id}`)} class="gigxr-list__item">
                    <Text>
                      <PrimaryText>{classesById[id].className}</PrimaryText>
                      <SecondaryText>{classHelperText(id)}</SecondaryText>
                    </Text>
                    <Meta>
                      {#if $role !== AccountRole.STUDENT}
                        <Button disabled>
                          <Label>View</Label>
                        </Button>
                      {/if}
                    </Meta>
                  </Item>
                {/each}
              </List>
            {/if}
          </ContentRow>

          <ConsentRecordList {consentRecords} />

          <div class="my-account__gdpr-links">
            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a id="withdraw-consent-link" class="gigxr-link" on:click={() => withdrawConsentDialog.open()}>Withdraw
                Consent</a>
              <span class="gigxr-link-note">You will lose access to GIGXR once you withdraw consent.</span>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="request-personal-information-link"
                class="gigxr-link"
                on:click={() => requestInformationDialog.open()}
              >Request Personal Information</a>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <div class="my-account__gdpr-link-delete">
                <a
                  id="request-removal-personal-data-link"
                  class="gigxr-link"
                  on:click={() => gdprDeleteDialog.open()}
                >Request Removal of Personal Data</a>
                <span class="gigxr-link-note">
                  This will remove all personal information from your record and you will no longer have access to
                  GIGXR. Your name and email will be retained as a record of this removal, but will no longer be
                  visible.
                </span>
              </div>
            </ContentRowCenteredOnMobile>
          </div>
        </div>
        <div slot="right">
          <KeyValueRow>
            <div slot="left">Apps:</div>
            <div slot="right">
              {#if licensedClientApps && licensedClientApps.length > 0}
                <ul class="simple-list">
                  {#each licensedClientApps as clientApp (clientApp.clientAppId)}
                    <li>{clientApp.clientAppName}</li>
                  {/each}
                </ul>
              {:else}–{/if}
            </div>
          </KeyValueRow>
        </div>
      </TwoColumnSection>
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<WithdrawConsentDialog bind:dialog={withdrawConsentDialog} bind:account />
<RequestGdprInformationDialog bind:dialog={requestInformationDialog} bind:account />
<RequestGdprDeleteDialog bind:dialog={gdprDeleteDialog} bind:account />

<style>
  .my-account-left {
    margin-bottom: 2em;
  }

  .my-account__gdpr-links {
    margin-top: 2em;
  }

  .my-account__gdpr-link-delete {
    margin-top: 3em;
  }

  .my-account-dept-admin {
    margin-left: 2em;
    font-weight: 700;
    font-size: 0.7em;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media (min-width: 1100px) {
    .my-account-left {
      margin-bottom: 0;
    }
  }
</style>
