/**
 * Compares two arrays of identically typed ids
 *
 * ```
 * idArraysEqual(['a', 'b'], ['b', 'a']); // true
 * idArraysEqual(['a'], ['a', 'b']); // false
 * ```
 *
 * @param arrayA
 * @param arrayB
 * @return {boolean}
 */
function idArraysEqual(arrayA, arrayB) {
  const set = new Set(arrayA);
  const allDeleted = arrayB.every((id) => set.delete(id));

  return allDeleted && set.size === 0;
}

export { idArraysEqual };
