<script>
  /**
   * An element to replace <Item> from @smui/list for when the fundamental element should be an anchor.
   */

  import { onMount, getContext } from 'svelte';
  import { Item } from '@smui/list';

  export let id = '';
  export let href = '';
  export let target = '_self';

  let anchor;
  let menuPromise = getContext('GigXrMenu');
  let menu;

  function disableClickHandler(event) {
    // Prevent human click from triggering on <a> (will be triggered programatically).
    if (event.isTrusted) {
      event.preventDefault();
    }

    // Prevent click from bubbling to GigXrMenu.
    event.stopPropagation();
  }

  function newClickHandler(event) {
    // Manual click. This is so keyboard actions on the menu will trigger the link.
    anchor.click();
    if (menu) {
      menu.setOpen(false);
    }
  }

  onMount(async () => {
    if (menuPromise) {
      menu = await menuPromise;
    }
  });
</script>

<Item on:click={disableClickHandler} on:SMUI:action={newClickHandler}>
  <a {id} bind:this={anchor} {href} {target}>
    <slot />
  </a>
</Item>

<style>
  a {
    align-items: center;
    display: flex;
    justify-content: start;
    height: 100%;
    margin: 0 -16px;
    padding: 0 16px;
    width: calc(100% + 32px);
  }

  a:hover {
    text-decoration: none;
  }
</style>
