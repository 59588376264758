import { authenticatedGet, authenticatedPost, authenticatedPut } from '../api';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { GmsError, RedirectError } from '../errors';
import { deepCopy } from '../util';
import { errorMessage } from '../../stores/core-store';
import { createAccountUploadRequest } from './accounts';

/**
 * ModuleDetailedView
 * @typedef {Object} AssetDetailedView
 * @property {string} institutionId
 * @property {string} institutionName
 * @property {string} contactId
 * @property {AccountLeafView} contact
 * @property {string} addressId
 * @property {AddressView} address
 * @property {string} phoneNumberId
 * @property {PhoneNumberView} phoneNumber
 * @property {string} createdOn
 * @property {string} createdById
 * @property {AccountBasicView} createdBy
 * @property {string} modifiedOn
 * @property {string} modifiedById
 * @property {AccountBasicView} modifiedBy
 */

/**
 * ModuleListView
 * @typedef {Object} AssetListView
 * @property {string} institutionId
 * @property {string} institutionName
 * @property {string} contactId
 * @property {AccountLeafView} contact
 * @property {string} addressId
 * @property {AddressView} address
 * @property {string} phoneNumberId
 * @property {PhoneNumberView} phoneNumber
 * @property {number} accountCount
 * @property {string} createdOn
 * @property {string} modifiedOn
 */


async function fetchModules() {
  console.log('in fetchModules');
  const response = await authenticatedGet('/modules');
  if (!response.ok) {
    throw new GmsError(`Error fetching modules! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchInstitutionModules(institutionId) {
  console.log('in fetchInstitutionModules');
  const response = await authenticatedGet(`/modules/institution/${institutionId}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching modules! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchLicensedAppModules(licenseId, clientAppId) {
  console.log('in fetchLicensedAppModules');
  const response = await authenticatedGet(`/modules/license/${licenseId}/app/${clientAppId}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching modules! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchAppModules(clientAppId) {
  console.log('in fetchAppModules');
  const response = await authenticatedGet(`/modules/app/${clientAppId}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching modules! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchModule(moduleId) {
  console.log(`in fetchModule, moduleId: ${moduleId}`);
  const response = await authenticatedGet(`/modules/${moduleId}`);

  if (response.status === 404) {
    throw new RedirectError('Module not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching asset! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function createModule(module) {
  const requestPayload = deepCopy(module);
  const response = await authenticatedPost('/modules', requestPayload);

  if (response.status !== 201) {
    throw new GmsError(`Error creating module! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newAsset = json.data;

  return newAsset;
}


async function editModule(module) {

  const requestPayload = deepCopy(module);
  const response = await authenticatedPut(`/modules/${module.moduleId}`, requestPayload);

  if (response.status !== 200) {
    throw new GmsError(`Error editing module! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedAsset = json.data;

  return editedAsset;
}

async function editLicensedModules(licenseId, clientAppId, licensedAppModules) {
  console.log('in editLicensedModules');
  const requestPayload = {
    clientAppId: clientAppId,
    moduleIds: licensedAppModules
  }
  const response = await authenticatedPut(`/modules/licensed/${licenseId}`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Error editing license modules! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

/**
 * 
 * @param {String} moduleId the Module Id
 * @param {Array} modules the Array of Module Objects
 */
function findModuleByModuleId(moduleId,modules) {
  if (modules.length > 0) {
    console.log(`modules is: ${modules}`);
    let module =  modules.find(function(obj) { return obj.moduleId === moduleId;});
    return module;
  }
  return false;
}


export {
  fetchModules,
  fetchAppModules,
  fetchLicensedAppModules,
  fetchInstitutionModules,
  fetchModule,
  createModule,
  editModule,
  editLicensedModules,
  findModuleByModuleId
};
