<script context="module">
  import { AccountRole } from '../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { title, errorMessage, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import WorkInProgress from '../components/WorkInProgress.svelte';
  import { setAuthorizedRoles } from '../util/authorization';
  import { fetchInstitutions } from '../util/api/institutions';
  import LoadingView from '../components/LoadingView.svelte';
  import ListPageTable, { rowNavigate } from '../components/ListPageTable.svelte';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import InstitutionListItemMenuButton from '../components/institutions/InstitutionListItemMenuButton.svelte';
  import MobileListPageControls from '../components/MobileListPageControls.svelte';
  import DesktopListPageControls from '../components/DesktopListPageControls.svelte';
  import AddInstitutionButton from '../components/institutions/AddInstitutionButton.svelte';
  import { LIST_VIEW_COUNT_PER_PAGE } from '../util/constants';
  import ListPageCount from '../components/ListPageCount.svelte';
  import ListPageViewMoreButton from '../components/ListPageViewMoreButton.svelte';
  import FilterComponent from '../components/FilterComponent.svelte';
  import FilterButton from '../components/FilterButton.svelte';
  import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../util/filters';
  import { Countries } from '../util/countries';
  import InstitutionMobileListCard from '../components/institutions/InstitutionMobileListCard.svelte';
  import ListPageSearch from '../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../components/ListPageMobileSearchButton.svelte';
  import {
    InstitutionSortBySet,
    InstitutionFilterByCountrySet,
    InstitutionFilterIncludeDemoSet,
  } from '../util/filters/institution-list-filters';
  import ListPageNoResultsMessage from '../components/ListPageNoResultsMessage.svelte';
  import { fetchLicenses } from '../util/api/licenses';
  import { utcStringToLocalDate } from '../util/dates';
  import FormattedSearchItem from '../components/FormattedSearchItem.svelte';
  import ListPageCardGrid from '../components/ListPageCardGrid.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('System Dashboard');
  breadcrumbPaths.set([]);

  let loading = true;
  let showMobileSearch = false;
  let filterComponent;
  let searchQuery = '';

  /** @type {[InstitutionListView]} */
  let institutions = [];

  /** @type {[InstitutionListView]} */
  let filteredInstitutions = [];

  /** @type {[InstitutionListView]} */
  let filteredAndSearchedInstitutions = [];

  let displayedItems = LIST_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedInstitutions.length - displayedItems;

  let filterBySets = [];

  let licensesById = new Map();

  onMount(async () => {
    let licenses;
    [institutions, licenses] = await Promise.all([fetchInstitutions(), fetchLicenses()]);
    filteredInstitutions = [...institutions];

    // Index licenses by licenseId
    licenses.forEach((license) => licensesById.set(license.licenseId, license));

    filterBySets = [InstitutionFilterByCountrySet(institutions), InstitutionFilterIncludeDemoSet()];

    loading = false;
  });

  const searchValueFunctions = {
    institutionName: (institution) => institution.institutionName,
  };

  function licenseDateTooltip(institution) {
    if (institution.licenseIds.length === 0) {
      return 'No license';
    }

    return `Expiration ${utcStringToLocalDate(
      licensesById.get(institution.licenseIds[0]).expirationDate,
    ).toLocaleDateString()}`;
  }
</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredInstitutions}
          bind:filteredList={filteredAndSearchedInstitutions}
          bind:query={searchQuery}
          valueFunctions={searchValueFunctions}
          placeholder="Search institutions"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageCount
          {loading}
          count={filteredAndSearchedInstitutions.length}
          singularLabel="Institution"
          pluralLabel="Institutions"
        />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddInstitutionButton />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} />
        <FilterButton on:click={filterComponent.toggle} />
        <AddInstitutionButton />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageCount
        {loading}
        count={filteredAndSearchedInstitutions.length}
        singularLabel="Institution"
        pluralLabel="Institutions"
      />
      <FilterButton on:click={filterComponent.toggle} />
      <ListPageSearch
        unfilteredList={filteredInstitutions}
        bind:filteredList={filteredAndSearchedInstitutions}
        bind:query={searchQuery}
        valueFunctions={searchValueFunctions}
        placeholder="Search institutions"
      />
    </div>
    <div slot="right">
      <AddInstitutionButton />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={institutions}
  bind:filteredList={filteredInstitutions}
  sortBySet={InstitutionSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <ListPageCardGrid>
        {#each filteredAndSearchedInstitutions as institution, index (institution.institutionId + searchQuery)}
          {#if index < displayedItems}
            <InstitutionMobileListCard {institution} match={searchQuery} {licensesById} />
          {/if}
        {/each}
      </ListPageCardGrid>

      {#if filteredAndSearchedInstitutions.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no institutions to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <ListPageTable ariaLabel="Institutions" class="institutions-data-table">
          <Head>
            <Row>
              <Cell class="institutions-data-table__name-column">Institution Name</Cell>
              <Cell class="institutions-data-table__contact-column">Contact</Cell>
              <Cell class="institutions-data-table__country-column">Country</Cell>
              <Cell class="institutions-data-table__licenses-column mdc-data-table__header-cell--numeric">
                Licensed Active Users
              </Cell>
              <Cell class="institutions-data-table__users-column mdc-data-table__header-cell--numeric">
                Current Active Users
              </Cell>
              <Cell class="institutions-data-table__licensed-apps-column">Licensed Apps</Cell>
              <Cell class="institutions-data-table__demo-column mdc-data-table__header-cell--numeric">Demo</Cell>
              <Cell class="institutions-data-table__menu-column" />
            </Row>
          </Head>
          <Body>
            {#each filteredAndSearchedInstitutions as institution, index (institution.institutionId + searchQuery)}
              {#if index < displayedItems}
                <Row on:click={(event) => rowNavigate(event, `/institutions/view/${institution.institutionId}`)}>
                  <Cell title={institution.institutionName}>
                    <FormattedSearchItem value={institution.institutionName} match={searchQuery} />
                  </Cell>
                  {#if institution.contact}
                    <Cell title={`${institution.contact.firstName} ${institution.contact.lastName}`}>
                      <a href="mailto:{institution.contact.email}">
                        {institution.contact.firstName}
                        {institution.contact.lastName}
                      </a>
                    </Cell>
                  {:else}
                    <Cell>–</Cell>
                  {/if}
                  <Cell title={Countries[institution.address.country] ? Countries[institution.address.country] : ''}>
                    {institution.address.country}
                  </Cell>
                  <Cell numeric title={licenseDateTooltip(institution)}>
                    {#if institution.licenseIds.length > 0 && licensesById.has(institution.licenseIds[0])}
                      {#if utcStringToLocalDate(licensesById.get(institution.licenseIds[0]).expirationDate).getTime() < Date.now()}
                        <span class="usage-warning">
                          <small>Expired</small>
                          {licensesById.get(institution.licenseIds[0]).licensedActiveUsers}
                        </span>
                      {:else}{licensesById.get(institution.licenseIds[0]).licensedActiveUsers}{/if}
                    {:else}–{/if}
                  </Cell>
                  <Cell numeric>
                    {#if institution.licenseIds.length > 0 && institution.accountCount > licensesById.get(institution.licenseIds[0]).licensedActiveUsers}
                      <span class="usage-warning"> {institution.accountCount} </span>
                    {:else}{institution.accountCount}{/if}
                  </Cell>
                  <Cell>
                    {#if institution.licenseIds.length > 0 && licensesById.has(institution.licenseIds[0]) && licensesById.get(institution.licenseIds[0]).clientApps.length > 0}
                      <ul class="simple-list">
                        {#each licensesById.get(institution.licenseIds[0]).clientApps as clientApp (clientApp.clientAppId)}
                          <li>{clientApp.clientAppName}</li>
                        {/each}
                      </ul>
                    {:else}–{/if}
                  </Cell>
                  <Cell numeric>
                    {#if institution.isDemoAccount}Demo{/if}
                  </Cell>
                  <Cell class="overflow-visible" numeric>
                    <InstitutionListItemMenuButton {institution} />
                  </Cell>
                </Row>
              {/if}
            {/each}
          </Body>
        </ListPageTable>
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Institutions"
          {remainingItems}
          on:click={() => (displayedItems += LIST_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .usage-warning small {
    margin-right: 1em;
    text-transform: uppercase;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  :global(.institutions-data-table__name-column) {
    width: 19%;
  }

  :global(.institutions-data-table__contact-column) {
    width: 15%;
  }

  :global(.institutions-data-table__country-column) {
    width: 10%;
  }

  :global(.institutions-data-table__licenses-column) {
    width: 15%;
    white-space: normal;
  }

  :global(.institutions-data-table__users-column) {
    width: 11%;
    white-space: normal;
  }

  :global(.institutions-data-table__licensed-apps-column) {
    width: 13%;
    white-space: normal;
  }

  :global(.institutions-data-table__demo-column) {
    width: 7%;
  }

  :global(.institutions-data-table__menu-column) {
    width: 10%;
  }
</style>
