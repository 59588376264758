<script>
  import Datepicker from 'svelte-calendar';
  import Textfield from '@smui/textfield';
  import Icon from '@smui/textfield/icon/index';

  export let id = '';
  export let dateValue;
  export let disabled = false;
  export let label = null;

  const endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 10);
</script>

<Datepicker bind:selected={dateValue} style={disabled ? 'pointer-events: none;' : ''} end={endDate}>
  <Textfield
    input$id={id}
    input$class="gigxr-datepicker"
    input$readonly
    value={dateValue.toLocaleDateString()}
    variant="filled"
    {label}
    withTrailingIcon
    {disabled}
  >
    <Icon class="material-icons">event</Icon>
  </Textfield>
</Datepicker>

<style>
  :global(.gigxr-datepicker) {
    width: 200px;
  }
  :global(.gigxr-datepicker:hover) {
    cursor: pointer;
  }
</style>
