<script>
  import { createEventDispatcher } from 'svelte';
  import MobileListCard from '../MobileListCard.svelte';
  import MobileListCardContent from '../MobileListCardContent.svelte';
  import { navigate } from 'svelte-routing';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import AccountListMenuButton from './AccountListMenuButton.svelte';
  import { NON_BREAKING_SPACE } from '../../util/constants';
  import ListPageActiveIndicator from '../ListPageActiveIndicator.svelte';
  import { AccountRoleText } from '../../util/api/accounts';
  import FormattedSearchItem from '../FormattedSearchItem.svelte';
  import DeleteAccountDialog from './DeleteAccountDialog.svelte';

  export let account;
  export let departmentsById;
  export let match = '';
  export let compact = false;
  export let mobileSelectedValues;

  let deleteAccountDialog;

  const dispatch = createEventDispatcher();

  function cardNavigate(event, to) {
    if (compact) {
      // Checkboxes are shown, so a click event should click the corresponding row's checkbox.
      const set = new Set(mobileSelectedValues);
      if (set.has(account.accountId)) {
        set.delete(account.accountId);
      } else {
        set.add(account.accountId);
      }
      mobileSelectedValues = [...set];

      return;
    }

    // Checkboxes are not shown, so a click event will navigate to the provided link.
    navigate(to);
  }
</script>

<MobileListCard {compact}>
  <MobileListCardContent on:click={(event) => cardNavigate(event, `/users/view/${account.accountId}`)}>
    <h2>
      <FormattedSearchItem value={`${account.firstName} ${account.lastName}`} {match} />
    </h2>

    {#if account.isActive}
      <div class="account-mobile-list-card__active-indicator">
        <ListPageActiveIndicator />
      </div>
    {/if}

    <dl>
      <dt>Email:</dt>
      <dd title={account.email}>
        <FormattedSearchItem value={account.email} {match} />
      </dd>

      <dt>Role:</dt>
      <dd title={AccountRoleText[account.accountRole]}>{AccountRoleText[account.accountRole]}</dd>

      <dt>Departments:</dt>
      {#each account.departmentIds as id (id)}
        <dd title={departmentsById[id].departmentName}>{departmentsById[id].departmentName}</dd>
      {/each}
      {#if account.departmentIds.length === 0}
        <dd>{NON_BREAKING_SPACE}</dd>
      {/if}

      <dt>Status:</dt>
      <dd>{account.registrationStatus}</dd>
    </dl>
  </MobileListCardContent>
  <ActionIcons>
    <AccountListMenuButton {account} on:AccountListMenuButton::delete={() => deleteAccountDialog.open()} />
  </ActionIcons>
</MobileListCard>

<DeleteAccountDialog
  bind:dialog={deleteAccountDialog}
  {account}
  on:DeleteAccountDialog::deleteConfirmed={() => dispatch('AccountMobileListCard::deleted', account)}
/>

<style>
  h2 {
    margin: 0 0 5px;
    text-align: center;
  }

  .account-mobile-list-card__active-indicator {
    width: 100%;
    text-align: center;
  }

  dl {
    margin: 11px 0 0 0;
  }

  dt {
    float: left;
    clear: left;
    width: 35%;
    font-weight: 500;
  }

  dd {
    margin: 0 0 0.2em 35%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.gigxr-mobile-list-card .mdc-card__action-icons) {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
