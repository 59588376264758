<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import * as EmailValidator from 'email-validator';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Switch from '@smui/switch';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { title, breadcrumbPaths, snackbar, snackbarMessage, errorMessage } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { deepCopy } from '../../../util/util';
  import { editModule, fetchModule } from '../../../util/api/modules';
  import CountrySelect from '../../../components/CountrySelect.svelte';
  import GigXrDialogIcon from '../../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialog from '../../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogActions from '../../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogContent from '../../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogCancelButton from '../../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrHorizontalRule from '../../../components/GigXrHorizontalRule.svelte';
  import { GmsError } from '../../../util/errors';
  import {
    RegistrationStatus,
    addAccount,
    createAccountUploadRequest,
    fetchInstructors,
  } from '../../../util/api/accounts';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import GigXrDatepicker from '../../../components/GigXrDatepicker.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { fetchClientApps, fetchLicensedClientApps } from '../../../util/api/client-apps';
  import { fetchClasses } from '../../../util/api/classes';

  export let moduleId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit Module');
  breadcrumbPaths.set([
    {
      name: 'Module',
      location: '/modules',
    },
    {
      name: 'Edit Module',
      location: `/modules/edit/${moduleId}`,
    },
  ]);

  let loading = true;
  let form;
  let dialog;
  let discardChangesDialog;
  let licensedClientApps = [];

  let lastSavedModule = {
    moduleId: '',
    moduleName: '',
    clientAppId: '',
  };

  let module = deepCopy(lastSavedModule);

  onMount(async () => {
    let modules;
    [lastSavedModule, licensedClientApps] = await Promise.all([
      fetchModule(moduleId),
      fetchClientApps(),
    ]);

    module = deepCopy(lastSavedModule);

    loading = false;
  });

  async function editModuleHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }


      const [editedModule] = await Promise.all([
      editModule(module),
    ]);

    snackbarMessage.set('Module edited!');
    $snackbar.open();
    navigate(`/modules/view/${editedModule.moduleId}`);
  }


  function discardChangesHandler(event) {
    module = deepCopy(lastSavedModule);
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <form bind:this={form}>
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={module.moduleId}
              variant="filled"
              label="Module Id"
              input$required
              disabled
            />
          </ContentRow>
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={module.moduleName}
              variant="filled"
              label="Module Name"
              input$required
            />
          </ContentRow>
          <ContentRow>
            <Select
              inputId="client-app-field"
              class="gigxr-input"
              bind:value={module.clientAppId}
              variant="filled"
              label="App"
              enhanced
            >
              <Option value="" />
              {#each licensedClientApps as app (app.clientAppId)}
                  <Option value={app.clientAppId} selected={app.clientAppId === module.clientAppId}>
                    {app.clientAppName}
                  </Option>
              {/each}
              {#if licensedClientApps.length == 0}
                <Option disabled on:click={(event) => event.stopPropagation()}>No Licensed Apps</Option>
              {/if}
            </Select>
          </ContentRow>

        </div>

        <div slot="right">

          <ContentRowCenteredOnMobile>
            <Button
              id="edit-module-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={editModuleHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="edit-module-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

        </div>
      </TwoColumnSection>
    </form>
  </PrimaryContent>
</SecondaryBackgroundWrapper>


<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
