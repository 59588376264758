<script>
  import { onMount } from 'svelte';
  import Select, { Option } from '@smui/select';
  import Button, { Label, Icon } from '@smui/button';

  /**
   * key / callback function map
   *
   * Example:
   *
   * actions = {
   *
   *   // A simple action that will trigger a callback without any parameters.
   *   'Make Inactive': { callback: () => {} },
   *
   *   // An action that requires a secondary choice, will trigger the callback with the selected option.
   *   'Change Role': {
   *     callback: (option) => {},
   *     optionName: 'Role',
   *     options: [
   *       { key: 0, value: 'Department Admin' },
   *       { key: 1, value: 'Department Superhero' },
   *     ],
   *   },
   * };
   *
   */
  export let actions = {};

  export let selectedCount = 0;

  let primarySelection;
  let secondarySelection;
  let lastPrimarySelection = primarySelection;

  onMount(() => {
    validateActions();
  });

  function validateActions() {
    for (var key in actions) {
      if (typeof actions[key] !== 'object') {
        throw new Error('Actions must be an object!');
      }
      if (typeof actions[key].callback !== 'function') {
        throw new Error('Actions.callback must be an object!');
      }
      // if (actions[key].options && typeof actions[key].options !== 'array') {
      if (actions[key].options && !Array.isArray(actions[key].options)) {
        throw new Error('Actions.options must be an array!');
      }
    }
  }

  function clickHandler() {
    actions[primarySelection].callback(secondarySelection);
  }

  // This reactive block clears the secondary selection when the primary selection changes.
  $: if (primarySelection !== lastPrimarySelection) {
    secondarySelection = '';
  }
</script>

<div class="list-page-action-controls">
  <Select bind:value={primarySelection} variant="filled" label="Select Action" enhanced>
    {#each Object.keys(actions) as key (key)}
      <Option value={key}>{key}</Option>
    {/each}
  </Select>

  {#if actions[primarySelection]?.options}
    <Select bind:value={secondarySelection} variant="filled" label={actions[primarySelection]?.optionsName} enhanced>
      {#each actions[primarySelection].options as option (option.key)}
        <Option value={option.key}>{option.value}</Option>
      {/each}
    </Select>
  {/if}

  <Button
    variant="unelevated"
    on:click={clickHandler}
    disabled={selectedCount === 0 || !primarySelection || (actions[primarySelection].options && !secondarySelection)}
  >
    <Label>Apply</Label>
  </Button>
</div>

<style>
  .list-page-action-controls {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  .list-page-action-controls :global(.mdc-select) {
    margin-right: 1em;
  }
</style>
