<script>
  /**
   * Material Design Filled Textarea
   *
   * https://material.io/develop/web/components/input-controls/text-field/
   *
   * The Svelte Material UI library does not have this one.
   */
  import { onMount } from 'svelte';
  import { MDCTextField } from '@material/textfield';

  export let id = '';
  export let label = 'Default Label';
  export let ariaLabel = 'default-aria-label';
  export let value = '';
  export let disabled = false;

  let textField;
  let mdcTextField;

  $: {
    if (mdcTextField) {
      mdcTextField.disabled = disabled;
    }
  }

  onMount(async () => {
    mdcTextField = new MDCTextField(textField);
  });
</script>

<label bind:this={textField} class="mdc-text-field mdc-text-field--fullwidth mdc-text-field--textarea">
  <div class="mdc-text-field__ripple" />
  <!-- https://github.com/sveltejs/language-tools/issues/300 -->
  <!-- prettier-ignore -->
  <textarea {id} class="mdc-text-field__input" aria-labelledby={ariaLabel} rows="8" cols="40" bind:value {disabled}></textarea>
  <span
    class="mdc-floating-label filled-text-area__label {value ? 'mdc-floating-label--float-above' : ''}"
    id={ariaLabel}
  >
    {label}
  </span>
  <div class="mdc-line-ripple" />
</label>

<style>
  .mdc-text-field__input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    padding: 0 16px 6px;
    margin-top: 26px;
  }

  .mdc-text-field__input:disabled {
    border-bottom: 1px dotted;
  }

  .mdc-text-field__input {
    pointer-events: initial;
    cursor: initial;
  }

  .mdc-text-field .mdc-floating-label {
    left: 16px;
    right: auto;
    top: 18px;
    pointer-events: none;
  }

  :global(.filled-text-area__label.mdc-floating-label--float-above) {
    cursor: auto;
    transform: translateY(-50%);
  }
</style>
