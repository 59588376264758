<script>
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';

  export let disabled = false;

  let menu;
</script>

<div>
  <IconButton
    id="add-account-button"
    title="Add User"
    class="material-icons gigxr-icon-button add-account-button"
    on:click={() => menu.setOpen(true)}
    {disabled}
  >
    add_box
  </IconButton>

  <Menu bind:this={menu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
    <List>
      <Item id="add-individual-user-link" on:SMUI:action={() => navigate('/users/add')}>
        <Text>Individual user</Text>
      </Item>
      <Item
        id="upload-users-link"
        class="add-account-button__upload-users"
        on:SMUI:action={() => navigate('/users/upload')}
      >
        <Text>Upload Users</Text>
      </Item>
    </List>
  </Menu>
</div>

<style>
  :global(.add-account-button__upload-users) {
    display: none;
  }

  :global(.add-account-button:disabled) {
    opacity: 0.38;
  }

  @media (min-width: 1100px) {
    :global(.add-account-button__upload-users) {
      display: flex;
    }
  }
</style>
