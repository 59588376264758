<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import ContentRow from '../ContentRow.svelte';
  import List, {
    Group,
    Item,
    Graphic,
    Meta,
    Label,
    Separator,
    Subheader,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import Button from '@smui/button';
  import IconButton, { Icon } from '@smui/icon-button';
  import { sendSessionInvite, SessionPermission } from '../../util/api/sessions';
  import Typeahead from '../Typeahead.svelte';
  import * as EmailValidator from 'email-validator';
  import { fetchStudentByEmail, fetchStudents } from '../../util/api/accounts';
  import RemoveSessionParticipantDialog, {
    openRemoveSessionParticipantDialog,
  } from './RemoveSessionParticipantDialog.svelte';
  import LoadingView from '../LoadingView.svelte';
  import SessionParticipantInviteButton from './SessionParticipantInviteButton.svelte';
  import SessionParticipantAttendedButton from './SessionParticipantAttendedButton.svelte';

  export let session;
  export let sessionParticipants;

  const dispatch = createEventDispatcher();

  let loading = true;

  let removeSessionParticipantDialog;

  let studentsById = {};

  let selectedClassId = '';
  let studentsInSelectedClass = [];
  $: (async () => {
    if (session.classId) {
      if (session.classId !== selectedClassId) {
        selectedClassId = session.classId;
        studentsInSelectedClass = await fetchStudents({ classId: session.classId });
        studentsInSelectedClass.forEach((student) => (studentsById[student.accountId] = student));
      } else {
        // Already fetched this class, do nothing.
      }
    } else {
      // Class cleared
      studentsInSelectedClass = [];
    }
  })();

  let availableStudentsInSelectedClass = [];
  $: {
    availableStudentsInSelectedClass = studentsInSelectedClass.filter(
      (student) => !sessionParticipants.map((sp) => sp.accountId).includes(student.accountId),
    );
  }

  let studentsInClassQuery = '';

  let studentsByEmailQuery = '';
  let studentsByEmailMatch = [];
  let studentsByEmailCache = new Map();

  $: (async () => {
    // Need to create a local copy of this variable so that it doesn't change for the lifetime of
    // this block.
    let email = studentsByEmailQuery;

    // If the email is not valid, then there is no need to send the API call.
    if (!email || !EmailValidator.validate(email)) {
      studentsByEmailMatch = [];
      return;
    }

    let student;
    // This call is pretty spammy, so we check a local cache first.
    if (studentsByEmailCache.has(email)) {
      student = studentsByEmailCache.get(email);
    } else {
      student = await fetchStudentByEmail(email);
      studentsByEmailCache.set(email, student);
    }

    if (student == null) {
      studentsByEmailMatch = [];
      return;
    }

    // Check if the student found is already in the student list. This is needed to prevent adding
    // the same student more than once.
    if (sessionParticipants.map((sp) => sp.accountId).includes(student.accountId)) {
      studentsByEmailMatch = [];
      return;
    }

    studentsById[student.accountId] = student;
    studentsByEmailMatch = [student];
  })();

  onMount(async () => {
    if (session.classId && session.classId !== selectedClassId) {
      selectedClassId = session.classId;
      studentsInSelectedClass = await fetchStudents({ classId: session.classId });
      studentsInSelectedClass.forEach((student) => (studentsById[student.accountId] = student));
    }
    // Index students
    sessionParticipants.forEach((participant) => (studentsById[participant.accountId] = participant.account));

    loading = false;
  });

  async function addParticipant(student) {
    sessionParticipants = [
      ...sessionParticipants,
      {
        accountId: student.accountId,
        sessionParticipantStatus: 'Added',
      },
    ];

    studentsInClassQuery = '';
    studentsByEmailQuery = '';
    dispatch('GigXr:participantAdded', student);
  }

  function removeParticipant(studentId) {
    sessionParticipants = sessionParticipants.filter((sp) => {
      return sp.accountId !== studentId;
    });
  }

  async function removeParticipantHandler(event) {
    const studentId = event.detail.studentId;
    removeParticipant(studentId);
    dispatch('GigXr:participantRemoved', studentId);
  }

  async function removeAndNotifyParticipantHandler(event) {
    const studentId = event.detail.studentId;
    removeParticipant(studentId);
    dispatch('GigXr:participantRemovedAndNotified', studentId);
  }
</script>

<h3 class="gigxr-list-header">Participants</h3>

{#if loading}
  <LoadingView />
{:else}
  {#if session.sessionPermission === SessionPermission.PRIVATE}
    <p>Change Permission to "Open" to add participants.</p>
  {/if}

  {#if sessionParticipants.length === 0 && session.sessionPermission !== SessionPermission.PRIVATE}
    <p>This Session has no invited participants.</p>
  {/if}

  {#if sessionParticipants.length > 0}
    <List class="gigxr-list" twoLine nonInteractive>
      {#each sessionParticipants as participant (participant.accountId)}
        <Item class="gigxr-list__item">
          <Text>
            <PrimaryText>
              {studentsById[participant.accountId].firstName}
              {studentsById[participant.accountId].lastName}
            </PrimaryText>
            <SecondaryText>{studentsById[participant.accountId].email}</SecondaryText>
          </Text>

          {#if session.sessionPermission !== SessionPermission.PRIVATE}
            <div class="session-participants-list__invite-button">
              {#if participant.sessionParticipantStatus === 'Added'}
                <SessionParticipantInviteButton
                  studentId={participant.accountId}
                  sessionId={session.sessionId}
                  on:GigXr:participantInvited
                />
              {:else if participant.sessionParticipantStatus === 'Attended'}
                <SessionParticipantAttendedButton />
              {:else}
                <!-- Display nothing -->
              {/if}
            </div>
            {#if participant.sessionParticipantStatus !== 'Attended'}
              <Meta>
                <IconButton
                  class="material-icons"
                  on:click={() => openRemoveSessionParticipantDialog(participant.accountId)}
                >
                  close
                </IconButton>
              </Meta>
            {/if}
          {/if}
        </Item>
      {/each}
    </List>
  {/if}

  {#if session.sessionPermission !== SessionPermission.PRIVATE}
    <Typeahead
      label="Add student from this class"
      items={availableStudentsInSelectedClass}
      bind:query={studentsInClassQuery}
      valueFunction={(student) => `${student.firstName} ${student.lastName}`}
      onSelectFunction={(student) => addParticipant(student)}
    />

    <Typeahead
      label="Add participant by email address"
      items={studentsByEmailMatch}
      bind:query={studentsByEmailQuery}
      valueFunction={(student) => student.email}
      onSelectFunction={(student) => addParticipant(student)}
    />
  {/if}
{/if}

<RemoveSessionParticipantDialog
  bind:dialog={removeSessionParticipantDialog}
  on:GigXrDialog:removeAndNotifyParticipant={removeAndNotifyParticipantHandler}
  on:GigXrDialog:removeParticipant={removeParticipantHandler}
/>

<style>
  .session-participants-list__invite-button {
    display: inline-block;
    align-self: center;
    justify-self: end;
  }
</style>
