<script>
  import { getContext } from 'svelte';
  import Checkbox from '@smui/checkbox';
  import { Cell } from '@smui/data-table';

  // Header checkboxes are broken in SMUI and provide no way to bind to their value, so this custom component in
  // combination with ListPageTable provides a way to bind to the value.

  // This initial value, which is bound to the underlying checkbox element, does not always update when it should,
  // but is reliable for the initial value.
  let initialValue;

  // This value is safe to bind to and will update appropriately. There is an on:change event manually triggered in
  // ListPageTable that will let us know when to check this value.
  export let value = initialValue;

  const showCheckboxes = getContext('ListPageTable:showCheckboxes');

  async function onChangeHandler(event) {
    value = event.target.checked;
  }
</script>

<Cell checkbox class={$showCheckboxes ? '' : 'display-none'}>
  <Checkbox input$id="list-page-header-checkbox" bind:value={initialValue} on:change={onChangeHandler} />
</Cell>
