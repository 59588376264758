// https://en.wikipedia.org/wiki/Non-breaking_space
export const NON_BREAKING_SPACE = '\u00A0';

// January 1, 2000 0:00:00 UTC - GMS Client requires version 3 of the millennium or greater
export const EARLIEST_VALID_TIMESTAMP = 946684800000;

export const CARD_VIEW_COUNT_PER_PAGE = 9;

export const LIST_VIEW_COUNT_PER_PAGE = 10;

export const GENERIC_ERROR_MESSAGE = 'Please refresh to try again or contact support.';
