import { authenticatedGet, authenticatedPatch, authenticatedPost, authenticatedPut } from '../api';
import { getAccountId } from '../account';
import { AccountRole } from './accounts';
import { GmsError, RedirectError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { role, errorMessage, institutionIdStore } from '../../stores/core-store';
import { get } from 'svelte/store';

function canEditClass(clazz) {
  let myRole = AccountRole.INVALID;
  role.subscribe((value) => (myRole = value));

  if (myRole === AccountRole.INSTRUCTOR && clazz.instructorId === getAccountId()) {
    return true;
  }

  if (myRole === AccountRole.DEPARTMENT_ADMIN && clazz.department.departmentAdminIds.includes(getAccountId())) {
    return true;
  }

  if (myRole === AccountRole.INSTITUTION_ADMIN) {
    return true;
  }

  return false;
}

async function fetchClasses() {
  const response = await authenticatedGet('/classes');
  if (!response.ok) {
    throw new GmsError(`Error fetching classes! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchClass(classId) {
  const response = await authenticatedGet(`/classes/${classId}`);

  if (response.status === 404) {
    throw new RedirectError('Class not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching class! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function createClass(clazz) {
  const validInput = clazz && clazz.className && clazz.departmentId && clazz.classStatus;

  if (!validInput) {
    throw new GmsError('Class name and department are required!');
  }

  const requestPayload = {
    ...clazz,
    institutionId: get(institutionIdStore),
  };

  const response = await authenticatedPost('/classes', requestPayload);

  if (response.status === 409) {
    throw new GmsError('Class name already exists!');
  }

  if (!response.ok) {
    throw new GmsError(`Error creating class! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newClass = json.data;

  return newClass;
}

async function editClass(clazz) {
  const validInput = clazz && clazz.className && clazz.departmentId && clazz.classStatus;

  if (!validInput) {
    throw new GmsError('Class name and instructor are required!');
  }

  const requestPayload = {
    ...clazz,
  };

  const response = await authenticatedPut(`/classes/${clazz.classId}`, requestPayload);

  if (response.status === 409) {
    throw new GmsError('Class name already exists!');
  }

  if (!response.ok) {
    throw new GmsError(`Error editing class! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedClass = json.data;

  return editedClass;
}

async function patchClasses(patches) {
  const requestPayload = [...patches];

  const response = await authenticatedPatch(`/classes`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error patching classes! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

export { canEditClass, fetchClasses, fetchClass, createClass, editClass, patchClasses };
