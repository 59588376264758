<script context="module">
  import { writable } from 'svelte/store';

  let visible = writable(false);

  export function showBottomModal() {
    visible.set(true);
  }
</script>

<script>
  import Button, { Label, Icon } from '@smui/button';
  import { slide, fade } from 'svelte/transition';

  export let buttonText = 'Got it!';
</script>

{#if $visible}
  <div class="bottom-modal-background" transition:fade={{ duration: 200 }} on:click={() => ($visible = false)} />
  <div class="bottom-modal" transition:slide={{ duration: 200 }}>
    <div class="bottom-modal__content">
      <slot />
      <Button
        id="bottom-modal-button"
        class="bottom-modal__button"
        variant="unelevated"
        on:click={() => ($visible = false)}
      >
        <Label>{buttonText}</Label>
      </Button>
    </div>
  </div>
{/if}

<style>
  .bottom-modal-background {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.5;
    z-index: 11;
  }

  .bottom-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #edf1f3;
    z-index: 12;
    padding: 16px 20px;
  }

  .bottom-modal :global(p) {
    margin-top: 0;
  }

  :global(.bottom-modal__button) {
    width: 100%;
    background-color: var(--gigxr-theme-primary-1c) !important;
    font-size: 16px;
  }

  @media (min-width: 1100px) {
    .bottom-modal__content {
      max-width: 650px;
      margin: 0 auto;
    }
  }
</style>
