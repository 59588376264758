<script>
  import Select, { Option } from '@smui/select';
  import ContentRow from '../ContentRow.svelte';
  import {
    AccountRole,
    AccountRoleValue,
    AccountRoleText,
    canAddAccountRole,
    canEditAccountRole,
    canEditAccount,
  } from '../../util/api/accounts';
  import { getAccountId } from '../../util/account';

  /** @type {Account} */
  export let account;

  /** @type {'add' | 'edit'} */
  export let type;

  function canAddOrEditRole(role) {
    if (type === 'edit' && account.accountId === getAccountId()) {
      return canEditAccountRole(role);
    }
    return canAddAccountRole(role);
  }
</script>

<ContentRow>
  <Select
    inputId="account-role-field"
    class="gigxr-input"
    bind:value={account.accountRole}
    variant="filled"
    label="Role"
    enhanced
    disabled={account.accountId === getAccountId()}
  >
    {#each Object.values(AccountRole) as role, index (index)}
      {#if role !== AccountRole.INVALID && canAddOrEditRole(role)}
        <Option value={role}>{AccountRoleText[role]}</Option>
      {/if}
    {/each}
  </Select>
</ContentRow>

<style>
</style>
