<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTRUCTOR, AccountRole.DEPARTMENT_ADMIN, AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { setAuthorizedRoles } from '../../util/authorization';
  import { title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import Textfield from '@smui/textfield';
  import Button, { Label } from '@smui/button';
  import {
    AccountUploadDtoStatus,
    AccountUploadDtoError,
    AccountUploadDtoUpdate,
    createAccountsFromUploadRequest,
    createAccountUploadRequest,
    fetchAccountUploadRequest,
    sendInvitesForUploadAccountRequest,
  } from '../../util/api/accounts';
  import ListPageTable from '../../components/ListPageTable.svelte';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import IconButton, { Icon } from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import { Logger } from '../../util/logs';
  import { FilterByRadioSet, FilterBySet } from '../../util/filters';

  setAuthorizedRoles(authorizedRoles);

  title.set('Upload Users');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'User Management',
      location: '/users/list',
    },
    {
      name: 'Upload Users',
      location: `/users/upload`,
    },
  ]);

  /** @type {'importComplete', 'uploadSuccessful', 'default'} */
  let pageState = 'default';

  let fileInput;
  let files = [];

  /** @type {AccountUploadRequest|{}} */
  let accountUploadRequest = {};

  /** @type {[AccountUploadDto]} */
  let createdAccountDtos = [];

  /** @type {[AccountUploadDto]} */
  let updatedAccountDtos = [];

  let atleastOneGoodAccount = false;

  const filterSet = new FilterByRadioSet({
    description: 'Filter',
    defaultValue: 'All',
    filterOptions: {
      All: (element) => true,
      'Ready, New Users': (element) => element.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT,
      'Ready, Existing Users': (element) => element.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_UPDATE,
      'Needs Modification': (element) => element.accountUploadDtoStatus === AccountUploadDtoStatus.NEEDS_MODIFICATION,
      Unmodified: (element) => element.accountUploadDtoStatus === AccountUploadDtoStatus.NO_UPDATE,
      // There are other statuses here, but they only occur after an import has been processed.
    },
  });

  let filteredList = [];
  $: {
    if (accountUploadRequest && accountUploadRequest.accountUploadDtos) {
      filteredList = filterSet.filter(accountUploadRequest.accountUploadDtos);
    }
  }

  // Loading booleans for buttons
  let uploadingCsv = false;
  let creatingAccounts = false;
  let sendingInvites = false;

  // Counts for buttons
  let allCount;
  let readyForImportCount;
  let readyForUpdateCount;
  let needsModificationCount;
  let unmodifiedCount;

  async function uploadHandler(event) {
    try {
      uploadingCsv = true;
      const unprocessedRequest = await createAccountUploadRequest(files[0]);
      accountUploadRequest = await fetchAccountUploadRequest(unprocessedRequest.accountUploadRequestId);
      Logger.log(accountUploadRequest);

      atleastOneGoodAccount = accountUploadRequest.accountUploadDtos.some(
        (dto) =>
          dto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT ||
          dto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_UPDATE,
      );

      allCount = accountUploadRequest.accountUploadDtos.length;

      readyForImportCount = accountUploadRequest.accountUploadDtos.filter(
        (dto) => dto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT,
      ).length;

      readyForUpdateCount = accountUploadRequest.accountUploadDtos.filter(
        (dto) => dto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_UPDATE,
      ).length;

      needsModificationCount = accountUploadRequest.accountUploadDtos.filter(
        (dto) => dto.accountUploadDtoStatus === AccountUploadDtoStatus.NEEDS_MODIFICATION,
      ).length;

      unmodifiedCount = accountUploadRequest.accountUploadDtos.filter(
        (dto) => dto.accountUploadDtoStatus === AccountUploadDtoStatus.NO_UPDATE,
      ).length;

      errorMessage.set('');
      pageState = 'uploadSuccessful';
    } catch (error) {
      fileInput.value = null;
      errorMessage.set('Your file was not uploaded. Please try again. Make sure you follow all instructions.');
      console.error(error);
    } finally {
      uploadingCsv = false;
    }
  }

  async function createAccountsHandler(event) {
    try {
      creatingAccounts = true;
      accountUploadRequest = await createAccountsFromUploadRequest(accountUploadRequest.accountUploadRequestId);

      createdAccountDtos = accountUploadRequest.accountUploadDtos.filter(
        (accountDto) => accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.ADDED,
      );

      updatedAccountDtos = accountUploadRequest.accountUploadDtos.filter(
        (accountDto) => accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.UPDATED,
      );

      pageState = 'importComplete';
    } finally {
      creatingAccounts = false;
    }
  }

  async function sendInviteHandler(event) {
    try {
      sendingInvites = true;
      await sendInvitesForUploadAccountRequest(accountUploadRequest.accountUploadRequestId);
      navigate('/users/list');
    } finally {
      sendingInvites = false;
    }
  }

  function resetPage() {
    files = [];
    pageState = 'default';
  }

  /**
   * Returns a class string if any of the errors are in the provided accountDto.
   * @param {AccountUploadDto} accountDto
   * @param {[AccountUploadDtoError]} errors
   * @param {[AccountUploadDtoUpdate]} updates
   * @param {boolean} supressErrors
   * @return {string}
   */
  function accountDtoClassHelper(accountDto, errors = [], updates = [], supressErrors = false) {
    if (!supressErrors && accountDto.accountUploadDtoErrors.some((error) => errors.includes(error))) {
      return 'upload-account-error-row';
    }
    if (
      accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_UPDATE &&
      accountDto.accountUploadDtoUpdates.some((update) => updates.includes(update))
    ) {
      return 'upload-account-update-row';
    }
    if (accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT) {
      return 'upload-account-update-row';
    }
    return '';
  }

  /**
   * Returns a tooltip string.
   * @param {AccountUploadDto} accountDto
   * @param {string} newValue
   * @param {string|[string]} oldValue
   */
  function accountDtoTooltipHelper(accountDto, newValue, oldValue) {
    if (accountDto.existingAccountFound) {
      if (Array.isArray(oldValue)) {
        // Not supported at this time. Can later be expanded to show a list of the existing departments/classes.
        return '';
      } else {
        return `${oldValue}`;
      }
    }

    return newValue;
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if pageState === 'importComplete'}
      <h2>Import Complete</h2>

      <p>
        {#if createdAccountDtos.length > 0}
          {createdAccountDtos.length === 1 ? `${createdAccountDtos[0].firstName} ${createdAccountDtos[0].lastName} has` : `${createdAccountDtos.length} users have`}
          been added to your institution.
        {/if}
        {#if updatedAccountDtos.length > 0}
          {updatedAccountDtos.length === 1 ? `${updatedAccountDtos[0].currentFirstName} ${updatedAccountDtos[0].currentLastName} has` : `${updatedAccountDtos.length} users have`}
          been updated in your institution.
        {/if}
        You can edit users and add more individual users in User Management.
      </p>

      {#if createdAccountDtos.length > 0}
        <p>
          Do you want to send
          {createdAccountDtos.length === 1 ? `an invite email to ${createdAccountDtos[0].firstName} ${createdAccountDtos[0].lastName}?` : `invite emails to all ${createdAccountDtos.length} users now?`}
        </p>
      {/if}

      {#if createdAccountDtos.length > 0}
        <div class="upload-row">
          <Button
            id="upload-users-invites-no-button"
            class="gigxr-button account-upload-left-button"
            variant="unelevated"
            on:click={() => navigate('/users/list')}
          >
            <Label>No</Label>
          </Button>
          <Button
            id="upload-users-send-invites-button"
            class="gigxr-button"
            variant="unelevated"
            on:click={sendInviteHandler}
            disabled={sendingInvites}
          >
            <Label>
              {#if sendingInvites}
                Sending invites...
              {:else}Yes Send Invite{createdAccountDtos.length === 1 ? '' : 's'}{/if}
            </Label>
          </Button>
        </div>

        <p>You will be returned to User Management after making your selection.</p>
      {:else}
        <div class="upload-row">
          <Button
            id="return-user-management-button"
            class="gigxr-button account-upload-left-button"
            variant="unelevated"
            on:click={() => navigate('/users/list')}
          >
            <Label>Return to User Management</Label>
          </Button>
        </div>
      {/if}
    {:else if pageState === 'uploadSuccessful'}
      <h2>Upload Successful!</h2>

      <p>
        One more step before the user import is complete. Please review the upload below. If you see any mismatches,
        adjust your upload file and try the upload again.
      </p>

      <ul class="account-upload-filter-list">
        <li class="account-upload-filter-list__item"><strong>Show:</strong></li>
        {#each Object.keys(filterSet.filterOptions) as filterName (filterName)}
          <li class="account-upload-filter-list__item">
            <Button
              variant={filterSet.selectedValue === filterName ? 'unelevated' : 'outlined'}
              on:click={() => (filterSet.selectedValue = filterName)}
            >
              <Label>
                {filterName}
                {#if filterName === 'All'}({allCount}){/if}
                {#if filterName === 'Ready, New Users'}({readyForImportCount}){/if}
                {#if filterName === 'Ready, Existing Users'}({readyForUpdateCount}){/if}
                {#if filterName === 'Needs Modification'}({needsModificationCount}){/if}
                {#if filterName === 'Unmodified'}({unmodifiedCount}){/if}
              </Label>
            </Button>
          </li>
        {/each}
      </ul>

      <ListPageTable ariaLabel="Accounts" class="accounts-data-table">
        <Head>
          <Row>
            <Cell class="account-upload-data-table__first-name-column">First Name</Cell>
            <Cell class="account-upload-data-table__last-name-column">Last Name</Cell>
            <Cell class="account-upload-data-table__email-column">Email Address</Cell>
            <Cell class="account-upload-data-table__department-column">Department</Cell>
            <Cell class="account-upload-data-table__class-column">Class</Cell>
            <Cell class="account-upload-data-table__role-column">Role</Cell>
            <Cell class="account-upload-data-table__active-column">Active</Cell>
            <Cell class="account-data-table__menu-column" />
          </Row>
        </Head>
        <Body>
          {#each filteredList as accountDto, index (index)}
            <Row>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.firstName, accountDto.currentFirstName)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.FIRST_NAME_EMPTY], [], accountDto.existingAccountFound)}
              >
                {#if accountDto.currentFirstName}
                  {accountDto.currentFirstName}
                {:else}
                  {accountDto.firstName}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.FIRST_NAME_EMPTY)}
                    <small class="upload-account-error-message">Empty</small>
                  {/if}
                {/if}
              </Cell>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.lastName, accountDto.currentLastName)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.LAST_NAME_EMPTY], [], accountDto.existingAccountFound)}
              >
                {#if accountDto.currentLastName}
                  {accountDto.currentLastName}
                {:else}
                  {accountDto.lastName}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.LAST_NAME_EMPTY)}
                    <small class="upload-account-error-message">Empty</small>
                  {/if}
                {/if}
              </Cell>
              <Cell
                title={accountDto.email}
                class={accountDtoClassHelper(accountDto, [
                  AccountUploadDtoError.EMAIL_EMPTY,
                  AccountUploadDtoError.EMAIL_INVALID,
                  AccountUploadDtoError.EMAIL_TAKEN,
                  AccountUploadDtoError.EMAIL_DUPLICATE,
                ])}
              >
                {accountDto.email}
                {#if accountDto.existingAccountFound}
                  <br /><small class="upload-account-info-message">User found</small>
                {:else if accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT}
                  <br /><small class="upload-account-info-message">New user</small>
                {/if}
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.EMAIL_EMPTY)}
                  <small class="upload-account-error-message">Empty</small>
                {/if}
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.EMAIL_INVALID)}
                  <br />
                  <small class="upload-account-error-message">Invalid</small>
                {/if}
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.EMAIL_TAKEN)}
                  <br />
                  <small class="upload-account-error-message">Taken</small>
                {/if}
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.EMAIL_DUPLICATE)}
                  <br />
                  <small class="upload-account-error-message">Duplicate entry</small>
                {/if}
              </Cell>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.departmentName, accountDto.currentDepartments)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.DEPARTMENT_EMPTY, AccountUploadDtoError.DEPARTMENT_NOT_FOUND, AccountUploadDtoError.DEPARTMENT_FORBID], [], accountDto.existingAccountFound)}
              >
                {#if accountDto.currentDepartments}
                  <!-- Not listing existing departments at this time. -->
                  &nbsp;
                {:else}
                  {accountDto.departmentName}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.DEPARTMENT_EMPTY)}
                    <small class="upload-account-error-message">Empty</small>
                  {/if}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.DEPARTMENT_NOT_FOUND)}
                    <br />
                    <small class="upload-account-error-message">Not found</small>
                  {/if}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.DEPARTMENT_FORBID)}
                    <br />
                    <small class="upload-account-error-message">Insufficient access</small>
                  {/if}
                {/if}
              </Cell>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.className, accountDto.currentDepartments)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.CLASS_NOT_FOUND, AccountUploadDtoError.CLASS_FORBID], [], accountDto.existingAccountFound)}
              >
                {#if accountDto.currentClasses}
                  <!-- Not listing existing classes at this time. -->
                  &nbsp;
                {:else}
                  {accountDto.className}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.CLASS_NOT_FOUND)}
                    <br />
                    <small class="upload-account-error-message">Not found</small>
                  {/if}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.CLASS_FORBID)}
                    <br />
                    <small class="upload-account-error-message">Insufficient access</small>
                  {/if}
                {/if}
              </Cell>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.accountRole, accountDto.currentAccountRole)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.ROLE_INVALID, AccountUploadDtoError.ROLE_FORBID], [], accountDto.existingAccountFound && !accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.ROLE_FORBID))}
              >
                {#if accountDto.currentAccountRole}
                  {accountDto.currentAccountRole}
                {:else}
                  {accountDto.accountRole !== 'Invalid' ? accountDto.accountRole : ''}
                  {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.ROLE_INVALID)}
                    <small class="upload-account-error-message">Invalid</small>
                  {/if}
                {/if}
                <!-- This error can be shown for both new and existing users. -->
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.ROLE_FORBID)}
                  <br />
                  <small class="upload-account-error-message">Insufficient access</small>
                {/if}
              </Cell>
              <Cell
                title={accountDtoTooltipHelper(accountDto, accountDto.activeOrInactive, accountDto.currentActiveOrInactive)}
                class={accountDtoClassHelper(accountDto, [AccountUploadDtoError.ACTIVE_INACTIVE_EMPTY, AccountUploadDtoError.ACTIVE_INACTIVE_INVALID], [AccountUploadDtoUpdate.ACTIVE_OR_INACTIVE])}
              >
                {#if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.ACTIVE_INACTIVE_EMPTY)}
                  <small class="upload-account-error-message">Empty</small>
                {:else if accountDto.accountUploadDtoErrors.includes(AccountUploadDtoError.ACTIVE_INACTIVE_INVALID)}
                  <small class="upload-account-error-message">Invalid</small>
                {:else}
                  {#if accountDto.accountUploadDtoUpdates.includes(AccountUploadDtoUpdate.ACTIVE_OR_INACTIVE)}
                    <span class="strikethrough">{accountDto.currentActiveOrInactive}</span><br />
                  {/if}
                  {accountDto.activeOrInactive}
                {/if}
              </Cell>
              <Cell
                class="overflow-visible {[AccountUploadDtoStatus.READY_FOR_IMPORT, AccountUploadDtoStatus.READY_FOR_UPDATE].includes(accountDto.accountUploadDtoStatus) ? 'upload-account-update-row' : ''}"
                numeric
              >
                {#if accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_IMPORT}
                  <Icon class="material-icons gigxr-success-color">check</Icon>
                {/if}
                {#if accountDto.accountUploadDtoStatus === AccountUploadDtoStatus.READY_FOR_UPDATE}
                  <Icon class="material-icons gigxr-success-color">check</Icon>
                {/if}
              </Cell>
            </Row>
          {/each}
          {#if filteredList.length === 0}
            <Row>
              <Cell colspan="8" class="account-upload-table__no-results">No data.</Cell>
            </Row>
          {/if}
        </Body>
      </ListPageTable>

      <div class="upload-row">
        <Button
          id="cancel-and-retry-button"
          class="gigxr-button account-upload-left-button"
          variant="unelevated"
          on:click={resetPage}
        >
          <Label>Cancel and Retry</Label>
        </Button>
        <Button
          id="confirm-and-import-button"
          class="gigxr-button"
          variant="unelevated"
          on:click={createAccountsHandler}
          disabled={!atleastOneGoodAccount || creatingAccounts}
        >
          <Label>
            {#if creatingAccounts}Importing...{:else}Confirm and Import{/if}
          </Label>
        </Button>
      </div>
    {:else}
      <h2>Upload Instructions</h2>
      <ol class="account-upload-list">
        <li class="account-upload-list__item">Your list must be in CSV format.</li>
        <!-- prettier-ignore -->
        <li class="account-upload-list__item">
          Make sure your user list is in the exact format required (see below). For your convenience you can
          <a id="download-template-link" href="/csv/upload-users.csv">download this template</a>. Add user data to the template in the exact order 
          of the header row. Start in the row below the header row.
        </li>
        <li class="account-upload-list__item">
          You can add or edit users to the list. Currently the following fields are supported for edits:
          <ul>
            <li>Active/Inactive</li>
          </ul>
        </li>
        <li class="account-upload-list__item">You will have a chance to review your upload before it is finalized.</li>
      </ol>

      <div class="upload-row">
        <input type="file" bind:this={fileInput} bind:files accept=".csv,text/csv" required />
        <Button
          id="upload-users-file-button"
          class="gigxr-button"
          variant="unelevated"
          on:click={uploadHandler}
          disabled={files.length === 0 || uploadingCsv}
        >
          <Label>
            {#if uploadingCsv}Uploading...{:else}Upload{/if}
          </Label>
        </Button>
      </div>

      <ContentRow>
        <h3>File Format</h3>
        <p>Use the following field names as a header in the first row of your file:</p>
        <ul class="account-upload-list">
          <li class="account-upload-list__item"><code>First Name</code></li>
          <li class="account-upload-list__item"><code>Last Name</code></li>
          <li class="account-upload-list__item"><code>Email Address</code></li>
          <li class="account-upload-list__item"><code>Department</code> <strong>(one department per user)</strong></li>
          <li class="account-upload-list__item"><code>Class</code> <strong>(one class per user)</strong></li>
          <li class="account-upload-list__item">
            <code>Role</code>
            <strong>
              (roles must be one of:
              <code>InstitutionAdmin</code>
              ,
              <code>DepartmentAdmin</code>
              ,
              <code>Instructor</code>
              ,
              <code>Student</code>
              )
            </strong>
          </li>
          <li class="account-upload-list__item"><code>Active/Inactive</code></li>
        </ul>
        <p>Each user should be one row below header row. Do not include any other information in the file.</p>
      </ContentRow>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  h2 {
    margin-top: 0;
  }

  .account-upload-list {
    padding-left: 1.1em;
  }

  .account-upload-list__item {
    margin-bottom: 0.5em;
    padding-left: 0.5em;
  }

  a {
    font-weight: 700;
  }

  input[type='file'] {
    width: 100%;
    margin-bottom: 1em;
  }

  :global(.account-upload-left-button) {
    margin-bottom: 1em;
  }

  :global(.upload-account-error-row) {
    font-weight: 700;
    background: rgba(255, 0, 0, 0.2);
  }

  :global(.upload-account-update-row) {
    font-weight: 700;
    background: rgba(0, 255, 0, 0.2);
  }

  .strikethrough {
    text-decoration: line-through;
  }

  .upload-account-info-message {
    color: var(--gigxr-theme-secondary-3a);
    font-weight: 700;
  }

  .upload-account-error-message {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .upload-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    flex-wrap: wrap;
  }

  .account-upload-filter-list {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 1.25em 0;
  }

  .account-upload-filter-list__item {
    display: inline-block;
    margin: 5px;
  }

  :global(.account-upload-table__no-results) {
    text-align: center;
  }

  :global(.account-upload-data-table__first-name-column) {
    width: 13%;
  }

  :global(.account-upload-data-table__last-name-column) {
    width: 13%;
  }

  :global(.account-upload-data-table__email-column) {
    width: 15%;
  }

  :global(.account-upload-data-table__department-column) {
    width: 15%;
  }

  :global(.account-upload-data-table__class-column) {
    width: 15%;
  }

  :global(.account-upload-data-table__role-column) {
    width: 14%;
  }

  :global(.account-upload-data-table__active-column) {
    width: 9%;
  }

  :global(.account-upload-data-table__menu-column) {
    width: 6%;
  }

  @media (min-width: 1100px) {
    input[type='file'] {
      width: initial;
      margin-right: 1em;
      margin-bottom: 0;
    }

    :global(.account-upload-left-button) {
      margin-right: 1em;
      margin-bottom: 0;
    }
  }

  :global(.account-upload-filter-list .mdc-select),
  :global(.account-upload-filter-list .mdc-button) {
    background-color: inherit;
    border-color: #31373d;
    color: #31373d;
    text-transform: capitalize;
    border-radius: 6px;
    /* min-width: 208px; */
  }

  :global(.account-upload-filter-list .mdc-button--unelevated) {
    background-color: #31373d;
    color: #fff;
  }
</style>
