<script>
  let className = '';
  export { className as class };
</script>

<div class="content-row {className}">
  <slot />
</div>

<style>
  .content-row {
    margin: 0 0 1em 0;
  }
</style>
