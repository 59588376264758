<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { setAuthorizedRoles } from '../../util/authorization';
  import { deepCopy } from '../../util/util';
  import { authenticatedPost } from '../../util/api';
  import CountrySelect from '../../components/CountrySelect.svelte';
  import { createInstitution } from '../../util/api/institutions';
  import GigXrHorizontalRule from '../../components/GigXrHorizontalRule.svelte';
  import { GmsError } from '../../util/errors';
  import * as EmailValidator from 'email-validator';
  import { RegistrationStatus, addAccount } from '../../util/api/accounts';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';
  import GigXrDatepicker from '../../components/GigXrDatepicker.svelte';
  import { createLicense, LicenseType, LicenseTypeText } from '../../util/api/licenses';
  import { fetchClientApps } from '../../util/api/client-apps';

  setAuthorizedRoles(authorizedRoles);

  title.set('Create Institution');
  breadcrumbPaths.set([
    {
      name: 'System Dashboard',
      location: '/',
    },
    {
      name: 'Create Institution',
      location: '/institutions/create',
    },
  ]);

  let form;
  let discardChangesDialog;

  let lastSavedInstitution = {
    institutionId: '',
    institutionName: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
    },
    address: {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNumber: {
      number: '',
    },
    isDemoAccount: false,
  };

  let institution = deepCopy(lastSavedInstitution);

  let newInstitutionAdmin = {
    firstName: '',
    lastName: '',
    email: '',
  };

  let lastSavedLicense = {
    licenseId: '',
    expirationDate: '',
    licenseType: '',
    licensedActiveUsers: 0,
    institutionId: '',
    clientAppIds: [],
  };

  let licenseExpirationValue = new Date();
  let license = deepCopy(lastSavedLicense);
  let clientApps = [];
  let loading = true;

  onMount(async () => {
    [clientApps] = await Promise.all([fetchClientApps()]);
    loading = false;
  });

  async function createInstitutionHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    institution.isDemoAccount = false;
    const newInstitution = await createInstitution(institution);

    await Promise.all([
      createNewInstitutionAdminIfProvided(newInstitution.institutionId),
      createLicenseIfProvided(newInstitution.institutionId),
    ]);

    snackbarMessage.set('Institution created!');
    $snackbar.open();
    navigate(`/institutions/view/${newInstitution.institutionId}`);
  }

  async function saveAsDemoAccountHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    institution.isDemoAccount = true;
    const newInstitution = await createInstitution(institution);

    await Promise.all([
      createNewInstitutionAdminIfProvided(newInstitution.institutionId),
      createLicenseIfProvided(newInstitution.institutionId),
    ]);

    snackbarMessage.set('Demo institution created!');
    $snackbar.open();
    navigate(`/institutions/view/${newInstitution.institutionId}`);
  }

  async function createNewInstitutionAdminIfProvided(institutionId) {
    if (
      newInstitutionAdmin.firstName.trim() === '' &&
      newInstitutionAdmin.lastName.trim() === '' &&
      newInstitutionAdmin.email.trim() === ''
    ) {
      // All fields blank, ok!
      return;
    }

    if (newInstitutionAdmin.firstName.trim() === '') {
      throw new GmsError('Institution admin first name is empty!');
    }

    if (newInstitutionAdmin.lastName.trim() === '') {
      throw new GmsError('Institution admin last name is empty!');
    }

    if (newInstitutionAdmin.email.trim() === '') {
      throw new GmsError('Institution admin email is empty!');
    }

    if (!EmailValidator.validate(newInstitutionAdmin.email)) {
      throw new GmsError('Institution admin email is not valid!');
    }

    const newAccount = {
      ...newInstitutionAdmin,
      accountRole: AccountRole.INSTITUTION_ADMIN,
      isActive: true,
      institutionId,
      registrationStatus: RegistrationStatus.INVITED,
      departmentIds: [],
      classIds: [],
    };

    return await addAccount(newAccount);
  }

  async function createLicenseIfProvided(institutionId) {
    if (!license.licenseType) {
      return;
    }

    license.institutionId = institutionId;
    license.expirationDate = licenseExpirationValue.toISOString();

    await createLicense(license);
  }

  function discardChangesHandler(event) {
    institution = deepCopy(lastSavedInstitution);
    newInstitutionAdmin = {
      firstName: '',
      lastName: '',
      email: '',
    };
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <form bind:this={form}>
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={institution.institutionName}
              variant="filled"
              label="Institution Name"
              input$required
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Add an Institution Admin (optional)</h3>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-first-name-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.firstName}
              variant="filled"
              label="Institution Admin First Name"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-last-name-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.lastName}
              variant="filled"
              label="Institution Admin Last Name"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="institution-admin-email-field"
              class="gigxr-input"
              bind:value={newInstitutionAdmin.email}
              variant="filled"
              label="Institution Admin Email"
              type="email"
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <h3>Institution Contact</h3>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="contact-first-name-field"
              class="gigxr-input"
              bind:value={institution.contact.firstName}
              variant="filled"
              label="Contact First Name"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="contact-last-name-field"
              class="gigxr-input"
              bind:value={institution.contact.lastName}
              variant="filled"
              label="Contact Last Name"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="contact-email-field"
              class="gigxr-input"
              bind:value={institution.contact.email}
              variant="filled"
              label="Contact Email Address"
              input$required
              type="email"
            />
          </ContentRow>

          <GigXrHorizontalRule />

          <ContentRow>
            <Textfield
              input$id="address1-field"
              class="gigxr-input"
              bind:value={institution.address.address1}
              variant="filled"
              label="Address1"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="address2-field"
              class="gigxr-input"
              bind:value={institution.address.address2}
              variant="filled"
              label="Address2"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="address3-field"
              class="gigxr-input"
              bind:value={institution.address.address3}
              variant="filled"
              label="Address3"
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="city-field"
              class="gigxr-input"
              bind:value={institution.address.city}
              variant="filled"
              label="City"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="state-field"
              class="gigxr-input"
              bind:value={institution.address.state}
              variant="filled"
              label="State/Province"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <CountrySelect bind:value={institution.address.country} />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="postal-code-field"
              class="gigxr-input"
              bind:value={institution.address.postalCode}
              variant="filled"
              label="Postal Code"
              input$required
            />
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="phone-field"
              class="gigxr-input"
              bind:value={institution.phoneNumber.number}
              variant="filled"
              label="Phone"
              input$required
            />
          </ContentRow>
        </div>

        <div slot="right">
          <ContentRow>
            <Select
              inputId="license-type-field"
              class="gigxr-input"
              bind:value={license.licenseType}
              variant="filled"
              label="License Type"
              enhanced
            >
              {#if !license.licenseType}
                <Option value="" disabled on:click={(event) => event.stopPropagation()} />
              {/if}
              {#each Object.values(LicenseType) as licenseType (licenseType)}
                <Option value={licenseType}>{LicenseTypeText[licenseType]}</Option>
              {/each}
            </Select>
          </ContentRow>

          <ContentRow>
            <Textfield
              input$id="licensed-active-users-field"
              type="number"
              class="gigxr-input"
              bind:value={license.licensedActiveUsers}
              variant="filled"
              label="Licensed Active Users"
            />
          </ContentRow>

          <ContentRow>
            <GigXrDatepicker
              id="license-expiration-date-field"
              label="Expiry Date"
              bind:dateValue={licenseExpirationValue}
            />
          </ContentRow>

          <!-- The entire list must be rendered at once, otherwise clicking on a row will not trigger toggling the checkbox. -->
          {#if !loading}
            <ContentRow>
              {#if license.licenseType}
                <List class="gigxr-list" checklist>
                  {#each clientApps as clientApp (clientApp.clientAppId)}
                    <Item inputId="license-client-app-checkbox-{clientApp.clientAppId}">
                      <Graphic>
                        <Checkbox bind:group={license.clientAppIds} value={clientApp.clientAppId} />
                      </Graphic>
                      <Label>{clientApp.clientAppName}</Label>
                    </Item>
                  {/each}
                </List>
              {/if}
            </ContentRow>
          {/if}

          <ContentRowCenteredOnMobile>
            <Button
              id="create-institution-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={createInstitutionHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="create-institution-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a id="create-institution-as-demo-link" class="gigxr-link" on:click={saveAsDemoAccountHandler}>Save as Demo
              Account</a>
          </ContentRowCenteredOnMobile>
        </div>
      </TwoColumnSection>
    </form>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
