<script>
  import { title } from '../stores/core-store.js';
  import ErrorBanner from './ErrorBanner.svelte';
  import PrimaryContent from './PrimaryContent.svelte';
  import BreadcrumbNavigation from './BreadcrumbNavigation.svelte';
</script>

<!-- 
Subheader.svelte

Responsible for the breadcrumbs, the main page title, and error banner.
-->
<PrimaryContent>
  <div class="subheader">
    <BreadcrumbNavigation />
    <h1>{$title}</h1>
    <ErrorBanner />
  </div>
</PrimaryContent>

<style>
  .subheader {
    padding-top: 70px;
    text-align: center;
    position: relative;
  }

  h1 {
    display: none;
    margin-top: 0;
  }

  @media (min-width: 1100px) {
    .subheader {
      padding-top: 0 !important;
    }

    h1 {
      display: block;
      margin-top: 0.5em;
    }
  }
</style>
