<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label, Icon } from '@smui/button';
  import IconButton from '@smui/icon-button';

  const dispatch = createEventDispatcher();

  export let loading = false;
  export let disabled = false;
</script>

{#if !loading}
  <IconButton
    id="mobile-download-accounts-button"
    class="material-icons gigxr-icon download-accounts-button__mobile"
    on:click={(event) => dispatch('click', event)}
    {disabled}
  >
    save_alt
  </IconButton>
{/if}

{#if !loading}
  <Button
    id="desktop-download-accounts-button"
    class="download-accounts-button__desktop"
    on:click={(event) => dispatch('click', event)}
    {disabled}
  >
    <Icon class="material-icons gigxr-icon">save_alt</Icon>
    <Label>Download List</Label>
  </Button>
{/if}

<style>
  :global(.download-accounts-button__desktop) {
    display: none;
  }

  :global(.download-accounts-button__mobile:disabled),
  :global(.download-accounts-button__desktop:disabled) {
    opacity: 0.38;
  }

  @media (min-width: 1100px) {
    :global(.download-accounts-button__mobile) {
      display: none !important;
    }

    :global(.download-accounts-button__desktop) {
      color: var(--mdc-theme-secondary) !important;
      display: inline-block;
    }
  }
</style>
