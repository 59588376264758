<script>
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-routing';
  import Paper, { Content as PaperContent } from '@smui/paper';
  import IconButton from '@smui/icon-button';
  import { replaceUtcStringsWithLocalDate } from '../util/dates';
  import { replaceDoubleSlash } from '../util/api';
  import { Icon } from '@smui/icon-button';

  export let textBody = '';
  export let url;
  export let read = true;

  const dispatch = createEventDispatcher();
</script>

<Paper class="notification-banner" elevation="2">
  <PaperContent class="notification-banner__content">
    <div class="notification-banner__indicator">
      {#if !read}
        <Icon class="material-icons">stop_circle</Icon>
      {/if}
    </div>
    <div class="notification-banner__main">
      <div class="notification-banner__text-body">{replaceUtcStringsWithLocalDate(textBody)}</div>
      <div class="notification-banner__url">
        {#if url}<a href={replaceDoubleSlash(url)} use:link>View Details</a>{/if}
      </div>
    </div>
    <div>
      <IconButton
        id="notification-banner-close-button"
        class="material-icons"
        title="Dismiss"
        on:click={(event) => dispatch('GigXr:closeNotification', event)}
      >
        close
      </IconButton>
    </div>
  </PaperContent>
</Paper>

<style>
  :global(.notification-banner) {
    padding: 5px 12px;
    border-radius: 10px !important;
  }

  :global(.notification-banner__content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification-banner__main {
    flex: 1;
    padding-left: 10px;
  }

  .notification-banner__indicator {
    width: 18px;
    margin-left: 2px;
    margin-top: 5px;
    display: flex;
    align-self: flex-start;
    color: var(--gigxr-theme-secondary-3c);
  }

  .notification-banner__indicator :global(.material-icons) {
    font-size: 16px;
  }

  .notification-banner__text-body {
    flex: 1;
    padding-right: 1em;
  }

  .notification-banner__url {
    margin-right: 1em;
  }

  a {
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    .notification-banner__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .notification-banner__indicator {
      align-self: center;
      margin-top: 0;
    }
  }
</style>
