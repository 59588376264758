<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [
    AccountRole.INSTRUCTOR,
    AccountRole.DEPARTMENT_ADMIN,
    AccountRole.INSTITUTION_ADMIN,
    AccountRole.GIGXR_ADMIN,
  ];
</script>

<script>
  import { onMount } from 'svelte';
  import { link } from 'svelte-routing';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import Checkbox from '@smui/checkbox';
  import Papa from 'papaparse';
  import { title, errorMessage, breadcrumbPaths, snackbarMessage, snackbar, mobileView } from '../../stores/core-store';
  import AccountRow from '../../components/AccountRow.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import ListPageTable from '../../components/ListPageTable.svelte';
  import MobileListPageControls from '../../components/MobileListPageControls.svelte';
  import ListPageCount from '../../components/ListPageCount.svelte';
  import DesktopListPageControls from '../../components/DesktopListPageControls.svelte';
  import FilterButton from '../../components/FilterButton.svelte';
  import FilterComponent from '../../components/FilterComponent.svelte';
  import AddAccountButton from '../../components/accounts/AddAccountButton.svelte';
  import DownloadAccountsButton from '../../components/accounts/DownloadAccountsButton.svelte';
  import ListPageViewMoreButton from '../../components/ListPageViewMoreButton.svelte';
  import { LIST_VIEW_COUNT_PER_PAGE } from '../../util/constants';
  import LoadingView from '../../components/LoadingView.svelte';
  import AccountMobileListCard from '../../components/accounts/AccountMobileListCard.svelte';
  import {
    fetchAccounts,
    deleteAccount,
    patchAccounts,
    deleteAccounts,
    AccountRoleText,
    canEditAccountRole,
  } from '../../util/api/accounts';
  import { fetchDepartments } from '../../util/api/departments';
  import { fetchClasses } from '../../util/api/classes';
  import { setAuthorizedRoles } from '../../util/authorization';
  import ListPageSearch from '../../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../../components/ListPageMobileSearchButton.svelte';
  import {
    AccountFilterByClassSet,
    AccountFilterByDepartmentSet,
    AccountFilterByRegistrationStatusSet,
    AccountFilterByRoleSet,
    AccountFilterIncludeInactiveSet,
    AccountSortBySet,
  } from '../../util/filters/account-list-filters';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import { sanitizeCsvField } from '../../util/security';
  import ListPageCardGrid from '../../components/ListPageCardGrid.svelte';
  import ListPageBatchSelect from '../../components/ListPageBatchSelect.svelte';
  import ListPageHeaderCheckboxCell from '../../components/ListPageHeaderCheckboxCell.svelte';
  import ListPageSelectionText from '../../components/ListPageSelectionText.svelte';
  import ListPageActionControls from '../../components/ListPageActionControls.svelte';
  import ListPageCardGridItem from '../../components/ListPageCardGridItem.svelte';
  import ListPageCardGridCheckbox from '../../components/ListPageCardGridCheckbox.svelte';
  import ListPageCardGridHeader from '../../components/ListPageCardGridHeader.svelte';
  import DeleteAccountsDialog from '../../components/accounts/DeleteAccountsDialog.svelte';
  import { getAccountId } from '../../util/account';
  import { GmsError } from '../../util/errors';

  setAuthorizedRoles(authorizedRoles);

  title.set('User Management');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'User Management',
      location: '/users/list',
    },
  ]);

  let loading = true;
  let showMobileSearch = false;
  let deleteAccountsDialog;

  // Batch select
  let showBatchActions = false;
  let selectedValues = [];
  let mobileSelectedValues = [];
  let allSelected = false;
  let allSelectedIncludingNotShown = false;

  // Pagination
  const incrementUsers = LIST_VIEW_COUNT_PER_PAGE;
  let numberDisplayedUsers = incrementUsers;

  // DownloadUsers
  let csvPrefix = 'data:text/csv;charset=utf-8,';

  let filterComponent;
  let searchQuery = '';
  let filterBySets = [];
  let listPageActionControlsConfig = {};

  let departmentsById = {};
  let classesById = {};
  let accounts = [];
  let filteredAccounts = [];
  let filteredAndSearchedAccounts = [];
  let classes = [];
  let departments = [];

  onMount(async () => {
    [accounts, classes, departments] = await Promise.all([fetchAccounts(), fetchClasses(), fetchDepartments()]);

    // We need a map for O(1) lookups later.
    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    classes.forEach((c) => (classesById[c.classId] = c));

    filterBySets = [
      AccountFilterByDepartmentSet(departments),
      AccountFilterByClassSet(classes),
      AccountFilterByRoleSet(),
      AccountFilterByRegistrationStatusSet(),
      AccountFilterIncludeInactiveSet(),
    ];

    initializeListPageActionControlsConfig();

    loading = false;
  });

  function downloadListedUsers() {
    // https://www.papaparse.com/docs#json-to-csv
    const data = {
      fields: ['AccountId', 'FirstName', 'LastName', 'Email', 'AccountRole', 'Departments', 'InstitutionId'],
      data: filteredAndSearchedAccounts.map((account) =>
        [
          account.accountId,
          account.firstName,
          account.lastName,
          account.email,
          account.accountRole,
          account.departmentIds
            .map((id) => departmentsById[id])
            .map((d) => d.departmentName)
            .join(' && '),
          account.institutionId,
        ].map(sanitizeCsvField),
      ),
    };

    const csv = Papa.unparse(data);

    const csvUserList = csvPrefix + csv;
    const encodedUri = encodeURI(csvUserList);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'gms_userlist.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function showMoreUsers() {
    numberDisplayedUsers += incrementUsers;
  }

  async function deleteAccountHandler(event) {
    const account = event.detail;
    await deleteAccount(account.accountId);
    snackbarMessage.set('User deleted!');
    $snackbar.open();
    accounts = accounts.filter((a) => a.accountId !== account.accountId);
  }

  // Desktop and mobile uses two different selection methods, so return whichever one is being used based upon browser width.
  let realSelectedAccounts = [];
  $: {
    const realSelectedValues = $mobileView ? mobileSelectedValues : selectedValues;
    const totalCount = filteredAndSearchedAccounts.length;
    const shownCount = Math.min(numberDisplayedUsers, filteredAndSearchedAccounts.length);

    if (totalCount > shownCount && realSelectedValues.length > shownCount && allSelectedIncludingNotShown) {
      // All values are selected AND the option to include hidden rows is checked.
      realSelectedAccounts = filteredAndSearchedAccounts.map((account) => account.accountId);
    } else {
      realSelectedAccounts = realSelectedValues;
    }
  }

  // This reactive block will clear the mobile batch selections when it is closed.
  $: if (!showBatchActions) {
    mobileSelectedValues = [];
  }

  async function bulkAccountPatch(patchToApply) {
    const patches = [];
    const patchesById = new Map();

    const accountIdsToPatch = realSelectedAccounts;

    accountIdsToPatch.forEach((accountId) => {
      const patch = {
        accountId,
        ...patchToApply,
      };
      patches.push(patch);
      patchesById.set(accountId, patch);
    });

    await patchAccounts(patches);

    // Update data on the client side so we don't have to fetch again.
    accounts.forEach((account) => {
      if (patchesById.has(account.accountId)) {
        const patch = patchesById.get(account.accountId);
        // Patch properties.
        Object.assign(account, patch);

        // Patch collections.
        if (patch.classIdsToRemove) {
          account.classIds = [...account.classIds.filter((id) => !patch.classIdsToRemove.includes(id))];
        }
        if (patch.classIdsToAdd) {
          const set = new Set(account.classIds);
          patch.classIdsToAdd.forEach((id) => set.add(id));
          account.classIds = [...set];
        }
        if (patch.departmentIdsToRemove) {
          account.departmentIds = [...account.departmentIds.filter((id) => !patch.departmentIdsToRemove.includes(id))];
        }
        if (patch.departmentIdsToAdd) {
          const set = new Set(account.departmentIds);
          patch.departmentIdsToAdd.forEach((id) => set.add(id));
          account.departmentIds = [...set];
        }
      }
    });
    filteredAndSearchedAccounts = filteredAndSearchedAccounts;

    return patches.length;
  }

  async function bulkAccountDelete() {
    const accountIdsToDelete = realSelectedAccounts;

    await deleteAccounts(accountIdsToDelete);

    // Update data on the client side so we don't have to fetch again.
    accounts = accounts.filter((a) => !accountIdsToDelete.includes(a.accountId));

    return accountIdsToDelete.length;
  }

  const searchValueFunctions = {
    fullName: (account) => `${account.firstName} ${account.lastName}`,
    email: (account) => account.email,
  };

  function initializeListPageActionControlsConfig() {
    listPageActionControlsConfig = {
      'Make Inactive': {
        callback: async () => {
          const count = await bulkAccountPatch({
            isActive: false,
          });
          snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} changed to inactive!`);
          $snackbar.open();
        },
      },
      'Make Active': {
        callback: async () => {
          const count = await bulkAccountPatch({
            isActive: true,
          });
          snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} changed to active!`);
          $snackbar.open();
        },
      },
      Delete: {
        callback: async () => {
          if (realSelectedAccounts.includes(getAccountId())) {
            throw new GmsError('You cannot delete your own account in a batch action!');
          }
          deleteAccountsDialog.open();
        },
      },
      'Change Role': {
        callback: async (option) => {
          const count = await bulkAccountPatch({
            accountRole: option,
          });
          snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} changed to ${AccountRoleText[option]}!`);
          $snackbar.open();
        },
        optionsName: 'Role',
        options: Object.values(AccountRole)
          .filter((role) => role !== AccountRole.INVALID)
          .filter((role) => canEditAccountRole(role))
          .map((role) => ({ key: role, value: AccountRoleText[role] })),
      },
      'Add to Department': {
        callback: async (option) => {
          const count = await bulkAccountPatch({
            departmentIdsToAdd: [option],
          });
          snackbarMessage.set(
            `${count} User${count === 1 ? '' : 's'} added to ${departmentsById[option]?.departmentName}!`,
          );
          $snackbar.open();
        },
        optionsName: 'Department',
        options: departments.map((department) => ({ key: department.departmentId, value: department.departmentName })),
      },
      'Remove from Department': {
        callback: async (option) => {
          const count = await bulkAccountPatch({
            departmentIdsToRemove: [option],
          });
          snackbarMessage.set(
            `${count} User${count === 1 ? '' : 's'} removed to ${departmentsById[option]?.departmentName}!`,
          );
          $snackbar.open();
        },
        optionsName: 'Department',
        options: departments.map((department) => ({ key: department.departmentId, value: department.departmentName })),
      },
      'Add to Class': {
        callback: async (option) => {
          const count = await bulkAccountPatch({
            classIdsToAdd: [option],
          });
          snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} added to ${classesById[option]?.className}!`);
          $snackbar.open();
        },
        optionsName: 'Class',
        options: classes.map((clazz) => ({ key: clazz.classId, value: clazz.className })),
      },
      'Remove from Class': {
        callback: async (option) => {
          const count = await bulkAccountPatch({
            classIdsToRemove: [option],
          });
          snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} removed from ${classesById[option]?.className}!`);
          $snackbar.open();
        },
        optionsName: 'Class',
        options: classes.map((clazz) => ({ key: clazz.classId, value: clazz.className })),
      },
    };
  }

  // This reactive block controls the header checkbox for mobile.
  $: {
    const headerCheckbox = document.getElementById('checkbox-header');
    if (headerCheckbox) {
      if (mobileSelectedValues.length >= Math.min(numberDisplayedUsers, filteredAndSearchedAccounts.length)) {
        headerCheckbox.checked = true;
        headerCheckbox.indeterminate = false;
      } else if (mobileSelectedValues.length === 0) {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = false;
      } else {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = true;
      }
    }
  }
</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredAccounts}
          bind:filteredList={filteredAndSearchedAccounts}
          bind:query={searchQuery}
          valueFunctions={searchValueFunctions}
          placeholder="Search users"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
        <ListPageCount {loading} count={filteredAndSearchedAccounts.length} singularLabel="User" pluralLabel="Users" />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddAccountButton />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} disabled={showBatchActions} />
        <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
        <DownloadAccountsButton on:click={downloadListedUsers} {loading} disabled={showBatchActions} />
        <AddAccountButton disabled={showBatchActions} />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount {loading} count={filteredAndSearchedAccounts.length} singularLabel="User" pluralLabel="Users" />
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
      <ListPageSearch
        unfilteredList={filteredAccounts}
        bind:filteredList={filteredAndSearchedAccounts}
        bind:query={searchQuery}
        valueFunctions={searchValueFunctions}
        placeholder="Search users"
        disabled={showBatchActions}
      />
    </div>
    <div slot="right">
      <DownloadAccountsButton on:click={downloadListedUsers} {loading} disabled={showBatchActions} />
      <AddAccountButton disabled={showBatchActions} />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={accounts}
  bind:filteredList={filteredAccounts}
  sortBySet={AccountSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      {#if showBatchActions}
        <ListPageActionControls
          actions={listPageActionControlsConfig}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
        />

        <ListPageSelectionText
          totalCount={filteredAndSearchedAccounts.length}
          shownCount={Math.min(numberDisplayedUsers, filteredAndSearchedAccounts.length)}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
          singularName="User"
          pluralName="Users"
          bind:allSelectedIncludingNotShown
        />
      {/if}

      <ListPageCardGrid>
        <ListPageCardGridItem hide={!showBatchActions}>
          <ListPageCardGridHeader
            on:change={(event) => {
              if (event.target.checked) {
                mobileSelectedValues = filteredAndSearchedAccounts
                  .slice(0, numberDisplayedUsers)
                  .map((account) => account.accountId);
              } else {
                mobileSelectedValues = [];
              }
            }}
          />
        </ListPageCardGridItem>
        {#each filteredAndSearchedAccounts.slice(0, numberDisplayedUsers) as account (account.accountId + searchQuery)}
          <ListPageCardGridItem>
            <ListPageCardGridCheckbox
              value={account.accountId}
              bind:group={mobileSelectedValues}
              show={showBatchActions}
            />
            <AccountMobileListCard
              {account}
              {departmentsById}
              match={searchQuery}
              on:AccountMobileListCard::deleted={deleteAccountHandler}
              compact={showBatchActions}
              bind:mobileSelectedValues
            />
          </ListPageCardGridItem>
        {/each}
      </ListPageCardGrid>

      {#if filteredAndSearchedAccounts.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no users to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <!-- 
          We have to redraw the entire table whenever the rows change because SMUI initializes checkboxes *from* the
          data table on initialization, so any rows added later will not work correctly.
        -->
        {#key numberDisplayedUsers + filteredAndSearchedAccounts}
          <ListPageTable
            ariaLabel="Accounts"
            class="accounts-data-table"
            showCheckboxes={showBatchActions}
            bind:selectedValues
          >
            <Head>
              <Row>
                <ListPageHeaderCheckboxCell bind:value={allSelected} />
                <Cell class="accounts-data-table__name-column">Name</Cell>
                <Cell class="accounts-data-table__email-column">Email</Cell>
                <Cell class="accounts-data-table__role-column">Role</Cell>
                <Cell
                  class="accounts-data-table__departments-column {showBatchActions ? 'accounts-data-table__departments-column--batch-select' : ''}"
                >
                  Departments
                </Cell>
                <Cell class="accounts-data-table__registration-column">Status</Cell>
                <Cell
                  class="accounts-data-table__active-column mdc-data-table__header-cell--numeric {showBatchActions ? 'accounts-data-table__active-column--batch-select' : ''}"
                >
                  Active
                </Cell>
                <Cell class="accounts-data-table__menu-column" />
              </Row>
            </Head>
            <Body>
              {#each filteredAndSearchedAccounts.slice(0, numberDisplayedUsers) as account (account.accountId + searchQuery + numberDisplayedUsers)}
                <AccountRow
                  {account}
                  {departmentsById}
                  match={searchQuery}
                  on:AccountRow::deleted={deleteAccountHandler}
                />
              {/each}
            </Body>
          </ListPageTable>
        {/key}
      {/if}

      {#if numberDisplayedUsers < filteredAndSearchedAccounts.length}
        <ListPageViewMoreButton
          itemName="Users"
          remainingItems={filteredAndSearchedAccounts.length - numberDisplayedUsers}
          on:click={showMoreUsers}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DeleteAccountsDialog
  bind:dialog={deleteAccountsDialog}
  count={realSelectedAccounts.length}
  on:DeleteAccountsDialog::deleteConfirmed={async () => {
    const count = await bulkAccountDelete();
    snackbarMessage.set(`${count} User${count === 1 ? '' : 's'} deleted!`);
    $snackbar.open();
  }}
/>

<style>
  /* Checkboxes in batch select need 6% */

  :global(.accounts-data-table__name-column) {
    width: 17%;
  }

  :global(.accounts-data-table__email-column) {
    width: 15%;
  }

  :global(.accounts-data-table__role-column) {
    width: 15%;
  }

  :global(.accounts-data-table__departments-column) {
    width: 20%;
  }

  :global(.accounts-data-table__departments-column--batch-select) {
    width: 18%;
  }

  :global(.accounts-data-table__registration-column) {
    width: 11%;
  }

  :global(.accounts-data-table__active-column) {
    width: 12%;
  }

  :global(.accounts-data-table__active-column--batch-select) {
    width: 8%;
  }

  :global(.accounts-data-table__menu-column) {
    width: 10%;
  }
</style>
