<script>
  import { navigate } from 'svelte-routing';
  import { snackbarMessage, snackbar, isAuthenticated, errorMessage } from '../../stores/core-store';
  import { gdprDeleteAccount } from '../../util/api/accounts';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';

  export let dialog = undefined;
  export let account;

  let deleteConfirmation = '';

  async function gdprDeleteHandler(event) {
    await gdprDeleteAccount(account.accountId);
    deleteConfirmation = '';
    snackbarMessage.set('User removed!');
    $snackbar.open();
    navigate('/users/list');
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="gdpr-delete-account">
  <GigXrDialogContent>
    <GigXrDialogIcon />

    <p>
      <strong>WARNING:</strong>
      You are about to remove this user's data and their access to GIGXR.
      <strong>THIS CANNOT BE UNDONE.</strong>
    </p>

    <p>The user's email address will be <strong>permanently unavailable</strong>.</p>

    <p>To proceed, type REMOVE and click the REMOVE USER DATA button.</p>
    <input type="text" class="gigxr-input" bind:value={deleteConfirmation} />
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={gdprDeleteHandler} disabled={deleteConfirmation !== 'REMOVE'}>
      Remove User Data
    </GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
