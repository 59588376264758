<script>
  import { navigate } from 'svelte-routing';
  import { snackbarMessage, snackbar, isAuthenticated, errorMessage } from '../../stores/core-store';
  import { WhereConsent, withdrawAllConsent } from '../../util/api/accounts';
  import Cookies from 'js-cookie';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  export let dialog = undefined;
  export let account;

  async function withdrawConsentHandler(event) {
    await withdrawAllConsent(account.accountId, WhereConsent.PROFILE);
    snackbarMessage.set('Consent withdrew!');
    $snackbar.open();
    Cookies.remove('jwt');
    isAuthenticated.set(false);
    navigate('/', { replace: false });
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="withdraw-consent">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You are about to withdraw your consent. You will lose access to GIGXR and have to agree again before you can regain
    access.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={withdrawConsentHandler}>Withdraw Consent</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
