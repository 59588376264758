<script>
  import Icon from '@smui/textfield/icon/index';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthorizedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import UnauthenticatedPageTextField from '../components/unauthenticated/UnauthenticatedPageTextField.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import { requestPasswordReset } from '../util/api/accounts';
  import UnauthenticatedPageErrorBanner from '../components/unauthenticated/UnauthenticatedPageErrorBanner.svelte';
  import { GmsError } from '../util/errors';
  import { errorMessage } from '../stores/core-store';

  let emailSent = false;
  let email = '';
  let form;

  async function clickHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      throw new GmsError('This is not a valid email. Please try again.');
    }

    try {
      await requestPasswordReset(email);
    } catch (error) {
      // Do nothing
    }
    // Show success state no matter what for security reasons
    emailSent = true;
  }
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageLogo />

    {#if emailSent}
      <UnauthorizedPageHeader>Password reset email sent!</UnauthorizedPageHeader>

      <UnauthenticatedPageSubheader>
        If you've provided a correct email address, we've sent you an email with a link to reset your password. Be sure
        to check your junk mail folder if you don't see it in your inbox.
      </UnauthenticatedPageSubheader>
    {:else}
      <UnauthorizedPageHeader>Forgot password?</UnauthorizedPageHeader>

      <UnauthenticatedPageErrorBanner />

      <UnauthenticatedPageSubheader>
        Please enter your account email below to receive a link to reset your password.
      </UnauthenticatedPageSubheader>

      <!-- Using a real form will submit on enter -->
      <form bind:this={form}>
        <UnauthenticatedPageTextField
          id="email-field"
          type="email"
          bind:value={email}
          label="Email Address"
          invalid={Boolean($errorMessage)}
          required
          withLeadingIcon
        >
          <Icon class="material-icons">person</Icon>
        </UnauthenticatedPageTextField>

        <UnauthenticatedPageButton id="forgot-password-button" label="Submit" on:click={clickHandler} />
      </form>
    {/if}

    <UnauthenticatedPageLink id="return-to-login-link" href="/" label="Return to Login" />
  </UnauthenticatedPageContent>
</UnauthenticatedPage>
