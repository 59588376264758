<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import Button from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import { authenticatedGet, authenticatedPost } from '../../util/api';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { createDepartment, fetchDepartments } from '../../util/api/departments';
  import { fetchDepartmentAdmins } from '../../util/api/accounts';
  import { setAuthorizedRoles } from '../../util/authorization';
  import List, {
    Group,
    Item,
    Graphic,
    Meta,
    Label,
    Separator,
    Subheader,
    Text,
    PrimaryText,
    SecondaryText,
  } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';
  import { deepCopy } from '../../util/util';

  setAuthorizedRoles(authorizedRoles);

  title.set('Create Department');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Department Management',
      location: '/departments/list',
    },
    {
      name: 'Create Department',
      location: `/departments/create`,
    },
  ]);

  let departmentAdmins = [];

  let lastSavedDepartment = {
    departmentName: '',
    departmentAdminIds: [],
    description: '',
    departmentStatus: 'Active',
  };

  let department = deepCopy(lastSavedDepartment);
  let discardChangesDialog;

  onMount(async () => {
    [departmentAdmins] = await Promise.all([fetchDepartmentAdmins()]);
  });

  async function createDepartmentHandler(event) {
    event.preventDefault();
    const newDepartment = await createDepartment(department);
    snackbarMessage.set('Department created!');
    $snackbar.open();
    navigate(`/departments/view/${newDepartment.departmentId}`);
  }

  function discardChangesHandler(event) {
    department = deepCopy(lastSavedDepartment);
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <TwoColumnSection>
      <div slot="left">
        <ContentRow>
          <Textfield
            input$id="department-name-field"
            class="gigxr-input"
            bind:value={department.departmentName}
            variant="filled"
            label="Department Name"
          />
        </ContentRow>

        <ContentRow>
          <h3 class="gigxr-list-header gigxr-list-header--first">Department Admins</h3>
          {#if !departmentAdmins || departmentAdmins.length === 0}
            <p>There are no department admins.</p>
          {:else}
            <List class="gigxr-list create-department-list" twoLine checklist>
              {#each departmentAdmins as da (da.accountId)}
                <Item inputId="department-admin-checkbox-{da.accountId}">
                  <Graphic>
                    <Checkbox bind:group={department.departmentAdminIds} value={da.accountId} />
                  </Graphic>
                  <Text>
                    <PrimaryText>{da.firstName} {da.lastName}</PrimaryText>
                    <SecondaryText>{da.email}</SecondaryText>
                  </Text>
                </Item>
              {/each}
            </List>
          {/if}
        </ContentRow>

        <ContentRow>
          <FilledTextArea
            id="department-description-field"
            bind:value={department.description}
            label="Description"
            ariaLabel="department-description"
          />
        </ContentRow>
      </div>
      <div slot="right">
        <ContentRow>
          <Select
            inputId="department-status-field"
            class="gigxr-input"
            bind:value={department.departmentStatus}
            variant="filled"
            label="Status"
            enhanced
          >
            <Option value="Inactive">Inactive</Option>
            <Option value="Active">Active</Option>
          </Select>
        </ContentRow>

        <DateAccountComponent label="Added" utcDateString={department.createdOn} account={department.createdBy} />

        <ContentRowCenteredOnMobile>
          <Button
            id="create-department-save-button"
            class="gigxr-button"
            variant="unelevated"
            on:click={createDepartmentHandler}
          >
            <Label>Save Changes</Label>
          </Button>
        </ContentRowCenteredOnMobile>

        <ContentRowCenteredOnMobile>
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            id="create-department-discard-changes-link"
            class="gigxr-link"
            on:click={() => discardChangesDialog.open()}
          >Discard Changes</a>
        </ContentRowCenteredOnMobile>
      </div>
    </TwoColumnSection>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
