<script>
  import Button, { Label as ButtonLabel } from '@smui/button';

  // import {InitialFocus} from '@smui/dialog';
  // As of 2020-5-18, using InitialFocus only works on the first Dialog on the page. As a workaround
  // to this issue we are relying on the default behavior of the first focusable element of the
  // dialog being the one selected. This means that the cancel button should generally be first in
  // the dialog markup.

  export let id = '';
</script>

<Button {id} class="gigxr-button" variant="unelevated">
  <ButtonLabel>
    <slot>Cancel</slot>
  </ButtonLabel>
</Button>

<style>
</style>
