<script>
  import { link } from 'svelte-routing';

  let className = '';
  export { className as class };
</script>

<h1 class="unauthenticated-page__logo {className}">
  <a id="unauthenticated-page-logo-link" href="/" use:link>
    <img src="/assets/gigxr-logo-black.svg" alt="GIGXR logo" width="127" height="47" />
  </a>
</h1>

<style>
  .unauthenticated-page__logo {
    margin: 1.5em 0;
  }
</style>
