import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../filters';
import { Countries } from '../countries';

function InstitutionSortBySet() {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: 'Institution Name (A-Z)',
    sortOptions: new Map([
      [
        'Institution Name (A-Z)',
        (a, b) => {
          const institutionNameA = a.institutionName.toLowerCase();
          const institutionNameB = b.institutionName.toLowerCase();
          if (institutionNameA < institutionNameB) return -1;
          if (institutionNameA > institutionNameB) return 1;
          return 0;
        },
      ],
      [
        'Institution Name (Z-A)',
        (b, a) => {
          const institutionNameA = a.institutionName.toLowerCase();
          const institutionNameB = b.institutionName.toLowerCase();
          if (institutionNameA < institutionNameB) return -1;
          if (institutionNameA > institutionNameB) return 1;
          return 0;
        },
      ],

      [
        'Date Added (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Added (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
    ]),
  });
}

function InstitutionFilterByCountrySet(institutions) {
  const filterByCountrySet = new FilterBySelectSet({
    description: 'Filter by Country',
    label: 'Country',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Countries',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.address.country === selectedValue);
    },
  });

  const countries = new Set();
  institutions.forEach((institution) => countries.add(institution.address.country));

  [...countries.values()]
    .sort((a, b) => {
      a = a.toLowerCase();
      b = b.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .forEach(
      (country) => (filterByCountrySet.filterOptions[country] = Countries[country] ? Countries[country] : country),
    );

  return filterByCountrySet;
}

function InstitutionFilterIncludeDemoSet() {
  const filterByDemoSet = new FilterByCheckboxSet({
    description: 'Demo Accounts',
    defaultValue: false,
    filterOptions: {
      option: 'Include Demo Accounts',
    },
    filterCallback: (array, includeDemoAccounts) => {
      if (includeDemoAccounts) return array;
      return array.filter((element) => !element.isDemoAccount);
    },
  });
  return filterByDemoSet;
}

export { InstitutionSortBySet, InstitutionFilterByCountrySet, InstitutionFilterIncludeDemoSet };
