<script>
  import Button, { Label, Icon } from '@smui/button';
  import { isUnity, sendUnityCreateSessionMessage, isDeveloper } from '../../util/unity';

  export let session;

  function clickHandler(event) {
    sendUnityCreateSessionMessage(session);
  }
</script>

{#if isUnity() && isDeveloper()}
  <Button id="create-photon-session-button" on:click={clickHandler} variant="unelevated" class="create-session-button">
    <Icon class="material-icons">auto_fix_normal</Icon>
    <Label class="create-session-button__label">Create</Label>
  </Button>
{/if}

<style>
  :global(.create-session-button) {
    height: 45px;
    border-radius: 6px;
  }

  :global(.create-session-button:not(:disabled)) {
    background-color: var(--gigxr-theme-secondary-3c);
  }

  :global(.create-session-button:disabled) {
    color: #fff !important;
  }

  :global(.create-session-button__label) {
    text-transform: uppercase;
    font-size: 16px;
  }

  @media (min-width: 1100px) {
    :global(.create-session-button) {
      border-radius: 6px 6px 0 0;
    }
  }
</style>
