<script>
  export let count = 0;
  export let singularLabel = 'Item';
  export let pluralLabel = 'Items';
  export let loading = true;
</script>

<span class="list-count">
  {#if loading}–{:else}{count}{/if}

  {#if count === 1}{singularLabel}{:else}{pluralLabel}{/if}
</span>

<style>
  .list-count {
    font-weight: 700;
    color: var(--gigxr-theme-primary-1c);
  }
</style>
