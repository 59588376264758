<script>
  export let withMargin = false;
</script>

<div class="loading-view {withMargin ? 'loading-view--with-margin' : ''}">Loading...</div>

<style>
  .loading-view {
    text-align: center;
    width: 100%;
  }

  .loading-view--with-margin {
    margin: 30px 0;
  }
</style>
