import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../filters';
import { Countries } from '../countries';

function ModulesSortBySet() {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: 'Asset Name (A-Z)',
    sortOptions: new Map([
      [
        'Module Name (A-Z)',
        (a, b) => {
          const assetNameA = a.name ? a.name.toLowerCase() : '';
          const assetNameB = b.name ? b.name.toLowerCase() : '';
          if (assetNameA < assetNameB) return -1;
          if (assetNameA > assetNameB) return 1;
          return 0;
        },
      ],
      [
        'Module Name (Z-A)',
        (b, a) => {
          const assetNameA = a.name ? a.name.toLowerCase() : '';
          const assetNameB = b.name? b.name.toLowerCase() : '';
          if (assetNameA < assetNameB) return -1;
          if (assetNameA > assetNameB) return 1;
          return 0;
        },
      ],

      [
        'Date Added (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Added (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
    ]),
  });
}

export { ModulesSortBySet };
