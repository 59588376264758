<script>
  /**
   * Custom GIGXR implementation of @smui/menu.
   *
   * Use this when you need to use custom GigXr* menu elements like GigXrListAnchorItem.
   */

  import { onMount, setContext } from 'svelte';
  import Menu from '@smui/menu';

  export let menu;
  export let open;

  let menuPromiseResolve;
  let menuPromise = new Promise((resolve) => (menuPromiseResolve = resolve));

  setContext('GigXrMenu', menuPromise);

  onMount(async () => {
    menuPromiseResolve(menu);
  });
</script>

<Menu bind:this={menu} bind:open {...$$restProps}>
  <slot />
</Menu>

<style>
</style>
