<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import List, { Item, Meta, Label, Text, PrimaryText, SecondaryText } from '@smui/list';
  import Button from '@smui/button';
  import { role, title, errorMessage, breadcrumbPaths } from '../../../stores/core-store';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import MultiLineText from '../../../components/MultiLineText.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import { fetchDepartment } from '../../../util/api/departments';
  import { fetchClasses } from '../../../util/api/classes';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import EditButton from '../../../components/EditButton.svelte';
  import { fetchDepartmentAdmins } from '../../../util/api/accounts';

  setAuthorizedRoles(authorizedRoles);

  export let departmentId;

  let loading = true;

  let department = null;
  let classesById = {};
  let departmentAdminsById = new Map();

  title.set('Department Details');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Department Management',
      location: '/departments/list',
    },
    {
      name: 'Department Details',
      location: `/departments/view/${departmentId}`,
    },
  ]);

  onMount(async () => {
    let classes;
    let departmentAdmins;
    [department, classes, departmentAdmins] = await Promise.all([
      fetchDepartment(departmentId),
      fetchClasses(),
      fetchDepartmentAdmins(),
    ]);

    classes.forEach((clazz) => (classesById[clazz.classId] = clazz));
    departmentAdmins.forEach((da) => departmentAdminsById.set(da.accountId, da));

    loading = false;
  });
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{department.departmentName}</h2>
      </div>

      <div slot="right">
        {#if $role === 'InstitutionAdmin'}
          <EditButton on:click={() => navigate(`/departments/edit/${departmentId}`)} />
        {/if}
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left" class="view-department-left-section">
          <KeyValueRow>
            <div slot="left">Dept Admins:</div>
            <div slot="right">
              {#each department.departmentAdminIds as id (id)}
                <div>
                  <a href="mailto:{departmentAdminsById.get(id).email}">
                    {departmentAdminsById.get(id).firstName}
                    {departmentAdminsById.get(id).lastName}
                  </a>
                </div>
              {/each}
              {#if department.departmentAdminIds.length === 0}–{/if}
            </div>
          </KeyValueRow>

          <KeyValueRow>
            <div slot="left">Description:</div>
            <div slot="right">
              <MultiLineText text={department.description.trim().length === 0 ? '–' : department.description} />
            </div>
          </KeyValueRow>

          <ContentRow>
            <h3 class="gigxr-list-header">Classes</h3>
            {#if !department.classIds || department.classIds.length === 0}
              <p>This department has no classes.</p>
            {:else}
              <List class="gigxr-list" twoLine>
                {#each department.classIds as id (id)}
                  <Item on:click={() => navigate(`/classes/view/${id}`)} class="gigxr-list__item">
                    <Text>
                      <PrimaryText>{classesById[id].className}</PrimaryText>
                      <SecondaryText>
                        Instructor:
                        {#if classesById[id].instructor}
                          {classesById[id].instructor.firstName}
                          {classesById[id].instructor.lastName}
                        {:else}–{/if}
                      </SecondaryText>
                    </Text>
                    <Meta>
                      <Button disabled>
                        <Label>View</Label>
                      </Button>
                    </Meta>
                  </Item>
                {/each}
              </List>
            {/if}
          </ContentRow>
        </div>

        <div slot="right">
          <KeyValueRow>
            <div slot="left">Status:</div>
            <div slot="right">{department.departmentStatus}</div>
          </KeyValueRow>

          <DateAccountComponent label="Added" utcDateString={department.createdOn} account={department.createdBy} />
        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  h3 {
    margin-top: 3em;
  }

  .view-department-left-section {
    margin-bottom: 3em;
  }

  @media (min-width: 1100px) {
    .view-department-left-section {
      margin-bottom: 1.5em;
    }
  }
</style>
