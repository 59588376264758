<script>
  let className = '';
  export { className as class };
</script>

<div class="content-row-centered-on-mobile {className}">
  <slot />
</div>

<style>
  .content-row-centered-on-mobile {
    margin: 0 0 1em 0;
    text-align: center;
  }

  @media (min-width: 1100px) {
    .content-row-centered-on-mobile {
      text-align: left;
    }
  }
</style>
