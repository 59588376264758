<script>
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';

  export let compact = false;
</script>

<Card class="gigxr-mobile-list-card {compact ? 'gigxr-mobile-list-card--compact' : ''}" nonInteractive>
  <Content>
    <slot />
  </Content>
</Card>

<style>
  :global(.gigxr-mobile-list-card .smui-card__content) {
    padding: 0;
  }

  :global(.gigxr-mobile-list-card) {
    width: 291px;
    font-family: 'Barlow', sans-serif;
    font-size: 13px;
    margin: 0 auto 30px;
    border-radius: 10px !important;
    position: relative;
  }

  :global(.gigxr-mobile-list-card--compact) {
    margin: 0 auto 30px 0;
  }

  @media (min-width: 1100px) {
    :global(.gigxr-mobile-list-card) {
      display: none;
    }
  }
</style>
