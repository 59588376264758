<script>
  import Button, { Label as ButtonLabel } from '@smui/button';
  import { createEventDispatcher } from 'svelte';

  export let id = '';
  export let disabled = false;

  const dispatch = createEventDispatcher();
</script>

<Button
  {id}
  {disabled}
  on:click={(event) => dispatch('click', event)}
  class="gigxr-button gigxr-dialog-button"
  variant="outlined"
>
  <ButtonLabel>
    <slot>Ok</slot>
  </ButtonLabel>
</Button>

<style>
  :global(.gigxr-dialog-button:disabled) {
    color: rgba(0, 0, 0, 0.37);
    /*Alternative disabled style:*/
    /*border: none;*/
    /*background-color: rgba(0, 0, 0, .12);*/
  }
</style>
