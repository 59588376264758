import { authenticatedGet, authenticatedPost, authenticatedPut } from '../api';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { GmsError, RedirectError } from '../errors';
import { errorMessage } from '../../stores/core-store';

/**
 * LicenseView
 * @typedef {Object} LicenseView
 * @property {string} licenseId
 * @property {string} clientAppId
 * @property {ClientAppView} clientApp
 * @property {string} expirationDate
 * @property {string} licenseType
 * @property {string} institutionId
 */

const LicenseType = {
  STARTER_PROGRAM: 'StarterProgram',
  GROWTH_PROGRAM: 'GrowthProgram',
  ACCELERATE_PROGRAM: 'AccelerateProgram',
};

const LicenseTypeText = {
  Invalid: 'Invalid',
  StarterProgram: 'Starter Program',
  GrowthProgram: 'Growth Program',
  AccelerateProgram: 'Accelerate Program',
};

/**
 * @param {string?} institutionId An optional institutionId to filter by.
 * @return {Promise<[LicenseView]>}
 */
async function fetchLicenses(institutionId) {
  const query = institutionId ? `?institutionId=${institutionId}` : '';
  const response = await authenticatedGet(`/licenses${query}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching licenses! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

/**
 * @param licenseId
 * @return {Promise<LicenseView>}
 */
async function fetchLicense(licenseId) {
  const response = await authenticatedGet(`/licenses/${licenseId}`);
  if (response.status === 404) {
    throw new RedirectError('License not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching license! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function createLicense(license) {
  const requestPayload = {
    ...license,
  };

  const response = await authenticatedPost(`/licenses`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error creating license! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = response.json();
  const newLicense = json.data;

  return newLicense;
}

async function editLicense(license) {
  const requestPayload = {
    ...license,
  };

  const response = await authenticatedPut(`/licenses/${license.licenseId}`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error editing license! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = response.json();
  const editedLicense = json.data;

  return editedLicense;
}

export { LicenseType, LicenseTypeText, fetchLicenses, fetchLicense, createLicense, editLicense };
