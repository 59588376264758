import QRCode from 'qrcode';

async function generateQrCodeImageData(value) {
  const options = {
    errorCorrectionLevel: 'H',
    color: {
      dark: '#000',
      light: '#0000',
    },
    width: 1100,
    height: 1100,
    margin: 1,
  };

  // This returns a promise.
  return QRCode.toDataURL(value, options);
}

export { generateQrCodeImageData };
