<script>
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import IconButton from '@smui/icon-button';
  import LinearProgress from '@smui/linear-progress';
  import { sendUnityStopClipDownloadMessage, sendUnityDeleteClipMessage } from '../util/unity';
  import { getMobileOperatingSystem, humanFileSize } from '../util/util';

  /**
   * The clip name.
   */
  export let name = 'Unknown file name';

  /**
   * The clip display names.
   */
  export let displayNames = [];

  /**
   * The size of the cilp in bytes.
   */
  export let size = -1;

  /**
   * A date to display with the clip.
   */
  export let date = new Date();

  /**
   * Progress of the clip download. 0.0 = 0%, 1.0 = 100%
   */
  export let progress = 0.0;

  /**
   * Converts a full clip name into a display name.
   *
   * Input:
   *  - 'Anaphylaxis/Anaphylaxis1.mp4'
   *  - 'Anaphylaxis/Anaphylaxis1'
   *
   * Output:
   *  - 'Anaphylaxis1'
   */
  function clipDisplayFilter(clip) {
    // parts[0] = pathology
    // parts[1] = clip
    const parts = clip.split('/');
    if (!parts[1]) {
      // If not valid, return raw string
      return clip;
    }
    // return clip without extension
    return parts[1].split('.')[0];
  }

  function deleteClickHandler() {
    sendUnityDeleteClipMessage(name);
  }

  function cancelClickHandler() {
    sendUnityStopClipDownloadMessage(name);
  }
</script>

<Card class="app-settings-clip-card">
  <Content class="app-settings-clip-card-content">
    <div class="app-settings-clip-card__information">
      <div class="app-settings-clip-card__name">
        {clipDisplayFilter(name)}<br />
        {#each displayNames as name (name)}{clipDisplayFilter(name)}<br />{/each}
      </div>
      <LinearProgress {progress} closed={progress >= 1} />
      <div class="app-settings-clip-card__details-row">
        <div class="app-settings-clip-card__details-date">{date.toLocaleDateString()}</div>
        <div class="app-settings-clip-card__details-size">
          {size === -1 ? 'Unknown size' : humanFileSize(size, true)}
        </div>
      </div>
    </div>
    <div class="app-settings-clip-card__controls">
      {#if progress >= 1}
        <IconButton class="material-icons" on:click={deleteClickHandler}>delete</IconButton>
      {:else if getMobileOperatingSystem() !== 'iOS'}
        <IconButton class="material-icons" on:click={cancelClickHandler}>clear</IconButton>
      {/if}
    </div>
  </Content>
</Card>

<style>
  :global(.app-settings-clip-card) {
    font-family: 'Barlow', sans-serif;
    border-radius: 10px !important;
    margin-bottom: 0.5em;
  }

  :global(.app-settings-clip-card-content) {
    display: flex;
    align-items: center;
  }

  .app-settings-clip-card__information {
    flex: 4;
  }

  .app-settings-clip-card__details-row {
    font-size: 0.83em;
  }

  .app-settings-clip-card__details-date {
    display: inline-block;
    color: var(--gigxr-theme-secondary-2c);
    font-weight: 700;
    min-width: 75px;
  }

  .app-settings-clip-card__details-size {
    display: inline-block;
  }

  .app-settings-clip-card__controls {
    flex: 1;
    text-align: right;
    align-items: center;
  }
</style>
