<script>
  import { onMount } from 'svelte';
  import { splitTextByLines } from '../util/text';

  export let text = '';

  let lines = [];

  onMount(async () => {
    lines = splitTextByLines(text);
  });
</script>

{#each lines as line, index (index)}
  <p>{line}</p>
  <p />
{/each}

<style>
  p {
    margin-top: 0;
  }
</style>
