<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { account, isAuthenticated } from '../stores/core-store';
  import { stopMasquerading } from '../util/masquerade';
  import Cookies from 'js-cookie';
  import { sendUnityLogoutMessage } from '../util/unity';
  import { logout } from '../util/api/accounts';

  onMount(() => {
    logout();
    Cookies.remove('jwt');
    isAuthenticated.set(false);
    stopMasquerading();
    sendUnityLogoutMessage();
    navigate('/', { replace: true });
  });
</script>

Logout
