/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 * https://stackoverflow.com/questions/1382107/whats-a-good-way-to-extend-error-in-javascript
 */

export class RedirectError extends Error {
  constructor(message, location) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RedirectError);
    }

    this.name = 'RedirectError';
    this.location = location;
  }
}

export class GmsError extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RedirectError);
    }

    this.name = 'GmsError';
  }
}

export class SessionForbidError extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RedirectError);
    }

    this.name = 'SessionForbidError';
  }
}
