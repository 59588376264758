<script>
</script>

<!-- Supress bugged slot warning  -->
<!-- TODO: Remove when this is fixed: https://github.com/sveltejs/svelte/issues/4546 -->
{#if false}
  <slot />
{/if}

<div class="two-column-section">
  <slot name="left" />
  <slot name="right" />
</div>

<style>
  @media (min-width: 1100px) {
    .two-column-section {
      display: flex;
    }

    :global(.two-column-section div[slot='left']) {
      width: 100%;
    }

    :global(.two-column-section div[slot='right']) {
      margin-left: 25px;
      width: 300px;
    }
  }
</style>
