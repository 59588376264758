import { FilterByCheckboxSet, FilterBySelectSet, SortBySet } from '../filters';

function DepartmentSortBySet() {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: 'Dept Name (A-Z)',
    sortOptions: new Map([
      [
        'Dept Name (A-Z)',
        (a, b) => {
          const deptNameA = a.departmentName.toLowerCase();
          const deptNameB = b.departmentName.toLowerCase();
          if (deptNameA < deptNameB) return -1;
          if (deptNameA > deptNameB) return 1;
          return 0;
        },
      ],
      [
        'Dept Name (Z-A)',
        (b, a) => {
          const deptNameA = a.departmentName.toLowerCase();
          const deptNameB = b.departmentName.toLowerCase();
          if (deptNameA < deptNameB) return -1;
          if (deptNameA > deptNameB) return 1;
          return 0;
        },
      ],

      [
        'Date Added (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Added (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
    ]),
  });
}

function DepartmentFilterByDepartmentAdminSet(departmentAdmins) {
  const filterByDepartmentAdminSet = new FilterBySelectSet({
    description: 'Filter by Department Admin',
    label: 'Department Admin',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Department Admins',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.departmentAdminIds.includes(selectedValue));
    },
  });

  departmentAdmins
    .sort((a, b) => {
      const aFullName = `${a.firstName} ${a.lastName}`;
      const bFullName = `${b.firstName} ${b.lastName}`;
      if (aFullName < bFullName) return -1;
      if (aFullName > bFullName) return 1;
      return 0;
    })
    .forEach(
      (departmentAdmin) =>
        (filterByDepartmentAdminSet.filterOptions[
          departmentAdmin.accountId
        ] = `${departmentAdmin.firstName} ${departmentAdmin.lastName}`),
    );

  return filterByDepartmentAdminSet;
}

function DepartmentFilterIncludeInactiveSet() {
  return new FilterByCheckboxSet({
    description: 'Inactive Departments',
    defaultValue: false,
    filterOptions: {
      option: 'Include Inactive',
    },
    filterCallback: (array, includeInactive) => {
      if (includeInactive) return array;
      return array.filter((element) => element.departmentStatus !== 'Inactive');
    },
  });
}

export { DepartmentSortBySet, DepartmentFilterByDepartmentAdminSet, DepartmentFilterIncludeInactiveSet };
