<script>
  import MobileListCard from '../MobileListCard.svelte';
  import MobileListCardContent from '../MobileListCardContent.svelte';
  import { navigate } from 'svelte-routing';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import ClassListItemMenuButton from './ClassListItemMenuButton.svelte';
  import ListPageActiveIndicator from '../ListPageActiveIndicator.svelte';

  export let clazz;
  export let compact = false;
  export let mobileSelectedValues;

  function cardNavigate(event, to) {
    if (compact) {
      // Checkboxes are shown, so a click event should click the corresponding row's checkbox.
      const set = new Set(mobileSelectedValues);
      if (set.has(clazz.classId)) {
        set.delete(clazz.classId);
      } else {
        set.add(clazz.classId);
      }
      mobileSelectedValues = [...set];

      return;
    }

    // Checkboxes are not shown, so a click event will navigate to the provided link.
    navigate(to);
  }
</script>

<MobileListCard {compact}>
  <MobileListCardContent on:click={(event) => cardNavigate(event, `/classes/view/${clazz.classId}`)}>
    <h2>{clazz.className}</h2>

    {#if clazz.classStatus === 'Active'}
      <div class="class-mobile-list-card__active-indicator">
        <ListPageActiveIndicator />
      </div>
    {/if}

    <dl>
      <dt>Instructor:</dt>
      {#if clazz.instructor}
        <dd title={`${clazz.instructor.firstName} ${clazz.instructor.lastName}`}>
          {`${clazz.instructor.firstName} ${clazz.instructor.lastName}`}
        </dd>
      {:else}
        <dd>–</dd>
      {/if}

      <dt>Department:</dt>
      <dd title={clazz.department.departmentName}>{clazz.department.departmentName}</dd>

      <dt>Students:</dt>
      <dd>{clazz.studentCount}</dd>
    </dl>
  </MobileListCardContent>
  <ActionIcons>
    <ClassListItemMenuButton {clazz} />
  </ActionIcons>
</MobileListCard>

<style>
  h2 {
    margin: 0 0 5px;
    text-align: center;
  }

  .class-mobile-list-card__active-indicator {
    width: 100%;
    text-align: center;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 11px 0 0 0;
  }

  dt {
    flex: 35%;
    font-weight: 500;
  }

  dd {
    margin-left: 0;
    margin-bottom: 0.2em;
    flex: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.gigxr-mobile-list-card .mdc-card__action-icons) {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
