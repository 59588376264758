<script>
  import { snackbarMessage, snackbar } from '../../stores/core-store';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import { requestPasswordReset } from '../../util/api/accounts';

  export let dialog = undefined;
  export let account;

  async function sendPasswordResetHandler(event) {
    await requestPasswordReset(account.email);
    snackbarMessage.set('Password reset email sent!');
    $snackbar.open();
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="admin-reset-password">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You are about to send this user an email which will allow them to reset their password.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={sendPasswordResetHandler}>Send Password Reset</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
