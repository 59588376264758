<script>
  let className = '';
  export { className as class };
</script>

<h2 class="unauthenticated-page__header {className}">
  <slot />
</h2>

<style>
  .unauthenticated-page__header {
    font-weight: 500;
  }
</style>
