<script>
  import { onDestroy } from 'svelte';
  import UnauthenticatedPage from '../components/unauthenticated/UnauthenticatedPage.svelte';
  import UnauthenticatedPageContent from '../components/unauthenticated/UnauthenticatedPageContent.svelte';
  import UnauthenticatedPageLogo from '../components/unauthenticated/UnauthenticatedPageLogo.svelte';
  import UnauthenticatedPageParagraph from '../components/unauthenticated/UnauthenticatedPageParagraph.svelte';
  import UnauthenticatedPageHeader from '../components/unauthenticated/UnauthenticatedPageHeader.svelte';
  import UnauthenticatedPageButton from '../components/unauthenticated/UnauthenticatedPageButton.svelte';
  import { Icon } from '@smui/icon-button';
  import UnauthenticatedPageSubheader from '../components/unauthenticated/UnauthenticatedPageSubheader.svelte';
  import {
    UnityAction,
    sendUnityEnableNotificationsMessage,
    subscribeToUnityMessage,
    isUnity,
    sendUnityFirstTimeExperienceFinishedMessage,
  } from '../util/unity';
  import UnauthenticatedPageLink from '../components/unauthenticated/UnauthenticatedPageLink.svelte';
  import { navigate } from 'svelte-routing';

  const unsubscribeNotificationDialogDismissed = subscribeToUnityMessage(
    UnityAction.NOTIFICATION_DIALOG_DISMISSED,
    (payload) => {
      navigate('/', { replace: true });
    },
  );

  function notificationHandler(event) {
    sendUnityEnableNotificationsMessage();
    sendUnityFirstTimeExperienceFinishedMessage();
  }

  onDestroy(() => {
    unsubscribeNotificationDialogDismissed();
  });
</script>

<UnauthenticatedPage>
  <UnauthenticatedPageContent>
    <UnauthenticatedPageHeader class="turn-on-notifications-header">Turn on Notifications</UnauthenticatedPageHeader>

    <Icon class="material-icons turn-on-notifications__notification-icon">notifications</Icon>

    <UnauthenticatedPageSubheader class="turn-on-notifications__text">
      GIGXR will only send you important notifications related to your sessions.
    </UnauthenticatedPageSubheader>

    <UnauthenticatedPageSubheader class="turn-on-notifications__text">
      Please accept notifications so you don't miss any of your sessions.
    </UnauthenticatedPageSubheader>

    <UnauthenticatedPageButton id="next-button" label="Next" on:click={notificationHandler} />
  </UnauthenticatedPageContent>
</UnauthenticatedPage>

<style>
  :global(.turn-on-notifications-header) {
    font-weight: 700 !important;
    padding-top: 50px;
  }

  :global(.material-icons.turn-on-notifications__notification-icon) {
    font-size: 6em;
    color: var(--gigxr-theme-primary-1d);
  }
</style>
