<script>
  import MobileListCard from '../MobileListCard.svelte';
  import MobileListCardContent from '../MobileListCardContent.svelte';
  import { navigate } from 'svelte-routing';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import InstitutionListItemMenuButton from './InstitutionListItemMenuButton.svelte';
  import { Countries } from '../../util/countries';
  import FormattedSearchItem from '../FormattedSearchItem.svelte';
  import { utcStringToLocalDate } from '../../util/dates';

  export let institution;
  export let match = '';
  export let licensesById;

  function licenseDateTooltip(institution) {
    if (institution.licenseIds.length === 0) {
      return 'No license';
    }

    return `Expiration ${utcStringToLocalDate(
      licensesById.get(institution.licenseIds[0]).expirationDate,
    ).toLocaleDateString()}`;
  }
</script>

<MobileListCard>
  <MobileListCardContent on:click={() => navigate(`/institutions/view/${institution.institutionId}`)}>
    <h2>
      <FormattedSearchItem value={institution.institutionName} {match} />
    </h2>

    {#if institution.isDemoAccount}
      <h3>Demo Account</h3>
    {/if}
    <dl>
      <dt>Contact:</dt>

      {#if institution.contact}
        <dd title={`${institution.contact.firstName} ${institution.contact.lastName}`}>
          {`${institution.contact.firstName} ${institution.contact.lastName}`}
        </dd>
      {:else}
        <dd>–</dd>
      {/if}

      <dt>Country:</dt>
      <dd title={Countries[institution.address.country] || institution.address.country}>
        {institution.address.country}
      </dd>

      <dt>Users:</dt>
      <dd title={licenseDateTooltip(institution)}>
        {#if institution.licenseIds.length > 0 && licensesById.has(institution.licenseIds[0])}
          {#if utcStringToLocalDate(licensesById.get(institution.licenseIds[0]).expirationDate).getTime() < Date.now()}
            <span class="usage-warning">
              {institution.accountCount}/{licensesById.get(institution.licenseIds[0]).licensedActiveUsers}
            </span>
          {:else}{institution.accountCount}/{licensesById.get(institution.licenseIds[0]).licensedActiveUsers}{/if}
        {:else}{institution.accountCount}{/if}
      </dd>

      <dt>Apps:</dt>
      <dd>
        {#if institution.licenseIds.length > 0 && licensesById.has(institution.licenseIds[0]) && licensesById.get(institution.licenseIds[0]).clientApps.length > 0}
          <ul class="simple-list">
            {#each licensesById.get(institution.licenseIds[0]).clientApps as clientApp (clientApp.clientAppId)}
              <li>{clientApp.clientAppName}</li>
            {/each}
          </ul>
        {:else}–{/if}
      </dd>
    </dl>
  </MobileListCardContent>
  <ActionIcons>
    <InstitutionListItemMenuButton {institution} />
  </ActionIcons>
</MobileListCard>

<style>
  h2 {
    margin: 0 0 16px;
    text-align: center;
  }

  h3 {
    margin: 0;
    text-align: center;
    color: var(--gigxr-theme-secondary-3c);
    font-weight: 700;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 0;
  }

  dt {
    flex: 35%;
    font-weight: 500;
  }

  dd {
    margin-left: 0;
    margin-bottom: 0.2em;
    flex: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  :global(.gigxr-mobile-list-card .mdc-card__action-icons) {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
