function sanitizeHtml(text) {
  const div = document.createElement('div');
  div.textContent = text;

  return div.innerHTML;
}

/**
 * A set of characters that are interpreted as formulas in Excel.
 */
const excelFormulaSymbols = new Set(['=', '+', '-', '@']);

/**
 * Sanitize text for output into a CSV or Excel document.
 *
 * If it encounters a formula, it will prefix a single quote (') to prevent the formula being executed when opened in
 * Excel.
 *
 * @see https://www.we45.com/blog/your-excel-sheets-not-safe-how-to-beat-csv-injection
 * @param {string} text
 */
function sanitizeCsvField(text) {
  const workingText = text.trim();

  if (workingText.length === 0) {
    return text;
  }

  if (excelFormulaSymbols.has(workingText[0])) {
    return `'${text}`;
  }

  return text;
}

export { sanitizeHtml, sanitizeCsvField };
