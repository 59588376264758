<script>
  let className = '';
  export { className as class };
</script>

<p class="unauthenticated-page__subheader {className}">
  <slot />
</p>

<style>
  .unauthenticated-page__subheader {
    margin: 1.5em 0 2em;
  }
</style>
