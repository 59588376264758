<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import { title, breadcrumbPaths, snackbar, snackbarMessage } from '../../../stores/core-store';
  import { resendRegistrationInviteEmail } from '../../../util/api/accounts';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../../components/DateAccountComponent.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { deepCopy } from '../../../util/util';
  import { fetchModule } from '../../../util/api/modules';
  import PageControlsRow from '../../../components/PageControlsRow.svelte';
  import LoadingView from '../../../components/LoadingView.svelte';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { fetchLicenses, LicenseTypeText } from '../../../util/api/licenses';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import EditButton from '../../../components/EditButton.svelte';
  import { encodeMasqueradeData } from '../../../util/masquerade';
  import GigXrHorizontalRule from '../../../components/GigXrHorizontalRule.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';

  export let moduleId;

  setAuthorizedRoles(authorizedRoles);

  title.set('Module Details');
  breadcrumbPaths.set([
    {
      name: 'Modules',
      location: '/modules',
    },
    {
      name: 'Module Details',
      location: `/modules/view/${moduleId}`,
    },
  ]);

  let loading = true;

  let modules = [];
  let clientApps = [];
  let lastSavedModule = {
    moduleId: '',
    moduleName: '',
    clientAppId: '',
  };

  let module = deepCopy(lastSavedModule);


  onMount(async () => {
    [lastSavedModule, clientApps] = await Promise.all([
      fetchModule(moduleId),
      fetchClientApps(),
    ]);
    module = deepCopy(lastSavedModule);
    loading = false;
  });

</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>
          {module.moduleName}
        </h2>
      </div>

      <div slot="right">
        <EditButton on:click={() => navigate(`/modules/edit/${moduleId}`)} />
      </div>
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      <TwoColumnSection>
        <div slot="left" class="view-institution-left-section">

          <KeyValueRow>
            <div slot="left">Module Id:</div>
            <div slot="right">{module.moduleId}</div>
          </KeyValueRow>
          <KeyValueRow>
            <div slot="left">Module Name:</div>
            <div slot="right">{module.moduleName}</div>
          </KeyValueRow>
          <KeyValueRow>
            <div slot="left">App:</div>
            <div slot="right">
              {#each clientApps as app (app.clientAppId)}
                {#if app.clientAppId === module.clientAppId}
                  {app.clientAppName}
                {/if}
              {/each}
            </div>
          </KeyValueRow>
        </div>

        <div slot="right">


        </div>
      </TwoColumnSection>
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .institution-demo-account {
    color: var(--gigxr-theme-secondary-3c);
    display: block;
    margin-top: 0.5em;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media (min-width: 1100px) {
    .institution-demo-account {
      display: inline;
      margin-left: 1em;
    }
  }
</style>
