<script>
</script>

<!-- Supress bugged slot warning  -->
<!-- TODO: Remove when this is fixed: https://github.com/sveltejs/svelte/issues/4546 -->
{#if false}
  <slot />
{/if}

<div class="page-controls-row">
  <slot name="left" />
  <slot name="right" />
</div>

<style>
  .page-controls-row {
    margin: 0 0 1.5em 0;
    text-align: center;
  }

  @media (min-width: 1100px) {
    .page-controls-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 1em;
      text-align: left;
    }

    :global(.page-controls-row h2) {
      margin: 0;
    }

    :global(.page-controls-row div[slot='right']) {
      margin-left: 25px;
    }
  }
</style>
