<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label } from '@smui/button';

  const dispatch = createEventDispatcher();

  export let itemName = 'Items';
  export let remainingItems = 0;

  function clickHandler(event) {
    event.preventDefault();
    dispatch('click', event);
  }
</script>

<div class="view-more">
  <Button id="list-page-view-more-button" on:click={clickHandler} variant="unelevated">
    <Label>View more <strong class="item-name">{itemName}</strong> ({remainingItems} remaining)</Label>
  </Button>
</div>

<style>
  .view-more {
    width: 100%;
    text-align: center;
  }

  .item-name {
    display: none;
  }

  @media (min-width: 1100px) {
    .item-name {
      display: inline;
    }
  }
</style>
