<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label } from '@smui/button';
  import ButtonCircularProgress from '../ButtonCircularProgress.svelte';

  export let id = '';
  export let label = 'Button';
  export let disabled = false;
  export let loading = false;

  let className = '';
  export { className as class };

  const dispatch = createEventDispatcher();

  function clickHandler(event) {
    event.preventDefault();
    dispatch('click', event);
  }
</script>

<div class="unauthenticated-page__button-wrapper">
  <Button
    {id}
    class="gigxr-button unauthenticated-page__button {className}"
    variant="unelevated"
    on:click={clickHandler}
    disabled={disabled || loading}
  >
    <Label>{label}</Label>
  </Button>
  {#if loading}
    <ButtonCircularProgress />
  {/if}
</div>

<style>
  .unauthenticated-page__button-wrapper {
    position: relative;
  }

  :global(.gigxr-button.unauthenticated-page__button) {
    background: var(--gigxr-theme-primary-1d);
    width: 100%;
  }

  :global(.gigxr-button.unauthenticated-page__button:disabled) {
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.38);
  }

  @media (min-width: 1100px) {
    :global(.gigxr-button.unauthenticated-page__button) {
      width: 370px;
    }
  }
</style>
