<script context="module">
  let dialog;

  export function openLogoutDialog() {
    dialog.open();
  }
</script>

<script>
  import { navigate } from 'svelte-routing';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
</script>

<GigXrDialog bind:dialog ariaPrefix="logout">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Log out of GIGXR now?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={() => navigate('/logout')}>Log Out</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
