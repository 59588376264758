import { institutionMasquerade, role } from '../stores/core-store';
import { b64DecodeUnicode, b64EncodeUnicode } from './util';

function startMasquerading({ institutionId, institutionName }) {
  institutionMasquerade.set({ institutionId, institutionName });
  sessionStorage.setItem('masquerade', JSON.stringify({ institutionId, institutionName }));
}

function stopMasquerading() {
  institutionMasquerade.set(null);
  sessionStorage.removeItem('masquerade');
}

function encodeMasqueradeData({ institutionId, institutionName }) {
  return b64EncodeUnicode(
    JSON.stringify({
      institutionId,
      institutionName,
    }),
  );
}

function decodeMasqueradeData(data) {
  return JSON.parse(b64DecodeUnicode(data));
}

export { startMasquerading, stopMasquerading, encodeMasqueradeData, decodeMasqueradeData };
