<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { title, errorMessage, breadcrumbPaths, snackbar, snackbarMessage } from '../../stores/core-store';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import WorkInProgress from '../../components/WorkInProgress.svelte';
  import { getAccountId } from '../../util/account';
  import { editAccount, fetchAccount, requestPasswordReset } from '../../util/api/accounts';
  import PageControlsRow from '../../components/PageControlsRow.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import List, { Group, Item, Graphic, Meta, Separator, Subheader, Text, PrimaryText, SecondaryText } from '@smui/list';
  import Button, { Label } from '@smui/button';
  import KeyValueRow from '../../components/KeyValueRow.svelte';
  import { AccountRoleText } from '../../util/api/accounts';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import { NON_BREAKING_SPACE } from '../../util/constants';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ClientAppLogo from '../../components/client-apps/ClientAppLogo.svelte';
  import { fetchDepartments } from '../../util/api/departments';
  import Textfield from '@smui/textfield';
  import { Logger } from '../../util/logs';
  import { v4 as uuidv4 } from 'uuid';
  import Dialog, { Title, Actions, Content } from '@smui/dialog';
  import MobileCloseDialogButton from '../../components/gigxr-dialog/GigXrDialogCloseButton.svelte';
  import GigXrDialog from '../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';
  import { deepCopy } from '../../util/util';

  title.set('Edit My Account');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Edit My Account',
      location: '/my-account/edit',
    },
  ]);

  let loading = true;
  let form;
  let resetPasswordDialog;
  let discardChangesDialog;
  let changedEmailDialog;

  let lastSavedAccount = {
    accountId: uuidv4(),
    firstName: '',
    lastName: '',
    email: '',
    departmentIds: [],
    classIds: [],
    accountRole: 'Student',
    isActive: true,
  };

  let account = { ...lastSavedAccount };

  let formUnmodified = false;
  let formUnmodifiedOrInvalid = false;
  $: {
    if (account) {
      formUnmodified =
        account.firstName === lastSavedAccount.firstName &&
        account.lastName === lastSavedAccount.lastName &&
        account.email === lastSavedAccount.email;
    }
  }
  $: {
    account;
    if (form) {
      formUnmodifiedOrInvalid = formUnmodified || !form.checkValidity();
    }
  }

  onMount(async () => {
    lastSavedAccount = await fetchAccount(getAccountId());
    account = deepCopy(lastSavedAccount);
    loading = false;
  });

  async function saveChangesHandler(event) {
    event.preventDefault();

    if (account.email !== lastSavedAccount.email) {
      changedEmailDialog.open();
      return;
    }

    await _saveChangesHandler(event);
  }

  async function _saveChangesHandler(event) {
    event.preventDefault();
    if (!form.reportValidity()) {
      return;
    }

    Logger.log('Saving account:', account);
    const editedAccount = await editAccount(account);
    snackbarMessage.set('Account edited!');
    $snackbar.open();
    navigate('/my-account/view');
  }

  async function resetPasswordHandler(event) {
    await requestPasswordReset(lastSavedAccount.email);
  }

  function discardChangesHandler(event) {
    account = deepCopy(lastSavedAccount);
  }
</script>

{#if !loading}
  <PrimaryContent>
    <PageControlsRow>
      <div slot="left">
        <h2>{`${account.firstName} ${account.lastName}`}</h2>
      </div>
      <div slot="right" />
    </PageControlsRow>
  </PrimaryContent>
{/if}

<SecondaryBackgroundWrapper>
  {#if loading}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <form bind:this={form}>
        <TwoColumnSection>
          <div slot="left" class="edit-my-account-left">
            <ContentRow>
              <Textfield
                input$id="first-name-field"
                class="gigxr-input"
                bind:value={account.firstName}
                variant="filled"
                label="First Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="last-name-field"
                class="gigxr-input"
                bind:value={account.lastName}
                variant="filled"
                label="Last Name"
                input$required
              />
            </ContentRow>

            <ContentRow>
              <Textfield
                input$id="email-name-field"
                type="email"
                class="gigxr-input"
                bind:value={account.email}
                variant="filled"
                label="Email"
                input$required
              />
            </ContentRow>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="my-account-reset-password-link"
                class="gigxr-link"
                on:click={() => resetPasswordDialog.open()}
              >Reset Password</a>
            </ContentRowCenteredOnMobile>
          </div>

          <div slot="right">
            <ContentRowCenteredOnMobile>
              <Button
                id="edit-my-account-save-button"
                class="gigxr-button"
                variant="unelevated"
                on:click={saveChangesHandler}
                disabled={formUnmodifiedOrInvalid}
              >
                <Label>Save Changes</Label>
              </Button>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                class="gigxr-link {formUnmodified ? 'gigxr-link--disabled' : ''}"
                on:click={() => discardChangesDialog.open()}
              >
                Discard Changes
              </a>
            </ContentRowCenteredOnMobile>
          </div>
        </TwoColumnSection>
      </form>
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<GigXrDialog bind:dialog={resetPasswordDialog} ariaPrefix="reset-password">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You will be sent a link to the email address in your account to reset your password.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={resetPasswordHandler}>Send Password Reset</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<GigXrDialog bind:dialog={changedEmailDialog} ariaPrefix="changed-email">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You will need to validate your new email address. Check your inbox for email validation.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={_saveChangesHandler}>Change Email</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
  .edit-my-account-left {
    margin-bottom: 2em;
  }

  @media (min-width: 1100px) {
    .edit-my-account-left {
      margin-bottom: 0;
    }
  }
</style>
