import { FilterByCheckboxSet, FilterByRadioSet, FilterBySelectSet, SortBySet } from '../filters';
import { AccountRole, AccountRoleText, canEditAccountRole } from '../api/accounts';

function AccountSortBySet() {
  return new SortBySet({
    description: 'Sort by',
    defaultValue: 'Last Active (Recent)',
    sortOptions: new Map([
      [
        'Last Active (Recent)',
        (a, b) => {
          if (a.lastActive < b.lastActive) return 1;
          if (a.lastActive > b.lastActive) return -1;
          return 0;
        },
      ],
      [
        'Last Active (Oldest)',
        (b, a) => {
          if (a.lastActive < b.lastActive) return 1;
          if (a.lastActive > b.lastActive) return -1;
          return 0;
        },
      ],

      [
        'Date Added (Recent)',
        (a, b) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],
      [
        'Date Added (Oldest)',
        (b, a) => {
          if (a.createdOn < b.createdOn) return 1;
          if (a.createdOn > b.createdOn) return -1;
          return 0;
        },
      ],

      [
        'First Name (A-Z)',
        (a, b) => {
          const firstNameA = a.firstName.toLowerCase();
          const firstNameB = b.firstName.toLowerCase();
          if (firstNameA < firstNameB) return -1;
          if (firstNameA > firstNameB) return 1;
          return 0;
        },
      ],
      [
        'First Name (Z-A)',
        (b, a) => {
          const firstNameA = a.firstName.toLowerCase();
          const firstNameB = b.firstName.toLowerCase();
          if (firstNameA < firstNameB) return -1;
          if (firstNameA > firstNameB) return 1;
          return 0;
        },
      ],

      [
        'Last Name (A-Z)',
        (a, b) => {
          const lastNameA = a.lastName.toLowerCase();
          const lastNameB = b.lastName.toLowerCase();
          if (lastNameA < lastNameB) return -1;
          if (lastNameA > lastNameB) return 1;
          return 0;
        },
      ],
      [
        'Last Name (Z-A)',
        (b, a) => {
          const lastNameA = a.lastName.toLowerCase();
          const lastNameB = b.lastName.toLowerCase();
          if (lastNameA < lastNameB) return -1;
          if (lastNameA > lastNameB) return 1;
          return 0;
        },
      ],
    ]),
  });
}

function AccountFilterByDepartmentSet(departments) {
  const filterByDepartmentSet = new FilterBySelectSet({
    description: 'Filter by Department',
    label: 'Department',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Departments',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.departmentIds.some((id) => id === selectedValue));
    },
  });

  departments
    .sort((a, b) => {
      if (a.departmentName < b.departmentName) return -1;
      if (a.departmentName > b.departmentName) return 1;
      return 0;
    })
    .forEach(
      (department) => (filterByDepartmentSet.filterOptions[department.departmentId] = department.departmentName),
    );

  return filterByDepartmentSet;
}

function AccountFilterByClassSet(classes) {
  const filterByClassSet = new FilterBySelectSet({
    description: 'Filter by Class',
    label: 'Class',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Classes',
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.classIds.some((id) => id === selectedValue));
    },
  });

  classes.forEach((clazz) => (filterByClassSet.filterOptions[clazz.classId] = clazz.className));

  return filterByClassSet;
}

function AccountFilterByRoleSet() {
  const filterByRoleSet = new FilterBySelectSet({
    description: 'Filter by Role',
    label: 'Role',
    defaultValue: '*',
    filterOptions: {
      '*': 'All Roles',
      // Add roles below
    },
    filterCallback: (array, selectedValue) => {
      if (selectedValue === '*') return array;
      return array.filter((element) => element.accountRole === selectedValue);
    },
  });

  Object.values(AccountRole).forEach((role) => {
    if (role !== AccountRole.INVALID && canEditAccountRole(role)) {
      filterByRoleSet.filterOptions[role] = AccountRoleText[role];
    }
  });

  return filterByRoleSet;
}

function AccountFilterByRegistrationStatusSet() {
  const filterByRegistrationStatusSet = new FilterByRadioSet({
    description: 'Filter by Registration Status',
    defaultValue: 'All',
    filterOptions: {
      All: (element) => true,
      Registered: (element) => element.registrationStatus === 'Registered',
      'Invited, Not Registered': (element) => element.registrationStatus === 'Invited',
      'Added, Not Invited': (element) => element.registrationStatus === 'Added',
    },
  });

  return filterByRegistrationStatusSet;
}

function AccountFilterIncludeInactiveSet() {
  return new FilterByCheckboxSet({
    description: 'Inactive Users',
    defaultValue: false,
    filterOptions: {
      option: 'Include Inactive',
    },
    filterCallback: (array, includeInactive) => {
      if (includeInactive) return array;
      return array.filter((element) => element.isActive);
    },
  });
}

export {
  AccountSortBySet,
  AccountFilterByDepartmentSet,
  AccountFilterByClassSet,
  AccountFilterByRoleSet,
  AccountFilterByRegistrationStatusSet,
  AccountFilterIncludeInactiveSet,
};
