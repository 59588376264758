<script>
  import { createEventDispatcher } from 'svelte';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';

  export let dialog = undefined;
  export let account;

  let deleteConfirmation = '';
  const dispatch = createEventDispatcher();

  async function deleteHandler(event) {
    dispatch('DeleteAccountDialog::deleteConfirmed');
    deleteConfirmation = '';
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="delete-account">
  <GigXrDialogContent>
    <GigXrDialogIcon />

    <p>
      <strong>WARNING:</strong>
      You are about to delete user<br /><br />
      <strong>{account.firstName} {account.lastName} ({account.email})</strong><br /><br />
      <strong>THIS CANNOT BE UNDONE.</strong>
    </p>

    <p>They will lose access to GIGXR and the user's email address will be available for use again.</p>

    <p>To proceed, type DELETE and click the DELETE USER button.</p>
    <input type="text" class="gigxr-input" bind:value={deleteConfirmation} />
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={deleteHandler} disabled={deleteConfirmation !== 'DELETE'}>
      Delete User
    </GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
