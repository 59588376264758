<script>
  import { title, errorMessage, breadcrumbPaths } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';

  title.set('404 Not Found');
  breadcrumbPaths.set([]);
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>This page does not exist!</PrimaryContent>
</SecondaryBackgroundWrapper>
