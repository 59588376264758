<script>
  import { snackbarMessage, snackbar, isAuthenticated, errorMessage } from '../../stores/core-store';
  import { WhereConsent, withdrawAllConsent } from '../../util/api/accounts';
  import GigXrDialog from '../gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../gigxr-dialog/GigXrDialogButton.svelte';

  export let dialog = undefined;
  export let account;

  async function withdrawConsentHandler(event) {
    await withdrawAllConsent(account.accountId, WhereConsent.ADMIN);
    snackbarMessage.set('Consent withdrew!');
    $snackbar.open();
  }
</script>

<GigXrDialog bind:dialog ariaPrefix="admin-withdraw-consent">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    You are about to withdraw this user's consent. They will have to agree again before they can use GIGXR.
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={withdrawConsentHandler}>Withdraw Consent</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
</style>
