function createAccountObjectFromPayload(payload) {
  return {
    institutionId: payload.institutionId,
    accountRole: payload.role,
    firstName: payload.firstName,
    lastName: payload.lastName,
    departmentIds: payload.departmentIds,
    classIds: payload.classIds,
  };
}

export { createAccountObjectFromPayload };
