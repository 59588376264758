<script context="module">
  import { AccountRole } from '../../util/api/accounts';
  import AccountRoleSelect from '../../components/accounts/AccountRoleSelect.svelte';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTRUCTOR, AccountRole.DEPARTMENT_ADMIN, AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import {
    role,
    account,
    title,
    errorMessage,
    breadcrumbPaths,
    snackbarMessage,
    snackbar,
  } from '../../stores/core-store';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Textfield from '@smui/textfield';
  import { authenticatedGet, authenticatedPost } from '../../util/api';
  import { getAccountId } from '../../util/account';
  import { navigate } from 'svelte-routing';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../components/ContentRow.svelte';
  import TwoColumnSection from '../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../components/ContentRowCenteredOnMobile.svelte';
  import FilledTextArea from '../../components/FilledTextArea.svelte';
  import DateAccountComponent from '../../components/DateAccountComponent.svelte';
  import { fetchDepartments } from '../../util/api/departments';
  import { fetchInstructors } from '../../util/api/accounts';
  import { setAuthorizedRoles } from '../../util/authorization';
  import { createClass } from '../../util/api/classes';
  import DiscardChangesDialog from '../../components/DiscardChangesDialog.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Create Class');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Class Management',
      location: '/classes/list',
    },
    {
      name: 'Create Class',
      location: `/classes/create`,
    },
  ]);

  let departmentsById = {};
  let instructorsById = {};
  let instructorIdsInSelectedDepartment = [];

  let lastSavedClass = {
    className: '',
    description: '',
    classStatus: 'Active',
    instructorId: null,
    departmentId: '',
  };

  let clazz = { ...lastSavedClass };
  let discardChangesDialog;

  $: if ($role !== AccountRole.INSTRUCTOR) {
    // Only show instructors in the dropdown that belong to the selected department.
    instructorIdsInSelectedDepartment = Object.values(instructorsById)
      .filter(
        (instructor) =>
          instructor.departmentIds.includes(clazz.departmentId) ||
          instructor.accountRole === AccountRole.INSTITUTION_ADMIN,
      )
      .map((instructor) => instructor.accountId);

    // Deselect instructorId if the departmentId is changed to an incompatible choice.
    if (!instructorIdsInSelectedDepartment.includes(clazz.instructorId)) {
      clazz.instructorId = '';
    }
  }

  onMount(async () => {
    let departments;
    let instructors;
    [departments, instructors] = await Promise.all([fetchDepartments(), fetchInstructors()]);
    departments.forEach((d) => (departmentsById[d.departmentId] = d));
    instructors.forEach((i) => (instructorsById[i.accountId] = i));

    if (departments.length === 0) {
      errorMessage.set('You will need to add a department before adding a class.');
    }

    if ($role === AccountRole.INSTRUCTOR) {
      clazz.instructorId = getAccountId();
    }
  });

  async function createClassHandler(event) {
    event.preventDefault();
    const newClass = await createClass(clazz);
    snackbarMessage.set('Class created!');
    $snackbar.open();
    navigate(`/classes/view/${newClass.classId}`);
  }

  let instructorSelectLabel = 'Instructor';
  $: {
    if (!clazz.departmentId) {
      instructorSelectLabel = 'Instructor (Select Department)';
    } else if (instructorIdsInSelectedDepartment.length === 0) {
      instructorSelectLabel = 'No Instructors in That Department';
    } else {
      instructorSelectLabel = 'Instructor';
    }
  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <TwoColumnSection>
      <div slot="left">
        <ContentRow>
          <Textfield
            input$id="class-name-field"
            class="gigxr-input"
            bind:value={clazz.className}
            variant="filled"
            label="Class Name"
          />
        </ContentRow>

        <ContentRow>
          <Select
            inputId="department-field"
            class="gigxr-input"
            bind:value={clazz.departmentId}
            variant="filled"
            label="Department"
            enhanced
          >
            <Option value="" />
            {#if $role === AccountRole.INSTRUCTOR}
              {#each $account.departmentIds as departmentId (departmentId)}
                <Option value={departmentId}>{departmentsById[departmentId]?.departmentName}</Option>
              {/each}
              {#if $account.departmentIds.length === 0}
                <Option disabled on:click={(event) => event.stopPropagation()}>
                  You do not belong to any departments.
                </Option>
              {/if}
            {:else}
              {#each Object.values(departmentsById) as department (department.departmentId)}
                <Option value={department.departmentId}>{department.departmentName}</Option>
              {/each}
            {/if}
          </Select>
        </ContentRow>

        <ContentRow>
          <Select
            inputId="instructor-field"
            class="gigxr-input"
            bind:value={clazz.instructorId}
            variant="filled"
            label={$role === AccountRole.INSTRUCTOR ? 'Instructor' : instructorSelectLabel}
            enhanced
            disabled={!clazz.departmentId || instructorIdsInSelectedDepartment.length === 0 || $role === AccountRole.INSTRUCTOR}
          >
            {#if $role === AccountRole.INSTRUCTOR}
              <Option value={getAccountId()}>{$account.firstName} {$account.lastName}</Option>
            {:else}
              <Option value="" />
              {#each instructorIdsInSelectedDepartment as instructorId (instructorId)}
                <Option value={instructorsById[instructorId].accountId}>
                  {instructorsById[instructorId].firstName}
                  {instructorsById[instructorId].lastName}
                </Option>
              {/each}
            {/if}
          </Select>
        </ContentRow>

        <ContentRow>
          <FilledTextArea
            id="class-description-field"
            bind:value={clazz.description}
            label="Description"
            ariaLabel="class-description"
          />
        </ContentRow>
      </div>

      <div slot="right">
        <ContentRow>
          <Select
            inputId="class-status-field"
            class="gigxr-input"
            bind:value={clazz.classStatus}
            variant="filled"
            label="Status"
            enhanced
          >
            <Option value="Inactive">Inactive</Option>
            <Option value="Active">Active</Option>
          </Select>
        </ContentRow>

        <DateAccountComponent label="Added" utcDateString={clazz.createdOn} account={clazz.createdBy} />

        <ContentRowCenteredOnMobile>
          <Button id="create-class-button" class="gigxr-button" variant="unelevated" on:click={createClassHandler}>
            <Label>Save Changes</Label>
          </Button>
        </ContentRowCenteredOnMobile>

        <ContentRowCenteredOnMobile>
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            id="create-class-discard-changes-link"
            class="gigxr-link"
            on:click={() => discardChangesDialog.open()}
          >Discard Changes</a>
        </ContentRowCenteredOnMobile>
      </div>
    </TwoColumnSection>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => (clazz = { ...lastSavedClass })} />

<style>
</style>
