<script>
  import { Cell } from '@smui/data-table';
  import Checkbox from '@smui/checkbox';
  import { getContext } from 'svelte';

  const showCheckboxes = getContext('ListPageTable:showCheckboxes');

  export let value = '';
</script>

<Cell checkbox class={$showCheckboxes ? '' : 'display-none'}>
  <!-- 
    Warning: `value` on checkbox does *not* work well, do not use it. SMUI does something that makes using it very
    unreliable. Use `data-gms-value` instead.
  -->
  <Checkbox input$class="data-table-checkbox" {value} input$data-gms-value={value} />
</Cell>
