<script>
  let className = '';
  export { className as class };
</script>

<div class="unauthenticated-page__row {className}">
  <slot />
</div>

<style>
  .unauthenticated-page__row {
    margin: 20px 0;
  }
</style>
