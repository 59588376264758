<script>
  import { createEventDispatcher } from 'svelte';
  import Button, { Label as ButtonLabel, Icon as ButtonIcon } from '@smui/button';

  const dispatch = createEventDispatcher();
</script>

<Button id="edit-button" on:click={(event) => dispatch('click', event)} variant="unelevated" class="edit-button">
  <ButtonIcon class="material-icons">edit</ButtonIcon>
  <ButtonLabel>Edit</ButtonLabel>
</Button>

<style>
  :global(.edit-button) {
    height: 45px;
    border-radius: 6px;
  }

  :global(.edit-button) {
    text-transform: uppercase;
    font-size: 16px;
  }

  @media (min-width: 1100px) {
    :global(.edit-button) {
      border-radius: 6px 6px 0 0;
      position: absolute;
      right: 25px;
      margin-bottom: -1em;
      bottom: 0;
    }
  }
</style>
