<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.INSTITUTION_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { title, errorMessage, breadcrumbPaths } from '../../stores/core-store';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import { setAuthorizedRoles } from '../../util/authorization';
  import { fetchLicenses, LicenseTypeText } from '../../util/api/licenses';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import ListPageTable from '../../components/ListPageTable.svelte';
  import Head from '@smui/data-table/Head.svelte';
  import Row from '@smui/data-table/Row.svelte';
  import Cell from '@smui/data-table/Cell.svelte';
  import Body from '@smui/data-table/Body.svelte';
  import { utcStringToLocalDate } from '../../util/dates';
  import LicenseMobileListCard from '../../components/licenses/LicenseMobileListCard.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Licenses');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Licenses',
      location: '/licenses/list',
    },
  ]);

  let loading = true;
  let licenses = [];

  onMount(async () => {
    licenses = await fetchLicenses();
    loading = false;
  });
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else if licenses.length === 0}
      <ListPageNoResultsMessage>
        <h3>There are no licenses to display.</h3>
      </ListPageNoResultsMessage>
    {:else}
      <LicenseMobileListCard license={licenses[0]} />

      <ListPageTable ariaLabel="Licenses" class="licenses-data-table">
        <Head>
          <Row>
            <Cell class="licenses-data-table__license-type-column">License Type</Cell>
            <Cell class="licenses-data-table__licensed-active-users-column mdc-data-table__header-cell--numeric">
              Licensed Active Users
            </Cell>
            <Cell class="licenses-data-table__current-active-users-column mdc-data-table__header-cell--numeric">
              Current Active Users
            </Cell>
            <Cell class="licenses-data-table__expiry-date-column">Expiry Date</Cell>
            <Cell class="licenses-data-table__licensed-apps-column">Licensed Apps</Cell>
          </Row>
        </Head>
        <Body>
          <Row>
            <Cell>{LicenseTypeText[licenses[0].licenseType]}</Cell>
            <Cell numeric>{licenses[0].licensedActiveUsers}</Cell>
            <Cell numeric>
              {#if licenses[0].currentActiveUsers > licenses[0].licensedActiveUsers}
                <span class="usage-warning">{licenses[0].currentActiveUsers}</span>
              {:else}{licenses[0].currentActiveUsers}{/if}
            </Cell>
            <Cell>
              {#if utcStringToLocalDate(licenses[0].expirationDate).getTime() < Date.now()}
                <span class="usage-warning">
                  {utcStringToLocalDate(licenses[0].expirationDate).toLocaleDateString()}
                </span>
              {:else}{utcStringToLocalDate(licenses[0].expirationDate).toLocaleDateString()}{/if}
            </Cell>
            <Cell>
              {#if licenses[0] && licenses[0].clientApps}
                <ul class="simple-list">
                  {#each licenses[0].clientApps as clientApp (clientApp.clientAppId)}
                    <li>{clientApp.clientAppName}</li>
                  {/each}
                </ul>
              {:else}–{/if}
            </Cell>
          </Row>
        </Body>
      </ListPageTable>
    {/if}

    <p class="license-info">
      Please contact GIGXR for more information about your licenses and to renew licenses or add additional seats or
      licenses.
    </p>
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .usage-warning {
    color: var(--gigxr-theme-secondary-4);
    font-weight: 700;
  }

  .license-info {
    margin-top: 2em;
  }

  .simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  :global(.licenses-data-table .mdc-data-table__row:not(.mdc-data-table__row--selected):hover) {
    /* Disable hover effect for rows because these are not clickable */
    background-color: initial;
  }

  :global(.licenses-data-table__license-type-column) {
    width: 20%;
  }

  :global(.licenses-data-table__licensed-active-users-column) {
    width: 20%;
  }

  :global(.licenses-data-table__current-active-users-column) {
    width: 20%;
  }

  :global(.licenses-data-table__licensed-apps-column) {
    width: 20%;
  }

  :global(.licenses-data-table__expiry-date-column) {
    width: 20%;
  }
</style>
