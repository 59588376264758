<script>
  import { errorMessage } from '../stores/core-store';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import IconButton, { Icon } from '@smui/icon-button';
</script>

{#if $errorMessage}
  <Card class="error-banner-wrapper">
    <Content class="error-banner">
      <Icon class="material-icons">error</Icon>
      <span>{$errorMessage}</span>
    </Content>
    <ActionIcons>
      <IconButton
        id="error-banner-close-button"
        class="material-icons error-banner__icon-button"
        on:click={() => errorMessage.set('')}
      >
        close
      </IconButton>
    </ActionIcons>
  </Card>
{/if}

<style>
  :global(.error-banner-wrapper) {
    margin: 1em 0;
    position: relative;
  }

  :global(.error-banner) {
    background: #5c4d4b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 48px;
  }

  :global(.error-banner-wrapper .mdc-card__action-icons) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  :global(.error-banner__icon-button) {
    color: #fff;
  }

  span {
    margin-left: 0.5em;
  }
</style>
