<script context="module">
  import { navigate } from 'svelte-routing';

  let showCheckboxesInternal = false;

  /**
   * This is a custom wrapper around `navigate` that ignores clicks from certain types of elements or classes.
   *
   * If the checkboxes are being displayed, then it switches to a mode where clicking a row will click the row's checkbox.
   */
  export function rowNavigate(event, to) {
    const elementsToIgnore = [HTMLAnchorElement, HTMLButtonElement];
    const classesToIgnore = ['mdc-menu', 'mdc-dialog', 'mdc-checkbox'];

    const propagatedFromIgnoredElement = event
      .composedPath()
      .some(
        (element) =>
          elementsToIgnore.some((ignoredElement) => element instanceof ignoredElement) ||
          classesToIgnore.some((ignoredClass) => element.classList && element.classList.contains(ignoredClass)),
      );

    if (propagatedFromIgnoredElement) {
      return;
    }

    if (showCheckboxesInternal) {
      // Checkboxes are shown, so a click event should click the corresponding row's checkbox.
      const checkbox = event.target.closest('tr').querySelector('input[type="checkbox"]');
      if (checkbox) {
        checkbox.click();
      }

      return;
    }

    // Checkboxes are not shown, so a click event will navigate to the provided link.
    navigate(to);
  }
</script>

<script>
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import { onMount, setContext } from 'svelte';
  import { writable } from 'svelte/store';

  let className = '';
  export { className as class };
  export let ariaLabel = '';

  // The selected values in this data table.
  export let selectedValues = [];

  export let showCheckboxes = false;

  let dataTable;
  let allSelectedCheckbox;

  const showCheckboxesStore = writable(false);
  setContext('ListPageTable:showCheckboxes', showCheckboxesStore);
  $: showCheckboxesStore.set(showCheckboxes);
  $: showCheckboxesInternal = showCheckboxes;

  // This reactive block clears all of the checkboxes when the actions panel is closed.
  $: if (!showCheckboxes) {
    selectedValues = [];
    clearAllCheckboxes();
    if (allSelectedCheckbox) {
      allSelectedCheckbox.checked = false;
      allSelectedCheckbox.indeterminate = false;
    }
  }

  function clearAllCheckboxes() {
    const elements = document.getElementsByClassName('data-table-checkbox');
    for (const element of elements) {
      if (element.checked) {
        // element.checked = false; // This does not work! It will uncheck the box, but the SMUI data table row will be bugged.
        element.click();
      }
    }
  }

  onMount(() => {
    allSelectedCheckbox = document.getElementById('list-page-header-checkbox');

    // On a redraw, restore the state of the checkboxes.
    reapplySelectedValuesToCheckboxes();

    if (allSelectedCheckbox) {
      allSelectedCheckbox.addEventListener('change', (event) => {
        const elements = document.getElementsByClassName('data-table-checkbox');
        const values = [];

        // This nasty hack is needed in order to get the correct value of the selected data-table rows. The SMUI data-table
        // API is broken so we need to get the values manually.
        // Related: https://github.com/hperrin/svelte-material-ui/issues/61
        if (event.isTrusted) {
          // Clicked on the checkbox in the header.
          if (event.target.checked) {
            // Checked, so return all results.
            for (const element of elements) {
              element.checked = true;
              values.push(element.dataset.gmsValue);
            }
          } else {
            // Unchecked, so return no results.
            for (const element of elements) {
              element.checked = false;
            }
          }
        } else {
          // Clicked on a checkbox in a row.
          for (const element of elements) {
            if (element.checked) {
              values.push(element.dataset.gmsValue);
            }
          }
        }

        selectedValues = values;
      });
    }
  });

  function reapplySelectedValuesToCheckboxes() {
    const set = new Set(selectedValues);
    const elements = document.getElementsByClassName('data-table-checkbox');
    // Check checkboxes that should be checked.
    for (const element of elements) {
      if (set.has(element.dataset.gmsValue)) {
        if (!element.checked) {
          // element.checked = true; // This does not work! It will update the checkbox but bug the SMUI data table row.
          element.click();
        }
        set.delete(element.dataset.gmsValue);
      } else {
        if (element.checked) {
          // element.checked = false; // This does not work! It will update the checkbox but bug the SMUI data table row.
          element.click();
        }
      }
    }
    // Remove selections that no longer exist. E.g., if a row was deleted.
    selectedValues = selectedValues.filter((value) => !set.has(value));
  }

  // We need to manually send a change event because SMUI is broken and doesn't have any way to bind to a header row checkbox.
  function simulateCheckboxChangeEvent(event) {
    if (allSelectedCheckbox) {
      allSelectedCheckbox.dispatchEvent(new Event('change'));
    }
  }
</script>

<DataTable
  bind:this={dataTable}
  on:MDCDataTable:rowSelectionChanged={simulateCheckboxChangeEvent}
  class="list-page-table {className}"
  table$aria-label={ariaLabel}
>
  <slot />
</DataTable>

<style>
  :global(.list-page-table) {
    display: none;
    background: #eef2f4;
    border: 0;
    overflow: visible;
    width: 100%;
    /* 30px - cell padding */
    margin-bottom: 14px;
  }

  :global(.list-page-table .mdc-data-table__table) {
    table-layout: fixed;
  }

  :global(.list-page-table .mdc-data-table__cell) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.list-page-table .overflow-visible) {
    overflow: visible;
  }

  :global(.list-page-table .mdc-data-table__row) {
    border-color: #c5d9e7;
  }

  /* :global(.mdc-data-table__cell, .mdc-data-table__header-cell) { */
  /* Disable for now, might need to re-enable later */
  /* padding-left: 0; */
  /* } */

  @media (min-width: 1100px) {
    :global(.list-page-table) {
      display: block;
    }
  }
</style>
