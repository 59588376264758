<script>
  import MobileListCard from '../MobileListCard.svelte';
  import MobileListCardContent from '../MobileListCardContent.svelte';
  import { navigate } from 'svelte-routing';
  import Card, { Content, PrimaryAction, Media, MediaContent, Actions, ActionButtons, ActionIcons } from '@smui/card';
  import DepartmentListItemMenuButton from '../departments/DepartmentListItemMenuButton.svelte';
  import ListPageActiveIndicator from '../ListPageActiveIndicator.svelte';
  import FormattedSearchItem from '../FormattedSearchItem.svelte';

  export let department;
  export let departmentAdminsById = new Map();
  export let match = '';
  export let compact = false;
  export let mobileSelectedValues;

  function cardNavigate(event, to) {
    if (compact) {
      // Checkboxes are shown, so a click event should click the corresponding row's checkbox.
      const set = new Set(mobileSelectedValues);
      if (set.has(department.departmentId)) {
        set.delete(department.departmentId);
      } else {
        set.add(department.departmentId);
      }
      mobileSelectedValues = [...set];

      return;
    }

    // Checkboxes are not shown, so a click event will navigate to the provided link.
    navigate(to);
  }
</script>

<MobileListCard {compact}>
  <MobileListCardContent on:click={(event) => cardNavigate(event, `/departments/view/${department.departmentId}`)}>
    <h2>
      <FormattedSearchItem value={department.departmentName} {match} />
    </h2>

    {#if department.departmentStatus === 'Active'}
      <div class="department-mobile-list-card__active-indicator">
        <ListPageActiveIndicator />
      </div>
    {/if}

    <dl>
      <dt>Dept. Admins:</dt>
      <dd>
        {#each department.departmentAdminIds as id (id)}
          <div
            class="departments__department-admin"
            title={`${departmentAdminsById.get(id).firstName} ${departmentAdminsById.get(id).lastName}`}
          >
            {departmentAdminsById.get(id).firstName}
            {departmentAdminsById.get(id).lastName}
          </div>
        {/each}
      </dd>

      <dt>Classes:</dt>
      <dd>{department.classCount}</dd>

      <dt>Students:</dt>
      <dd>{department.studentCount}</dd>
    </dl>
  </MobileListCardContent>
  <ActionIcons>
    <DepartmentListItemMenuButton departmentId={department.departmentId} />
  </ActionIcons>
</MobileListCard>

<style>
  h2 {
    margin: 0 0 5px;
    text-align: center;
  }

  .department-mobile-list-card__active-indicator {
    width: 100%;
    text-align: center;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin: 11px 0 0 0;
  }

  dt {
    flex: 35%;
    font-weight: 500;
  }

  dd {
    margin-left: 0;
    margin-bottom: 0.2em;
    flex: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .departments__department-admin {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.gigxr-mobile-list-card .mdc-card__action-icons) {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
</style>
