<script context="module">
  import { AccountRole } from '../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [
    AccountRole.INSTRUCTOR,
    AccountRole.DEPARTMENT_ADMIN,
    AccountRole.INSTITUTION_ADMIN,
    AccountRole.GIGXR_ADMIN,
  ];
</script>

<script>
  import { onMount } from 'svelte';
  import DataTable, { Head, Body, Row, Cell } from '@smui/data-table';
  import { title, breadcrumbPaths, mobileView, snackbar, snackbarMessage } from '../../stores/core-store';
  import FilterComponent from '../../components/FilterComponent.svelte';
  import { LIST_VIEW_COUNT_PER_PAGE } from '../../util/constants';
  import DesktopListPageControls from '../../components/DesktopListPageControls.svelte';
  import ListPageCount from '../../components/ListPageCount.svelte';
  import FilterButton from '../../components/FilterButton.svelte';
  import ListPageTable, { rowNavigate } from '../../components/ListPageTable.svelte';
  import MobileListPageControls from '../../components/MobileListPageControls.svelte';
  import AddClassButton from '../../components/classes/AddClassButton.svelte';
  import ClassListItemMenuButton from '../../components/classes/ClassListItemMenuButton.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import ListPageViewMoreButton from '../../components/ListPageViewMoreButton.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import ClassMobileListCard from '../../components/classes/ClassMobileListCard.svelte';
  import { fetchClasses, patchClasses } from '../../util/api/classes';
  import { fetchDepartments } from '../../util/api/departments';
  import { fetchInstructors } from '../../util/api/accounts';
  import { setAuthorizedRoles } from '../../util/authorization';
  import ListPageSearch from '../../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../../components/ListPageMobileSearchButton.svelte';
  import {
    ClassFilterByDepartmentSet,
    ClassFilterByInstructorSet,
    ClassFilterIncludeInactiveSet,
    ClassSortBySet,
  } from '../../util/filters/class-list-filters';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import ListPageActiveIndicator from '../../components/ListPageActiveIndicator.svelte';
  import FormattedSearchItem from '../../components/FormattedSearchItem.svelte';
  import ListPageCardGrid from '../../components/ListPageCardGrid.svelte';
  import ListPageBatchSelect from '../../components/ListPageBatchSelect.svelte';
  import ListPageActionControls from '../../components/ListPageActionControls.svelte';
  import ListPageSelectionText from '../../components/ListPageSelectionText.svelte';
  import ListPageCardGridHeader from '../../components/ListPageCardGridHeader.svelte';
  import ListPageCardGridItem from '../../components/ListPageCardGridItem.svelte';
  import ListPageCardGridCheckbox from '../../components/ListPageCardGridCheckbox.svelte';
  import ListPageHeaderCheckboxCell from '../../components/ListPageHeaderCheckboxCell.svelte';
  import ListPageCheckboxCell from '../../components/ListPageCheckboxCell.svelte';

  setAuthorizedRoles(authorizedRoles);

  title.set('Class Management');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Class Management',
      location: '/classes/list',
    },
  ]);

  let loading = true;
  let showMobileSearch = false;
  let searchQuery = '';
  let classes = [];
  let filteredClasses = [];
  let filteredAndSearchedClasses = [];
  let filterComponent;
  let filterBySets = [];

  // Batch select
  let showBatchActions = false;
  let selectedValues = [];
  let mobileSelectedValues = [];
  let allSelected = false;
  let allSelectedIncludingNotShown = false;
  let listPageActionControlsConfig = {};

  let displayedItems = LIST_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedClasses.length - displayedItems;

  onMount(async () => {
    let departments;
    let instructors;
    [classes, departments, instructors] = await Promise.all([fetchClasses(), fetchDepartments(), fetchInstructors()]);

    filterBySets = [
      ClassFilterByDepartmentSet(departments),
      ClassFilterByInstructorSet(instructors),
      ClassFilterIncludeInactiveSet(),
    ];

    initializeListPageActionControlsConfig();

    loading = false;
  });

  // Desktop and mobile uses two different selection methods, so return whichever one is being used based upon browser width.
  let realSelectedClasses = [];
  $: {
    const realSelectedValues = $mobileView ? mobileSelectedValues : selectedValues;
    const totalCount = filteredAndSearchedClasses.length;
    const shownCount = Math.min(displayedItems, filteredAndSearchedClasses.length);

    if (totalCount > shownCount && realSelectedValues.length > shownCount && allSelectedIncludingNotShown) {
      // All values are selected AND the option to include hidden rows is checked.
      realSelectedClasses = filteredAndSearchedClasses.map((clazz) => clazz.classId);
    } else {
      realSelectedClasses = realSelectedValues;
    }
  }

  // This reactive block will clear the mobile batch selections when it is closed.
  $: if (!showBatchActions) {
    mobileSelectedValues = [];
  }

  async function bulkClassPatch(patchToApply) {
    const patches = [];
    const patchesById = new Map();

    const classIdsToPatch = realSelectedClasses;

    classIdsToPatch.forEach((classId) => {
      const patch = {
        classId,
        ...patchToApply,
      };
      patches.push(patch);
      patchesById.set(classId, patch);
    });

    await patchClasses(patches);

    // Update data on the client side so we don't have to fetch again.
    classes.forEach((clazz) => {
      if (patchesById.has(clazz.classId)) {
        Object.assign(clazz, patchesById.get(clazz.classId));
      }
    });
    filteredAndSearchedClasses = filteredAndSearchedClasses;

    return patches.length;
  }

  function initializeListPageActionControlsConfig() {
    listPageActionControlsConfig = {
      'Make Inactive': {
        callback: async () => {
          const count = await bulkClassPatch({
            classStatus: 'Inactive',
          });
          snackbarMessage.set(`${count} Class${count === 1 ? '' : 'es'} changed to inactive!`);
          $snackbar.open();
        },
      },
      'Make Active': {
        callback: async () => {
          const count = await bulkClassPatch({
            classStatus: 'Active',
          });
          snackbarMessage.set(`${count} Class${count === 1 ? '' : 'es'} changed to active!`);
          $snackbar.open();
        },
      },
    };
  }

  const searchValueFunctions = {
    className: (clazz) => clazz.className,
  };

  // This reactive block controls the header checkbox for mobile.
  $: {
    const headerCheckbox = document.getElementById('checkbox-header');
    if (headerCheckbox) {
      if (mobileSelectedValues.length >= Math.min(displayedItems, filteredAndSearchedClasses.length)) {
        headerCheckbox.checked = true;
        headerCheckbox.indeterminate = false;
      } else if (mobileSelectedValues.length === 0) {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = false;
      } else {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = true;
      }
    }
  }
</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredClasses}
          bind:filteredList={filteredAndSearchedClasses}
          bind:query={searchQuery}
          valueFunctions={searchValueFunctions}
          placeholder="Search classes"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
        <ListPageCount
          {loading}
          count={filteredAndSearchedClasses.length}
          singularLabel="Class"
          pluralLabel="Classes"
        />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddClassButton disabled={showBatchActions} />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} disabled={showBatchActions} />
        <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
        <AddClassButton disabled={showBatchActions} />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount {loading} count={filteredAndSearchedClasses.length} singularLabel="Class" pluralLabel="Classes" />
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
      <ListPageSearch
        unfilteredList={filteredClasses}
        bind:filteredList={filteredAndSearchedClasses}
        bind:query={searchQuery}
        valueFunctions={searchValueFunctions}
        placeholder="Search classes"
        disabled={showBatchActions}
      />
    </div>
    <div slot="right">
      <AddClassButton disabled={showBatchActions} />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={classes}
  bind:filteredList={filteredClasses}
  sortBySet={ClassSortBySet()}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      {#if showBatchActions}
        <ListPageActionControls
          actions={listPageActionControlsConfig}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
        />

        <ListPageSelectionText
          totalCount={filteredAndSearchedClasses.length}
          shownCount={Math.min(displayedItems, filteredAndSearchedClasses.length)}
          selectedCount={$mobileView ? mobileSelectedValues.length : selectedValues.length}
          singularName="Class"
          pluralName="Classes"
          bind:allSelectedIncludingNotShown
        />
      {/if}

      <ListPageCardGrid>
        <ListPageCardGridItem hide={!showBatchActions}>
          <ListPageCardGridHeader
            on:change={(event) => {
              if (event.target.checked) {
                mobileSelectedValues = filteredAndSearchedClasses
                  .slice(0, displayedItems)
                  .map((clazz) => clazz.classId);
              } else {
                mobileSelectedValues = [];
              }
            }}
          />
        </ListPageCardGridItem>
        {#each filteredAndSearchedClasses as clazz, index (clazz.classId + searchQuery)}
          {#if index < displayedItems}
            <ListPageCardGridItem>
              <ListPageCardGridCheckbox
                value={clazz.classId}
                bind:group={mobileSelectedValues}
                show={showBatchActions}
              />
              <ClassMobileListCard {clazz} compact={showBatchActions} bind:mobileSelectedValues />
            </ListPageCardGridItem>
          {/if}
        {/each}
      </ListPageCardGrid>

      {#if filteredAndSearchedClasses.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no classes to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        {#key displayedItems + filteredAndSearchedClasses}
          <ListPageTable
            ariaLabel="Classes"
            class="classes-data-table"
            showCheckboxes={showBatchActions}
            bind:selectedValues
          >
            <Head>
              <Row>
                <ListPageHeaderCheckboxCell bind:value={allSelected} />
                <Cell class="classes-data-table__name-column">Name</Cell>
                <Cell
                  class="classes-data-table__instructor-column {showBatchActions ? 'classes-data-table__instructor-column--batch-select' : ''}"
                >
                  Instructor
                </Cell>
                <Cell
                  class="classes-data-table__department-column {showBatchActions ? 'classes-data-table__department-column--batch-select' : ''}"
                >
                  Department
                </Cell>
                <Cell class="classes-data-table__students-column mdc-data-table__header-cell--numeric">Students</Cell>
                <Cell class="classes-data-table__inactive-column mdc-data-table__header-cell--numeric">Active</Cell>
                <Cell class="classes-data-table__menu-column" />
              </Row>
            </Head>
            <Body>
              {#each filteredAndSearchedClasses as clazz, index (clazz.classId + searchQuery)}
                {#if index < displayedItems}
                  <Row on:click={(event) => rowNavigate(event, `/classes/view/${clazz.classId}`)}>
                    <ListPageCheckboxCell value={clazz.classId} />
                    <Cell title={clazz.className}>
                      <FormattedSearchItem value={clazz.className} match={searchQuery} />
                    </Cell>
                    {#if clazz.instructor}
                      <Cell title={`${clazz.instructor.firstName} ${clazz.instructor.lastName}`}>
                        {clazz.instructor.firstName}
                        {clazz.instructor.lastName}
                      </Cell>
                    {:else}
                      <Cell>–</Cell>
                    {/if}
                    <Cell title={clazz.department.departmentName}>{clazz.department.departmentName}</Cell>
                    <Cell numeric>{clazz.studentCount}</Cell>
                    <Cell numeric>
                      {#if clazz.classStatus === 'Active'}
                        <ListPageActiveIndicator />
                      {:else}Inactive{/if}
                    </Cell>
                    <Cell class="overflow-visible" numeric>
                      <ClassListItemMenuButton {clazz} />
                    </Cell>
                  </Row>
                {/if}
              {/each}
            </Body>
          </ListPageTable>
        {/key}
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Classes"
          {remainingItems}
          on:click={() => (displayedItems += LIST_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  :global(.classes-data-table__name-column) {
    width: 23%;
  }

  :global(.classes-data-table__instructor-column) {
    width: 19%;
  }

  :global(.classes-data-table__instructor-column--batch-select) {
    width: 16%;
  }

  :global(.classes-data-table__department-column) {
    width: 24%;
  }

  :global(.classes-data-table__department-column--batch-select) {
    width: 21%;
  }

  :global(.classes-data-table__students-column) {
    width: 10%;
  }

  :global(.classes-data-table__inactive-column) {
    width: 15%;
  }

  :global(.classes-data-table__menu-column) {
    width: 10%;
  }
</style>
