<script context="module">
  import { AccountRole } from '../../../util/api/accounts';

  /**
   * The roles that are allowed to access this page.
   *
   * @type {[AccountRole]}
   */
  export const authorizedRoles = [AccountRole.GIGXR_ADMIN];
</script>

<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import * as EmailValidator from 'email-validator';
  import Button, { Label } from '@smui/button';
  import Select, { Option } from '@smui/select';
  import Switch from '@smui/switch';
  import Textfield from '@smui/textfield';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import { title, breadcrumbPaths, snackbar, snackbarMessage } from '../../../stores/core-store';
  import PrimaryContent from '../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../components/SecondaryBackgroundWrapper.svelte';
  import ContentRow from '../../../components/ContentRow.svelte';
  import TwoColumnSection from '../../../components/TwoColumnSection.svelte';
  import ContentRowCenteredOnMobile from '../../../components/ContentRowCenteredOnMobile.svelte';
  import { setAuthorizedRoles } from '../../../util/authorization';
  import { deepCopy } from '../../../util/util';
  import { editInstitution, fetchInstitution } from '../../../util/api/institutions';
  import { createLicense, editLicense, fetchLicense, LicenseType, LicenseTypeText } from '../../../util/api/licenses';
  import CountrySelect from '../../../components/CountrySelect.svelte';
  import GigXrDialogIcon from '../../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialog from '../../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogActions from '../../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogContent from '../../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogCancelButton from '../../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import GigXrHorizontalRule from '../../../components/GigXrHorizontalRule.svelte';
  import { GmsError } from '../../../util/errors';
  import { RegistrationStatus, addAccount } from '../../../util/api/accounts';
  import DiscardChangesDialog from '../../../components/DiscardChangesDialog.svelte';
  import GigXrDatepicker from '../../../components/GigXrDatepicker.svelte';
  import { utcStringToLocalDate } from '../../../util/dates';
  import KeyValueRow from '../../../components/KeyValueRow.svelte';
  import { fetchClientApps } from '../../../util/api/client-apps';
  import {
    fetchAppModules,
    fetchInstitutionModules,
    editLicensedModules,
    fetchLicensedAppModules,
  } from '../../../util/api/modules';

  export let licensedClientApp;
  let institutionId = licensedClientApp;
  let licensedClientAppComponents = licensedClientApp.split("_");
  institutionId = licensedClientAppComponents[0];
  let licenseId = licensedClientAppComponents[1];
  let clientAppId = licensedClientAppComponents[2];

  setAuthorizedRoles(authorizedRoles);

  title.set('Edit License Modules');
  breadcrumbPaths.set([
    {
      name: 'System Dashboard',
      location: '/',
    },
    {
      name: 'Institution Details',
      location: `/institutions/view/${institutionId}`,
    },
    {
      name: 'Edit License Modules',
      location: `/licenses/edit/${licensedClientApp}`,
    },
  ]);

  let loading = true;
  let form;
  let dialog;
  let discardChangesDialog;

  let institution = {
    institutionId: '',
    institutionName: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
    },
    address: {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    phoneNumber: {
      number: '',
    },
  };


  let licenseExpirationValue = new Date();
  let license = {
    licenseId: '',
    expirationDate: '',
    licenseType: '',
    licensedActiveUsers: 0,
    institutionId,
    clientAppIds: [],
  };
  let clientApps = [];
  let clientApp = {
    clientAppId: '',
    clientAppName: ''
  }
  let allModules = [];
  let licensedAppModules = []
  let licensedAppModuleIds = []

  onMount(async () => {
    let licenses;
    [institution, license, clientApps, allModules, licensedAppModules] = await Promise.all([
      fetchInstitution(institutionId),
      fetchLicense(licenseId),
      fetchClientApps(),
      fetchAppModules(clientAppId),
      fetchLicensedAppModules(licenseId, clientAppId),
    ]);
    console.log(`fetched institution: ${JSON.stringify(institution)}`);
    console.log(`fetched clientApps: ${JSON.stringify(clientApps)}`);
    console.log(`fetched licensedAppModules: ${JSON.stringify(licensedAppModules)}`);
    console.log(`fetched allModules: ${JSON.stringify(allModules)}`);
    console.log(`looking for clientAppId: ${clientAppId}`);
    for (var i = 0; i < clientApps.length; i++) {
      if (clientApps[i].clientAppId == clientAppId) {
        clientApp = clientApps[i];
      }
    }
    console.log(`found clientApp: ${JSON.stringify(clientApp)}`);
    for (var i = 0; i < licensedAppModules.length; i++) {
      licensedAppModuleIds[i] = licensedAppModules[i].moduleId;
    }
    loading = false;
  });

  async function editLicensedModulesHandler(event) {
    if (event) event.preventDefault();
    console.log(`in editLicensedModulesHandler, institutionModules: ${JSON.stringify(licensedAppModules)}`);
    const [editedLicensedModules] = await Promise.all([
      editLicensedModules(licenseId, clientAppId, licensedAppModuleIds),
    ]);

    snackbarMessage.set('Licensed Modules edited!');
    $snackbar.open();
    navigate(`/institutions/view/${institutionId}`);
  }

  function discardChangesHandler(event) {

  }
</script>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    <form bind:this={form}>
      <TwoColumnSection>
        <div slot="left">
          <ContentRow>
            <Textfield
              input$id="institution-name-field"
              class="gigxr-input"
              bind:value={institution.institutionName}
              variant="filled"
              label="Institution Name"
              input$required
              disabled
            />
          </ContentRow>


          <ContentRow>
            <h4>Modules for {clientApp.clientAppName}:</h4>
          </ContentRow>
          {#if !loading}
            <ContentRow>
              <List class="gigxr-list" checklist>
                {#each allModules as module (module.moduleId)}
                  <Item id="license-module-checkbox-{module.moduleId}">
                    <Graphic>
                      <Checkbox bind:group={licensedAppModuleIds} value={module.moduleId} />
                    </Graphic>
                    <Label>{module.moduleName}</Label>
                  </Item>
                {/each}
              </List>
            </ContentRow>
          {/if}
        </div>

        <div slot="right">

          <ContentRowCenteredOnMobile>
            <Button
              id="edit-institution-save-button"
              class="gigxr-button"
              variant="unelevated"
              on:click={editLicensedModulesHandler}
            >
              <Label>Save Changes</Label>
            </Button>
          </ContentRowCenteredOnMobile>

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            <a
              id="edit-institution-discard-changes-link"
              class="gigxr-link"
              on:click={() => discardChangesDialog.open()}
            >Discard Changes</a>
          </ContentRowCenteredOnMobile>

        </div>
      </TwoColumnSection>
    </form>
  </PrimaryContent>
</SecondaryBackgroundWrapper>


<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={discardChangesHandler} />

<style>
</style>
