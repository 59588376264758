<script>
  import SmuiSelect, { Option as SmuiOption } from '@smui/select';

  export let hourValue = '00';
  export let disabled = false;

  const hourValues = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
</script>

<SmuiSelect
  bind:value={hourValue}
  class="gigxr-hour-timepicker"
  {disabled}
  enhanced
  label="Hour"
  name="hour"
  variant="filled"
>
  {#each hourValues as value (value)}
    <SmuiOption value={Number(value)}>{value}</SmuiOption>
  {/each}
</SmuiSelect>

<style>
  :global(.gigxr-hour-timepicker) {
    width: 88px;
  }
</style>
