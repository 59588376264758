<script>
</script>

<!-- Supress bugged slot warning  -->
<!-- TODO: Remove when this is fixed: https://github.com/sveltejs/svelte/issues/4546 -->
{#if false}
  <slot />
{/if}

<div class="mobile-list-page-controls">
  <div class="mobile-list-page-controls__top">
    <slot name="top" />
  </div>

  <div class="mobile-list-page-controls__bottom">
    <slot name="left" />
    <slot name="right" />
  </div>
</div>

<style>
  .mobile-list-page-controls__bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
  }

  :global(.mobile-list-page-controls__bottom div[slot='left']) {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  /* First-level children */
  :global(.mobile-list-page-controls__bottom div[slot='left'] > *) {
    margin-right: 10px;
  }

  :global(.mobile-list-page-controls__bottom div[slot='right']) {
    display: flex;
    align-items: center;
  }

  /* First-level children */
  :global(.mobile-list-page-controls__bottom div[slot='right'] > *) {
    margin-left: 10px;
  }

  @media (min-width: 1100px) {
    .mobile-list-page-controls {
      display: none;
    }
  }
</style>
