<script>
  import { escapeRegExp } from '../util/util';

  export let value = '';
  export let match = '';

  let start = '';
  let middle = '';
  let end = '';

  (() => {
    const regExp = new RegExp(escapeRegExp(match), 'i');
    const matchResult = value.match(regExp);
    if (!matchResult) {
      start = value;
      middle = '';
      end = '';
      return;
    }

    start = value.slice(0, matchResult.index);
    middle = value.slice(matchResult.index, matchResult.index + matchResult[0].length);
    end = value.slice(matchResult.index + matchResult[0].length, value.length);
  })();
</script>

<span>
  {start}{#if middle}<strong>{middle}</strong>{/if}{end}
</span>

<style>
</style>
