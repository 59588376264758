<script>
  import Select from '@smui/select';
  import { Countries } from '../util/countries';

  export let value;
</script>

<!-- https://www.textfixer.com/tutorials/dropdowns/country-dropdown-iso-html.txt -->
<Select
  inputId="country-field"
  class="gigxr-input"
  variant="filled"
  label="Country"
  bind:value
  required
  invalid={false}
>
  <!-- Using regular <option> elements for two reasons:  -->
  <!-- 1. The "enhanced" list does not support looking up entries via typing on the keyboard. -->
  <!-- 2. Using <Option> is stripping out the "value" for some reason. -->
  <option value="" />

  {#each Object.keys(Countries) as countryKey (countryKey)}
    <option value={countryKey}>{Countries[countryKey]}</option>
  {/each}
</Select>

<style>
  /* As of v1.0.0-beta.21, adding required to a select does not add an astrix like it does for
   other elements. */
  :global(.mdc-select__native-control:required ~ .mdc-floating-label:after) {
    margin-left: 1px;
    content: '*';
  }
</style>
