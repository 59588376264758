<script>
  import Button, { Label } from '@smui/button';
  import { institutionMasquerade, isMasquerading } from '../stores/core-store';
  import { navigate } from 'svelte-routing';
  import GigXrDialog from './gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from './gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from './gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from './gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from './gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from './gigxr-dialog/GigXrDialogButton.svelte';
  import { stopMasquerading } from '../util/masquerade';

  let dialog;

  $: institutionName = $institutionMasquerade ? $institutionMasquerade.institutionName : 'Institution';

  function logoutAdminHandler(event) {
    stopMasquerading();
    window.close();
    // Re-enable these if we masquerading is changed to open in the same tab.
    // institutionMasquerade.set(null);
    // navigate('/', { replace: true });
  }
</script>

{#if $isMasquerading}
  <div class="masquerade-header">
    <div class="masquerade-header__left masquerade-header__left--desktop">Viewing as {institutionName} Admin</div>
    <div class="masquerade-header__left masquerade-header__left--mobile">Admin View</div>
    <div class="masquerade-header__right">
      <Button id="logout-as-admin-button" on:click={() => dialog.open()}>
        <Label>Logout as Admin</Label>
      </Button>
    </div>
  </div>
{/if}

<GigXrDialog bind:dialog ariaPrefix="logout-admin">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Logout as Admin and return to GIGXR Dashboard?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={logoutAdminHandler}>Logout as Admin</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<style>
  .masquerade-header {
    align-items: center;
    background: var(--gigxr-theme-secondary-4);
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 5px 5px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 12;
  }

  .masquerade-header__left {
    padding-left: 8px;
  }

  .masquerade-header__left--desktop {
    display: none;
  }

  .masquerade-header :global(.mdc-button__label) {
    color: #fff;
  }

  @media (min-width: 1100px) {
    .masquerade-header__left--mobile {
      display: none;
    }

    .masquerade-header__left--desktop {
      display: block;
    }
  }
</style>
