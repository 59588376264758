<script>
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';
  import { canEditClass } from '../../util/api/classes';

  export let clazz;

  let menu;
  let open;

  function toggleMenu(event) {
    menu.setOpen(!open);
  }
</script>

<IconButton id="class-menu-button-{clazz.classId}" class="material-icons" on:click={toggleMenu}>more_vert</IconButton>

<Menu bind:open bind:this={menu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
  <List>
    <Item id="class-menu-view-{clazz.classId}" on:SMUI:action={() => navigate(`/classes/view/${clazz.classId}`)}>
      <Text>View</Text>
    </Item>
    {#if canEditClass(clazz)}
      <Item id="class-menu-view-{clazz.classId}" on:SMUI:action={() => navigate(`/classes/edit/${clazz.classId}`)}>
        <Text>Edit</Text>
      </Item>
    {/if}
  </List>
</Menu>

<style>
</style>
