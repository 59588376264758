import Cookies from 'js-cookie';
import { b64DecodeUnicode } from './util';

function getJwtPayload(token) {
  try {
    return JSON.parse(b64DecodeUnicode(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

function getJwt() {
  return Cookies.get('jwt');
}

export { getJwtPayload, getJwt };
