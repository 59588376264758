<script>
  import Button, { Label as ButtonLabel } from '@smui/button';
  import { removeNotification } from '../../util/api/notifications';
  import NotificationBanner from '../NotificationBanner.svelte';

  export let notifications = [];

  async function clickHandler(event, notification) {
    notifications = notifications.filter((n) => n !== notification);
    await removeNotification(notification.notificationId);
  }
</script>

<ul>
  {#if notifications.length > 0}
    {#each notifications as notification (notification.notificationId)}
      <li>
        <NotificationBanner
          textBody={notification.textBody}
          url={notification.url}
          read={notification.read}
          on:GigXr:closeNotification={(event) => clickHandler(event, notification)}
        />
      </li>
    {/each}
  {/if}
</ul>

<style>
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    margin-bottom: 1em;
  }

  li:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1100px) {
    ul {
      margin: 1em 0;
    }
  }
</style>
