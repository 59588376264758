import { authenticatedGet, authenticatedPatch, authenticatedPost, authenticatedPut } from '../api';
import { GmsError, RedirectError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { errorMessage, institutionIdStore } from '../../stores/core-store';
import { get } from 'svelte/store';

async function fetchDepartments() {
  const response = await authenticatedGet('/departments');
  if (!response.ok) {
    throw new GmsError(`Error fetching departments! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchDepartment(departmentId) {
  const response = await authenticatedGet(`/departments/${departmentId}`);

  if (response.status === 404) {
    throw new RedirectError('Department not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching department! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function createDepartment(department) {
  const validInput =
    department &&
    department.departmentName &&
    Array.isArray(department.departmentAdminIds) &&
    department.departmentStatus;

  if (!validInput) {
    throw new GmsError('Department name is required!');
  }

  const requestPayload = {
    ...department,
    institutionId: get(institutionIdStore),
  };

  const response = await authenticatedPost('/departments', requestPayload);

  if (response.status === 409) {
    throw new GmsError('Department name already exists!');
  }

  if (response.status !== 201) {
    throw new GmsError(`Error creating department! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newDepartment = json.data;

  return newDepartment;
}

async function editDepartment(department) {
  const validInput =
    department &&
    department.departmentName &&
    Array.isArray(department.departmentAdminIds) &&
    department.departmentStatus;

  if (!validInput) {
    throw new GmsError('Department name is required!');
  }

  const requestPayload = {
    ...department,
  };

  const response = await authenticatedPut(`/departments/${department.departmentId}`, requestPayload);

  if (response.status !== 200) {
    throw new GmsError(`Error editing department! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedDepartment = json.data;

  return editedDepartment;
}

async function patchDepartments(patches) {
  const requestPayload = [...patches];

  const response = await authenticatedPatch(`/departments`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error patching departments! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

export { fetchDepartments, fetchDepartment, createDepartment, editDepartment, patchDepartments };
