import {
  apiGet,
  apiPost,
  authenticatedDelete,
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
  authenticatedPatch,
  clearJsonWebTokenRefreshTimer,
} from '../api';
import { institutionIdStore, role, errorMessage } from '../../stores/core-store';
import { Logger } from '../logs';
import { GmsError, RedirectError } from '../errors';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { get } from 'svelte/store';
import * as EmailValidator from 'email-validator';
import { getClientAppId, isPlatform, isUnity } from '../unity';

/**
 * AccountLeafView
 * @typedef {Object} AccountLeafView
 * @property {string} accountId
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {AccountRole} accountRole
 * @property {boolean} isActive
 * @property {string} lastActive
 * @property {RegistrationStatus} registrationStatus
 */

/**
 * AccountBasicView
 * @typedef {Object} AccountBasicView
 * @property {string} accountId
 * @property {string} firstName
 * @property {string} lastName
 */

/** @enum {string} */
const AccountRole = {
  INVALID: 'Invalid',
  STUDENT: 'Student',
  INSTRUCTOR: 'Instructor',
  DEPARTMENT_ADMIN: 'DepartmentAdmin',
  INSTITUTION_ADMIN: 'InstitutionAdmin',
  GIGXR_ADMIN: 'GigXrAdmin',
};

const AccountRoleValue = {
  Invalid: 0,
  Student: 1,
  Instructor: 2,
  DepartmentAdmin: 3,
  InstitutionAdmin: 4,
  GigXrAdmin: 5,
};

const AccountRoleText = {
  Invalid: '–',
  Student: 'Student',
  Instructor: 'Instructor',
  DepartmentAdmin: 'Department Admin',
  InstitutionAdmin: 'Institution Admin',
  GigXrAdmin: 'GIGXR Admin',
};

/** @enum {string} */
const RegistrationStatus = {
  INVALID: 'Invalid',
  REGISTERED: 'Registered',
  INVITED: 'Invited',
  ADDED: 'Added',
};

/**
 * AccountUploadRequest
 * @typedef {Object} AccountUploadRequest
 * @property {string} accountUploadRequestId
 * @property {boolean} processed
 * @property {[AccountUploadDto]} accountUploadDtos
 */

/**
 * AccountUploadDto
 * @typedef {Object} AccountUploadDto
 * @property {AccountUploadDtoStatus} accountUploadDtoStatus
 * @property {[AccountUploadDtoError]} accountUploadDtoErrors
 * @property {[AccountUploadDtoUpdate]} accountUploadDtoUpdates
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} departmentName
 * @property {string} className
 * @property {AccountRole} accountRole
 * @property {string} activeOrInactive
 */

/** @enum {string} */
const AccountUploadDtoStatus = {
  UNPROCESSED: 'Unprocessed',
  NEEDS_MODIFICATION: 'NeedsModification',
  READY_FOR_IMPORT: 'ReadyForImport',
  READY_FOR_UPDATE: 'ReadyForUpdate',
  NO_UPDATE: 'NoUpdate',
  ADDED: 'Added',
  INVITED: 'Invited',
  UPDATED: 'Updated',
};

/** @enum {string} */
const AccountUploadDtoError = {
  FIRST_NAME_EMPTY: 'FirstNameEmpty',
  LAST_NAME_EMPTY: 'LastNameEmpty',

  EMAIL_EMPTY: 'EmailEmpty',
  EMAIL_INVALID: 'EmailInvalid',
  EMAIL_DUPLICATE: 'EmailDuplicate',
  EMAIL_TAKEN: 'EmailTaken',

  ROLE_INVALID: 'RoleInvalid',
  ROLE_FORBID: 'RoleForbid',

  DEPARTMENT_EMPTY: 'DepartmentEmpty',
  DEPARTMENT_NOT_FOUND: 'DepartmentNotFound',
  DEPARTMENT_FORBID: 'DepartmentForbid',

  CLASS_NOT_FOUND: 'ClassNotFound',
  CLASS_FORBID: 'ClassForbid',

  ACTIVE_INACTIVE_EMPTY: 'ActiveInactiveEmpty',
  ACTIVE_INACTIVE_INVALID: 'ActiveInactiveInvalid',
};

/** @enum {string} */
const AccountUploadDtoUpdate = {
  ACTIVE_OR_INACTIVE: 'ActiveOrInactive',
};

/**
 * Can the currently authenticated account add an account with the specified role?
 *
 * @param {AccountRole} targetRole
 * @returns {boolean}
 */
function canAddAccountRole(targetRole) {
  let myRole = AccountRole.INVALID;
  role.subscribe((value) => (myRole = value));
  switch (myRole) {
    case AccountRole.INSTRUCTOR:
      return targetRole === AccountRole.STUDENT;

    case AccountRole.DEPARTMENT_ADMIN:
      return AccountRoleValue[targetRole] <= AccountRoleValue[AccountRole.INSTRUCTOR];

    case AccountRole.INSTITUTION_ADMIN:
      return AccountRoleValue[targetRole] <= AccountRoleValue[AccountRole.INSTITUTION_ADMIN];

    case AccountRole.GIGXR_ADMIN:
      return targetRole === AccountRole.INSTITUTION_ADMIN;

    case AccountRole.INVALID:
    case AccountRole.STUDENT:
    default:
      return false;
  }
}

/**
 * Can the currently authenticated account edit an account with the specified role?
 *
 * @param {AccountRole} targetRole
 * @return {boolean}
 */
function canEditAccountRole(targetRole) {
  let myRole = AccountRole.INVALID;
  role.subscribe((value) => (myRole = value));
  return myRole === targetRole || canAddAccountRole(targetRole);
}

function canEditAccount(account) {
  return canEditAccountRole(account.accountRole);
}

const ConsentLocations = {
  Invalid: '–',
  SignUp: 'Sign Up',
  Profile: 'Profile',
  Admin: 'Admin',
};

const WhereConsent = {
  SIGN_UP: 'SignUp',
  PROFILE: 'Profile',
  ADMIN: 'Admin',
};

const ConsentTypeHtml = {
  Terms:
    '<a id="terms-of-use-link" href="/terms-of-use" target="_blank">Terms of Use</a>, <a id="privacy-policy-link" href="/privacy-policy" target="_blank">Privacy Policy</a>',
  Email: 'Notification Emails',
};

function validatePassword(password) {
  if (password.length < 8) {
    throw new GmsError('Your password must be at least 8 characters long. Please try again.');
  }

  if (!password.match(/[A-Z]+/g)) {
    throw new GmsError('Your password must contain at least one capital letter. Please try again.');
  }

  if (!password.match(/[0-9]+/g)) {
    throw new GmsError('Your password must contain at least one number. Please try again.');
  }
}

async function authenticate({ email, password, validDuration = '1:00:00' }) {
  const body = {
    email,
    password,
    validDuration,
  };

  if (isUnity()) {
    // In unity, identify based upon the passed in clientAppId
    const clientAppId = getClientAppId();
    if (clientAppId) {
      body.clientAppId = clientAppId;
    } else {
      // Legacy, assume HoloPatient.
      // TODO: This can be removed when legacy GIG Mobile is updated to also pass in a clientAppId.
      body.clientAppId = '0d9a8cc4-413f-4876-8e90-c1ad44b822e6';
    }
  }

  console.log(`in account.js, about to post to accounts/login`)
  const response = await apiPost('/accounts/login', body);

  if (response.headers.has('X-AccountInactive-GMS')) {
    throw new RedirectError(
      'Your account is inactive. Contact your administrator for more details.',
      '/account-inactive',
    );
  } else if (response.status !== 200) {
    throw new GmsError('Your email and password combination is not valid. Please try again.');
  }

  const json = await response.json();

  const validJson = json && json.data && json.data.jsonWebToken;
  if (!validJson) {
    throw new GmsError(`Error logging in. ${GENERIC_ERROR_MESSAGE}`);
  }

  return json;
}

async function logout() {
  try {
    clearJsonWebTokenRefreshTimer();
    const response = await authenticatedPost('/accounts/logout');
  } catch (error) {
    // Do nothing. Even if the JWT does not get invalidated on the server we are still destroying it client-side.
  }
}

async function fetchAccounts() {
  const response = await authenticatedGet('/accounts');
  if (response.status !== 200) {
    throw new GmsError(`Error fetching users! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

/**
 * @param departmentId An optional departmentId to filter by.
 * @return {Promise<[Account]>}
 */
async function fetchStudents({ departmentId = null, classId = null } = {}) {
  let path = '/accounts/students';

  const params = new URLSearchParams();
  if (departmentId) {
    params.set('departmentId', departmentId);
  }

  if (classId) {
    params.set('classId', classId);
  }

  if (Array.from(params).length > 0) {
    path = `${path}?${params.toString()}`;
  }

  const response = await authenticatedGet(path);
  if (!response.ok) {
    throw new GmsError(`Error fetching students! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchStudentByEmail(email) {
  const response = await authenticatedGet(`/accounts/students/${email}`);
  if (!response.ok) {
    return null;
  }

  const json = await response.json();

  return json.data;
}

async function fetchInstructors() {
  const response = await authenticatedGet('/accounts/instructors');
  if (!response.ok) {
    throw new GmsError(`Error fetching instructors! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchDepartmentAdmins() {
  const response = await authenticatedGet('/accounts/department-admins');
  if (!response.ok) {
    throw new GmsError(`Error fetching department admins! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function fetchAccount(accountId) {
  const response = await authenticatedGet(`/accounts/${accountId}`);
  if (response.status === 404) {
    throw new RedirectError('User not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching user! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function addAccount(account) {
  const validInput =
    account &&
    account.firstName &&
    account.firstName.length > 0 &&
    account.lastName &&
    account.lastName.length > 0 &&
    account.email &&
    account.email.length > 0 &&
    Array.isArray(account.departmentIds) &&
    Array.isArray(account.classIds);
  // TODO: more validation

  if (!validInput) {
    throw new GmsError('All user fields are required!');
  }

  if (!EmailValidator.validate(account.email)) {
    throw new GmsError('Invalid email address!');
  }

  const requestPayload = {
    ...account,
    institutionId: account.institutionId ? account.institutionId : get(institutionIdStore),
  };

  const response = await authenticatedPost('/accounts', requestPayload);

  if (response.status === 409) {
    throw new GmsError('Email address is taken!');
  }

  if (response.status !== 201) {
    throw new GmsError(`Error creating user! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newAccount = json.data;

  return newAccount;
}

async function editAccount(account) {
  const validInput =
    account &&
    account.firstName &&
    account.firstName.length > 0 &&
    account.lastName &&
    account.lastName.length > 0 &&
    account.email &&
    account.email.length > 0 &&
    Array.isArray(account.departmentIds) &&
    Array.isArray(account.classIds);
  // TODO: more validation

  if (!validInput) {
    throw new GmsError('All user fields are required!');
  }

  if (!EmailValidator.validate(account.email)) {
    throw new GmsError('Invalid email address!');
  }

  account.firstName = account.firstName.trim();
  account.lastName = account.lastName.trim();
  account.email = account.email.trim();
  const requestPayload = {
    ...account,
  };

  const response = await authenticatedPut(`/accounts/${account.accountId}`, requestPayload);

  if (response.status === 409) {
    throw new GmsError('Email address is taken!');
  }

  if (response.status !== 200) {
    throw new GmsError(`Error editing user! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedAccount = json.data;

  return editedAccount;
}

async function patchAccounts(patches) {
  const requestPayload = [...patches];

  const response = await authenticatedPatch(`/accounts`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error patching users! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function deleteAccounts(accountIds) {
  const response = await authenticatedDelete(`/accounts?accountIds=${accountIds.join(',')}`);

  if (!response.ok) {
    throw new GmsError(`Error deleting users! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function requestPasswordReset(email) {
  const requestPayload = {
    email,
  };

  const response = await apiPost('/accounts/password/email', requestPayload);

  if (!response.ok) {
    throw new GmsError(`There was a problem requesting a password reset. ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function resetPasswordByToken(password, token) {
  const requestPayload = {
    password,
    token,
  };

  const response = await apiPost('/accounts/password/token', requestPayload);

  if (!response.ok) {
    throw new GmsError(`Invalid token! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function fetchConsentDisplayInformation(token) {
  const response = await apiGet(`/accounts/consent-information/${token}`);
  if (!response.ok) {
    throw new GmsError(`Invalid token! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function updateConsentRecords(token, whereConsented, consentRecords) {
  if (!token) {
    throw new GmsError(`Invalid token! ${GENERIC_ERROR_MESSAGE}`);
  }

  if (!consentRecords || consentRecords.length === 0) {
    throw new GmsError(`No consent records were provided! ${GENERIC_ERROR_MESSAGE}`);
  }

  const requestPayload = {
    whereConsented,
    consentRecords,
  };

  Logger.log('Update consent payload: ', requestPayload);
  const response = await apiPost(`/accounts/consent-information/${token}`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Error saving consent! ${GENERIC_ERROR_MESSAGE}`);
  }

  return response.headers.get('X-SecretToken-GMS');
}

async function fetchRegistrationInformation(token) {
  const response = await apiGet(`/accounts/registration/${token}`);
  if (!response.ok) {
    throw new GmsError(`Invalid token! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();

  return json.data;
}

async function completeRegistrationByToken(token, account) {
  const requestPayload = {
    firstName: account.firstName,
    lastName: account.lastName,
    password: account.password,
  };

  const response = await apiPost(`/accounts/registration/${token}`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Invalid token! ${GENERIC_ERROR_MESSAGE}`);
  }

  return response.headers.get('X-SecretToken-GMS');
}

async function resendRegistrationInviteEmail(accountId) {
  const response = await authenticatedPost(`/accounts/email/invitation/${accountId}`);
  if (!response.ok) {
    throw new GmsError(`Error sending invite! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function sendGdprInformationRequest(accountId) {
  const response = await authenticatedPost(`/accounts/email/information-request/${accountId}`);
  if (!response.ok) {
    throw new GmsError(`Error requesting personal information! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function sendGdprDeleteRequest(accountId) {
  const response = await authenticatedPost(`/accounts/email/deletion/${accountId}`);
  if (!response.ok) {
    throw new GmsError(`Error requesting deletion! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function sendAppStoreEmail(secretToken) {
  const response = await apiPost(`/accounts/email/app-store/${secretToken}`);
  if (!response.ok) {
    throw new GmsError(`Error sending app store email!`);
  }

  return Promise.resolve();
}

async function sendAppStoreAndSessionEmail(sessionId) {
  const response = await authenticatedPost(`/accounts/email/app-store-and-session/${sessionId}`);
  if (!response.ok) {
    throw new GmsError(`Error sending email!`);
  }

  return Promise.resolve();
}

async function fetchConsentRecords(accountId) {
  const response = await authenticatedGet(`/accounts/consent-records/${accountId}`);
  if (response.status === 404) {
    throw new RedirectError('Consent records not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching consent records! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();

  return json.data;
}

async function fetchConsents() {
  const response = await authenticatedGet('/accounts/consents');
  if (!response.ok) {
    throw new GmsError(`Error fetching consents! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();

  return json.data;
}

async function withdrawAllConsent(accountId, whereConsented) {
  const consents = await fetchConsents();

  const consentRecords = consents.map((consent) => {
    return {
      consentId: consent.consentId,
      consentValue: false,
    };
  });

  const requestPayload = {
    whereConsented,
    consentRecords,
  };

  const response = await authenticatedPost(`/accounts/consent-records/${accountId}`, requestPayload);
  if (!response.ok) {
    throw new GmsError(`Error withdrawing consent! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function deleteAccount(accountId) {
  const response = await authenticatedDelete(`/accounts/${accountId}`);
  if (!response.ok) {
    throw new GmsError(`Error deleting account! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function gdprDeleteAccount(accountId) {
  const response = await authenticatedDelete(`/accounts/${accountId}?gdpr=true`);
  if (!response.ok) {
    throw new GmsError(`Error deleting account! ${GENERIC_ERROR_MESSAGE}`);
  }

  return Promise.resolve();
}

async function createAccountUploadRequest(csvFile) {
  const requestPayload = new FormData();
  requestPayload.append('csvFile', csvFile);

  const headers = {
    Accept: 'application/json',
  };

  const response = await authenticatedPost('/accounts/upload', requestPayload, headers);
  if (!response.ok) {
    throw new GmsError(`Error uploading CSV! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchAccountUploadRequest(accountUploadRequestId) {
  const response = await authenticatedGet(`/accounts/upload/${accountUploadRequestId}`);
  if (!response.ok) {
    throw new GmsError(`Error fetching account upload request! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function createAccountsFromUploadRequest(accountUploadRequestId) {
  const response = await authenticatedPost(`/accounts/upload/${accountUploadRequestId}`);
  if (!response.ok) {
    throw new GmsError(`Error creating accounts! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function sendInvitesForUploadAccountRequest(accountUploadRequestId) {
  const response = await authenticatedPost(`/accounts/upload/${accountUploadRequestId}/invitations`);
  if (!response.ok) {
    throw new GmsError(`Error sending invites! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchLoginQrCode(payload = null) {
  const response = await authenticatedPost('/accounts/qr', {
    callbackPayload: payload,
  });
  const json = await response.json();

  return json.data.qrCode;
}

export {
  AccountRole,
  AccountRoleValue,
  AccountRoleText,
  RegistrationStatus,
  AccountUploadDtoStatus,
  AccountUploadDtoError,
  AccountUploadDtoUpdate,
  canAddAccountRole,
  canEditAccountRole,
  canEditAccount,
  ConsentLocations,
  WhereConsent,
  ConsentTypeHtml,
  validatePassword,
  authenticate,
  logout,
  fetchAccounts,
  fetchStudents,
  fetchStudentByEmail,
  fetchInstructors,
  fetchDepartmentAdmins,
  fetchAccount,
  addAccount,
  editAccount,
  patchAccounts,
  deleteAccounts,
  requestPasswordReset,
  resetPasswordByToken,
  fetchConsentDisplayInformation,
  updateConsentRecords,
  fetchRegistrationInformation,
  completeRegistrationByToken,
  resendRegistrationInviteEmail,
  sendGdprInformationRequest,
  sendGdprDeleteRequest,
  sendAppStoreEmail,
  sendAppStoreAndSessionEmail,
  fetchConsentRecords,
  fetchConsents,
  withdrawAllConsent,
  deleteAccount,
  gdprDeleteAccount,
  createAccountUploadRequest,
  fetchAccountUploadRequest,
  createAccountsFromUploadRequest,
  sendInvitesForUploadAccountRequest,
  fetchLoginQrCode,
};
