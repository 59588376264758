<script>
  import { errorMessage } from '../../stores/core-store';
</script>

{#if $errorMessage}
  <div class="unauthenticated-page__error-banner unauthenticated-page__error-banner--active">{$errorMessage}</div>
{:else}
  <div class="unauthenticated-page__error-banner unauthenticated-page__error-banner--placeholder">––––</div>
{/if}

<style>
  .unauthenticated-page__error-banner {
    padding: 11px 20px;
    margin: 1px;
    color: var(--gigxr-theme-primary-1c);
  }

  .unauthenticated-page__error-banner--active {
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--gigxr-theme-secondary-4);
    border: 1px solid var(--gigxr-theme-secondary-4);
  }

  .unauthenticated-page__error-banner--placeholder {
    font-family: sans-serif;
  }
</style>
