<script>
  import Menu from '@smui/menu';
  import List, { Item, Separator, Text } from '@smui/list';
  import IconButton from '@smui/icon-button';
  import { navigate } from 'svelte-routing';

  export let departmentId;

  let menu;
  let open;

  function toggleMenu(event) {
    menu.setOpen(!open);
  }
</script>

<IconButton id="department-menu-button-{departmentId}" class="material-icons" on:click={toggleMenu}>
  more_vert
</IconButton>

<Menu bind:open bind:this={menu} anchorCorner="BOTTOM_LEFT" class="gigxr-menu">
  <List>
    <Item id="department-menu-view-{departmentId}" on:SMUI:action={() => navigate(`/departments/view/${departmentId}`)}>
      <Text>View</Text>
    </Item>
    <Item id="department-menu-edit-{departmentId}" on:SMUI:action={() => navigate(`/departments/edit/${departmentId}`)}>
      <Text>Edit</Text>
    </Item>
  </List>
</Menu>

<style>
</style>
