<script>
  import { link } from 'svelte-routing';
  import { breadcrumbPaths } from '../stores/core-store';
  import { NON_BREAKING_SPACE } from '../util/constants';

  // Example $breadcrumbPaths
  // const paths = [
  //   {
  //     name: 'Dashboard',
  //     location: '/',
  //   },
  //   {
  //     name: 'Session List',
  //     location: '/sessions/list',
  //   },
  // ];
</script>

{#if $breadcrumbPaths && $breadcrumbPaths.length > 1}
  <div class="breadcrumb-nav-mobile">
    <a id="mobile-breadcrumb" href={$breadcrumbPaths[$breadcrumbPaths.length - 2].location} use:link>
      {`< ${NON_BREAKING_SPACE}`}{$breadcrumbPaths[$breadcrumbPaths.length - 1].name}
    </a>
  </div>
{/if}

{#if $breadcrumbPaths && $breadcrumbPaths.length > 1}
  <ol class="breadcrumb-nav-desktop">
    {#each $breadcrumbPaths as path, index (path.name)}
      <li class="breadcrumb-nav-desktop__item">
        <a
          id="desktop-breadcrumb-{index}"
          class="breadcrumb-nav-desktop__link"
          href={path.location}
          use:link
        >{path.name}</a>
      </li>
    {/each}
  </ol>
{/if}

<style>
  .breadcrumb-nav-mobile {
    text-align: left;
    margin-bottom: 1em;
  }

  .breadcrumb-nav-desktop {
    display: none;
  }

  a {
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    .breadcrumb-nav-mobile {
      display: none;
    }

    .breadcrumb-nav-desktop {
      display: block;
      text-align: left;
      list-style-type: none;
      margin: 0.5em 0 0 0;
      padding: 0;
    }

    .breadcrumb-nav-desktop__item {
      display: inline;
    }

    li:not(:first-child):before {
      content: ' - ';
      margin: 0 0.2em;
    }

    .breadcrumb-nav-desktop__item:last-child a {
      font-weight: 400;
      pointer-events: none;
    }
  }
</style>
