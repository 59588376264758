<script>
  export let hide = false;
</script>

<div class="list-page-card-grid-item {hide ? 'list-page-card-grid-item--hide' : ''}">
  <slot />
</div>

<style>
  .list-page-card-grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-page-card-grid-item--hide {
    display: none;
  }
</style>
