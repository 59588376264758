import { authenticatedGet, authenticatedPost, authenticatedPut } from '../api';
import { GENERIC_ERROR_MESSAGE } from '../constants';
import { GmsError, RedirectError } from '../errors';
import { deepCopy } from '../util';
import { errorMessage } from '../../stores/core-store';

/**
 * InstitutionDetailedView
 * @typedef {Object} InstitutionDetailedView
 * @property {string} institutionId
 * @property {string} institutionName
 * @property {string} contactId
 * @property {AccountLeafView} contact
 * @property {string} addressId
 * @property {AddressView} address
 * @property {string} phoneNumberId
 * @property {PhoneNumberView} phoneNumber
 * @property {string} createdOn
 * @property {string} createdById
 * @property {AccountBasicView} createdBy
 * @property {string} modifiedOn
 * @property {string} modifiedById
 * @property {AccountBasicView} modifiedBy
 */

/**
 * InstitutionListView
 * @typedef {Object} InstitutionListView
 * @property {string} institutionId
 * @property {string} institutionName
 * @property {string} contactId
 * @property {AccountLeafView} contact
 * @property {string} addressId
 * @property {AddressView} address
 * @property {string} phoneNumberId
 * @property {PhoneNumberView} phoneNumber
 * @property {number} accountCount
 * @property {string} createdOn
 * @property {string} modifiedOn
 */

/**
 * AddressView
 * @typedef {Object} AddressView
 * @property {string} addressId
 * @property {string} address1
 * @property {string} address2
 * @property {string} address3
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} postalCode
 */

/**
 * PhoneNumberView
 * @typedef {Object} PhoneNumberView
 * @property {string} phoneNumberId
 * @property {string} number
 */

async function fetchInstitutions() {
  const response = await authenticatedGet('/institutions');
  if (!response.ok) {
    throw new GmsError(`Error fetching institutions! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchInstitution(institutionId) {
  const response = await authenticatedGet(`/institutions/${institutionId}`);

  if (response.status === 404) {
    throw new RedirectError('Institution not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching institution! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function fetchInstitutionLeaf(institutionId) {
  const response = await authenticatedGet(`/institutions/${institutionId}/leaf`);

  if (response.status === 404) {
    throw new RedirectError('Institution not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching institution! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function createInstitution(institution) {
  const requestPayload = deepCopy(institution);
  const response = await authenticatedPost('/institutions', requestPayload);

  if (response.status !== 201) {
    throw new GmsError(`Error creating institution! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const newInstitution = json.data;

  return newInstitution;
}

async function editInstitution(institution) {
  const requestPayload = deepCopy(institution);
  const response = await authenticatedPut(`/institutions/${institution.institutionId}`, requestPayload);

  if (response.status !== 200) {
    throw new GmsError(`Error editing institution! ${GENERIC_ERROR_MESSAGE}`);
  }

  errorMessage.set('');
  const json = await response.json();
  const editedInstitution = json.data;

  return editedInstitution;
}

async function fetchInstitutionConfig(institutionId) {
  const response = await authenticatedGet(`/institutions/${institutionId}/config`);

  if (response.status === 404) {
    throw new RedirectError('Institution config not found!', '/not-found');
  }

  if (!response.ok) {
    throw new GmsError(`Error fetching institution config! ${GENERIC_ERROR_MESSAGE}`);
  }
  const json = await response.json();
  return json.data;
}

async function editInstitutionConfig(institutionConfig) {
  const requestPayload = deepCopy(institutionConfig);
  const response = await authenticatedPut(`/institutions/${institutionConfig.institutionId}/config`, requestPayload);

  if (!response.ok) {
    throw new GmsError(`Error editing institution config! ${GENERIC_ERROR_MESSAGE}`);
  }

  const json = await response.json();
  const editedInstitutionConfig = json.data;

  return editedInstitutionConfig;
}

export {
  fetchInstitutions,
  fetchInstitution,
  fetchInstitutionLeaf,
  createInstitution,
  editInstitution,
  fetchInstitutionConfig,
  editInstitutionConfig,
};
